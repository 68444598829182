import { Fragment } from "react";
import { customDateFormatter } from "../../../../components/tools";
import ancestoryLinesFormatter from "./ancestoryLinesFormatter";
import longestAncestoryLineDecider from "./longestAncestoryLineDecider";
import findRetryIterationCount from "./findRetryIterationCount";
import originTrxCountDecider from "./originTrxCountDecider";
import initialFailedTrxCountDecider from "./initialFailedTrxCountDecider";
import { booleanState } from "../../../../components/Status";
import retriedCountDecider from "./retriedCountDecider";
import retriedTrxCountDecider from "./retriedTrxCountDecider";

const retryUpdatesFormatter = ({
  state = "",
  updatedAt = new Date(),
  transactions = [],
  statusHistories = [],
  releaser = {},
}) => {
  const { isFailed, isPartialFailed, isCompleted } = booleanState(state);

  const ancestoryLines = ancestoryLinesFormatter(transactions);

  const longestAncestoryLine = longestAncestoryLineDecider(ancestoryLines);

  const releaserName = releaser?.name || releaser?.email || "-";

  const firstUpdate = () => {
    const initialFailedDate =
      statusHistories?.find(({ to }) => {
        const isInitialFailed = to?.includes("fail");
        return isInitialFailed;
      })?.created_at ?? new Date();

    const originTrxCount = originTrxCountDecider(transactions);
    const initialFailedTrxCount = initialFailedTrxCountDecider(transactions);

    const wasPartiallyFailed = initialFailedTrxCount < originTrxCount;

    if (wasPartiallyFailed) {
      return {
        text: `${initialFailedTrxCount} of ${originTrxCount} transactions failed`,
        isActive: true,
        isPartialFailed: true,
        date: customDateFormatter(initialFailedDate),
        reason: "Something's gone wrong. Contact us for details.",
      };
    }

    return {
      text: "Transaction failed",
      isActive: true,
      isFailed: true,
      date: customDateFormatter(initialFailedDate),
      reason: "Something's gone wrong. Contact us for details.",
    };
  };

  const retryUpdates = longestAncestoryLine.map((item, index) => {
    const { updated_at } = item ?? {};
    const isLast = index === longestAncestoryLine.length - 1;
    const isOddIndex = (index + 1) % 2 !== 0;

    if (isLast && !isOddIndex) return null;

    const retryIteration = longestAncestoryLine.length - index;
    const totalTrxCount = findRetryIterationCount({
      ancestoryLines,
      desiredAncestoryLineLength: retryIteration,
    });
    const failedTrxCount = findRetryIterationCount({
      ancestoryLines,
      desiredAncestoryLineLength: retryIteration + 1,
    });

    const isPartialFailed = failedTrxCount < totalTrxCount;

    if (isOddIndex) {
      const latestFailedHistoryIndex =
        [...statusHistories]?.reverse()?.findIndex(({ to }) => {
          const isLatestFailed = to?.includes("fail");
          return isLatestFailed;
        }) +
        (statusHistories?.length - 1);

      const retriedDate =
        longestAncestoryLine[index + 1]?.updated_at ??
        statusHistories[latestFailedHistoryIndex]?.created_at ??
        longestAncestoryLine[longestAncestoryLine.length - 1]?.created_at;

      return {
        isCustomText: true,
        text: (
          <Fragment>
            <span style={{ fontFamily: "GothamMedium" }}>{releaserName}</span>{" "}
            retried the failed transaction(s)
          </Fragment>
        ),
        isActive: true,
        isRetry: true,
        date: customDateFormatter(retriedDate),
      };
    }

    const isAlreadyReachedMaxRetryAttempt = index > 1;

    if (isPartialFailed) {
      return {
        text: `${failedTrxCount} of ${totalTrxCount} transactions failed`,
        isActive: true,
        isPartialFailed: true,
        date: customDateFormatter(updated_at),
        reason: isAlreadyReachedMaxRetryAttempt
          ? "Something's gone wrong. Please your AM for further assistance."
          : "Something's gone wrong. Contact us for details.",
      };
    }

    return {
      text: "Transaction failed",
      isActive: true,
      isFailed: true,
      date: customDateFormatter(updated_at),
      reason: isAlreadyReachedMaxRetryAttempt
        ? "Something's gone wrong. Please your AM for further assistance."
        : "Something's gone wrong. Contact us for details.",
    };
  });

  const retriedTrxCount = retriedTrxCountDecider(transactions);
  const endOfUpdateFailedTrxCount = findRetryIterationCount({
    isFindLastIndex: true,
    isOnlyFindFailedTrx: true,
    ancestoryLines: ancestoryLinesFormatter(transactions),
    desiredAncestoryLineLength: retriedCountDecider(transactions) + 1,
  });
  const isSameFailedCount = retriedTrxCount === endOfUpdateFailedTrxCount;

  const endOfUpdate = () => {
    const endOfUpdateText = () => {
      if (isFailed) return "Transaction failed";
      if (isCompleted) return "Transaction completed!";
      if (isPartialFailed) {
        if (isSameFailedCount) return "Transactions failed";
        return `${endOfUpdateFailedTrxCount} of ${retriedTrxCount} transactions failed`;
      }
      return "Sending money to recipient(s)";
    };

    const showFinalDate = isFailed || isPartialFailed || isCompleted;

    const payload = {
      isCustomText: true,
      text: endOfUpdateText(),
      isActive: true,
      isFailed: isFailed || (isPartialFailed && isSameFailedCount),
      isPartialFailed: isPartialFailed && !isSameFailedCount,
      isCompleted,
      date: showFinalDate && customDateFormatter(updatedAt),
    };

    if (isFailed || isPartialFailed)
      payload.reason = "Something's gone wrong. Contact us for details";

    return payload;
  };

  return [firstUpdate(), ...retryUpdates, endOfUpdate()];
};

export default retryUpdatesFormatter;
