import React from "react";
import { SpanMedium } from "./components";

export const useBooleanTransferService = (service) => {
  const isBiFast = service == "bifast";
  const isOptimized = service == "optimized_route";
  const isOnline = service == "online";
  const isSkn = service == "skn";
  const isRtgs = service == "rtgs";

  return { isBiFast, isOptimized, isOnline, isSkn, isRtgs };
};

export const getTotalTrxCalculateFee = ({ dataHandlingFee: data }) => {
  let totalTrxCount = 0;

  for (let key in data) {
    if (
      [
        "invoice",
        "local",
        "payroll",
        "reimbursement",
        "to_danamon",
        "to_wallet",
      ].includes(key)
    ) {
      // Add the total_transactions value to the total
      totalTrxCount += data[key].total_transactions;
    }
  }

  const { max_free, total_free } = data || {};
  const { max_free_all_transactions } = max_free || {};
  const { total_free_all_transactions } = total_free || {};

  const remainingRaw =
    +max_free_all_transactions - +total_free_all_transactions;
  const remaining = remainingRaw < 0 ? 0 : remainingRaw;
  const quota = max_free_all_transactions;
  const used = quota - remaining;
  const outOfQuota = remaining <= 0;
  const partialFree = remaining < totalTrxCount && remaining > 0;

  return { totalTrxCount, quota, remaining, partialFree, outOfQuota, used };
};

export const OptionRulesFunc = ({ option: optionRaw }) => {
  const { transaction_flag, danamon_services: option } = optionRaw;

  const ObjFilterFunc = (key) => {
    for (let i = 0; i < option?.length; i++) {
      const obj = option[i];
      if (key in obj) {
        const newObj = {
          ...obj[key],
          product: key,
        };
        return newObj;
      }
    }
    return null;
  };

  return { ObjFilterFunc, option, transaction_flag };
};

export const UnavailableTextDecider = ({
  obj,
  transaction_flag,
  isMultiRelease,
}) => {
  const { available, maintenance, amount_exceed, working_hours, product } =
    obj || {};
  const { is_non_partner } = transaction_flag || {};

  const { isBiFast, isOnline, isSkn, isRtgs } =
    useBooleanTransferService(product);

  const AmountExceedDecider = (amount) => {
    if (isRtgs)
      return (
        <span>
          {isMultiRelease
            ? "Some transaction(s) don't"
            : "This transaction doesn't"}{" "}
          reach <SpanMedium> IDR {amount}</SpanMedium>.
        </span>
      );
    return (
      <span>
        {isMultiRelease
          ? "Some transaction(s) exceed"
          : "This transaction exceeds"}
        <SpanMedium> IDR {amount}</SpanMedium>.
      </span>
    );
  };

  if (!available || is_non_partner)
    return "Recipient bank(s) not supported by this service.";
  if (amount_exceed) {
    if (isRtgs) return AmountExceedDecider("100M");
    if (isBiFast) return AmountExceedDecider("250M");
    if (isOnline) return AmountExceedDecider("50M");
    if (isSkn) return AmountExceedDecider("1B");
    return `Max amount has been exceed.`;
  }
  if (!working_hours) return "This service is outside its service hours.";
  if (maintenance) return "Service is under maintenance.";
};
