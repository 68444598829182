export const canBeAccessedAllTheTimePathnames = [
  "/",
  "/home",
  "/login-demo",
  "/login",
  "/register",
  "/new-register",
  "/create-password",
  "/account-details",
  "/verification",
  "/settings",
  // "/dev-tools",
  "/local-transfer",
  "/local-disbursement",
  "/kyb",
  "/account/invite-users",
  // "/cards",
  "/old-activity",
  // "/workflow",
  // "/e-wallet",
  "/task",
  "/history",
  "/success",
  "/dashboard",
  "/transactions",
  "/kyb-jack",
  "/alerts-and-attention",
  "/people-management",
  "/invitation-request",
  "/bank-account",
  "/draft",
  "/settings-jack",
  "/reset-pin",
  "/change-password",
  "/email-redirect",
  // "/plans",
  "/plans/showcase",
  "/terms-and-conditions",
  "/tnc-login",
  "/tnc-subscription",
  "/category",
  // "/calendar",
  "/payment-cycle",

  "/notifications",
  "/jack-transfer",
  // "/move-balance",
  // "/va-pocket",
  // "/primary-bank-account",
  "/my-activity",
  "/company-transaction",
  "/api-transaction",
];

export const activitiesORTransactionsPathnames = ["/home", "/history"];

export const createTransactionPathnames = ["/cross-border"];

export const VAPathnames = ["/virtual-account", "/collection-report"];

export const accountPathnames = ["/account"];

export const settingPathnames = [
  "/menu-api",
  "/settings",
  "/workflow",
  "/customize-receipt",
];
