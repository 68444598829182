import { useEffect } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Banner } from "../../Banner";
import { GothamRegular } from "../../Text";
import { fileSizeString, windowDimension } from "../../tools";
import { useModalHook } from "../../Modals";

const FileItem = ({ file, enablePreview, handleDelete, onClickShow }) => {
  const { name, size } = file;
  const sizeLabel = fileSizeString(size);
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        padding: 12,
        border: `1px solid ${jackColors.greyE6}`,
        borderRadius: 4,
        marginBottom: 8,
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => enablePreview && onClickShow(file)}
    >
      <div className="d-flex align-items-center">
        <JackIcons
          name="Uploaded_File"
          fill={jackColors.green06}
          style={{ marginRight: 8 }}
        />
        <div>
          <GothamRegular
            className="font12"
            style={{
              color: jackColors.black34,
              width: 250,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {name}
          </GothamRegular>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.grey90 }}
          >
            {sizeLabel}
          </GothamRegular>
        </div>
      </div>
      <div className="hover" onClick={() => handleDelete(index)}>
        <JackIcons name="close" fill={jackColors.grey90} />
      </div>
    </div>
  );
};

export const Files = ({
  files,
  multiple,
  name,
  setValue,
  containerMaxHeight,
  customError,
  enablePreview = false,
  handleShow,
  inputRef,
}) => {
  const handleDelete = (index) => {
    const filtered = files.filter((_, number) => number !== index);
    const isUndefined = !filtered.length;

    if (inputRef?.current) inputRef.current.value = "";
    const result = isUndefined ? "" : filtered;

    setValue(name, result);
  };

  const onClickShow = (index) => handleShow(index);
  const { isTabOrPhone } = windowDimension();

  if (!multiple) return null;
  if (!files.length) return null;

  return (
    <>
      <div style={{ marginBottom: 8, marginTop: 4 }}>
        {customError && <Banner type="error" msg={customError} />}
      </div>
      <GothamRegular
        style={{ marginBottom: 8, marginTop: customError ? 0 : 16 }}
      >
        {files.length} file(s) uploaded
      </GothamRegular>
      <div
        style={{
          maxHeight: containerMaxHeight,
          overflow: containerMaxHeight && "auto",
          marginTop: customError && 8,
        }}
      >
        {files.map((file, index) => {
          const { name, size } = file;
          const sizeLabel = fileSizeString(size);
          return (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between"
              style={{
                padding: 12,
                border: `1px solid ${jackColors.greyE6}`,
                borderRadius: 4,
                marginBottom: 8,
                width: "100%",
                cursor: "pointer",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                enablePreview && onClickShow(index);
              }}
            >
              <div
                className="d-flex align-items-center"
                style={{
                  width: "80%",
                }}
              >
                <JackIcons
                  name="Uploaded_File"
                  fill={jackColors.green06}
                  style={{ marginRight: 8 }}
                />
                <div className="d-flex-column">
                  <GothamRegular
                    className="font12"
                    style={{
                      color: jackColors.black34,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: 250,
                    }}
                  >
                    {name}
                  </GothamRegular>
                  {!!size && !isTabOrPhone && (
                    <GothamRegular
                      className="font12"
                      style={{ color: jackColors.grey90 }}
                    >
                      {sizeLabel}
                    </GothamRegular>
                  )}
                </div>
              </div>
              <div
                className="darkhover"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return handleDelete(index);
                }}
              >
                <JackIcons name="close" fill={jackColors.grey90} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const SpanError = ({ isError, children }) => (
  <span style={{ color: isError ? jackColors.redE7 : "" }}>{children}</span>
);
