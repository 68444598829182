import React, { useEffect } from "react";
import validateGif from "../../../assets/images/validating-local.gif";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { jackColors } from "../../../assets/colors";
import { useHeader } from "../../../contexts/Layout/parent";
import { ProgressBarJack } from "../../../components/Stepper";
import { stepArrayLocalFunc } from "./parent";
import { useModalHook } from "../../../components/Modals";
import { ModalConfirmationCloseLocal } from "./modal";
import { stepArrayFunc } from "../../payrollCreateComponents/loop/validation/parent";

export const LoadingScreenImport = ({ isPayroll = false }) => {
  const { setHeader } = useHeader();
  const { isOpen: isOpenClose, toggle: toggleClose } = useModalHook();

  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => toggleClose(),
      middleComponent: (
        <ProgressBarJack
          arrayStep={isPayroll ? stepArrayFunc(1) : stepArrayLocalFunc(1)}
        />
      ),
    });
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center flex-column"
      style={{ marginTop: 140 }}
    >
      <img src={validateGif} className="mb-0" style={{ width: 240 }} />
      <GothamMedium className="font20 mb-3">
        Processing your data...
      </GothamMedium>
      <div style={{ maxWidth: 438 }}>
        <GothamRegular
          style={{ color: jackColors.neutral700, textAlign: "center" }}
        >
          It may take a while. Please keep this page open until we finish
          processing.
        </GothamRegular>
      </div>
      <ModalConfirmationCloseLocal
        toggle={toggleClose}
        isOpen={isOpenClose}
        product="local"
        customText={
          <>
            You can always visit{" "}
            <span style={{ fontFamily: "GothamMedium" }}>Draft</span> to edit
            and submit this request.
          </>
        }
      />
    </div>
  );
};
