import { jackColors } from "../../assets/colors";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../components/Text";
import DownloadTalentaTemplate from "../../public/images/guide-modal-download-talenta-payroll.svg";
import UploadTalentaTemplate from "../../public/images/guide-modal-upload-talenta-payroll.svg";

export const TextArr = ({ arr, customStyles, onClick }) => {
  return arr.map((string, index) => {
    const isOdd = index % 2;
    if (isOdd)
      return (
        <TextInlineMedium
          className={`${
            customStyles?.className ? customStyles?.className : `font14`
          }`}
          style={{ color: jackColors.neutral900, ...customStyles }}
          onClick={() => onClick && onClick(0)}
        >
          {string}
        </TextInlineMedium>
      );

    return string;
  });
};
export const detailSecondStep = [
  "Employee ID",
  "Full Name",
  "Job Position",
  "Take Home Pay",
  "Bank Account",
  "Bank Name",
  <TextArr
    arr={["Email ", "(Optional)"]}
    customStyles={{ color: jackColors.neutral700, className: "font12" }}
  />,
];

export const detailFirstStep = [
  "Log in to the Talenta dashboard",
  <TextArr arr={["Select ", `"Payroll"`, " menu"]} />,
  <TextArr arr={["Choose ", `"View Reports"`, " menu"]} />,
  <TextArr
    arr={[
      "On the Report page, under ",
      `"Salary Report”`,
      ", click ",
      `"Salary Detail”`,
    ]}
  />,
  <TextArr arr={["Click ", `“Additional Data”`]} />,
  <TextArr
    arr={[
      "Select ",
      `“Bank Account”`,
      ",",
      `“Bank Account
  Holder”`,
      ",",
      `“Bank Name”`,
      ", and ",
      `“Email”`,
      `. Then click `,
      `“Filter”`,
    ]}
  />,

  "Ensure all payroll data is correct",
  ,
  <TextArr arr={["Click ", `“Download XLS”`]} />,
  "The file is ready to be uploaded to Jack Payroll",
  ,
];

export const detailPointSecondStep = (onChange) => {
  return [
    <TextArr arr={["Ensure the file is in ", `XLSX`, ` format`]} />,
    <TextArr
      arr={[
        "Ensure the file uploaded to Jack Payroll is correct. You can verify this by following ",
        `this guide`,
      ]}
      customStyles={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={onChange}
    />,
    "Make sure these fields are included and correctly filled in: ",
  ];
};

export const contentModalCustomPayroll = (onChange) => {
  const detailPointSecondStepVar = detailPointSecondStep(onChange);
  return [
    {
      title: "How to download Talenta Salary Detail",
      img: DownloadTalentaTemplate,
      desctext: (
        <>
          <GothamRegular
            className="font14"
            style={{ color: jackColors.neutral900, textAlign: "justify" }}
          >
            Jack now allows direct payroll data imports from other HRIS or
            payment gateways.
          </GothamRegular>
          <GothamRegular
            className="font14"
            style={{
              color: jackColors.neutral900,
              margin: "20px 0px",
              textAlign: "justify",
              marginRight: 20,
            }}
          >
            Download the Salary Detail from your Talenta dashboard, upload it to
            Jack Payroll, and let our system automatically handle the data
            extraction. This means no more manual data entry for you.
          </GothamRegular>
          <GothamMedium
            className="font14"
            style={{
              color: jackColors.neutral900,
              textAlign: "justify",
              marginRight: 20,
              marginBottom: 10,
            }}
          >
            You can follow these easy steps to download the Salary Detail report
            from your Talenta dashboard:
          </GothamMedium>
          {detailFirstStep.map((string, index) => {
            return (
              <div className="d-flex flex-row" style={{ marginBottom: 12 }}>
                <div
                  style={{
                    marginRight: 9,

                    paddingLeft: 4,
                  }}
                >
                  <GothamRegular>{index + 1}.</GothamRegular>
                </div>
                <div>
                  <GothamRegular>{string}</GothamRegular>
                </div>
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "Upload the downloaded file to Jack Payroll",
      img: UploadTalentaTemplate,
      desctext: (
        <>
          <GothamMedium
            className="font14"
            style={{
              color: jackColors.neutral900,
              textAlign: "justify",
              marginBottom: 20,
            }}
          >
            Here's what you need to check before uploading the Talenta Salary
            Detail:
          </GothamMedium>
          {detailPointSecondStepVar.map((string, index) => {
            return (
              <div
                className="d-flex flex-row"
                style={{
                  marginBottom:
                    index == detailPointSecondStep.length - 1 ? 8 : 12,
                }}
              >
                <div
                  style={{
                    marginRight: 9,
                    paddingLeft: 6,
                  }}
                >
                  <GothamRegular>{index + 1}.</GothamRegular>
                </div>
                <div>
                  <GothamRegular>{string}</GothamRegular>
                </div>
              </div>
            );
          })}
          <ol
            style={{ paddingLeft: 20, listStyleType: "disc", marginLeft: 30 }}
          >
            {detailSecondStep.map((string, index) => {
              return (
                <li key={index} style={{ marginBottom: 8 }}>
                  <GothamMedium>{string}</GothamMedium>
                </li>
              );
            })}
          </ol>
        </>
      ),
    },
  ];
};
