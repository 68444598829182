import { Modal } from "reactstrap";
import styles from "../../styles.module.css";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { jackColors } from "../../../../assets/colors";
import UpgradeContent from "./UpgradeContent";
import { Fragment, useState } from "react";
import Requesting from "./Requesting";
import { useMutation } from "../../../../tools/api";
import DowngradeContent from "./DowngradeContent";
import { useRouter } from "next/router";

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button className={styles["close-btn"]} onClick={onClick}>
      <JackIcons name="close-outline" fill={jackColors.neutral900} />
    </button>
  );
};

const RequestModal = ({
  isOpen = false,
  preventRedirect = false,
  type = "",
  redirectUrl = "",
  toggle = () => {},
  onFinish = () => {},
}) => {
  const [isRequesting, setIsRequesting] = useState(false);

  const { pathname, query } = useRouter();

  const moduleQueryDecider = () => {
    const isInvoice = pathname.includes("invoice");
    const isReimbursement = pathname.includes("reimbursement");
    const isCard = query.module === "card";
    const isUser = query.module === "user";

    if (isInvoice) return "?module=invoice";
    if (isReimbursement) return "?module=reimbursement";
    if (isCard) return "?module=card";
    if (isUser) return "?module=user";

    return "";
  };

  const moduleQuery = moduleQueryDecider();

  const { mutation: mutationUpgrade, loading: loadingUpgrade } = useMutation({
    url: "/subscription_module/upgrade_request" + moduleQuery,
    method: "post",
    afterSuccess: () => {
      setTimeout(() => {
        onFinish();
        setIsRequesting(false);
      }, 2000);
    },
    handleError: () => {
      toggle();
      setIsRequesting(false);
    },
  });

  const contentProps = {
    type,
    redirectUrl,
    preventRedirect,
    toggle,
    mutationUpgrade,
    setIsRequesting,
  };

  const contentDecider = () => {
    switch (type) {
      case "upgrade":
      case "upgrade-admin":
      case "upgrade-custom":
      case "upgrade-custom-admin":
        return <UpgradeContent {...contentProps} />;

      case "downgrade":
      case "downgrade-admin":
        return <DowngradeContent {...contentProps} />;

      default:
        return null;
    }
  };

  const content = contentDecider();

  const showRequestLoading = loadingUpgrade || isRequesting;

  return (
    <Fragment>
      <Modal
        centered
        hidden={showRequestLoading}
        isOpen={isOpen}
        toggle={toggle}
        style={{ width: "480px" }}
      >
        <div className={styles["request-modal"]}>
          <CloseButton onClick={toggle} />
          {content}
        </div>
      </Modal>
      <Requesting isShown={showRequestLoading} />
    </Fragment>
  );
};

export default RequestModal;
