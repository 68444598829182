import { useForm } from "@tools";
import { useRef } from "react";
import { colors } from "../../assets/colors";
import dots from "../../assets/images/3dots-hori-new.svg";
import { fileSize } from "../../modals/smartActivityModal/invoicePayment/supportingDocs";
import { ElipsisTextHover } from "../../pageComponents/invoiceComponents/table";
import { useClickOutside } from "../../universalFunctions/useClickOutside";
import { CustomButton } from "../Buttons";
import { CustomTextField } from "../inputs";
import { useModalHook } from "../Modals";
import { GothamMedium, GothamRegular } from "../Text";

const ImgOrPdf = ({ type }) => {
  const isImage = String(type).includes("image");
  if (isImage) return <img src={"/images/jpg.svg"} />;
  return <img src={"/images/pdf.svg"} />;
};

const SelectionItem = ({ src, text, style, className = "", onClick }) => (
  <div
    className={`d-flex darkhover ${className}`}
    onClick={() => onClick && onClick()}
  >
    <img src={src} className="mr-2 pb-1" style={{ width: 20 }} />
    <GothamMedium style={style}>{text}</GothamMedium>
  </div>
);

const DotsSelection = ({ onClick, array }) => {
  const { isOpen, toggle, close } = useModalHook();
  const ref = useRef();

  useClickOutside({ ref, clickOutside: close });

  const defaultOnClick = (type) => {
    toggle();
    onClick && onClick(type);
  };

  if (!array.length) return null;

  return (
    <div style={{ position: "relative" }} ref={ref}>
      <img
        src={dots}
        className="darkhover"
        style={{ height: 20 }}
        onClick={toggle}
      />
      {isOpen && (
        <div
          style={{
            position: "absolute",
            right: 20,
            top: 0,
            zIndex: 4,
            backgroundColor: "white",
            border: `1px solid ${colors.greye6}`,
            padding: 8,
            borderRadius: 8,
          }}
        >
          {array.map((item, index) => {
            if (!item) return null;
            const { src, label, value, isPink } = item || {};
            const isLast = index == array.length - 1;
            return (
              <SelectionItem
                key={index}
                src={src}
                text={label}
                onClick={() => defaultOnClick(value)}
                className={!isLast && "mb-2"}
                style={{
                  color: isPink ? colors.pink : "",
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const ListItem = ({ file, onClick, dotsArray }) => {
  const { isOpen: isEdit, toggle } = useModalHook();
  const { name, type, size } = file || {};
  const nameWoFormat = String(name).split(".")[0];
  const useFormObj = useForm({ defaultValues: { file_name: nameWoFormat } });
  const { handleSubmit, errors } = useFormObj;

  if (isEdit)
    return (
      <div className="d-flex align-items-center w-100 justify-content-between">
        <ImgOrPdf type={type} />
        <CustomTextField
          name="file_name"
          useFormObj={useFormObj}
          containerStyle={{ marginBottom: 0, width: 271 - 24 }}
          error={errors["file_name"]}
        />
        <CustomButton
          onClick={handleSubmit((values) => {
            const { file_name } = values;
            onClick({ file_name, file }, "edit_submit");
            toggle();
          })}
          style={{ paddingLeft: 16, paddingRight: 16, height: 32 }}
        >
          Update
        </CustomButton>
      </div>
    );

  return (
    <>
      <div className="d-flex" style={{ maxWidth: "90%" }}>
        <ImgOrPdf type={type} />
        <div
          style={{
            marginLeft: 12,
          }}
        >
          {/* <GothamRegular>{name}</GothamRegular> */}
          <ElipsisTextHover length={50}>{name}</ElipsisTextHover>
          <GothamRegular>{fileSize(size)}</GothamRegular>
        </div>
      </div>
      <DotsSelection
        onClick={(type) => {
          if (type == "edit") return toggle();
          onClick && onClick(file, type);
        }}
        array={dotsArray}
      />
    </>
  );
};

export const FileInputList = ({ value, onClick, dotsArray }) =>
  (value || []).map((file, index) => (
    <div
      key={index}
      style={{
        border: `1px solid ${colors.greye6}`,
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 8,
        marginBottom: 8,
      }}
      className="d-flex justify-content-between align-items-center"
    >
      <ListItem file={file} onClick={onClick} dotsArray={dotsArray} />
    </div>
  ));
