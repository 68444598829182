import { Fragment } from "react";
import { Banner } from "../../../../../components/Banner";

const MissingCategoryBanner = ({ count = 0 }) => {
  if (!count || count <= 0) return null;

  const isPlural = count > 1;

  const trxText = isPlural ? "transactions" : "transaction";
  const beText = isPlural ? "are" : "is";

  return (
    <Banner
      type="warning"
      bannerStyle={{ marginBottom: "20px" }}
      msg={
        <Fragment>
          There {beText}{" "}
          <span style={{ fontFamily: "GothamMedium" }}>
            {count} {trxText}
          </span>{" "}
          with no category or missing description. You can still export, but
          this data will be left empty.
        </Fragment>
      }
    />
  );
};

export default MissingCategoryBanner;
