import React from "react";
import { windowDimension } from "../../../components/tools";
import { useRouter } from "next/router";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { jackColors } from "../../../assets/colors";
import emptyImage from "../../../assets/images/empty-state-va.svg";
import emptySearchImage from "../../../assets/images/empty-state-search.svg";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { TransactionsFilters } from "../../transactionsComponents/transactions/filters/parent";
import { getUserRole } from "../../../contexts/AuthContext";

const EmptyList = ({
  menu = "index",
  noButton = false,
  ignoreQuery = false,
  doubleSubtext = false,
  filterComponent = <></>,
  customImage = emptyImage,
}) => {
  const { height } = windowDimension();
  const { query, push } = useRouter();
  const isActive = menu === "active" || menu === "api";
  const isDetails = menu === "details";
  const isArchived = menu === "archived";
  const isCreate = menu === "create";

  const componentHeight = isActive
    ? height - (96 + 80 + 32 + 32 + 14)
    : height - 564;
  const { menu: place, runQuery, id, searchbox, ...rest } = query;
  const isButtonFiltered = ignoreQuery
    ? false
    : !!Object.values(rest).filter((item) => item).length;

  const isSearched = !!searchbox;

  const isFiltered = isButtonFiltered || isSearched;

  const showCreateButton = !noButton && !isFiltered; // original: admin only

  const titleDecider = () => {
    let title;
    let msg;

    if (isSearched) {
      title = "We can’t find what you’re looking for.";
      msg = "Let’s try another keyword.";
    }
    if (isButtonFiltered) {
      title = "We can’t find what you’re looking for. ";
      msg = "Try removing some filter. ";
    }
    if (isActive && !isFiltered) {
      title = "Collect payment efficiently with Virtual Account";
      msg =
        "Let’s create and manage virtual accounts based on your business needs";
    }
    if (isDetails) {
      title = "Nothing to see here";
      msg = "You have no payment received yet.";
    }
    if (isArchived) {
      title = "You have no archived virtual account";
      msg = "All VA that you’ve archived will appear here.";
    }

    return { title, msg };
  };

  const imageDecider = () => {
    if (isFiltered) return emptySearchImage;
    return customImage;
  };

  return (
    <>
      {filterComponent}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          minHeight: 240,
          height: componentHeight,
          flexDirection: "column",
        }}
      >
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            flexDirection: "column",
          }}
        >
          <img src={imageDecider()} />
          {!doubleSubtext && !isFiltered && (
            <GothamMedium
              style={{
                textAlign: "center",
                marginBottom: 8,
                marginTop: 16,
                fontSize: 14,
                lineHeight: "20px",
              }}
            >
              {titleDecider()?.title}
            </GothamMedium>
          )}
          {doubleSubtext ||
            (isFiltered && (
              <GothamRegular
                style={{
                  color: jackColors.grey6C,
                  textAlign: "center",
                  marginBottom: 8,
                  marginTop: 16,
                  fontSize: 14,
                  lineHeight: "20px",
                }}
              >
                {titleDecider()?.title}
              </GothamRegular>
            ))}

          <GothamRegular
            style={{
              color: jackColors.grey6C,
              textAlign: "center",
              fontSize: 14,
              lineHeight: "20px",
              marginBottom: 26,
            }}
          >
            {titleDecider()?.msg}
          </GothamRegular>
          {isButtonFiltered && (
            <GothamRegular
              style={{
                color: jackColors.grey6C,
                textAlign: "center",
                marginBottom: 20,
                marginTop: -10,
                fontSize: 14,
                lineHeight: "20px",
              }}
            >
              or
            </GothamRegular>
          )}
          {showCreateButton && (
            <ButtonJack
              style={{ padding: "10px" }}
              leftIcon={
                <JackIcons name="plus-outline" fill={jackColors.neutral900} />
              }
              onClick={() => push("/virtual-account/create")}
            >
              Create Virtual Account
            </ButtonJack>
          )}
          {isButtonFiltered && (
            <ButtonJack
              style={{ padding: "10px" }}
              onClick={() => {
                const resetButton = document.getElementById(
                  "reset-filter-button"
                );

                resetButton.click();
              }}
            >
              Reset Filter
            </ButtonJack>
          )}
        </div>
      </div>
    </>
  );
};

export default EmptyList;
