import { titleCase } from "change-case";
import React, { Fragment, useEffect, useState } from "react";
import { colors, jackColors } from "../../../assets/colors";
import { GetFlag, NakedSearchInput } from "../../../components/inputs";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { EmptyScreenSearchRecipients } from "../components";
import { CheckBox } from "../../../components/Checkbox";

export const ModalSelectCountry = ({
  close,
  isOpen,
  setSearch,
  search,
  isEmptySearch,
  array,
  handleClick,
  customStyle = {},
  values = [],
  isMultipleSelect,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(false);
  const [ref, setRef] = useState(null);

  const handleClickCountry = (val) => {
    setSelectedCountry(val);
    setSearch("");
  };

  const handleCurrency = (value) => {
    const { item, type } = value || {};
    if (type === "selectCurr") return handleClick(item);
    return setSelectedCountry(false);
  };

  const TitleDecider = Boolean(selectedCountry)
    ? "Select currency"
    : "Select country";

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setSelectedCountry(false);
      }, 200);
    }
  }, [isOpen]);

  useClickOutside({
    clickOutside: () =>
      setTimeout(() => {
        close();
        if (values.length == 0) setSelectedCountry(false); //TANYA ELVAN BOLEH GAK
      }, 20),
    ref: { current: ref },
  });

  return (
    <div
      ref={(e) => setRef(e)}
      style={{
        position: "absolute",
        width: 360,
        maxHeight: 400,
        overflowY: "scroll",
        zIndex: 10,
        padding: 8,
        top: 50,
        left: 26,
        boxShadow:
          "0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)",
        borderRadius: 8,
        backgroundColor: "white",
        ...customStyle,
      }}
    >
      {!Boolean(selectedCountry) ? (
        <SelectCountryScreen
          setSearch={setSearch}
          search={search}
          isEmptySearch={isEmptySearch}
          array={array}
          handleClick={handleClickCountry}
        />
      ) : (
        <SelectCurrencyScreen
          data={selectedCountry}
          handleClick={handleCurrency}
          isMultipleSelect={isMultipleSelect}
          values={values}
        />
      )}
    </div>
  );
};

const SelectCountryScreen = ({
  setSearch,
  search,
  isEmptySearch,
  array,
  handleClick,
}) => {
  return (
    <div>
      <NakedSearchInput
        onChange={(search) => setSearch(search)}
        placeholder={"Search country"}
        style={{ marginTop: 0, marginBottom: 0 }}
        autoFocus={true}
      />
      <div
        style={{
          background: "#FFF",
          maxHeight: 400,
        }}
      >
        {isEmptySearch ? (
          <EmptyScreenSearchRecipients text={search} />
        ) : (
          <>
            {array?.map((item, childIndex) => {
              const {
                country_iso: iso,
                country_iso_3,
                country_name,
                currency,
                currencyGroup,
              } = item || {};
              const isLast = childIndex === array.length - 1;
              const countryName = titleCase(country_name);
              return (
                <Fragment key={childIndex}>
                  <div
                    className="d-flex w-100 align-items-center hover-600 justify-content-between mb-1"
                    style={{
                      cursor: "pointer",
                      padding: "10px 0px",
                      borderRadius: 4,
                    }}
                    onClick={() => handleClick(item)}
                  >
                    <div className="d-flex">
                      <GetFlag iso={iso} style={{ marginRight: 12 }} />
                      <GothamRegular style={{ color: jackColors.neutral900 }}>
                        {countryName}
                      </GothamRegular>
                    </div>
                    <div className="d-flex mr-2">
                      {currencyGroup?.map((item, index) => {
                        const isSgdSwift = item === "SGD-SWIFT";
                        const isUsdSwift = item === "USD-SWIFT";
                        const isFirst = index === 0;

                        if (isSgdSwift || isUsdSwift)
                          return <div key={index} />;
                        return (
                          <div key={index}>
                            <GothamMedium>
                              {!isFirst && ", "}
                              {item}
                            </GothamMedium>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

const SelectCurrencyScreen = ({
  data,
  handleClick,
  isMultipleSelect = false,
  values = [],
}) => {
  const { details, country_name, country_iso } = data || {};
  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between borderE6"
        style={{
          padding: 8,
          marginBottom: 8,
        }}
      >
        <div className="d-flex align-items-center" style={{ gap: 16 }}>
          <GetFlag iso={country_iso} style={{ margin: 0 }} />
          <GothamRegular>{titleCase(country_name)}</GothamRegular>
        </div>
        <div
          className="borderE6 hover-600"
          style={{ padding: "4px 16px" }}
          onClick={() => handleClick({ type: "changeCurr" })}
        >
          <GothamRegular className="font12">Change</GothamRegular>
        </div>
      </div>
      {details.map((item, index) => {
        const { currency } = item;
        const isSgdSwift = currency === "SGD-SWIFT";
        const isUsdSwift = currency === "USD-SWIFT";
        const isLast = index === details.length - 1;

        const CurrMasking = () => {
          if (isSgdSwift) return "SGD (Swift)";
          if (isUsdSwift) return "USD (Swift)";
          return currency;
        };

        const stringValue = String(currency);
        const isActive = values.includes(stringValue);

        return (
          <Fragment key={index}>
            <div
              className="d-flex w-100 align-items-center hover-600 justify-content-between mb-1"
              style={{
                cursor: "pointer",
                padding: "10px 0px",
                borderRadius: 4,
              }}
              onClick={() => handleClick({ type: "selectCurr", item })}
            >
              <div className="d-flex ml-2" style={{ gap: 8 }}>
                {isMultipleSelect && <CheckBox isActive={isActive} />}
                <GothamMedium>{CurrMasking()}</GothamMedium>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export const SelectBusinessTypeModal = ({
  close,
  data,
  selectedData,
  handleClick,
}) => {
  const [ref, setRef] = useState(null);
  const handleClickType = (val) => handleClick(val);

  useClickOutside({
    clickOutside: () => setTimeout(close, 20),
    ref: { current: ref },
  });

  return (
    <div
      ref={(e) => setRef(e)}
      style={{
        position: "absolute",
        top: 85,
        padding: 8,
        backgroundColor: "white",
        boxShadow:
          "0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)",
        borderRadius: 8,
        width: "100%",
      }}
    >
      <div
        style={{
          background: "#FFF",
        }}
      >
        {data?.map((item, index) => {
          const { value, title } = item || {};
          const isActive = selectedData?.value === value;
          return (
            <Fragment key={index}>
              <div
                className="d-flex w-100 align-items-center hover-400"
                style={{
                  padding: "12px 8px",
                  cursor: "pointer",
                  borderRadius: 4,
                  backgroundColor: isActive && jackColors.neutral500,
                }}
                onClick={() => handleClickType(item)}
              >
                <GothamRegular
                  className="font12"
                  style={{
                    color: jackColors.neutral800,
                    fontFamily: isActive && "GothamMedium",
                  }}
                >
                  {title}
                </GothamRegular>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export const SelectDeliveryMethod = ({
  close,
  data,
  selectedData,
  handleClick,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(false);
  const [tempSelected, setTempSelected] = useState(selectedData);
  const [ref, setRef] = useState(null);

  const handleClickType = (val) => {
    setTempSelected(val);
    setHoveredIndex(false);
  };

  const handleConfirm = () => {
    handleClick(tempSelected);
  };

  useClickOutside({
    clickOutside: () => setTimeout(close, 20),
    ref: { current: ref },
  });

  return (
    <div
      ref={(e) => setRef(e)}
      className="borderE6"
      style={{
        position: "absolute",
        bottom: 55,
        zIndex: 10,
        padding: 8,
        backgroundColor: "white",
        boxShadow:
          "0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)",
        borderRadius: 8,
        width: "100%",
      }}
    >
      <GothamMedium
        className="font12"
        style={{
          padding: "12px 8px",
          marginBottom: 16,
          borderBottom: "1px solid #e6e6e8",
        }}
      >
        Select Settlement Method
      </GothamMedium>
      {/* <hr /> */}
      {data?.map((item, index) => {
        const { title, desc, icon, fee, items } = item || {};
        const isSelected = tempSelected?.title == title;
        const isHoveredIndex = hoveredIndex === index;
        const isBgPink = isSelected || isHoveredIndex;
        const hoverColor = isBgPink
          ? jackColors.neutral900
          : jackColors.neutral500;
        const hoverTextColor = isBgPink
          ? jackColors.neutral800
          : jackColors.neutral700;
        return (
          <div
            key={index}
            style={{
              border: `1px solid ${hoverColor}`,
              borderRadius: 4,
              padding: 12,
              marginBottom: 8,
              cursor: "pointer",
              position: "relative",
            }}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(false)}
            onClick={() => handleClickType(item)}
          >
            <div
              className="d-flex align-items-center"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                backgroundColor: hoverColor,
                borderRadius: "0px 2px 0px 2px",
                padding: "2px 8px",
              }}
            >
              <GothamRegular
                style={{
                  color: isBgPink
                    ? jackColors.neutral100
                    : jackColors.neutral800,
                  fontSize: 12,
                  lineHeight: "14px",
                }}
              >
                {fee}
              </GothamRegular>
            </div>
            <div className="d-flex align-items-center">
              <JackIcons
                className="mr-2"
                name={icon}
                fill={isBgPink ? jackColors.neutral900 : jackColors.neutral700}
              />
              <div style={{ width: "100%" }}>
                <div className="d-flex align-items-center justify-content-between mb-1">
                  <GothamMedium
                    style={{
                      color: isBgPink
                        ? jackColors.neutral900
                        : jackColors.neutral700,
                      fontSize: 12,
                      lineHeight: "16px",
                      marginBottom: 4,
                    }}
                  >
                    {title}
                  </GothamMedium>
                </div>
                <GothamRegular
                  style={{
                    color: hoverTextColor,
                    fontSize: 12,
                    lineHeight: "16px",
                    marginBottom: 8,
                  }}
                >
                  {desc}
                </GothamRegular>
                {items?.map((item, index) => {
                  const { point, xicon, customPoint, pointBold } = item || {};
                  return (
                    <div
                      className="d-flex align-items-center mb-1"
                      style={{ gap: 4 }}
                      key={index}
                    >
                      <JackIcons
                        style={{
                          width: "12px",
                          maxHeight: "12px",
                          // marginTop: "-4px",
                        }}
                        name={xicon ? "close-circle" : "checkmark-circle-2"}
                        fill={
                          isBgPink
                            ? xicon
                              ? colors.redE7
                              : colors.green06
                            : jackColors.neutral600
                        }
                      />
                      <GothamRegular
                        style={{
                          fontSize: 12,
                          lineHeight: "12px",
                          color: hoverTextColor,
                        }}
                      >
                        {point}{" "}
                        <span style={{ fontFamily: "GothamMedium" }}>
                          {pointBold}
                        </span>
                      </GothamRegular>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <ButtonJack
        style={{ width: "100%", marginTop: 16 }}
        disabled={!Boolean(tempSelected)}
        onClick={handleConfirm}
      >
        Confirm
      </ButtonJack>
    </div>
  );
};
