import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "@tools";
import uploadIcon from "../../assets/images/upload-icon.svg";
import { BankCard } from "../../components/bankCards";
import { CustomButton } from "../../components/Buttons";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { FileInput } from "../../components/inputs";
import { useModalHook } from "../../components/Modals";
import {
  RightModal,
  useSmoothRightModal,
} from "../../components/Modals/RightModal/parent";
import { GothamMedium } from "../../components/Text";
import {
  arrayToObjByID,
  schemaGenerator,
  submitToLabelValue,
} from "../../components/tools";
import { fetch } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import { BankSelectionModal } from "./bankSelectionModal";
import { useUploadReceiptScreenType } from "./logics";
import { FileInputJack } from "../../components/inputs/fileInputs/fileInput";
import { WrapperModalButtons } from "../../pageComponents/homeComponents/topup/components";
import { ButtonJack } from "../../components/ButtonsJack/parent";

export const banksFormatter = (data) => {
  const array = data?.data || [];
  const result = array.map((item) => {
    const {
      bank_name: top,
      account_name: middle,
      account_number: bottom,
    } = item;
    return { ...item, top, middle, bottom };
  });
  return result;
};

export const FinalUploadReceiptModal = ({
  isOpen,
  toggle,
  id,
  handleUpload,
  isClose = false,
}) => {
  if (!isOpen) return null;
  const { query, push } = useRouter();
  const { success, loading: loadingSubmit, type } = query;
  const { toToggle, toListener } = useSmoothRightModal();

  useEffect(() => {
    if (!success) return;
    toggle();
  }, [success]);
  const [getId, setGetId] = useState(id);

  // const resolver = schemaGenerator({ objectArr: ["upload_file"] });

  const useFormObj = useForm();

  const { handleSubmit, watch } = useFormObj;
  const watchUploadFile = watch("upload_file");

  const disabledButton =
    !watchUploadFile || watchUploadFile?.data?.length === 0;

  const isTopup = type == "top_up";

  const onSubmit = (val) => {
    const result = submitToLabelValue({
      ...val,
      reference_type: "PartnerBankAccount",
      reference_id: id,
    });

    const { upload_file } = val;
    const final = isTopup ? { ...result, upload_file } : result;
    handleUpload && handleUpload(final);
  };

  // bank selection
  const { isOpen: isOpenBankSelection, toggle: toggleBankSelection } =
    useModalHook();

  const onSubmitBankSelection = (id) => {
    setGetId(id);
    push({ query: { ...query, reference_id: id } });
    toggleBankSelection();
  };
  // bank selection

  const { data, setData, refetch, loading } = fetch({
    url: "/partner_bank_accounts?q[main_acc_eq]=false",
    formatter: banksFormatter,
    woInit: true,
  });
  const item = arrayToObjByID(data, getId);

  useEffect(() => {
    if (!isOpen) return setData([]);
    refetch();
  }, [isOpen, getId]);

  const [middleHeight, setMiddleHeight] = useState(0);

  const hideModal = isOpenBankSelection;
  const { isTopUp } = useUploadReceiptScreenType();

  const components = [
    <WrapperModalButtons
      childrenButton={
        <div style={{ width: "100%", marginRight: 16 }}>
          <ButtonJack
            style={{ width: "100%", marginBottom: 16 }}
            disabled={disabledButton}
            isLoading={loadingSubmit == "true" || loading}
            onClick={handleSubmit(onSubmit)}
            children="Confirm"
          />
          <ButtonJack
            children="Back"
            type="outline"
            onClick={toToggle}
            style={{ width: "100%" }}
          />
        </div>
      }
    >
      <GothamMedium className="font24" style={{ marginBottom: 32 }}>
        Source of Payment
      </GothamMedium>
      <BankCard
        loading={loading}
        item={item}
        isUploadReceipt
        onChangeSource={() => {
          eventsTracker("change_bank");
          toggleBankSelection();
        }}
      />
      <div style={{ height: 32 }} />
      <FileInputJack
        type="general"
        fileTypes={["image/jpeg", "image/png", "application/pdf"]}
        useFormObj={useFormObj}
        name="upload_file"
        label="Transfer Receipt"
        formData
        multiple={!isTopUp}
      />
    </WrapperModalButtons>,
  ];

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        array={components}
        listener={toListener}
        width={[420]}
      />
      <BankSelectionModal
        isClose={false}
        data={data}
        setData={setData}
        isOpen={isOpenBankSelection}
        toggle={toggleBankSelection}
        onSubmit={onSubmitBankSelection}
        selectedId={getId}
      />
    </>
  );

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        hideModal={hideModal}
        middleHeight={middleHeight}
        header={
          <MainCardHeaderMolecule
            toggle={toggle}
            isClose={isClose}
            title="Upload Receipt"
            subTitle=""
            alignLeft
            middleHeight={middleHeight}
          />
        }
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <GothamMedium
              style={{ margin: 0, lineHeight: "22px", color: "white" }}
            >
              Source of Payment
            </GothamMedium>
            <BankCard
              loading={loading}
              item={item}
              isUploadReceipt
              onChangeSource={() => {
                eventsTracker("change_bank");
                toggleBankSelection();
              }}
            />
          </MiddleWrapper>
        }
        body={
          <>
            <GothamMedium style={{ lineHeight: "22px", marginBottom: 8 }}>
              Receipt
            </GothamMedium>
            <FileInput
              isPinkButton
              type="general"
              fileTypes={["image/jpeg", "image/png", "application/pdf"]}
              useFormObj={useFormObj}
              name="upload_file"
              isDottedBorder
              formData
              multiple={!isTopUp}
            />
            <CustomButton
              disabled={disabledButton}
              isLoading={loadingSubmit == "true" || loading}
              onClick={handleSubmit(onSubmit)}
              className="w-100"
              style={{ marginTop: 24 }}
              // icon={
              //   <img
              //     src={uploadIcon}
              //     style={{ marginRight: 8, width: 20, height: 20 }}
              //   />
              // }
            >
              Upload Receipt
            </CustomButton>
          </>
        }
      />
      <BankSelectionModal
        isClose={false}
        data={data}
        setData={setData}
        isOpen={isOpenBankSelection}
        toggle={toggleBankSelection}
        onSubmit={onSubmitBankSelection}
        selectedId={getId}
      />
    </>
  );
};
