import { jackColors } from "../../assets/colors";
import { GothamRegular } from "../Text";

const DynamicText = ({ text, isCustomText }) => {
  if (isCustomText) return text;

  const isIncludes = (include) => String(text).includes(include);

  const isDrafterRequested = isIncludes("requested");
  const isDrafterCreated = isIncludes("created");
  const isDrafterEdited = isIncludes("edited");
  const isDrafterRequestedInvite = isIncludes("requested to invite");
  const isDrafterEditedRequest = isIncludes("edited the request");
  const isDrafterSentInvitation = isIncludes("sent invitation to");
  const isDrafterJoinedJack = isIncludes("joined Jack under your company");
  const isDrafterDeactivated = isIncludes("deactivated");
  const isDrafterActivated = isIncludes("activated");
  const isDrafterInvitationRequest =
    isDrafterEditedRequest ||
    isDrafterSentInvitation ||
    isDrafterJoinedJack ||
    isDrafterRequestedInvite ||
    isDrafterEdited ||
    isDrafterDeactivated ||
    isDrafterActivated;
  const isDrafter =
    isDrafterCreated || isDrafterRequested || isDrafterInvitationRequest;
  const isApprover = isIncludes("from");
  const isApproved = isIncludes("Approved by");
  const isRejected = isIncludes("Rejected by");
  const isCanceled = isIncludes("Canceled by");
  const isMarkRejected = isIncludes("Marked as rejected by");
  const isMarkApproved = isIncludes("Marked as approved by");
  const isReviewed = isIncludes("Reviewed by");
  const isReleasedBy = isIncludes("Payment has been released by");

  let splittedString = "";

  const textDecider = () => {
    const splitter = (split) => {
      splittedString = split;
      return String(text).split(split);
    };

    if (isDrafterRequested) return splitter("requested");
    if (isDrafterCreated) return splitter("created");
    if (isApprover) return splitter("from");
    if (isApproved) return splitter("Approved by");
    if (isRejected) return splitter("Rejected by");
    if (isCanceled) return splitter("Canceled by");
    if (isMarkRejected) return splitter("Marked as rejected by");
    if (isMarkApproved) return splitter("Marked as approved by");
    if (isReviewed) return splitter("Reviewed by");
    if (isReleasedBy) return splitter("Payment has been released by");
    if (isDrafterRequestedInvite) return splitter("requested to invite");
    if (isDrafterEditedRequest) return splitter("edited the request");
    if (isDrafterSentInvitation) return splitter("sent invitation to");
    if (isDrafterJoinedJack) return splitter("joined Jack under your company");
    if (isDrafterEdited) return splitter("edited");
    if (isDrafterDeactivated) return splitter("deactivated");
    if (isDrafterActivated) return splitter("activated");
    return String(text);
  };

  const isSpecialCase =
    isDrafterRequested ||
    isDrafterCreated ||
    isApprover ||
    isApproved ||
    isRejected ||
    isMarkRejected ||
    isMarkApproved ||
    isCanceled ||
    isReviewed ||
    isReleasedBy ||
    isDrafterEditedRequest ||
    isDrafterJoinedJack ||
    isDrafterEdited ||
    isDrafterActivated ||
    isDrafterDeactivated ||
    isDrafterRequestedInvite;

  const isMultipleBold = isDrafterSentInvitation;

  const textToRender = textDecider();

  if (isSpecialCase) {
    return (
      <>
        <span style={{ fontFamily: isDrafter ? "GothamMedium" : "GothamBook" }}>
          {textToRender[0] + " "}
        </span>
        <span>{splittedString}</span>
        <span style={{ fontFamily: isDrafter ? "GothamBook" : "GothamMedium" }}>
          {" " + textToRender[1]}
        </span>
      </>
    );
  }

  if (isMultipleBold) {
    return (
      <>
        <span style={{ fontFamily: "GothamMedium" }}>
          {textToRender[0] + " "}
        </span>
        <span>{splittedString}</span>
        <span style={{ fontFamily: "GothamMedium" }}>
          {" " + textToRender[1]}
        </span>
      </>
    );
  }

  return <span>{textToRender}</span>;
};

const Text = ({ item, children, isCustomText }) => {
  const { isActive } = item || {};

  return (
    <GothamRegular
      style={{
        color: isActive ? jackColors.neutral900 : jackColors.neutral700,
        fontSize: "12px",
        lineHeight: "16px",
      }}
    >
      <DynamicText text={children} isCustomText={isCustomText} />
    </GothamRegular>
  );
};

export default Text;
