import { isSameDay } from "date-fns";
import { isEmpty } from "lodash";
import { jackColors } from "../../assets/colors";

export const generateTimeOptions = (from = 0, to = 24, woWIB = true) => {
  const options = [];
  for (let hour = from; hour <= to; hour++) {
    const formattedHour = hour.toString().padStart(2, "0");
    const normalLabel = `${formattedHour}:00`;
    const WIBLabel = normalLabel + " WIB";
    const label = woWIB ? normalLabel : WIBLabel;
    options.push({ label, value: normalLabel });
  }
  return options;
};

export const generateTimeOptionsComplex = (date, woWIB = true) => {
  if (!Boolean(date)) return [];
  const isToday = isSameDay(date, new Date());
  const defaultOptions = generateTimeOptions(6, 22, woWIB);
  if (isToday) {
    const currentDate = new Date();
    const hour = currentDate?.getHours();

    if (hour <= 6) return defaultOptions;
    if (hour >= 22) return [];

    return generateTimeOptions(hour + 1, 22, woWIB);
  }

  return defaultOptions;
};

export const timeRange = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

export const days = [
  { label: "Sun", value: 0 },
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
];

export const daysLabel = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const productsMenuOptions = [
  { label: "Payroll", value: "Payroll" },
  { label: "Local Transfer", value: "LocalTransfer" },
  { label: "Bill Payment", value: "InvoiceTransaction" },
  { label: "Reimbursement", value: "Reimbursement" },
];

export const blueYellowStyle = () => ({
  control: (style, { isFocused, hasValue }) => ({
    ...style,
    borderRadius: "4px",
    height: "40px",
    fontSize: "14px",
    fontFamily: "GothamBook",
    borderColor: isFocused
      ? jackColors.black34
      : hasValue
      ? jackColors.greyE6
      : jackColors.yellowFC,
    backgroundColor: hasValue ? "#fff" : "rgba(255, 246, 230, 1)",
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${jackColors.neutral600}`,
    },
    marginBottom: "32px",
    color: "red",
  }),
  input: (style) => {
    return {
      ...style,
      fontFamily: "GothamBook",
      display: "flex",
      alignItems: "center",
      padding: 0,
      margin: 0,
    };
  },
  option: (style, { isSelected, isFocused }) => ({
    ...style,
    fontSize: "14px",
    fontFamily: "GothamBook",
    color: jackColors.grey6C,
    backgroundColor: isSelected
      ? jackColors.greyE6
      : isFocused
      ? jackColors.greyF1
      : "transparent",
  }),
  menu: (style) => ({
    ...style,
    border: `1px solid ${jackColors.greyE6}`,
    borderRadius: "4px",
  }),
  indicatorSeparator: () => ({}),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "GothamBook",
    marginTop: 3,
    color: "#343434",
  }),
  singleValue: (style, { hasValue }) => {
    return { ...style, color: hasValue ? "#6087E7" : "#343434", marginTop: 3 };
  },
  indicatorsContainer: (style) => {
    return {
      ...style,
      marginRight: -8,
    };
  },
});
