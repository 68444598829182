import moment from "moment";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import {
  dateFormatStripDMY,
  dateToIsoDateFormatter,
  getTotalAmountSummary,
} from "../../invoiceComponents/data/formatter";

export const reimbursementFormatter = (array) => {
  if (!array) return [];
  return array?.map((item) => {
    const {
      reimbursement_file,
      purchased_date,
      created_at,
      amount,
      total_amount,
      user,
      state: status,
      thumbnail_receipt,
      memo_text,
      state: stateRaw,
      activity_state,
    } = item || {};

    const createdBy = user?.name;
    const createdDate = created_at
      ? moment(created_at).format("DD MMM YYYY")
      : "-";
    const purchasedDate = purchased_date
      ? moment(purchased_date).format("DD MMM YYYY")
      : "-";
    const purchasedDateISO = purchased_date
      ? dateToIsoDateFormatter(purchased_date)
      : "-";
    const purchasedDateShort = purchased_date
      ? moment(purchased_date).format("DD/MM/YYYY")
      : "-";

    const getUrl = (url) => {
      if (!url) return "";
      return `${process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL}${url}`;
    };
    const file_url = getUrl(reimbursement_file);
    const thumbnail = getUrl(thumbnail_receipt);
    const amountIDR = `IDR ${formatCurrencyNoDecimal(total_amount || amount)}`;
    const description = shortDescriptionNoHTML(memo_text);
    const state = stateRaw == activity_state ? stateRaw : activity_state;
    return {
      ...item,
      type: "reimbursement",
      createdBy,
      createdDate,
      file_url,
      status,
      purchasedDate,
      purchasedDateISO,
      purchasedDateShort,
      amountIDR,
      thumbnail,
      description,
      state,
    };
  });
};

export const shortDescriptionNoHTML = (description) => {
  if (!description) return description;

  return `${description}`
    .replace(/<[^>]*>?/gm, "")
    .replace(/(\r\n|\n|\r)/gm, "");
};

export const draftTotalAmount = (reimbursement_child) => {
  if (!reimbursement_child) return "IDR 0";

  const { totalAmountString } = getTotalAmountSummary(
    reimbursement_child,
    "amount"
  );

  return totalAmountString;
};
