import { Fragment } from "react";
import { GothamMedium } from "../../../../../components/Text";
import { jackColors } from "../../../../../assets/colors";
import { Banner } from "../../../../../components/Banner";

const ResubmitBanner = ({ batchName = "" }) => {
  return (
    <Fragment>
      <GothamMedium
        className="font24"
        style={{ color: jackColors.neutral900, marginBottom: "24px" }}
      >
        Resubmit Transaction
      </GothamMedium>
      <Banner
        style={{ marginBottom: "24px" }}
        msg={
          <Fragment>
            These are the failed transaction(s) in{" "}
            <span style={{ fontFamily: "GothamMedium" }}>{batchName}</span>.
            Please review the details thoroughly before you validate and
            resubmit the transaction(s).
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default ResubmitBanner;
