import { Fragment } from "react";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { jackColors } from "../../../../assets/colors";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";

const DowngradeContent = ({ toggle = () => {} }) => {
  return (
    <Fragment>
      <img src="/images/downgrade-request-image.svg" />
      <GothamMedium
        className="font20"
        style={{ color: jackColors.neutral900, marginTop: "32px" }}
      >
        To downgrade your plan…
      </GothamMedium>
      <GothamRegular
        woFontColor
        style={{ color: jackColors.neutral700, marginTop: "16px" }}
      >
        Please reach out to your Account Manager for assistance.
      </GothamRegular>
      <ButtonJack style={{ width: "100%", marginTop: "32px" }} onClick={toggle}>
        I understand
      </ButtonJack>
    </Fragment>
  );
};

export default DowngradeContent;
