import { isObject } from "lodash";
import { CUSTOM, PRIMARY, REASON, SECONDARY } from "../../constants";
import { jackColors } from "../../../../assets/colors";
import { GothamRegular } from "../../../Text";

const DefaultContent = ({ content = "", color = jackColors.neutral900 }) => {
  const isArrayContent = Array.isArray(content);

  let renderedContent = content;

  if (isArrayContent) {
    renderedContent = content.map((item, index) => {
      const isObjectItem = isObject(item);
      const hasType = !!item.type;
      const hasValue = !!item.value;
      const isValid = hasType && hasValue;
      if (isObjectItem) {
        const isBold = isValid && item.type === "bold";
        if (!isValid) return "[invalid object]";
        if (isBold) {
          return (
            <span
              key={`${item.type}-${item.value}-${index}`}
              style={{ fontFamily: "GothamMedium" }}
            >
              {item.value}
            </span>
          );
        }
        return item.value;
      }
      return item;
    });
  }

  return (
    <GothamRegular woNewLine woFontColor className="font12" style={{ color }}>
      {renderedContent}
    </GothamRegular>
  );
};

const UpdateContent = ({ type, content }) => {
  switch (type) {
    case PRIMARY:
      return <DefaultContent content={content} />;
    case SECONDARY:
      return <DefaultContent content={content} color={jackColors.neutral700} />;
    case REASON:
      return (
        <div>
          <DefaultContent content="Reason:" />
          <DefaultContent content={content} />
        </div>
      );
    case CUSTOM:
      return content;
    default:
      return null;
  }
};

export default UpdateContent;
