import { Modal } from "reactstrap";
import React, { useEffect } from "react";
import { useModalHook, useModalWidth } from "../../components/Modals";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../components/Text";
import { jackColors } from "../../assets/colors";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import YouTube from "react-youtube";

export const WelcomeModalJack = ({ isOpen, toggle }) => {
  const modalWidth = useModalWidth(480);
  const setHasOpened = () => {
    const storageKey = "hasOpenedNew";
    localStorage.setItem(storageKey, "true");
  };
  
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={"modal-transfez"}
      onClosed={() => {
        setHasOpened();
      }}
    >
      <div
        style={{
          position: "relative",
          width: modalWidth,
          borderRadius: 8,
          padding: 20,
          flexDirection: "column",
          gap: 32,
        }}
        className="d-flex"
      >
        <YouTube
          videoId={"HlWss2xaKaI"}
          opts={{
            width: "100%",
            height: 247.5,
            playerVars: {
              controls: 1,
              autoplay: 1,
              mute: 1,
              loop: 1,
              modestbranding: 1,
            },
          }}
        />
        {/* <video width="100%" height="100%" autoPlay muted controls>
          <source
            src="https://www.youtube.com/watch?v=Fc3NVioDGfA"
            type="video/mp4"
          />
        </video> */}
        <div style={{ flexDirection: "column", gap: 16 }} className="d-flex">
          <GothamMedium className="font20">Welcome to Jack Portal</GothamMedium>
          <GothamRegular style={{ color: jackColors.grey6C }}>
            Enjoy our new look! We have refreshed the Jack portal and app
            interface to take your experience managing your financial operations
            to the new height.
          </GothamRegular>
          <GothamRegular style={{ color: jackColors.grey6C }}>
            Introducing two new features:{" "}
            <TextInlineMedium>Batch reimbursement</TextInlineMedium> for
            processing multiple reimbursements in one go, and{" "}
            <TextInlineMedium>Prepaid Cards</TextInlineMedium> that gives more
            flexibility in raising card limits.
          </GothamRegular>
          <GothamRegular style={{ color: jackColors.grey6C }}>
            Say goodbye (for a while) to{" "}
            <TextInlineMedium>Team Wallet</TextInlineMedium> product. We are
            upgrading this product and will bring it back soon with better
            experience.
          </GothamRegular>
          <GothamRegular style={{ color: jackColors.grey6C }}>
            Let's discover the enhanced features and layouts on Jack, to help
            you navigating your financial journey with ease.
          </GothamRegular>
        </div>
        <ButtonJack
          onClick={() => {
            toggle();
            setHasOpened();
          }}
        >
          Lets Go!
        </ButtonJack>
      </div>
    </Modal>
  );
};
