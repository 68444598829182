import { noCase } from "change-case";
import {
  customDateFormatter,
  deepRemoveDuplicates,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../../../components/tools";
import {
  useConstants,
  useInjectDeletedUsers,
} from "../../../../../contexts/ConstantsContext/parent";
import { cardTypeDecider } from "../../../../cardPageComponents/tools";

// mahakarya apuy
export const useNeedActionTableFormatter = (array) => {
  const { allUsers } = useConstants();

  const drafterIds = (array || [])?.map(
    ({ originator, transaction_detail }) => {
      const foundUserId = transaction_detail?.user?.id ?? originator?.user_id;
      return foundUserId;
    }
  );
  useInjectDeletedUsers({ userIds: drafterIds });

  const formattedArray = (array || []).map((item) => {
    const {
      id,
      created_at,
      transaction_detail = {},
      originator,
      originator_type,
      role, // just for cards
      is_last_order,
      fee: feeParent,
    } = item || {};

    const { user_id, amount: originatorAmount } = originator || {};
    const { id: t_user_id } = transaction_detail?.user || {};
    const userId = t_user_id || user_id;

    const user = t_user_id
      ? transaction_detail?.user
      : allUsers.find(({ id }) => id == userId);

    const isApprove = !is_last_order;

    const {
      action,
      action_originator,
      amount,
      fee,
      description: walletDescription,
    } = transaction_detail || {};

    const { name: action_requester, card_type } = action_originator || {};
    const { isPrepaid } = cardTypeDecider(card_type);
    const {
      action_type,
      description,
      beneficiary,
      name: originatorName,
      amount: amountOriginator,
      merchant,
      originator_type: action_originator_type,
      transaction_type,
      reference_number,
    } = originator || {};

    const { receiving_registered_name, bank_account_number } =
      beneficiary || {};

    const accNumber = String(bank_account_number);
    const beneDetails =
      receiving_registered_name ||
      `***${accNumber.substring(accNumber.length - 3)}`;

    const { name } = user || {};

    const createdBy = name || "Unknown";

    const isAction = originator_type === "Action";

    const isInvoice = originator_type === "InvoiceTransaction";
    const isLocalTransfer = originator_type === "LocalTransactionBatch";
    const isCrossBorder = originator_type === "BusinessTransaction";
    const isCrossBorderBatch = originator_type === "Job";
    const isPayroll = originator_type === "Payroll";
    const isWallet = originator_type === "WalletTransaction";
    const isReimbursement = originator_type === "Reimbursement";
    const isCard = originator_type === "UserCard";
    const isJackTransfer = originator_type === "BalanceTransfer";
    const isWithdrawal = originator_type === "BalanceWithdrawal";

    const isActionCard = action_originator_type === "UserCard";
    const isCardRequestActive =
      action_type === "request_active" && isActionCard;
    const isCardRequestLimit =
      action_type === "request_adjust_limit" && isActionCard;
    const isCardTopUpBalance =
      action_type === "request_withdraw_balance_company" && isActionCard;
    const isCardWithdrawBalance =
      action_type === "request_topup_balance_company" && isActionCard;
    const isCardMoveBalanceToCard =
      action_type === "request_move_balance" && isActionCard;
    const isCardWithAmount =
      isCardTopUpBalance || isCardWithdrawBalance || isCardMoveBalanceToCard;

    const typeDecider = () => {
      if (isAction) {
        if (isActionCard) return "card";
      }
      if (isInvoice) return "invoice";
      if (isLocalTransfer) return "local_disbursement_batch";
      if (isPayroll) return "payroll";
      if (isCrossBorder) return "single";
      if (isWallet) return transaction_type;
      if (isCrossBorderBatch) return "batch";
      if (isReimbursement) return "reimbursement";
      if (isCard) return "card";
      if (isJackTransfer) return "balance_transfer";
      if (isWithdrawal) return "withdrawal";

      return "";
    };

    const typeOutput = typeDecider();

    const productNameDecider = () => {
      if (isAction && isActionCard) return "Card";
      if (isInvoice) return "Bill Payment";
      if (isLocalTransfer) return "Local Transfer";
      if (isPayroll) return "Payroll";
      if (isCrossBorder) return "International Transfer";
      if (isWallet) return transaction_type;
      // if (isCrossBorderBatch) return "batch";
      if (isReimbursement) return "Reimbursement";
      if (isCard) return "Card";
      if (isJackTransfer) return "Balance Transfer";
      if (isWithdrawal) return "Withdrawal";

      return "";
    };

    const productName = productNameDecider();

    const decriptionDecider = () => {
      if (isAction) {
        if (isActionCard) {
          if (isCardRequestActive) {
            if (isPrepaid) return `Prepaid card request - ${action_requester}`;
            return `Virtual card request - ${action_requester}`;
          }
          if (isCardRequestLimit)
            return `Card limit adjustment request - ${action_requester}`;
          if (isCardTopUpBalance)
            return `Top up prepaid card to ${action_requester}`;
          if (isCardWithdrawBalance)
            return `Return prepaid balance from ${action_requester}`;
          if (isCardMoveBalanceToCard)
            return `Card move balance request - ${action_requester}`;
        }
      }

      if (isInvoice) return description || "Invoice Transaction";
      if (isLocalTransfer || isPayroll) return originatorName;
      if (isCrossBorder) return `Transfer to ${beneDetails}`;
      if (isWallet) return walletDescription || "Wallet Transaction";
      if (isCrossBorderBatch) return "Batch Transfer";
      if (isReimbursement) return merchant;
      if (isJackTransfer) return walletDescription || "Balance Transfer";
      if (isWithdrawal) return "Main balance withdrawal";
      return "";
    };

    const descriptionOutput = decriptionDecider();

    const amountDecider = () => {
      if (isAction) {
        if (isCardWithAmount) return transaction_detail?.amount;
        return 0;
      }
      if (isReimbursement) return amountOriginator;
      if (isJackTransfer || isWithdrawal) return Number(originatorAmount);
      if (!amount) return 0;
      return amount;
    };

    const getAmountText = (amount) => `IDR ${formatCurrency(amount)}`;

    const amountFixed = amountDecider();
    const amountText =
      isAction && !isCardWithAmount ? "-" : getAmountText(amountFixed);

    const feeDecider = () => {
      if (isAction) {
        if (isActionCard) return 0;
      }
      if (!fee) return 0;
      return fee;
    };

    const feeFixed = feeDecider();
    const feeText = isAction ? "-" : getAmountText(feeFixed);

    // cancer in home
    const msgDecider = () => {
      if (isAction) {
        if (isActionCard) {
          if (isCardRequestActive)
            return `Card ${action_requester} request active`;
          if (isCardRequestLimit)
            return `Card ${action_requester} request adjust limit`;
        }
      }

      if (isInvoice)
        return `${createdBy} has drafted a new invoice - ${reference_number} and need your approval`;
      if (isLocalTransfer) return `Local Transfer - ${originatorName}`;
      if (isPayroll) return `Payroll - ${originatorName}`;
      if (isCrossBorder) return `Cross Border - Single - ${beneDetails}`;
      if (isWallet)
        return `Wallet Allocation - ${createdBy} is waiting for approval`;
      if (isCrossBorderBatch) return "Batch Transfer";
      if (isReimbursement)
        return `${createdBy} has drafted a new reimbursement - ${reference_number} and need your approval`;
      return "";
    };

    const msg = msgDecider();

    const titleDecider = () => {
      if (isAction) {
        if (isActionCard) {
          return;
        }
      }

      const totalAmount = Number(feeFixed) + Number(amountFixed);
      return `IDR ${formatCurrencyNoDecimal(totalAmount)}`;
    };

    const title = titleDecider();

    const buttonTextDecider = () => {
      if (isAction) return "See details";
      if (isApprove) return "Review & Approve";
      return "View & Release Payment";
    };

    const buttonText = buttonTextDecider();

    // cancer in home

    const output = {
      ...item,
      isCard,
      isActionCard,
      buttonText,
      title, // home props
      msg, // home props
      feeText,
      amountText,
      id,
      description: descriptionOutput,
      createdBy,
      date: customDateFormatter(created_at, true, true),
      amount: Number(amountFixed),
      fee: Number(feeFixed),
      type: typeOutput,
      productName,
    };

    if (isJackTransfer) output.is_debit = true;

    return output;
  });

  return { formattedArray };
};

export const defaultPaginationFormatter = (res, prev) => {
  const { array: prevArray } = prev || {};
  const { data } = res || {};
  const array = deepRemoveDuplicates([...(prevArray || []), ...data], "id");
  return { ...res, array };
};
