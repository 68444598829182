import { useEffect, useRef } from "react";
import { jackColors } from "../../assets/colors";
import { GothamMedium } from "../Text";

const menuPropsDefault = {
  screenName: "Placeholder",
  number: undefined,
  numberStyle: {},
  icon: null,
};

const Menu = ({
  isSelected = false,
  menu = menuPropsDefault,
  className = "",
  style = {},
  onClick = () => {},
  listener = () => {},
  hideZero = false
}) => {
  const { screenName, number, icon, numberStyle, nameStyle } = menu ?? {};

  const menuRef = useRef();

  const menuClientWidth = menuRef.current?.clientWidth;

  useEffect(() => listener({ menuRef }), [menuClientWidth]);

  const canShowNumber = number != undefined;
  const numberToUse = number > 99 ? "99+" : number;
  const hideCounter = hideZero && number == 0

  return (
    <li
      ref={menuRef}
      className={className}
      style={{
        height: "40px",
        padding: "4px 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        listStyleType: "none",
        cursor: "pointer",
        ...style,
      }}
      onClick={onClick}
    >
      {icon}
      <GothamMedium
        style={{
          fontSize: "14px",
          lineHeight: "20px",
          color: isSelected ? jackColors.neutral900 : jackColors.neutral700,
          fontFamily: isSelected ? "GothamMedium" : "GothamBook",
          ...nameStyle,
        }}
      >
        {screenName}
      </GothamMedium>
      {!hideCounter && canShowNumber && (
        <GothamMedium
          style={{
            padding: "2px 8px",
            borderRadius: "4px",
            fontSize: "12px",
            lineHeight: "16px",
            color: jackColors.neutral100,
            background: jackColors.neutral900,
            height: "20px",
            ...numberStyle,
          }}
        >
          {numberToUse}
        </GothamMedium>
      )}
    </li>
  );
};

export default Menu;
