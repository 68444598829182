import React, { createContext, useEffect } from "react";
import { useTranslation } from "next-i18next";

export const TranslationContext = createContext();
const TranslationProvider = ({ children }) => {
  const { i18n } = useTranslation();

  useEffect(() => {}, [i18n.language]); // Update when i18n language changes

  return (
    <TranslationContext.Provider value={{ language: i18n.language || "id" }}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
