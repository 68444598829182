export const useBooleanDanamonProduct = (type) => {
  if (!type) return {};

  // product
  const isCard = type === "cards";
  const isInternationalTransfer = type == "international_transfer";
  const isBalanceTransfer = type == "balance_transfer";
  const isWithdraw = type == "withdraw" || type == "withdrawal";
  const isPayroll = type == "payroll";
  const isLocalTransfer =
    type == "local_transfer" ||
    type == "local_disbursement_batch" ||
    type == "local";
  const isReimbursement = type == "reimbursement";
  const isBillPayment = type == "invoice_payment" || type == "invoice";

  // balance
  const isSelectedBalance = !!type;
  const isBankTransfer = type == "bank_transfer";
  const isDanamonBalance = type == "danamon_balance";
  const isCollectionPocket = type == "indonesian_wallet";

  return {
    isInternationalTransfer,
    isBalanceTransfer,
    isWithdraw,
    isPayroll,
    isLocalTransfer,
    isReimbursement,
    isBillPayment,
    isBankTransfer,
    isDanamonBalance,
    isSelectedBalance,
    isCollectionPocket,
    isCard,
  };
};

export const useBooleanMultipleProduct = (tasksRaw = []) => {
  const tasks = !!tasksRaw.length ? tasksRaw : [];
  const findProduct = (key) => tasks.some(({ type }) => type == key);
  const allProduct = (key) => tasks.every(({ type }) => type == key);
  const findOriginatorType = (key) =>
    tasks.some(({ originator_type }) => originator_type == key);

  const includeInternational = findProduct("single");
  const includePayroll = findProduct("payroll");
  const includeLocalTransfer = findProduct("local_disbursement_batch");
  const includeBillPayment = findProduct("invoice");
  const includeReimbursement = findProduct("reimbursement");
  const includeBalanceTransfer = findProduct("balance_transfer");
  const includeWithdraw = findProduct("withdrawal");

  const isAllTaskInternational = allProduct("single");
  const isAllTaskBalanceTransfer = allProduct("balance_transfer");
  const isAllTaskWithdraw = allProduct("withdrawal");

  const includeCard = findOriginatorType("Action");

  return {
    includeInternational,
    includePayroll,
    includeLocalTransfer,
    includeBillPayment,
    includeReimbursement,
    isAllTaskInternational,
    includeBalanceTransfer,
    isAllTaskBalanceTransfer,
    includeWithdraw,
    isAllTaskWithdraw,
    includeCard,
  };
};
