import {
  cardTypeDecider,
  paymentMethodDecider,
  transactionTypeDecider,
} from "../../../../tools";
import { customDateFormatter } from "../../../../../../components/tools";
import { CardSummary } from "../../../CardSummary";
import { TagTeamLabel } from "../../../../../../components/StatusLabel";
import { GothamRegular } from "../../../../../../components/Text";
import { Avatar } from "../../../../../../components/Avatar";
import { colors } from "../../../../../../assets/colors";

export const cardTransactionDetailFormatter = ({ transactionDetail }) => {
  const {
    additional_data: additionalData,
    date,
    merchant_name: merchantName,
    payment_method: paymentMethod,
    reference_number: referenceNumber,
    transaction_type: transactionType,
    user_card,
  } = transactionDetail || {};

  const {
    requester_name: requesterName,
    source_card_nickname: sourceCardNickname,
    source_card_number: sourceCardNumber,
    source_card_username: sourceCardUsername,
    target_card_nickname: targetCardNickname,
    target_card_number: targetCardNumber,
    target_card_username: targetCardUsername,
  } = additionalData || {};

  const {
    card_type: cardType,
    masked_card_number: maskedCardNumber,
    nickname,
    user,
  } = user_card || {};

  const { name: userName, team } = user || {};
  const { color: teamColor, name: teamName } = team || {};

  const { isPhysical } = cardTypeDecider(cardType);
  const logo = isPhysical ? "visa-logo" : "mastercard";

  const formattedDate = customDateFormatter(date);
  const realMerchantName = merchantName?.split("-")[0] || merchantName;

  const { isMainBalance, isMoveBalance } = paymentMethodDecider(paymentMethod);
  const methodTextDecider = () => {
    if (isMainBalance) return "Request to Company";
    return paymentMethod;
  };

  const paymentMethodText = methodTextDecider();

  const formattedInformations = [
    {
      left: "Created at",
      right: formattedDate,
    },
    {
      left: "Transaction ID",
      right: referenceNumber,
    },
    {
      left: "Merchant",
      right: realMerchantName,
    },
    {
      left: "Team",
      right: team ? (
        <TagTeamLabel
          teamName={teamName}
          teamColor={teamColor}
          isOutline
          style={{
            marginLeft: "auto",
          }}
        />
      ) : (
        "Unassigned"
      ),
    },
    {
      left: "Payer",
      right: (
        <CardSummary
          userName={userName}
          maskedCardNumber={maskedCardNumber?.substr(-4)}
          cardName={nickname}
          logo={logo}
        />
      ),
    },
  ];

  const requester = requesterName && {
    left: "Requester",
    right: (
      <div
        style={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <GothamRegular
          style={{
            fontSize: "12px",
            letterSpacing: "0.2px",
            color: colors.neutral900,
          }}
        >
          {requesterName}
        </GothamRegular>
        <Avatar name={requesterName} size="small" />
      </div>
    ),
  };

  const moveBalanceInformations = [
    {
      left: "Date",
      right: formattedDate,
    },
    requester,
    {
      left: "Source",
      right: (
        <CardSummary
          userName={sourceCardUsername}
          maskedCardNumber={sourceCardNumber}
          cardName={sourceCardNickname}
        />
      ),
    },
    {
      left: "Recipient",
      right: (
        <CardSummary
          userName={targetCardUsername}
          maskedCardNumber={targetCardNumber}
          cardName={targetCardNickname}
        />
      ),
    },
    {
      left: "Team",
      right: (
        <TagTeamLabel
          teamName={teamName}
          teamColor={teamColor}
          style={{
            marginLeft: "auto",
          }}
        />
      ),
    },
  ];

  const prepaidTopUpInformations = [
    {
      left: "Date",
      right: formattedDate,
    },
    {
      left: "Method",
      right: paymentMethodText,
    },
    requester,
    {
      left: "Top up to",
      right: (
        <CardSummary
          userName={userName}
          maskedCardNumber={maskedCardNumber?.substr(-4)}
          cardName={nickname}
          logo={logo}
        />
      ),
    },
    {
      left: "Team",
      right: (
        <TagTeamLabel
          teamName={teamName}
          teamColor={teamColor}
          style={{
            marginLeft: "auto",
          }}
        />
      ),
    },
  ];

  const withdrawalInformations = [
    {
      left: "Date",
      right: formattedDate,
    },
    requester,
    {
      left: "Returned from",
      right: (
        <CardSummary
          userName={userName}
          maskedCardNumber={maskedCardNumber?.substr(-4)}
          cardName={nickname}
          logo={logo}
        />
      ),
    },
    {
      left: "Team",
      right: (
        <TagTeamLabel
          teamName={teamName}
          teamColor={teamColor}
          style={{
            marginLeft: "auto",
          }}
        />
      ),
    },
  ];

  const creditTopUpInformations = [
    {
      left: "Created at",
      right: formattedDate,
    },
    {
      left: "Transaction ID",
      right: referenceNumber,
    },
    {
      left: "Payment to",
      right: (
        <CardSummary
          userName={userName}
          maskedCardNumber={maskedCardNumber?.substr(-4)}
          cardName={nickname}
          logo={logo}
        />
      ),
    },
    {
      left: "Team",
      right: (
        <TagTeamLabel
          teamName={teamName}
          teamColor={teamColor}
          style={{
            marginLeft: "auto",
          }}
        />
      ),
    },
  ];

  const informationsDecider = () => {
    const { isPrepaidTopUp, isWithdrawal, isCreditTopUp } =
      transactionTypeDecider(transactionType);

    if (isCreditTopUp) return creditTopUpInformations;
    if (isMoveBalance) return moveBalanceInformations;
    if (isPrepaidTopUp) return prepaidTopUpInformations;
    if (isWithdrawal) return withdrawalInformations;

    return formattedInformations;
  };

  const informations = informationsDecider();
  return informations;
};
