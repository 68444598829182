import { ToasterHook } from "../../contexts/ToasterContext";

export const useInvalidOnChangeFileInput = (fileTypes, maxSize) => {
  const { errorToaster } = ToasterHook();

  const invalidOnChange = (file) => {
    const fileTypesMsg = fileTypes
      .map((string) => {
        const splitted = string.split("/");
        const lastString = splitted[splitted.length - 1];
        return lastString;
      })
      .join(" or ");

    const { type, name: fileName, size } = file || {};
    const invalidFileType = !fileTypes.includes(type);
    const invalidSize = size > maxSize;

    const defaultError = (string) => errorToaster("Error!", string, 5000);

    if (invalidFileType) {
      defaultError(
        `File format for ${fileName} is invalid. (Accepted file types are: ${fileTypesMsg})`
      );
      return true;
    }

    if (invalidSize) {
      defaultError(
        `File size exceeds maximum limit (${maxSize / (1024 * 1024)} MB)`
      );
      return true;
    }
  };

  return { invalidOnChange };
};
