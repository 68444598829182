import React, { useState } from "react";
import styled from "styled-components";
import { colors, jackColors } from "../../../../assets/colors";
import imgSrc from "../../../../assets/images/vector-pattern.svg";
import WalletIcon from "../../../../assets/images/balance-icon-blue.svg";
import selectedIcon from "../../../../assets/images/selected-radio-icon.svg";
import TransferBankIcon from "../../../../assets/images/transfer-bank-icon-blue.svg";
import RefreshIconPink from "../../../../assets/images/refresh-icon-pink.svg";
import InfoIcon from "../../../../assets/images/info-icon-red.svg";
import { CustomButton } from "../../../../components/Buttons";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import CustomTooltip from "../../../../components/Tooltip";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { DetailsModal } from "../components";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";

export const PaymentMethodSelection = ({
  setSelectedMethod,
  selectedMethod,
  balance,
}) => {
  const dataRender = [
    {
      title: "Company balance",
      value: `Current balance: IDR ${formatCurrencyNoDecimal(balance)}`,
      icon: "account_balance_wallet",
      type: "balance",
    },
    {
      title: "Bank Transfer",
      value: "Manual verification",
      icon: "bank",
      type: "transfer",
    },
  ];

  return (
    <div>
      <GothamMedium style={{ color: jackColors.neutral900, marginBottom: 16 }}>
        Payment Method
      </GothamMedium>
      {dataRender?.map(({ title, value, icon, type }, index) => {
        const isFirst = index === 0;
        const isActive = type === selectedMethod;
        return (
          <div
            key={index}
            className="d-flex justify-content-between align-items-center"
            style={{
              border: `1px solid ${
                isActive ? jackColors.neutral900 : jackColors.neutral500
              }`,
              borderRadius: 4,
              padding: 12,
              marginTop: !isFirst && 12,
              cursor: "pointer",
            }}
            onClick={() => setSelectedMethod(type)}
          >
            <div className="d-flex align-items-center">
              <div
                style={{
                  borderRadius: 8,
                  backgroundColor: jackColors.neutral900,
                  padding: 6,
                  width: 32,
                  height: 32,
                  marginRight: 16,
                }}
              >
                <JackIcons
                  style={{ width: 20, height: 20 }}
                  name={icon}
                  fill="white"
                />
              </div>
              <div>
                <GothamMedium
                  className="font12"
                  style={{ color: colors.grey25, marginBottom: 2 }}
                >
                  {title}
                </GothamMedium>
                <TypeFezNeueRegular
                  style={{
                    color: jackColors.neutral800,
                    fontSize: 10,
                    lineHeight: "12px",
                  }}
                >
                  {value}
                </TypeFezNeueRegular>
              </div>
            </div>

            <JackIcons
              style={{ width: 20, height: 20 }}
              name={isActive ? "radio-button-on" : "radio-button-off"}
              fill={isActive ? jackColors.neutral900 : jackColors.neutral500}
            />
          </div>
        );
      })}
    </div>
  );
};

export const PaymentSummaryMethod = ({
  isNotEnoughBalance,
  selectedMethod,
  refetchUser,
  totalTrx,
  balance,
}) => {
  const isBankTransfer = selectedMethod === "transfer";

  return (
    <div>
      <GothamMedium style={{ color: jackColors.neutral900, marginBottom: 16 }}>
        Payment Summary
      </GothamMedium>
      <Summary
        refetchUser={refetchUser}
        balance={balance}
        totalTrx={totalTrx}
        isBankTransfer={isBankTransfer}
        isNotEnoughBalance={isNotEnoughBalance}
      />
    </div>
  );
};

export const AlertTopup = ({ onClick, balance }) => {
  const balanceText = `Company Balance: IDR ${formatCurrencyNoDecimal(
    balance
  )}`;
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        position: "relative",
        overflow: "hidden",
        padding: 12,
        backgroundColor: "black",
        margin: "0px -1px",
      }}
    >
      <div className="d-flex align-items-center">
        <JackIcons name="alert-circle-outline" fill="white" />
        <div className="ml-2">
          <GothamRegular className="font12" style={{ color: colors.whiteFC }}>
            Insufficient Balance
          </GothamRegular>
          <GothamMedium style={{ color: colors.whiteFC, marginBottom: 0 }}>
            {balanceText}
          </GothamMedium>
        </div>
      </div>
      <div
        onClick={onClick}
        className="d-flex align-items-center"
        style={{
          position: "relative",
          zIndex: 10,
          backgroundColor: jackColors.black34,
          padding: "4px 8px",
          borderRadius: 4,
          right: 24,
          gap: 4,
          cursor: "pointer",
        }}
      >
        <JackIcons
          name="top-up"
          fill={jackColors.greenB9}
          style={{ width: 16, height: 16, marginTop: -2 }}
        />
        <GothamRegular style={{ color: jackColors.greenB9 }}>
          Top up
        </GothamRegular>
      </div>
      <img
        src={imgSrc}
        style={{
          right: 0,
          top: 0,
          position: "absolute",
        }}
      />
    </div>
  );
};

const Summary = ({
  isBankTransfer,
  isNotEnoughBalance,
  balance,
  totalTrx,
  refetchUser,
}) => {
  const remainingBalance = Number(balance) - Number(totalTrx);

  const summaryBalance = [
    {
      title: (
        <>
          Available balance{" "}
          {isNotEnoughBalance && (
            <img src={InfoIcon} style={{ marginTop: -4 }} />
          )}
        </>
      ),
      value: (
        <div className="d-flex align-items-center justify-content-end">
          IDR {formatCurrencyNoDecimal(balance)}{" "}
          {isNotEnoughBalance && (
            <CustomTooltip
              text={<span style={{ fontSize: 12 }}>Refresh</span>}
              style={{ padding: "0px 12px" }}
            >
              <JackIcons
                style={{
                  width: 16,
                  height: 16,
                  marginTop: -4,
                  marginLeft: 4,
                  cursor: "pointer",
                }}
                name="refresh"
                fill={jackColors.orangeF8}
                onClick={refetchUser}
              />
            </CustomTooltip>
          )}
        </div>
      ),
    },
    {
      title: "Amount to pay",
      value: `IDR ${formatCurrencyNoDecimal(totalTrx)}`,
      rightBold: true,
    },
    // { spacer: true },
    // {
    //   title: isNotEnoughBalance ? "You need to top up" : "Remaining balance",
    //   value: `IDR ${formatCurrencyNoDecimal(Math.abs(remainingBalance))}`,
    //   rightBold: true,
    // },
  ];

  const summaryBankTransfer = [
    {
      title: "Amount to pay",
      value: `IDR ${formatCurrencyNoDecimal(totalTrx)}`,
      rightBold: true,
    },
  ];

  const array = isBankTransfer ? summaryBankTransfer : summaryBalance;

  return <DetailsModal array={array} />;
};

const GreyCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 2px;
  border: 1px solid ${colors.greyC2};
`;
