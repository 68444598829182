export const fieldRecipientDetails = [
  "city",
  "msisdn",
  "tax_id",
  "address",
  "id_type",
  "lastname",
  "firstname",
  "id_number",
  "postal_code",
  "registered_name",
  "recipient_email",
  "country_iso_code",
  "country",
  "middlename",
  "nationality_iso_code",
  "province_state",
  "business_name",
  "company_registration_number",
  "gender"
];

export const fieldBankDetails = [
  "bank_id",
  "account_type",
  "routing_code",
  "swift_bic_code",
  "bank_account_name",
  "bank_account_number",
  "iban",
  "branch_number",
  "sort_code",
  "cnaps",
  "bsb_number",
  "aba_routing_number",
];

export const fieldSelection = [
  "bank_id",
  "id_type",
  "account_type",
  "country_iso_code",
  "nationality_iso_code",
  "branch_number",
  "gender"
];

export const fieldTextArea = ["address"];

export const accountTypeOptions = [
  { label: "Checking", value: "checking" },
  { label: "Saving", value: "saving" },
];

export const idTypeOptions = [
  { label: "National ID", value: "NATIONAL_ID" },
  { label: "Driving License", value: "DRIVING_LICENSE" },
  { label: "Passport", value: "PASSPORT" },
];

export const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const gbpAccNumberFields = [
  "bank_id",
  "sort_code",
  "bank_account_number",
];

export const gbpIbanFields = ["iban", "swift_bic_code"];
