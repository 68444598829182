import React from "react";

export const Line = () => (
  <div
    style={{
      height: 1,
      backgroundColor: "rgba(0,0,0,.1)",
      width: "100%",
    }}
  />
);
