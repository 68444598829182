import { useRouter } from "next/router";
import React, { useEffect } from "react";
import LoadingSpinner from "../../../components/Loading";
import { fetch, useMutation } from "../../../tools/api";
import { ValidatingScreen } from "./components";
import { localTransferCounterFormatter } from "./formatter";

export const ValidatingLocalTransfer = () => {
  const { query, push } = useRouter();
  const { id } = query || {};
  const { data, refetch } = fetch({
    url: `/local_transaction_batches/${id}/show_validating_progress`,
    formatter: localTransferCounterFormatter,
    defaultValue: {},
  });

  const { mutation: changeState } = useMutation({
    url: `/local_transaction_batches/${id}/change_state`,
    afterSuccess: () => push(`/local-transfer/${id}`),
  });

  useEffect(() => {
    let interval = setInterval(async () => await refetch(), 3000);

    return () => clearInterval(interval);
  }, []);

  const { processed = 0, total = 1, isCompleted, isDataValidation, res } = data;
  const { state } = res?.data || {};

  useEffect(() => {
    if (!state) return;
    if (total == 0) return changeState({ state: "draft" });
    if (!isCompleted) return;

    if (state == "draft")
      return push({ query: { ...query, id, state: "create" } });

    if (isDataValidation)
      return push({ query: { ...query, id, state: "validation" } });

    push({
      pathname: `/local-transfer/${id}`,
      query: { ...query, state: "confirmation" },
    });
  }, [isCompleted]);

  if (!res) return <LoadingSpinner />;

  return <ValidatingScreen processed={processed} total={total} />;
};
