import React, { useEffect } from "react";
import { colors } from "../../assets/colors";
import { showPDFOrJPEGHooks, useRegisterField } from "../inputs";
import { GothamMedium } from "../Text";
import { FileInputList } from "./list";
import { useInvalidOnChangeFileInput } from "./logics";
import eye from "../../assets/images/eye-show.svg";
import trashGrey from "../../assets/images/trash-grey2.svg";
import pencil from "../../assets/images/thin-pencil.svg";

export const FileInputText = ({
  text = "upload",
  useFormObj,
  name = "upload_file",
  fileTypes = ["application/pdf", "image/jpeg"],
  multiple = true,
  maxSize = 1024 * 1024 * 10,
  defaultValue = [],
  editFilename,
}) => {
  useRegisterField(useFormObj, name);
  const defaultSetValue = (value) => setValue(name, value);

  const { watch, setValue } = useFormObj;
  const currValue = watch(name) || [];

  useEffect(() => {
    if (!defaultValue) return;
    if (currValue.length) return;
    defaultSetValue(defaultValue);
  }, [defaultValue.length]);

  const { handleShow, modalComponent } = showPDFOrJPEGHooks(currValue);

  const { invalidOnChange } = useInvalidOnChangeFileInput(fileTypes, maxSize);

  const onChange = (event) => {
    const { files = [] } = event?.target || {};

    let newFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const invalid = invalidOnChange(file);
      if (invalid) return;
      newFiles.push(file);
    }
    defaultSetValue([...currValue, ...newFiles]);
  };

  const onClick = async (file, type) => {
    const { name: fileName } = file || {};

    const isDelete = type == "delete";
    const isShow = type == "show";
    const isEditName = type == "edit_submit";

    if (isDelete) {
      const filtered = currValue.filter(({ name }) => name != fileName);
      return defaultSetValue(filtered);
    }
    if (isShow) return handleShow(fileName);
    if (isEditName) {
      const { file: realFile, file_name } = file || {};
      const { type, name, url, id } = realFile || {};

      if (url) {
        const changeValues = currValue.map((file) => {
          const { name: currName } = file;
          if (currName == name) return { ...file, name: file_name };
          return file;
        });
        defaultSetValue(changeValues);
        editFilename({ file_name, id });
        return;
      }

      const dotType = `.${String(name).split(".").pop()}`;
      const renamedFile = new File([realFile], `${file_name}${dotType}`, {
        type,
      });

      const changeValues = currValue.map((file) => {
        const { name: currName } = file;
        if (currName == name) return renamedFile;
        return file;
      });

      defaultSetValue(changeValues);
      return;
    }
  };

  const dotsArray = [
    {
      label: "Show",
      value: "show",
      isPink: true,
      src: eye,
    },
    Boolean(editFilename) && {
      label: "Edit",
      value: "edit",
      src: pencil,
      isPink: true,
    },
    ,
    {
      label: "Delete",
      value: "delete",
      src: trashGrey,
    },
  ];

  return (
    <>
      <FileInputList
        value={currValue}
        onClick={onClick}
        dotsArray={dotsArray}
      />
      <label>
        <GothamMedium style={{ color: colors.pink }} className="hover">
          {text}
        </GothamMedium>
        <input
          type="file"
          style={{
            display: "none",
          }}
          onChange={onChange}
          multiple={multiple}
        />
      </label>
      {modalComponent}
    </>
  );
};
