const InvoiceImage = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M196.18 56.9396V173.64H3.82001V56.9396C3.82001 52.4696 7.43001 48.8496 11.9 48.8496H188.1C192.56 48.8496 196.18 52.4696 196.18 56.9396Z"
        fill="white"
      />
      <path
        d="M196.18 173.96H3.82C3.64 173.96 3.5 173.82 3.5 173.64V56.9403C3.5 52.3003 7.27 48.5303 11.91 48.5303H188.11C192.74 48.5303 196.52 52.3003 196.52 56.9403V173.64C196.51 173.82 196.36 173.96 196.18 173.96ZM4.14 173.32H195.86V56.9403C195.86 52.6603 192.38 49.1703 188.1 49.1703H11.9C7.62 49.1703 4.14 52.6503 4.14 56.9403V173.32Z"
        fill="black"
      />
      <path
        d="M196.18 62.4703H3.82C3.64 62.4703 3.5 62.3303 3.5 62.1503V56.9403C3.5 52.3003 7.27 48.5303 11.91 48.5303H188.11C192.74 48.5303 196.52 52.3003 196.52 56.9403V62.1503C196.51 62.3303 196.36 62.4703 196.18 62.4703ZM4.14 61.8303H195.86V56.9503C195.86 52.6703 192.38 49.1803 188.1 49.1803H11.9C7.62 49.1803 4.14 52.6603 4.14 56.9503V61.8303Z"
        fill="black"
      />
      <path
        d="M11.81 57.6301C12.9864 57.6301 13.94 56.6765 13.94 55.5001C13.94 54.3238 12.9864 53.3701 11.81 53.3701C10.6336 53.3701 9.67999 54.3238 9.67999 55.5001C9.67999 56.6765 10.6336 57.6301 11.81 57.6301Z"
        fill="#B9FC00"
      />
      <path
        d="M17.8 57.6301C18.9763 57.6301 19.93 56.6765 19.93 55.5001C19.93 54.3238 18.9763 53.3701 17.8 53.3701C16.6236 53.3701 15.67 54.3238 15.67 55.5001C15.67 56.6765 16.6236 57.6301 17.8 57.6301Z"
        fill="#B9FC00"
      />
      <path
        d="M23.79 57.6301C24.9663 57.6301 25.92 56.6765 25.92 55.5001C25.92 54.3238 24.9663 53.3701 23.79 53.3701C22.6136 53.3701 21.66 54.3238 21.66 55.5001C21.66 56.6765 22.6136 57.6301 23.79 57.6301Z"
        fill="#B9FC00"
      />
      <path
        d="M84.54 90.8705H20.23C18.71 90.8705 17.47 89.6305 17.47 88.1105V81.4905C17.47 79.9705 18.71 78.7305 20.23 78.7305H84.54C86.06 78.7305 87.3 79.9705 87.3 81.4905V88.1105C87.3 89.6305 86.07 90.8705 84.54 90.8705ZM20.23 79.3805C19.06 79.3805 18.12 80.3305 18.12 81.4905V88.1105C18.12 89.2805 19.07 90.2205 20.23 90.2205H84.54C85.71 90.2205 86.65 89.2705 86.65 88.1105V81.4905C86.65 80.3205 85.7 79.3805 84.54 79.3805H20.23Z"
        fill="black"
      />
      <path
        d="M84.54 166.79H20.23C18.71 166.79 17.47 165.55 17.47 164.03V99.72C17.47 98.2 18.71 96.96 20.23 96.96H84.54C86.06 96.96 87.3 98.2 87.3 99.72V164.03C87.3 165.55 86.07 166.79 84.54 166.79ZM20.23 97.61C19.06 97.61 18.12 98.56 18.12 99.72V164.03C18.12 165.2 19.07 166.14 20.23 166.14H84.54C85.71 166.14 86.65 165.19 86.65 164.03V99.72C86.65 98.55 85.7 97.61 84.54 97.61H20.23Z"
        fill="black"
      />
      <path
        d="M179.77 166.79H99.7C98.18 166.79 96.94 165.55 96.94 164.03V81.4905C96.94 79.9705 98.18 78.7305 99.7 78.7305H179.76C181.28 78.7305 182.52 79.9705 182.52 81.4905V164.03C182.53 165.55 181.29 166.79 179.77 166.79ZM99.7 79.3805C98.53 79.3805 97.59 80.3305 97.59 81.4905V164.03C97.59 165.2 98.54 166.14 99.7 166.14H179.76C180.93 166.14 181.87 165.19 181.87 164.03V81.4905C181.87 80.3205 180.92 79.3805 179.76 79.3805H99.7Z"
        fill="black"
      />
      <path
        d="M160.54 173.64V26.3604H73.97L44.44 56.1504V173.64H160.54Z"
        fill="white"
      />
      <path
        d="M160.54 173.96H44.44C44.26 173.96 44.12 173.82 44.12 173.64V56.15C44.12 56.06 44.15 55.98 44.21 55.92L73.74 26.14C73.8 26.08 73.88 26.04 73.97 26.04H160.54C160.72 26.04 160.86 26.18 160.86 26.36V173.64C160.86 173.82 160.72 173.96 160.54 173.96ZM44.77 173.32H160.22V26.68H74.1L44.77 56.28V173.32Z"
        fill="black"
      />
      <path d="M73.97 56.1504V26.3604L44.44 56.1504H73.97Z" fill="white" />
      <path
        d="M73.97 56.47H44.44C44.31 56.47 44.19 56.39 44.14 56.27C44.09 56.15 44.12 56.01 44.21 55.92L73.74 26.14C73.83 26.05 73.97 26.02 74.09 26.07C74.21 26.12 74.29 26.24 74.29 26.37V56.15C74.29 56.32 74.15 56.47 73.97 56.47ZM45.22 55.82H73.65V27.15L45.22 55.82Z"
        fill="black"
      />
      <path
        d="M119.362 67.2658C122.869 58.5385 118.366 48.5127 109.306 44.8726C100.246 41.2324 90.0582 45.3563 86.5519 54.0836C83.0456 62.8109 87.548 72.8367 96.6084 76.4768C105.669 80.117 115.856 75.9931 119.362 67.2658Z"
        fill="white"
      />
      <path
        d="M103.39 78.1194C101.1 78.1194 98.76 77.6894 96.49 76.7794C92.03 74.9894 88.51 71.6294 86.57 67.3194C84.63 63.0094 84.52 58.2694 86.25 53.9594C89.82 45.0794 100.21 40.8694 109.42 44.5694C113.88 46.3594 117.4 49.7194 119.34 54.0294C121.28 58.3394 121.39 63.0793 119.66 67.3794C116.98 74.0793 110.41 78.1194 103.39 78.1194ZM102.52 43.8794C95.76 43.8794 89.44 47.7694 86.85 54.2094C85.19 58.3494 85.3 62.9094 87.16 67.0594C89.03 71.2094 92.42 74.4494 96.73 76.1794C98.92 77.0594 101.18 77.4794 103.39 77.4794C110.15 77.4794 116.47 73.5894 119.06 67.1494C120.72 63.0094 120.61 58.4494 118.75 54.2994C116.88 50.1494 113.49 46.9094 109.18 45.1794C106.99 44.2894 104.73 43.8794 102.52 43.8794Z"
        fill="black"
      />
      <path
        d="M101.18 71.7601C100.56 71.7601 100.29 71.4801 100.3 70.8701L100.31 69.5401L96.93 69.5201C96.31 69.5201 96.04 69.2401 96.05 68.6301L96.07 65.7401C96.07 65.1201 96.35 64.8501 96.96 64.8601L103.59 64.9101C104.01 64.9101 104.23 64.5901 104.24 64.2001C104.24 63.6301 104.02 63.4101 103.6 63.3601L99.9 62.8201C97.58 62.4601 96.09 61.4601 96.1 58.7001L96.12 56.1101C96.14 53.5401 97.73 52.0801 100.42 51.8501L100.43 50.4701C100.43 49.8501 100.71 49.5801 101.32 49.5901L104.9 49.6101C105.52 49.6101 105.79 49.8901 105.78 50.5001L105.77 51.8301L108.16 51.8501C108.78 51.8501 109.05 52.1301 109.04 52.7401L109.02 55.6801C109.02 56.3001 108.74 56.5701 108.13 56.5601L102.38 56.5201C101.96 56.5201 101.71 56.8101 101.71 57.2601C101.71 57.7001 101.95 58.0001 102.37 58.0501L106.04 58.5701C108.36 58.9301 109.88 59.9301 109.86 62.6901L109.84 65.2801C109.82 67.8001 108.26 69.2901 105.64 69.5401L105.63 70.9201C105.63 71.5401 105.35 71.8101 104.74 71.8001L101.18 71.7601Z"
        fill="#B9FC00"
      />
      <path d="M102.95 89.2598H57.79V89.9098H102.95V89.2598Z" fill="black" />
      <path d="M145.77 105.8H57.79V106.45H145.77V105.8Z" fill="black" />
      <path d="M145.77 122.34H57.79V122.99H145.77V122.34Z" fill="black" />
      <path d="M145.77 138.87H57.79V139.52H145.77V138.87Z" fill="black" />
      <path d="M145.77 155.41H57.79V156.06H145.77V155.41Z" fill="black" />
    </svg>
  );
};

export default InvoiceImage;
