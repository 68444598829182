import { startCase } from "lodash";
import { useState } from "react";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../components/Text";
import CustomTooltip from "../../components/Tooltip";
import { customDateFormatter, windowDimension } from "../../components/tools";
import RadioButton from "../cardPageComponents/components/CardModal/components/RadioButton";
import { iconAndSubCategoryDecider } from "./logic";
import styles from "./notif.module.css";
import { eventsTracker } from "universalFunctions/events";
import { memo } from "react";

export const NotifFilter = ({
  handleMarkAllAsRead = () => {},
  disableUnread = false,
  loading,
  tab = "activity",
}) => {
  const filterList = [
    {
      label: "All",
      value: false,
      disabled: false,
    },
    {
      label: "Unread",
      value: true,
      disabled: disableUnread,
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        position: "sticky",
        top: 41 + 72,
        zIndex: 10000,
        background: "white",
      }}
    >
      <div style={{ display: "flex", gap: 8, padding: "12px 0" }}>
        <div id="filter-gt">
          <RadioButton
            queryName="is_read"
            label=""
            list={filterList}
            defaultValue={filterList[0].value}
            // runQueryName="runQueryTransaction"
            loading={loading}
            fontClassName={"font12"}
            customOnclick={(item) => {
              const isAll = item?.label === "All";
              const isActivity = tab === "activity";

              eventsTracker(`click_filter_${isAll ? 1 : 2}`, {
                page_title: `notif_tab_${isActivity ? 1 : 2}`,
              });
            }}
          />
        </div>
      </div>
      {!disableUnread && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleMarkAllAsRead()}
          id="markasread-gt"
        >
          <GothamMedium
            className="font12"
            style={{ textDecoration: "underline" }}
          >
            Mark all as read
          </GothamMedium>
        </div>
      )}
    </div>
  );
};

export const NotifList = ({ data, handleOnPress, handleMarkAsRead }) => {
  return (
    <div>
      {data?.map((item, index) => (
        <NotifItem
          item={item}
          key={index}
          handleOnPress={handleOnPress}
          handleMarkAsRead={handleMarkAsRead}
        />
      ))}
    </div>
  );
};

export const NotifItem = memo(({ item, handleOnPress, handleMarkAsRead }) => {
  const [hover, setHover] = useState(false);
  const {
    id,
    created_at: date,
    title_eng: title,
    content_eng: contentRaw,
    is_read: read,
    notification_subgroup,
    navigation_type,
  } = item;

  const { iconName, subcategory } = iconAndSubCategoryDecider(
    notification_subgroup
  );
  const content = contentRaw + " Click here for more details.";

  return (
    <div
      className={`${styles["notif-item-container"]} ${
        !read && styles["notif-item-unread"]
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={styles["notif-item"]} onClick={() => handleOnPress(item)}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
              <JackIcons
                name={iconName}
                fill={jackColors.black34}
                style={{ height: 16, width: 16 }}
              />
              <GothamRegular
                className="font12"
                style={{ color: jackColors.grey90 }}
              >
                {subcategory}
              </GothamRegular>
            </div>
            <JackIcons
              name="Ellipse"
              fill={jackColors.grey6C}
              style={{ height: 4, width: 4, padding: "0px 8px" }}
            />
            <GothamRegular
              className="font12"
              style={{ color: jackColors.grey90 }}
            >
              {customDateFormatter(
                date,
                false,
                false,
                false,
                false,
                false,
                true
              )}
            </GothamRegular>
          </div>
        </div>
        <div>
          <GothamMedium
            className="font14"
            style={{ color: jackColors.black34 }}
          >
            {title}
          </GothamMedium>
        </div>
        <div>
          <GothamRegular
            className="font14"
            style={{ color: jackColors.grey6C, letterSpacing: 0.1 }}
          >
            {content}
          </GothamRegular>
        </div>
      </div>

      {!read && (
        <div
          style={{ opacity: hover ? "100%" : 0, paddingLeft: 16 }}
          onClick={() => handleMarkAsRead(id, notification_subgroup)}
        >
          <CustomTooltip
            placement="left"
            hideArrow
            innerClassName="duplicateTooltip"
            text={
              <GothamRegular
                className="text-grey6c font12"
                style={{ color: jackColors.grey6C }}
              >
                mark as read
              </GothamRegular>
            }
          >
            <div className={styles["check-icon"]}>
              <JackIcons name="NotifRead" />
            </div>
          </CustomTooltip>
        </div>
      )}
    </div>
  );
});

export const NotificationIcon = ({ counter, handleClickBell, showCounter }) => {
  const counterDecider = +counter > 99 ? "99+" : counter;
  const getRightPosition = () => {
    const digit = (Math.log(counter) * Math.LOG10E + 1) | 0;
    switch (digit) {
      case 1:
        return -4;
      case 2:
        return -8;
      case 3:
        return -14;
    }
  };

  return (
    <div
      id="notification-icon"
      className="notification-icon"
      style={{
        position: "relative",
        display: "inline-block",
      }}
    >
      <JackIcons
        name="bell"
        fill={jackColors.black34}
        style={{ height: 20, width: 20 }}
        onClick={handleClickBell}
        className="hover"
      />
      {showCounter && counter > 0 && (
        <span
          style={{
            position: "absolute",
            top: -4,
            right: getRightPosition(),
            backgroundColor: "red",
            borderRadius: 49,
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <GothamMedium
            style={{
              color: "white",
              padding: "0 4px",
              fontSize: 10,
              lineHeight: "120%",
            }}
          >
            {counterDecider}
          </GothamMedium>
        </span>
      )}
    </div>
  );
};

export const EmptyStateNotif = () => {
  const { screenRealHeight } = windowDimension();
  const contentHeight = screenRealHeight - 96;

  return (
    <div
      className={styles["empty-state-container"]}
      style={{ height: contentHeight }}
    >
      <JackIcons name="EmptyState" />
      <GothamMedium
        className={"font14"}
        style={{ marginTop: 16, marginBottom: 8, textAlign: "center" }}
      >
        You’re up to date! <br />
        Check back later for new notifications
      </GothamMedium>
    </div>
  );
};
