import { colors } from "../assets/colors";
import { JackIcons } from "../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "./Text";
import { windowDimension } from "./tools";

export const Banner = ({
  title = "",
  msg = "",
  type = "information",
  button = null,
  titleStyle,
  msgStyle: msgStyleProps = {},
  className: classNameProps = "",
  bannerStyle: bannerStyleProps = {},
  iconStyle: iconStyleProps = {},
  textContainerStyle: textContainerStyleProps = {},
  noIcon = false,
  style = {},
  customIcon,
  noTopBorderRadius = false,
  noBorder = false,
}) => {
  // type = information || warning || error

  const iconDecider = () => {
    if (customIcon) return customIcon;
    switch (type) {
      case "information":
        return { name: "info", fill: colors.neutral700 };
      case "warning":
        return { name: "alert-circle", fill: colors.yellowFC };
      case "error":
        return { name: "alert-triangle", fill: colors.redE7 };
      case "info-alternate":
        return { name: "Lightbulb", fill: colors.grey90 };
      case "success":
        return { name: "CheckmarkCircle2", fill: "white" };
    }
  };

  const iconProps = iconDecider();

  const typeStyleDecider = () => {
    switch (type) {
      case "information":
      case "info-alternate":
        return {
          backgroundColor: colors.neutral400,
          border: noBorder ? "" : `1px solid ${colors.neutral600}`,
        };
      case "warning":
        return {
          backgroundColor: colors.yellowF7,
          border: `1px solid ${colors.yellowFC}`,
        };
      case "error":
        return {
          backgroundColor: colors.redFF,
          border: `1px solid ${colors.redE7}`,
        };
      case "success":
        return {
          backgroundColor: colors.greenE5,
          border: `1px solid ${colors.green06}`,
        };
    }
  };

  const typeStyle = typeStyleDecider();

  const bannerStyle = {
    display: "flex",
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
    justifyContent: "space-between",
    borderTopLeftRadius: noTopBorderRadius ? "0" : "4px", // Adjust the values as needed
    borderTopRightRadius: noTopBorderRadius ? "0" : "4px",
    ...typeStyle,
    ...bannerStyleProps,
    ...style,
  };

  const textIconStyle = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    width: "100%",
  };

  const iconStyle = {
    width: "20px",
    heigth: "20px",
    ...iconStyleProps,
  };

  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    width: "100%",
    ...textContainerStyleProps,
  };

  const headingStyle = {
    color: colors.neutral900,
    fontSize: "12px",
    lineHeight: "16px",
    ...titleStyle,
  };

  const msgStyle = {
    color: colors.neutral800,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    ...msgStyleProps,
  };

  return (
    <div className={classNameProps} style={bannerStyle}>
      <div style={textIconStyle}>
        {!noIcon && (
          <JackIcons
            name={iconProps.name}
            fill={iconProps.fill}
            style={iconStyle}
          />
        )}
        <div style={textContainerStyle}>
          {title && <GothamMedium style={headingStyle}>{title}</GothamMedium>}
          <GothamRegular style={msgStyle}>{msg}</GothamRegular>
        </div>
      </div>
      {button && button}
    </div>
  );
};
