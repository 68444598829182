import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import styles from "./NewFeature.module.css";
import CustomHoverInfo from "../../../../../components/CustomHoverInfo/CustomHoverInfo";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { useEffect, useState } from "react";

const NewFeature = ({
  woHoverInfo = false,
  hoverText = {
    title: "Keep your transactions organized!",
    sub: "Get better company finance report and analysis with Categories!",
  },
}) => {
  const [showHoverInfo, setShowHoverInfo] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowHoverInfo(true), 1000);
  }, []);

  return (
    <div className={styles["new-tag"]}>
      <GothamMedium className="font12">New</GothamMedium>
      {!woHoverInfo && (
        <CustomHoverInfo
          show={showHoverInfo}
          isStopBubbling
          backgroundColor={jackColors.neutral900}
          translateTrianglePoint="-9px 32px"
          style={{
            translate: "48px -30px",
            width: "320px",
            padding: "12px",
            paddingRight: "8px",
            paddingTop: "8px",
          }}
        >
          <div className={styles["info"]}>
            <div>
              <GothamMedium className="font14" style={{ color: "white" }}>
                {hoverText.title}
              </GothamMedium>
              <JackIcons
                name="close"
                isStopBubbling
                style={{ scale: "0.7", cursor: "pointer" }}
                onClick={() => setShowHoverInfo(false)}
              />
            </div>
            <div>
              <GothamRegular
                woFontColor
                className="font12"
                style={{ color: jackColors.neutral500 }}
              >
                {hoverText.sub}
              </GothamRegular>
            </div>
          </div>
        </CustomHoverInfo>
      )}
    </div>
  );
};

export default NewFeature;
