import { Modal } from "reactstrap";
import styles from "../../styles.module.css";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { jackColors } from "../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import {
  useSubscriptionTemplates,
  useSubscriptionUsage,
} from "../../logics/general-hooks";
import { useModalHook } from "../../../../components/Modals";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { useMutation } from "../../../../tools/api";
import { useGetAuth } from "../../../../contexts/AuthContext";
import InvoiceImage from "./InvoiceImage";
import ReimbursementImage from "./ReimbursementImage";

const mainTextDecider = (type = "") => {
  switch (type) {
    case "invoice":
      return "Let’s create your first bill payment!";
    case "reimbursement":
      return "Let’s create your first reimbursement!";
    default:
      return "";
  }
};
const secondaryTextDecider = (type = "", number = 0) => {
  switch (type) {
    case "invoice":
      return (
        <>
          With your current plan, you can submit{" "}
          <span
            style={{ fontFamily: "GothamMedium", color: jackColors.neutral800 }}
          >
            max. {number} invoices/month
          </span>
          . Upgrade your plan anytime for unlimited invoices.
        </>
      );
    case "reimbursement":
      return (
        <>
          With your current plan, you can submit{" "}
          <span
            style={{ fontFamily: "GothamMedium", color: jackColors.neutral800 }}
          >
            max. {number} expenses/month
          </span>
          . Upgrade your plan anytime for unlimited expenses.
        </>
      );
    default:
      return "";
  }
};

const CloseButton = ({ onClick = () => {} }) => {
  return (
    <button className={styles["close-btn"]} onClick={onClick}>
      <JackIcons name="close-outline" fill={jackColors.neutral900} />
    </button>
  );
};

const OneTimeSeedModal = ({ type = "" }) => {
  const isInvoice = type === "invoice";
  const isReimbursement = type === "reimbursement";

  const { push } = useRouter();

  const { isOpen, open, close } = useModalHook();

  const { user, refetchUser } = useGetAuth();
  const { first_invoice, first_reimbursement } = user ?? {};

  const {
    isSeedPlan,
    invoice_qty_left,
    reimbursement_qty_left,
    loadingSubscriptionUsage,
  } = useSubscriptionUsage();
  const { seedPlanRules, loading } = useSubscriptionTemplates();

  const { mutation: declareNotifed } = useMutation({
    url: `/business_users/reimbursement_and_invoice_upload_notif?module=${type}`,
  });

  const isSeedV2 =
    isSeedPlan && (invoice_qty_left != null || reimbursement_qty_left != null);

  const isSeedV2Reimbursement = isSeedPlan && reimbursement_qty_left != null;
  const isSeedV2Invoice = isSeedPlan && invoice_qty_left != null;

  const { max_invoice_free_fee, max_reimbursement_free_fee } =
    seedPlanRules ?? {};

  const isLoading = loading || loadingSubscriptionUsage;

  const mainText = mainTextDecider(type);
  const secondaryText = secondaryTextDecider(
    type,
    isInvoice ? max_invoice_free_fee : max_reimbursement_free_fee
  );

  useEffect(() => {
    if (isSeedV2 && !isLoading) {
      if (
        (isInvoice && first_invoice && isSeedV2Invoice) ||
        (isReimbursement && first_reimbursement && isSeedV2Reimbursement)
      ) {
        open();
        declareNotifed().then(() => {
          refetchUser();
        });
      }
    }
  }, [isSeedV2, isLoading]);

  const handleClose = () => {
    close();
  };

  const handleExplore = () => {
    push("/plans/showcase");
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={handleClose}
      style={{ width: "480px" }}
    >
      <div className={styles["one-time-seed-modal"]}>
        <CloseButton onClick={handleClose} />
        {isInvoice ? <InvoiceImage /> : <ReimbursementImage />}
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          {mainText}
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          {secondaryText}
        </GothamRegular>
        <div>
          <ButtonJack style={{ width: "100%" }} onClick={handleClose}>
            I understand
          </ButtonJack>
          <ButtonJack
            type="outline"
            style={{ width: "100%" }}
            onClick={handleExplore}
          >
            Explore plan now
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

export default OneTimeSeedModal;
