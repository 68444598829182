import { jackColors } from "../../assets/colors";
import { GothamMedium } from "../Text";
import styles from "./styles.module.css";

const NewTag = () => {
  return (
    <div className={styles.newTag}>
      <GothamMedium className="font10" style={{ color: jackColors.neutral900 }}>
        New
      </GothamMedium>
    </div>
  );
};

export default NewTag;
