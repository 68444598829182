import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { RightModal } from "../../components/Modals/RightModal/parent";
import { booleanState } from "../../components/Status";
import TabMenu from "../../components/TabMenu/parent";
import { GothamBold } from "../../components/Text";
import { useConstants } from "../../contexts/ConstantsContext/parent";
import NotifTabScreen from "./screens/NotifTabScreen";
import useNotifications from "./useNotifications";
import { queryDecider, pathDecider, urlCategoryDecider } from "./logic";
import { eventsTracker } from "../../universalFunctions/events";
import { apiBusiness } from "../../tools/api";

const styles = {
  scrollContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
  },
  outerContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    alignItems: "center",
    backgroundColor: "white",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  tabMenuContainer: {
    position: "sticky",
    top: 72,
    zIndex: 1000,
    margin: 0,
    padding: "0 16px",
    backgroundColor: "white",
  },
  tabMenuStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    position: "sticky",
  },
};

export const NotificationModal = ({ isOpen, toggle }) => {
  if (!isOpen) return null;

  const {
    counterActivity,
    counterNews,
    tourRunning,
    toListener,
    refetchActivity,
    refetchNews,
  } = useConstants();

  const [scrollProps, setScrollProps] = useState({});
  const { scrollTop = 0, scrollHeight = 0, clientHeight } = scrollProps || {};

  const canRefetch = scrollTop + clientHeight > scrollHeight - 30;

  const {
    push,
    pathname: currentPathname,
    query: currentQuery,
    replace,
  } = useRouter();

  useEffect(() => {
    refetchActivity();
    refetchNews();
  }, []);

  const { markAsRead } = useNotifications();

  const ctaHandler = async (item) => {
    const {
      navigation_type,
      originator_type,
      originator_id,
      id,
      additional_data,
      notification_subgroup,
    } = item;

    const params = {
      id: originator_id,
      type: originator_type,
    };

    const { card_id, card_type } = additional_data || {};
    const isPrepaid = card_type === "virtual_prepaid";

    const pathname = pathDecider(navigation_type);
    const query = queryDecider(navigation_type, params);
    const detailsNavType = ["transactions_detail", "task_detail"];

    const redirectItem = (desiredPath, desiredQuery) => {
      markAsRead(id);
      eventsTracker("click_notif", { subgroup: notification_subgroup });
      toggle();
      if (originator_type === "CardTransaction") {
        if (isPrepaid)
          return setTimeout(() => {
            push(
              `/cards/?menu=prepaid&spendingChart=this_cycle&tempSpendingChart=this_cycle&id=${card_id}&originator_id=${originator_id}`
            );
          }, 200);
        else
          return setTimeout(() => {
            push(
              `/cards/?menu=credit&spendingChart=this_cycle&tempSpendingChart=this_cycle&id=${card_id}&originator_id=${originator_id}`
            );
          }, 200);
      } else if (
        pathname === currentPathname &&
        originator_type !== "CardTransaction"
      ) {
        replace({
          query: {
            ...currentQuery,
            ...desiredQuery,
          },
        });
      } else {
        setTimeout(() => {
          push({
            pathname: desiredPath,
            query: desiredQuery,
          });
        }, 200);
      }
    };

    if (detailsNavType.includes(navigation_type)) {
      try {
        const url = urlCategoryDecider(originator_type, originator_id, true);
        const { data } = await apiBusiness.get(url);
        redirectItem(pathname, query);
      } catch (error) {
        console.log(error);
        redirectItem(
          navigation_type === "task_detail" ? "/task" : "/transactions",
          {}
        );
      }
      return;
    }

    redirectItem(pathname, query);
  };

  const components = [
    <div
      onScroll={(e) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } =
          e.target || {};
        setScrollProps({
          scrollTop,
          scrollHeight,
          clientHeight,
          scrollLeft,
        });
      }}
      style={styles.scrollContainer}
    >
      <div style={styles.outerContainer}>
        <GothamBold style={{ fontSize: 24, lineHeight: "32px" }}>
          Notifications
        </GothamBold>
        <JackIcons
          name="SettingsNotif"
          fill={jackColors.greyBB}
          style={{ height: 20, width: 20 }}
          onClick={() => {
            push("/notifications");
            eventsTracker("click_notif_settings");
          }}
          className="hover"
        />
      </div>
      <TabMenu
        id="notiftab-gt"
        hideZero
        deleteQueries
        containerStyle={styles.tabMenuContainer}
        style={styles.tabMenuStyle}
        menus={[
          {
            screenName: "Activity",
            number: tourRunning ? 0 : counterActivity,
            screen: (
              <NotifTabScreen
                tourRunning={tourRunning}
                canRefetch={canRefetch}
                tab={"activity"}
                handleOnPress={ctaHandler}
              />
            ),
          },
          {
            screenName: "News & Promo",
            number: tourRunning ? 1 : counterNews,
            screen: (
              <NotifTabScreen
                tourRunning={tourRunning}
                canRefetch={canRefetch}
                tab={"news_and_promo"}
                handleOnPress={ctaHandler}
              />
            ),
          },
        ]}
      />
    </div>,
  ];

  // useEffect(() => {
  //   if (!isOpen) return;
  //   massRefetch();
  // }, [isOpen]);

  return (
    <RightModal
      listener={toListener}
      isOpen={isOpen}
      toggle={toggle}
      array={components}
      width={[420]}
      noPadding
    />
  );
};
