import styles from "./CategoryWelcomingModal.module.css";
import SimpleCenteredModal from "../../../../../modals/SimpleCenteredModal";
import { useEffect } from "react";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../../../components/Text";
import { jackColors } from "../../../../../assets/colors";
import { useModalHook } from "../../../../../components/Modals";
import { useCategoryFlag, useCategoryWelcomingUpdates } from "../../hooks";
import { useRouter } from "next/router";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { isEmpty } from "lodash";
import NextImage from "../../../../../components/NextImage";
import CategoryArticle from "../CategoryArticle";

const CategoryWelcomingModal = () => {
  const { pathname } = useRouter();

  const { user } = useGetAuth();

  const { isOpen, open, close } = useModalHook();

  const {
    categoryFlags,
    isActiveCategory,
    hasWelcomedCategoryTrx,
    hasWelcomedCategoryTask,
    addCategoryFlag,
  } = useCategoryFlag();

  const updates = useCategoryWelcomingUpdates();

  const handleClose = () => {
    close();

    switch (pathname) {
      case "/task":
        addCategoryFlag(categoryFlags.hasWelcomedCategoryTask);
        break;

      case "/transactions":
        addCategoryFlag(categoryFlags.hasWelcomedCategoryTrx);
        break;

      default:
        break;
    }
  };

  // Modal opener
  useEffect(() => {
    if (isEmpty(user) || !user) return;

    if (isActiveCategory) {
      switch (pathname) {
        case "/task":
          if (!hasWelcomedCategoryTask) return open();

        case "/transactions":
          if (!hasWelcomedCategoryTrx) return open();

        default:
          return;
      }
    }
  }, [
    user,
    pathname,
    isActiveCategory,
    hasWelcomedCategoryTrx,
    hasWelcomedCategoryTask,
  ]);

  return (
    <SimpleCenteredModal
      modalClassName={styles.categoryWelcomingModal}
      isOpen={isOpen}
      toggle={handleClose}
      buttonText="Got it!"
      leftHeaderComponent={
        <div className={styles.leftHeader}>
          <GothamBold
            woFontSize
            className="font20"
            style={{ color: jackColors.neutral900, margin: 0 }}
          >
            We have some updates for you!
          </GothamBold>
          <GothamRegular woFontColor style={{ color: jackColors.neutral800 }}>
            For further details, you can go check <CategoryArticle />
          </GothamRegular>
        </div>
      }
      onClick={() => {
        handleClose();
      }}
    >
      <div className={styles.updateContainer}>
        {updates.map((update) => {
          const { name, imgSrc, description } = update ?? {};

          if (!update) return null;

          return (
            <div key={name} className={styles.update}>
              <div className={styles.imageContainer}>
                <NextImage
                  src={imgSrc}
                  layout="fill"
                  objectFit="fill"
                  alt="Category Update Image"
                />
              </div>
              <div className={styles.textContainer}>
                <GothamMedium style={{ color: jackColors.neutral900 }}>
                  {name}
                </GothamMedium>
                <GothamRegular
                  woFontColor
                  className="font12"
                  style={{ color: jackColors.neutral800 }}
                >
                  {description}
                </GothamRegular>
              </div>
            </div>
          );
        })}
      </div>
    </SimpleCenteredModal>
  );
};

export default CategoryWelcomingModal;
