import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../../components/Text";
import styles from "../../styles.module.css";

const AlertInfoIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1C5.0111 1 4.0444 1.29324 3.22215 1.84265C2.39991 2.39206 1.75904 3.17295 1.3806 4.08658C1.00217 5.00021 0.90315 6.00555 1.09608 6.97545C1.289 7.94536 1.76521 8.83627 2.46447 9.53553C3.16373 10.2348 4.05465 10.711 5.02455 10.9039C5.99446 11.0969 6.99979 10.9978 7.91342 10.6194C8.82705 10.241 9.60794 9.6001 10.1573 8.77785C10.7068 7.95561 11 6.98891 11 6C11 5.34339 10.8707 4.69321 10.6194 4.08658C10.3681 3.47995 9.99983 2.92876 9.53554 2.46447C9.07124 2.00017 8.52005 1.63188 7.91342 1.3806C7.30679 1.12933 6.65661 1 6 1ZM6 8.5C5.90111 8.5 5.80444 8.47068 5.72222 8.41574C5.63999 8.36079 5.57591 8.2827 5.53806 8.19134C5.50022 8.09998 5.49032 7.99945 5.50961 7.90245C5.5289 7.80546 5.57652 7.71637 5.64645 7.64645C5.71638 7.57652 5.80547 7.5289 5.90246 7.50961C5.99945 7.49031 6.09998 7.50022 6.19134 7.53806C6.28271 7.5759 6.3608 7.63999 6.41574 7.72221C6.47068 7.80444 6.5 7.90111 6.5 8C6.5 8.13261 6.44732 8.25979 6.35356 8.35355C6.25979 8.44732 6.13261 8.5 6 8.5ZM6.5 6.5C6.5 6.63261 6.44732 6.75979 6.35356 6.85355C6.25979 6.94732 6.13261 7 6 7C5.86739 7 5.74022 6.94732 5.64645 6.85355C5.55268 6.75979 5.5 6.63261 5.5 6.5V4C5.5 3.86739 5.55268 3.74021 5.64645 3.64645C5.74022 3.55268 5.86739 3.5 6 3.5C6.13261 3.5 6.25979 3.55268 6.35356 3.64645C6.44732 3.74021 6.5 3.86739 6.5 4V6.5Z"
        fill="#DC2F44"
      />
    </svg>
  );
};

const InfoIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 3.5H6.5V4.5H5.5V3.5ZM5.5 5.5H6.5V8.5H5.5V5.5ZM6 1C3.24 1 1 3.24 1 6C1 8.76 3.24 11 6 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 6 1ZM6 10C3.795 10 2 8.205 2 6C2 3.795 3.795 2 6 2C8.205 2 10 3.795 10 6C10 8.205 8.205 10 6 10Z"
        fill="#343434"
      />
    </svg>
  );
};

const CompanyLimit = ({ used = 0, limit = 0 }) => {
  const isReachedLimit = used >= limit;

  return (
    <div
      className={
        styles[isReachedLimit ? "company-limit_reached" : "company-limit"]
      }
    >
      {isReachedLimit ? <AlertInfoIcon /> : <InfoIcon />}
      <GothamMedium className="font10" style={{ color: jackColors.neutral900 }}>
        Company limit: {used}/{limit}
      </GothamMedium>
    </div>
  );
};

export default CompanyLimit;
