import { useEffect } from "react";

export const useRequestTimer = ({ onRequest = () => {} }) => {
  useEffect(() => {
    const currentTime = new Date();
    const timer = new Date(localStorage.getItem("upgrade_request_timer"));

    const isStillOnRequest = currentTime < timer;

    if (isStillOnRequest) onRequest();
  }, []);
};
