import { useRef } from "react";
import { CategoryColorSelectionDropdown } from "..";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { useModalHook } from "../../../../../components/Modals";
import { GothamRegular } from "../../../../../components/Text";
import styles from "./CategoryColorSelection.module.css";
import { useClickOutside } from "../../../../../universalFunctions/useClickOutside";

const CategoryColorSelection = ({
  closeOnClickOutside = false,
  selectedColor = "",
  onSelect = () => {},
}) => {
  const { isOpen, close, toggle } = useModalHook();

  const dropdownRef = useRef();

  useClickOutside({
    ref: dropdownRef,
    clickOutside: () => closeOnClickOutside && close(),
  });

  return (
    <div className={styles.categoryColorSelection}>
      <GothamRegular
        woFontColor
        className="font12"
        style={{ color: jackColors.neutral900 }}
      >
        Category Color
      </GothamRegular>
      <div className={styles.categoryColorSelectionInput} onClick={toggle}>
        <div className={styles.leftSection}>
          <div className={styles.categoryColorLabel}>
            <div
              className={styles.color}
              style={{ backgroundColor: selectedColor }}
            />
          </div>

          <GothamRegular woFontColor style={{ color: jackColors.neutral800 }}>
            Select color
          </GothamRegular>
        </div>

        <CategoryColorSelectionDropdown
          isOpen={isOpen}
          ref={dropdownRef}
          style={{ top: "48px" }}
          onClose={close}
          onSelect={onSelect}
          selectedColor={selectedColor}
        />

        <JackIcons name="chevron-down" fill={jackColors.neutral600} />
      </div>
    </div>
  );
};

export default CategoryColorSelection;
