import React, { Fragment, useEffect, useRef, useState } from "react";
import { useModalHook } from "../../../components/Modals";
import { GothamBold, GothamRegular } from "../../../components/Text";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import caretDown from "../../../assets/images/caret-down-pink.svg";
import IndividuTeal from "../../../assets/images/individual-teal-icon.svg";
import IndividuWhite from "../../../assets/images/individual-white-icon.svg";
import officeGray from "../../../assets/images/office-gray-icon.svg";
import officePink from "../../../assets/images/office-pink-icon.svg";
import { colors, jackColors } from "../../../assets/colors";
import { SelectBusinessTypeModal } from "./modal";
import { JackIcons } from "../../../assets/jackIcons/parent";
import Skeleton from "react-loading-skeleton";
import { useDebounce } from "../../../components/tools";

export const SelectionBusinessType = ({
  data = [],
  setBusinessType,
  loading,
  watchDestinationCountry,
}) => {
  const isSomeB2B = data.some((item) => item === "B2B");
  const isSomeB2C = data.some((item) => item === "B2C");
  const dataRender = [
    ...(isSomeB2B
      ? [
          {
            title: "Business/Institution",
            value: "business",
          },
        ]
      : []),
    ...(isSomeB2C
      ? [
          {
            title: "Individual/Personal",
            value: "personal",
          },
        ]
      : []),
  ];

  const [selectedData, setSelectedData] = useState(false);
  const { isOpen, toggle, close } = useModalHook();
  const { title, iconPink } = selectedData || {};

  const handleClick = (val) => {
    const { value } = val;
    setSelectedData(val);
    setBusinessType(value);
    close();
  };

  useEffect(() => {
    setSelectedData(false);
    setBusinessType("");
  }, [watchDestinationCountry?.label]);

  // useEffect(() => {
  //   setSelectedData(dataRender[0]);
  // }, [data]);

  return (
    <div style={{ marginBottom: 32 }}>
      <SelectionTypeCrossBorder
        isOpen={isOpen}
        title={title}
        toggle={toggle}
        placeholder="Select Recipient Type"
        loading={loading}
      />
      {isOpen && (
        <SelectBusinessTypeModal
          close={close}
          toggle={toggle}
          data={dataRender}
          handleClick={handleClick}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export const SelectionTypeCrossBorder = ({
  isOpen,
  optionTitle = "Recipient Type",
  title = "",
  placeholder = "",
  toggle,
  loading,
}) => {
  const [hover, setHover] = useState(false);
  const renderTitle = !!title ? title : placeholder;
  return (
    <div>
      <GothamRegular
        className="font12"
        style={{ marginBottom: 8, color: jackColors.neutral900 }}
      >
        {optionTitle}
      </GothamRegular>
      {loading ? (
        <Skeleton width={"100%"} height={42} />
      ) : (
        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            cursor: "pointer",
            padding: "10px 12px",
            borderRadius: 4,
            border: `1px solid ${
              isOpen
                ? jackColors.neutral900
                : hover
                ? jackColors.neutral600
                : jackColors.greyE6
            }`,
            transition: "all 100ms",
          }}
          onClick={toggle}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <div className="d-flex align-items-center">
            <GothamRegular
              style={{
                color: !title && !!placeholder && jackColors.neutral600,
              }}
            >
              {renderTitle}
            </GothamRegular>
          </div>
          <JackIcons
            name="chevron-down"
            fill={jackColors.neutral900}
            style={{ width: 20, height: 20 }}
            className={`rotate-icon ${isOpen ? "down" : ""}`}
          />
        </div>
      )}
    </div>
  );
};
