import {
  isAfter,
  format,
  addWeeks,
  startOfDay,
  isBefore,
  parse,
  getDay,
  addDays,
  setDay,
  setHours,
  setMinutes,
  parseISO,
  getDate,
  isPast,
} from "date-fns";
import { enUS } from "date-fns/locale";
import { pluralize } from "../invoiceComponents/data/formatter";
import { extractTime } from "../paymentCycleComponents/paymentCycleTable/tableRow";
import { daysLabel, generateTimeOptions, timeRange } from "./constant";
export const formatDateTimeString = (dateTimeObj) => {
  const { time, date } = dateTimeObj;

  // Format the time part to include "WIB" (Western Indonesia Time) at the end
  const formattedTime = `${time.value} WIB`;

  // Format the date part to "Monday, 1 June 2023"
  const newDate = new Date(date);

  const formattedDate = newDate.toLocaleDateString("en-US", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  // Combine the formatted date and time parts
  const formattedDateTimeString = `${formattedDate} (${formattedTime})`;

  return formattedDateTimeString;
};

export const formatScheduleString = (payload) => {
  const { time, repeat_every } = payload;
  const currentDateTime = new Date();
  const selectedDateTime = new Date();
  const [selectedHourString, selectedMinuteString] = time.split(":");

  selectedDateTime.setHours(
    Number(selectedHourString),
    Number(selectedMinuteString),
    0,
    0
  );

  const startingDate = isAfter(selectedDateTime, currentDateTime)
    ? selectedDateTime
    : addDays(selectedDateTime, 1);

  const formattedStartingDate = format(startingDate, "eeee, d MMMM yyyy", {
    locale: enUS,
  });
  const formattedTimeString = `${time} WIB`;
  const days = `${repeat_every > 0 ? repeat_every : ""} ${pluralize(
    repeat_every,
    "day"
  )}`;

  const scheduleString = `Start on ${formattedStartingDate}. Will repeat every ${days} at ${formattedTimeString} WIB.`;

  return { text: scheduleString, startingDate: changeHour(startingDate, time) };
};

export const changeHour = (dateString, newHour, newMinutes = 0) => {
  const [hours, minutes] = newHour.split(":").map(Number);
  const updatedDate = setHours(setMinutes(dateString, newMinutes), hours);
  return updatedDate;
};

export const getNextPaymentDates = (formData) => {
  const { time, days, repeat_every } = formData;
  const selectedTime = parse(time, "HH:mm", new Date());
  const currentDateTime = new Date();
  const currentDayOfWeek = getDay(currentDateTime);
  const today = startOfDay(currentDateTime);

  // Find the closest day of the week for payment based on days array
  const closestDay = days.reduce((closest, dayNumber) => {
    const dayOfWeek = dayNumber === 0 ? 7 : dayNumber; // Adjust Sunday to be represented as 7
    const diff = (dayOfWeek - currentDayOfWeek + 7) % 7;
    return diff < (closest.diff || 7) ? { day: dayOfWeek, diff } : closest;
  }, {});
  // Calculate the start date for payment
  const startDateRaw = setDay(today, closestDay.day);
  const isPass = isBefore(startDateRaw, today);
  const startDate = changeHour(
    isPass ? addWeeks(startDateRaw, 1) : startDateRaw,
    time
  );

  const formattedStartingDate = format(startDate, "eeee, d MMMM yyyy", {
    locale: enUS,
  });

  // Check if the selected time has already passed for the current day
  const selectedDateTime = startOfDay(addDays(today, closestDay.diff));
  if (isBefore(selectedDateTime, currentDateTime) || closestDay.diff === 0) {
    closestDay.diff += 7;
  }

  // Calculate the next three payment dates
  // const nextPaymentDates = Array.from({ length: 3 }, (_, index) =>
  //   format(addWeeks(startDate, index * repeat_every), "eeee 'at' HH:mm 'WIB'", {
  //     locale: enUS,
  //   })
  // );

  const daysOfWeekNames = days.map((dayNumber) =>
    format(setDay(today, dayNumber), "eeee", { locale: enUS })
  );
  const scheduleString = `Payment Schedule Start on ${formattedStartingDate}. Will repeat every week on ${daysOfWeekNames
    .map((day) => day.charAt(0).toUpperCase() + day.slice(1))
    .join(" and ")} at ${format(selectedTime, "HH:mm", {
    locale: enUS,
  })} WIB.`;

  return { text: scheduleString, startDate };
};

export const payloadMaker = (obj) => {
  const {
    time: timeRaw,
    date,
    recurrence: recurrenceRaw,
    transaction_type: typeRaw,
    repeat_every: repeatEveryString,
    days,
    non_business_days_skip_to,
    is_default,
    days_before_due: before_due,
  } = obj;

  const recurrence = recurrenceRaw?.value;
  const isDueDate = recurrence == "due_date";
  const isDaily = recurrence == "day";
  const isWeekly = recurrence == "week";

  const time = timeRaw.value;
  const transaction_type = typeRaw.value;

  const isInvoice = transaction_type == "InvoiceTransaction";
  const repeat_every = Number(repeatEveryString);

  if (isInvoice && isDueDate) {
    return {
      transaction_type,
      is_default,
      recurrence,
      days_before_due: Number(before_due),
      date_and_time: time,
      // berapa hari sebelum due_date mau dibayarnya?
    };
  }

  const dateAndTimeDecider = () => {
    if (isDaily) return formatScheduleString({ time }).startingDate;
    if (isWeekly)
      return getNextPaymentDates({ time, days, repeatEveryString }).startDate;

    return changeHour(date, time);
  };

  const date_and_time = dateAndTimeDecider();

  const payload = {
    time,
    recurrence,
    repeat_every,
    date_and_time,
    days,
    non_business_days_skip_to,
    transaction_type,
    is_default,
  };
  return payload;
};
const extractTimeAsString = (dateObject) => {
  const hours = dateObject.getHours().toString().padStart(2, "0"); // Ensure two-digit hours
  const minutes = dateObject.getMinutes().toString().padStart(2, "0"); // Ensure two-digit minutes
  return `${hours}:${minutes}`;
};

export const extractTimeForForm = (date_and_time) => {
  const isObj = typeof date_and_time == "object";
  const hour = isObj
    ? extractTimeAsString(date_and_time)
    : extractTime(date_and_time, true);
  const time = generateTimeOptions()
    ?.filter(({ value }) => value == hour)
    ?.pop();
  return time;
};

export const setFormDefaultValue = ({ defaultValue, useFormObj }) => {
  const { setValue, register } = useFormObj;
  const {
    recurrence,
    repeat_every,
    date_and_time,
    is_default,
    non_business_days_skip_to,
    days,
  } = defaultValue;

  const isDueDate = recurrence == "due_date";
  const isDay = recurrence == "day";
  const isWeek = recurrence == "week";
  const isMonth = recurrence == "month";
  const isYear = recurrence == "year";

  //set recurrence
  setValue(
    "recurrence",
    timeRange?.filter(({ value }) => value == recurrence)?.pop()
  );

  //set interval
  setValue("repeat_every", repeat_every);

  //set Date
  const dateVal = new Date(date_and_time);
  setValue("date", dateVal);

  if (isWeek) {
    register("days");
    setValue("days", days);
  }

  //set Time
  const time = extractTimeForForm(date_and_time);
  setValue("time", time);

  //set is default
  register("is_default");
  setValue("is_default", is_default);

  //set skip to
  register("non_business_days_skip_to");
  setValue("non_business_days_skip_to", non_business_days_skip_to);
};

export const getOrdinal = (number) => {
  if (number === 1) {
    return "1st";
  } else if (number === 2) {
    return "2nd";
  } else if (number === 3) {
    return "3rd";
  } else if (number >= 4 && number <= 20) {
    return `${number}th`;
  } else {
    const lastDigit = number % 10;
    switch (lastDigit) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }
};

export const rangeString = (count, string, isInvoice = false) => {
  const isPlural = count > 1;
  const word = `${pluralize(count, string)}`;
  const counts = isPlural || isInvoice ? `${count} ` : "";
  return counts + word;
};

export const extractDayOfMonth = (dateString) => {
  const date = parseISO(dateString);
  return getDate(date);
};

export const dMMMMformatter = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "d MMMM");
};

export const getMonthlySummary = ({
  date_and_time,
  date,
  repeat_every,
  time,
}) => {
  const dayInteger = getDate(date_and_time);
  const day = getOrdinal(Number(dayInteger));
  const text = `Scheduled for the ${day} day of every ${rangeString(
    repeat_every,
    "month"
  )} at ${time} WIB.`;

  return text;
};

export const getYearlySummary = ({ date_and_time, repeat_every, time }) => {
  const date = format(date_and_time, "d MMMM");
  return `Scheduled yearly every ${rangeString(
    repeat_every,
    "year"
  )} on ${date} at ${time} WIB`;
};

export const spesificDateFormat = (dateRaw, woDay = false) => {
  if (!dateRaw) return null;

  const isString = typeof dateRaw == "string";
  const date = isString ? new Date(dateRaw) : dateRaw;
  const formatwoDay = format(date, "d MMM yyyy (HH:mm 'WIB')");
  const formatWithDay = format(date, "cccc, d MMMM yyyy (HH:mm 'WIB')");
  const formattedDate = woDay ? formatwoDay : formatWithDay;
  return formattedDate;
};

export const pcDescription = (data) => {
  const {
    date_and_time,
    recurrence,
    repeat_every: repeats,
    days_before_due: dbd,
    days,
    non_business_days_skip_to: skipTo,
  } = data;
  const isDueDate = recurrence == "due_date";
  const isDay = recurrence == "day";
  const isWeek = recurrence == "week";
  const isMonth = recurrence == "month";
  const isYear = recurrence == "year";

  const daysString = (days) => {
    const length = days?.length;
    const dayArr = days?.map((day) => daysLabel[day]);

    if (length == 1) return dayArr[0];
    if (length == 2) return `${dayArr[0]} and ${dayArr[1]}`;

    const lastDay = dayArr?.pop();
    return dayArr.join(", ") + `, and ${lastDay}`;
  };

  const skipString = skipTo
    ? `. Weekend payments ${
        skipTo == "Monday"
          ? "postponed to the next Monday"
          : "accelerated to Friday"
      }`
    : "";

  if (isDueDate) {
    if (dbd == 0) return "On invoice’s due date";
    return `${rangeString(dbd, "day", true)} before the invoice’s due date`;
  }

  if (isDay) {
    if (repeats == 1) return "Every day";
    return `Every ${rangeString(repeats, "day")}`;
  }

  if (isWeek) {
    return `Every ${rangeString(repeats, "week")} on ${daysString(days)}`;
  }

  if (isMonth) {
    const day = extractDayOfMonth(date_and_time);
    const base = `${getOrdinal(day)} day of every ${rangeString(
      repeats,
      "month"
    )}`;

    return base + skipString;
  }

  if (isYear) {
    const base = `Every ${rangeString(repeats, "year")} on ${dMMMMformatter(
      date_and_time
    )}`;
    return base + skipString;
  }

  return "";
};

export const isDateExpired = (dateString) => {
  const date = parseISO(dateString);
  return isPast(date);
};

export const createPCPayload = (data) => {
  const { nearest_date_from_today, id } = data;
  const payment = {
    type: "payment_cycle",
    payload: {
      is_scheduled: true,
      date_and_time: nearest_date_from_today,
      predefined_schedule_id: id,
    },
    description: pcDescription(data),
  };

  return payment;
};
