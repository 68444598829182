import { useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import styles from "../../styles.module.css";
import RequestModal from "../RequestModal/parent";
import { useModalHook } from "../../../../components/Modals";
import { getUserRole } from "../../../../contexts/AuthContext";
import { useRequestTimer } from "../../logics/hooks";
import { eventsTracker } from "../../../../universalFunctions/events";

const Hourglass = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1.6665V6.6665H5.00833L5 6.67484L8.33333 9.99984L5 13.3332L5.00833 13.3415H5V18.3332H15V13.3415H14.9917L15 13.3332L11.6667 9.99984L15 6.67484L14.9917 6.6665H15V1.6665H5ZM13.3333 13.7498V16.6665H6.66667V13.7498L10 10.4165L13.3333 13.7498ZM10 9.58317L6.66667 6.24984V3.33317H13.3333V6.24984L10 9.58317Z"
        fill="#343434"
      />
    </svg>
  );
};

const Alert = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7999 13.5834L12.4083 2.9834C12.1498 2.57908 11.7937 2.24634 11.3728 2.01585C10.9519 1.78536 10.4798 1.66455 9.99992 1.66455C9.52005 1.66455 9.04791 1.78536 8.62702 2.01585C8.20613 2.24634 7.85004 2.57908 7.59159 2.9834L1.19992 13.5834C0.974132 13.9598 0.851411 14.389 0.844097 14.8278C0.836783 15.2667 0.945133 15.6997 1.15825 16.0834C1.40465 16.5153 1.7613 16.874 2.19176 17.1229C2.62221 17.3718 3.11102 17.502 3.60825 17.5001H16.3916C16.8855 17.5053 17.3722 17.3802 17.8023 17.1373C18.2325 16.8945 18.591 16.5424 18.8416 16.1167C19.061 15.7291 19.1728 15.2898 19.1655 14.8444C19.1581 14.399 19.0319 13.9637 18.7999 13.5834ZM9.99992 14.1667C9.8351 14.1667 9.67399 14.1179 9.53694 14.0263C9.3999 13.9347 9.29309 13.8046 9.23002 13.6523C9.16695 13.5 9.15044 13.3325 9.1826 13.1708C9.21475 13.0092 9.29412 12.8607 9.41066 12.7441C9.52721 12.6276 9.67569 12.5482 9.83734 12.5161C9.999 12.4839 10.1666 12.5004 10.3188 12.5635C10.4711 12.6266 10.6012 12.7334 10.6928 12.8704C10.7844 13.0075 10.8333 13.1686 10.8333 13.3334C10.8333 13.5544 10.7455 13.7664 10.5892 13.9227C10.4329 14.0789 10.2209 14.1667 9.99992 14.1667ZM10.8333 10.8334C10.8333 11.0544 10.7455 11.2664 10.5892 11.4227C10.4329 11.5789 10.2209 11.6667 9.99992 11.6667C9.77891 11.6667 9.56695 11.5789 9.41066 11.4227C9.25438 11.2664 9.16659 11.0544 9.16659 10.8334V7.50007C9.16659 7.27906 9.25438 7.0671 9.41066 6.91082C9.56695 6.75453 9.77891 6.66674 9.99992 6.66674C10.2209 6.66674 10.4329 6.75453 10.5892 6.91082C10.7455 7.0671 10.8333 7.27906 10.8333 7.50007V10.8334Z"
        fill="#E73126"
      />
    </svg>
  );
};

const UpgradeLimitBanner = () => {
  const [isOnRequest, setIsOnRequest] = useState(false);

  const { isOpen, open, close } = useModalHook();

  const { isAdminOrSuperAdmin } = getUserRole();

  useRequestTimer({ onRequest: () => setIsOnRequest(true) });

  const handleOpen = () => {
    eventsTracker("open_upgrade_plan_request_modal");
    open();
  };

  return (
    <div className={styles["upgrade-limit-banner"]}>
      <div>
        <Alert />
        <div>
          <GothamMedium
            className="font12"
            style={{ color: jackColors.neutral800 }}
          >
            Your company has reached monthly limit
          </GothamMedium>
          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: jackColors.neutral800 }}
          >
            Upgrade your plan to submit more receipt
          </GothamRegular>
        </div>
      </div>
      <div onClick={() => !isOnRequest && handleOpen()}>
        {isOnRequest && <Hourglass />}
        <GothamMedium style={{ color: jackColors.neutral900 }}>
          {isOnRequest ? "Requesting.." : "Upgrade Plan"}
        </GothamMedium>
      </div>
      <RequestModal
        preventRedirect
        isOpen={isOpen}
        type={isAdminOrSuperAdmin ? "upgrade-admin" : "upgrade"}
        toggle={close}
        onFinish={() => setIsOnRequest(true)}
      />
    </div>
  );
};

export default UpgradeLimitBanner;
