import React, { useEffect, useRef, useState } from "react";
import { useForm } from "@tools";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { ListMenuItem } from "../../../components/ListMenuItem/parent";
import { useModalHook, useModalWidth } from "../../../components/Modals";
import { ProgressBarJack } from "../../../components/Stepper";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { FileInputJack } from "../../../components/inputs/fileInputs/fileInput";
import { useHeader } from "../../../contexts/Layout/parent";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { typeCSVDecider } from "../../../decider/payroll";
import { downloadApi } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import {
  BCATemplate,
  TalentaTemplate,
} from "../../PayrollCreateEnhancement/second/uploadCardComponent";
import { HrVertical32, WrapperJackCenter } from "../../crossBorder/components";
import { TemplatePayrollArr } from "./constant";
import { ModalConfirmationCloseLocal } from "./modal";
import { stepArrayLocalFunc } from "./parent";
import { HeaderDataTableLocal } from "./table/data";
import { ModalAddRowLocal } from "./table/modal";

export const dummyDataLocal = [
  { name: "delete" },
  { name: "number" },
  { name: "name", value: "", label: "Name" },
  { name: "bank", value: "", label: "Bank" },
  { name: "account_number", value: "", label: "Bank Account Number" },
  { name: "notes", value: "", label: "Notes" },
  { name: "email", value: "", label: "Email" },
  { name: "amount", value: "", label: "Amount" },
  { name: "action" },
];

const LocalTransferSecondForm = ({
  onSubmit: onSubmitProps = () => {},
  toggle,
  loading,
}) => {
  const useFormObj = useForm();
  const { watch, handleSubmit } = useFormObj;
  const { successSnackBar } = ToasterHook();
  const { headerArray, tableBodyRef } = HeaderDataTableLocal();
  const {
    isOpen: isOpenAdd,
    toggle: toggleAdd,
    close: closeAdd,
  } = useModalHook();
  const { isOpen: isOpenClose, toggle: toggleClose } = useModalHook();
  const { setHeader } = useHeader();

  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => toggleClose(),
      middleComponent: <ProgressBarJack arrayStep={stepArrayLocalFunc(1)} />,
    });
  }, []);

  const [screen, setScreen] = useState("choose");

  const isChooseScreen = screen === "choose";
  const isUploadScreen = screen === "upload";
  const isEmptyTableScreen = screen === "empty_table";

  const handleClick = ({ value, type }) => {
    const isBack = type === "back";
    const isNext = type === "next";
    const isToChoose = type === "to_choose";
    const isCSV = value === "csv";
    const isEmptyTable = value === "empty_table";

    if (isEmptyTable) return setCombineData([dummyDataLocal]);
    if (isToChoose) return setScreen("choose");
    if (isNext) {
      if (isCSV) return setScreen("upload");
      // return setScreen("emptytable");
    }
    if (isBack) return toggle();
  };

  // const { realFile } = watch("upload_file") || {};
  const file = watch("upload_file");
  const isDisabled = !file;

  const afterSuccess = () => {
    eventsTracker("download_template_local_transfer");
    successSnackBar({
      msg: "Template has been downloaded.",
      showClose: true,
    });
  };

  const onClickDownload = () => {
    downloadApi({
      url: "/local_transaction_batches/template_batch_import?format_template=excel",
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      fileName: "Jack-Template-Local-Transfer.xlsx",
      afterSuccess,
    });
  };

  const onSubmit = (values) => onSubmitProps({ type: "second", values });
  const setCombineData = (values) =>
    onSubmitProps({ type: "second_modal", values });

  const modalWidth = useModalWidth(528);

  const arrayInputMethod = [
    {
      title: "CSV",
      desc: "Transfer to up to 1000 recipients by uploading Excel/CSV file",
      icon: "file-text-outline",
      value: "csv",
    },
    {
      title: "Empty Table",
      desc: "Enter your recipients details manually, one by one",
      icon: "backup-table",
      value: "empty_table",
    },
  ];

  const arrayUploadDetails = {
    title1: "1. Download this template",
    desc1:
      "Download and fill in the recipient details according to this template.",
    title2: "2. Upload the filled template",
    desc2: "Upload the filled Excel/CSV to start creating your transaction.",
  };

  return (
    <div ref={tableBodyRef}>
      {isChooseScreen && (
        <ChooseInputMethod
          array={arrayInputMethod}
          handleClick={handleClick}
          title={"Input Recipient Details"}
          desc={"Select the way you want to input your recipient details."}
        />
      )}
      {isUploadScreen && (
        <ImportRecipientCSV
          useFormObj={useFormObj}
          onClick={handleSubmit(onSubmit)}
          handleClick={handleClick}
          onClickDownload={onClickDownload}
          isDisabled={isDisabled}
          arrayDetails={arrayUploadDetails}
          loading={loading}
        />
      )}

      {/* <GothamRegular>
        Or, click here to{" "}
        <span
          style={{
            color: colors.pink,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={toggleAdd}
        >
          start with an empty table
        </span>
      </GothamRegular> */}
      <ModalAddRowLocal
        widthParent={1376}
        isOpen={isOpenAdd}
        toggle={toggleAdd}
        headerArray={headerArray}
        setCombineData={setCombineData}
      />
      <ModalConfirmationCloseLocal
        toggle={toggleClose}
        isOpen={isOpenClose}
        product="local"
      />
    </div>
  );
};

export default LocalTransferSecondForm;

export const ChooseInputMethod = ({
  array,
  handleClick,
  // title = "Input your recipient details",
  // desc = "Select the way you want to input your recipient details",
  title,
  desc,
  woButton = false,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [active, setActive] = useState(false);

  return (
    <>
      <WrapperJackCenter title={title} styleTitle={{ marginBottom: 8 }}>
        <GothamRegular
          style={{ color: jackColors.neutral800, marginBottom: 32 }}
        >
          {desc}
        </GothamRegular>
        {array?.map((item, index) => {
          const { title, desc, icon, value } = item || {};
          const isFirst = index === 0;
          const isActive = value === active;
          const isActiveHover = isHover === index;
          return (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between"
              style={{
                border: `1px solid ${
                  isActive ? jackColors.black34 : jackColors.neutral500
                }`,
                borderRadius: 4,
                cursor: "pointer",
                padding: 12,
                height: 64,
                marginTop: !isFirst && 16,
                backgroundColor: "white",
                transition: "all 0.4s ease",
                backgroundColor: isActiveHover
                  ? "rgba(0, 0, 0, 0.04)"
                  : "white",
              }}
              onMouseEnter={() => setIsHover(index)}
              onMouseLeave={() => setIsHover(false)}
              onClick={() => setActive(value)}
            >
              <div className="d-flex align-items-center">
                <JackIcons
                  style={{ width: 20, height: 20 }}
                  styleDiv={{
                    backgroundColor: jackColors.neutral900,
                    width: 32,
                    height: 32,
                    padding: 6,
                    borderRadius: 8,
                    marginRight: 16,
                  }}
                  name={icon}
                  fill="white"
                />
                <div>
                  <GothamMedium
                    style={{
                      color: isActive
                        ? jackColors.neutral900
                        : jackColors.neutral800,
                      lineHeight: "20px",
                    }}
                  >
                    {title}
                  </GothamMedium>
                  <GothamRegular
                    className="font12"
                    style={{ color: jackColors.neutral800, lineHeight: "20px" }}
                  >
                    {desc}
                  </GothamRegular>
                </div>
              </div>
              {isActive && (
                <JackIcons
                  name="checkmark-circle-2"
                  fill={jackColors.neutral900}
                  style={{ width: 20, height: 20 }}
                />
              )}
            </div>
          );
        })}
        {!woButton && (
          <div
            className="d-flex justify-content-end"
            style={{ gap: 16, marginTop: 32 }}
          >
            <ButtonJack
              type="outline"
              children="Back"
              style={{ minWidth: 65 }}
              onClick={() => handleClick({ type: "back" })}
              leftIcon={
                <JackIcons
                  name="arrow-back-outline"
                  fill="black"
                  style={{ width: 20, height: 20 }}
                />
              }
            />
            <ButtonJack
              disabled={!active}
              type="filled"
              children="Next"
              style={{ minWidth: 60 }}
              onClick={() => handleClick({ type: "next", value: active })}
              rightIcon={
                <JackIcons
                  style={{ width: 20, height: 20 }}
                  name="arrow-forward"
                  fill={!active ? jackColors.neutral600 : "black"}
                />
              }
            />
          </div>
        )}
      </WrapperJackCenter>
    </>
  );
};

export const ImportRecipientCSV = ({
  onClick,
  handleClick,
  useFormObj,
  onClickDownload,
  isDisabled,
  arrayDetails,
  title = "Input recipient details in CSV",
  loading,
  moreFileTypes = [],
  wrapperStyle,
  woButton = false,
  isNewPayroll = false,
  setActiveTemplate = () => {},
  activeTemplate = "default",
  ...customPropsPayroll
}) => {
  const { title1, title2, desc1, desc2 } = arrayDetails || {};
  const isDefault = activeTemplate === "default";
  const containerStyle = isNewPayroll ? { maxWidth: 970 } : {};
  const { watch } = useFormObj;
  const typeCustomTemplate = watch("type");
  const styleTitle = isNewPayroll
    ? { textAlign: "center" }
    : { textAlign: "left" };
  const { isDisabledCustomeTemplatePayroll, onSubmitCustomPayroll } =
    customPropsPayroll;
  const disableDecider = isDefault
    ? isDisabled
    : isDisabledCustomeTemplatePayroll;
  return (
    <WrapperJackCenter
      title={title}
      containerStyle={containerStyle}
      styleTitle={styleTitle}
    >
      {Boolean(isNewPayroll) ? (
        <ChooseTemplateExcel
          setActiveTemplate={setActiveTemplate}
          activeTemplate={activeTemplate}
          handleClick={handleClick}
          loading={loading}
          onClick={onClick}
          woButton
          containerStyle={{ paddingBottom: 0 }}
          useFormObj={useFormObj}
          onClickDownload={onClickDownload}
          isDisabled={isDisabled}
          title={title}
          wrapperStyle={wrapperStyle}
          moreFileTypes={moreFileTypes}
          {...customPropsPayroll}
        />
      ) : (
        <>
          <GothamMedium className="mb-1">{title1}</GothamMedium>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.neutral700, marginBottom: 16 }}
          >
            {desc1}
          </GothamRegular>
          <ButtonJack
            onClick={onClickDownload}
            className="w-100"
            type="outline"
            children="Download template"
            style={{ marginBottom: 32, height: 32 }}
            rightIcon={
              <JackIcons
                style={{ width: 20, heigth: 20 }}
                name="download-outline"
                fill={jackColors.neutral900}
              />
            }
          />
          <GothamMedium className="mb-1">{title2}</GothamMedium>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.neutral700, marginBottom: 16 }}
          >
            {desc2}
          </GothamRegular>
          <FileInputJack
            maxSizeInfoInside
            showLabel={false}
            formData
            fileTypes={[
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ...moreFileTypes,
            ]}
            useFormObj={useFormObj}
            name="upload_file"
            type="general"
            firstCopy="Browse or drag files here"
            multiple={false}
          />
        </>
      )}

      {!woButton && (
        <div
          className="d-flex justify-content-end"
          style={{ gap: 16, marginTop: 32 }}
        >
          <ButtonJack
            type="outline"
            children="Back"
            style={{ minWidth: 65 }}
            onClick={() => handleClick({ type: "to_choose" })}
            leftIcon={
              <JackIcons
                name="arrow-back-outline"
                fill="black"
                style={{ width: 20, height: 20 }}
              />
            }
          />
          <ButtonJack
            disabled={disableDecider}
            isLoading={loading}
            type="filled"
            children="Next"
            style={{ minWidth: 60 }}
            onClick={() => {
              isDefault
                ? onClick()
                : onSubmitCustomPayroll({
                    typeCustomTemplate: typeCustomTemplate,
                  });
            }}
            rightIcon={
              <JackIcons
                style={{ width: 20, height: 20 }}
                name="arrow-forward"
                fill={disableDecider ? jackColors.neutral600 : "black"}
              />
            }
          />
        </div>
      )}
    </WrapperJackCenter>
  );
};

export const DefaultTemplateComponent = ({
  useFormObj,
  onClickDownload,
  moreFileTypes,
}) => {
  return (
    <div>
      <GothamMedium className="font16" style={{ marginBottom: 24 }}>
        2. Upload Jack template
      </GothamMedium>
      <GothamRegular
        className="font14"
        style={{ color: jackColors.neutral800, marginBottom: 16 }}
      >
        Download Jack Template to fill in your recipient details
      </GothamRegular>
      <ButtonJack
        onClick={onClickDownload}
        className="w-100"
        type="outline"
        children="Download Jack Template"
        style={{ marginBottom: 32, height: 32 }}
        rightIcon={
          <JackIcons
            style={{ width: 20, heigth: 20 }}
            name="download-outline"
            fill={jackColors.neutral900}
          />
        }
      />
      <GothamRegular
        className="font14"
        style={{ color: jackColors.neutral800, marginBottom: 12 }}
      >
        Upload the filled Excel to start creating your transaction
      </GothamRegular>
      <FileInputJack
        maxSizeInfoInside
        showLabel={false}
        formData
        fileTypes={[
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ...moreFileTypes,
        ]}
        useFormObj={useFormObj}
        name="upload_file"
        firstCopy="Browse or drag files here"
        multiple={false}
      />
    </div>
  );
};

export const CustomTemplateComponent = ({ useFormObj }) => {
  const { watch, register, setValue } = useFormObj;
  const [customTemplate, setCustomTemplate] = useState({});

  const isActive = !!customTemplate?.title;
  const { title: activeTitle, image: activeImg } = customTemplate || {};
  const { isOpen, toggle, close } = useModalHook();
  const ref = useRef();
  const activeColor =
    isOpen || isActive ? jackColors.neutral900 : jackColors.neutral600;
  const borderColor = isOpen ? jackColors.neutral900 : jackColors.neutral500;
  const checkType = watch("type");

  const getInitialValue = () => {
    setCustomTemplate(TemplatePayrollArr[0]);
    setValue("type", TemplatePayrollArr[0]?.value);
  };
  const onClickChooseTemplate = (item) => {
    setValue("type", item?.value);
    setCustomTemplate(item);
    close();
  };
  useClickOutside({ clickOutside: close, ref });
  const checkIsFileExist = (checkType) => {
    const [data] = TemplatePayrollArr?.filter(
      ({ value }) => value == checkType
    );
    setCustomTemplate(data);
    register("type");
  };
  const { isBCA, isTalenta } = typeCSVDecider({ value: customTemplate?.value });

  useEffect(() => {
    checkIsFileExist(checkType);
    getInitialValue();
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <GothamMedium className="font16" style={{ marginBottom: 24 }}>
        2. Upload your payroll template
      </GothamMedium>
      <GothamRegular
        className="font14"
        style={{ color: jackColors.neutral800, marginBottom: 12 }}
      >
        Pick the HRIS or payment gateway template you frequently use
      </GothamRegular>
      <div
        className="d-flex justify-content-between"
        style={{
          border: `1px solid ${borderColor}`,
          padding: isActive ? "12px 10px" : "18px 10px",
          borderRadius: 4,
          marginTop: 16,
          cursor: "pointer",
          height: 56,
          marginBottom: 32,
        }}
        onClick={toggle}
      >
        {isActive ? (
          <div
            className="d-flex align-items-center"
            style={{ gap: 16, height: 32 }}
            onClick={toggle}
          >
            <img
              src={activeImg}
              style={{ height: "auto", width: 32, aspectRatio: "auto" }}
              onClick={toggle}
            />
            <GothamMedium onClick={toggle}>{activeTitle}</GothamMedium>
          </div>
        ) : (
          <GothamRegular style={{ color: activeColor, height: 20 }}>
            Select template
          </GothamRegular>
        )}
        <JackIcons
          name="chevron-down"
          fill={activeColor}
          className={`rotate-icon ${isOpen ? "down" : ""}`}
          onClick={toggle}
        />
      </div>
      {isOpen && (
        <div
          style={{
            right: 0,
            width: "100%",
            top: 153,
            backgroundColor: "white",
            boxShadow: "0px 16px 24px 0px #16223314",
            padding: 8,
            borderRadius: 8,
            position: "absolute",
            zIndex: 1,
          }}
          ref={ref}
        >
          <div
            style={{
              padding: "12px 8px",
              borderBottom: `1px solid ${jackColors.neutral500}`,
              marginBottom: 16,
            }}
          >
            <GothamMedium className="font12">Select Template</GothamMedium>
          </div>
          {TemplatePayrollArr.map((item, index) => {
            const { title, image } = item || {};
            const isSelectedList = title == customTemplate?.title;
            return (
              <div
                key={index}
                style={{
                  padding: 12,
                  gap: 16,
                  height: 56,
                  cursor: "pointer",
                  borderRadius: 4,
                  backgroundColor: isSelectedList
                    ? jackColors.neutral500
                    : "white",
                }}
                className="d-flex align-items-center hover-600"
                onClick={() => {
                  onClickChooseTemplate(item);
                }}
              >
                <img
                  src={image}
                  style={{ height: "auto", width: 32, aspectRatio: "auto" }}
                />
                <GothamMedium>{title}</GothamMedium>
              </div>
            );
          })}
          <div
            style={{
              padding: "16px 0",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <GothamRegular
              className="font12"
              style={{ color: jackColors.neutral800 }}
            >
              We’re bringing more platforms to you soon!
            </GothamRegular>
          </div>
        </div>
      )}
      {Boolean(isTalenta) && <TalentaTemplate useFormObj={useFormObj} />}
      {Boolean(isBCA) && <BCATemplate />}
    </div>
  );
};

export const ChooseTemplateExcel = ({
  activeTemplate,
  setActiveTemplate,
  woButton = false,
  isDisabled = false,
  handleClick,
  loading,
  onClick,
  useFormObj,
  onClickDownload,
  wrapperStyle,
  moreFileTypes,
}) => {
  const arrayInputMethod = [
    {
      title: "Jack Template",
      desc: "Ideal if you're setting up for the first time or already familiar with Jack Template.",
      icon: "file-text",
      value: "default",
    },
    {
      title: "Import from Other Platform",
      desc: "Utilize your usual payroll template and we will extract the data for your Jack Payroll.",
      icon: "file-add",
      value: "custom",
    },
  ];
  const isDefault = activeTemplate == "default";
  return (
    <>
      <div>
        <div
          className="d-flex flex-row"
          style={{
            borderRadius: 16,
            border: `1px solid ${jackColors.neutral500}`,
            padding: 32,
          }}
        >
          <div>
            <GothamMedium className="font16" style={{ marginBottom: 24 }}>
              1. Select Method
            </GothamMedium>
            <ListMenuItem
              array={arrayInputMethod}
              onSelected={setActiveTemplate}
              woButton
              activeTemplate={activeTemplate}
            />
          </div>
          <HrVertical32 />
          <div style={{ width: "100%" }}>
            {isDefault ? (
              <DefaultTemplateComponent
                useFormObj={useFormObj}
                onClick={onClick}
                handleClick={handleClick}
                onClickDownload={onClickDownload}
                isDisabled={isDisabled}
                loading={loading}
                wrapperStyle={wrapperStyle}
                moreFileTypes={moreFileTypes}
              />
            ) : (
              <CustomTemplateComponent
                useFormObj={useFormObj}
                handleClick={handleClick}
                isDisabled={isDisabled}
                loading={loading}
                onClick={onClick}
              />
            )}
          </div>
        </div>
      </div>
      {!woButton && (
        <div
          className="d-flex justify-content-end"
          style={{ gap: 16, marginTop: 32 }}
        >
          <ButtonJack
            type="outline"
            children="Back"
            style={{ minWidth: 65 }}
            onClick={() => handleClick({ type: "to_choose" })}
            leftIcon={
              <JackIcons
                name="arrow-back-outline"
                fill="black"
                style={{ width: 20, height: 20 }}
              />
            }
          />
          <ButtonJack
            disabled={isDisabled}
            isLoading={loading}
            type="filled"
            children="Next"
            style={{ minWidth: 60 }}
            onClick={onClick}
            rightIcon={
              <JackIcons
                style={{ width: 20, height: 20 }}
                name="arrow-forward"
                fill={isDisabled ? jackColors.neutral600 : "black"}
              />
            }
          />
        </div>
      )}
    </>
  );
};
