import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { deepRemoveDuplicates } from "../../../components/tools";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { fetch, useMutation } from "../../../tools/api";
import { roleDescriptionDecider } from "./deciders";
import { filteredUserFormatter, teamsWithUsersFormatter } from "./formatters";

export const useSelectingMemberRole = () => {
  const [memberListScrollPosition, setMemberListScrollPosition] = useState(0);
  const [selectedMemberRefs, setSelectedMemberRefs] = useState([]);
  const [selectingMemberRole, setSelectingMemberRole] = useState({});

  const showRoleDropdown = !isEmpty(selectingMemberRole);

  const resetSelectingMemberRole = () => {
    setMemberListScrollPosition(0);
    setSelectedMemberRefs([]);
    setSelectingMemberRole({});
  };

  const validSelectedMemberRefs = selectedMemberRefs
    .filter(({ current }) => current)
    .sort((prev, curr) => {
      if (prev.current.offsetTop > curr.current.offsetTop) return 1;
      if (prev.current.offsetTop < curr.current.offsetTop) return -1;
      return 0;
    });

  return {
    showRoleDropdown,
    selectedMemberRefs: validSelectedMemberRefs,
    selectingMemberRole,
    memberListScrollPosition,
    setSelectedMemberRefs,
    setSelectingMemberRole,
    resetSelectingMemberRole,
    setMemberListScrollPosition,
  };
};

export const usePeopleData = () => {
  const { query } = useRouter();
  const { user } = useGetAuth();
  const { isWeakManager } = getUserRole();

  const teamId = Number(query.id);
  const userTeamId = user?.team_id;
  const userId = user?.id;
  const userName = user?.name;

  const {
    users: usersRaw,
    teamsData: teams,
    refetchTeamsData: refetchTeams,
    refetchUsers,
    loadingUsers,
    teamsLoading: loadingTeams,
    requestedUsers,
    loadingRequestedUsers,
    refetchRequestedUsers,
  } = useConstants();

  // Sort by created at
  teams.sort((prev, curr) => {
    if (prev.created_at > curr.created_at) return -1;
    if (prev.created_at < curr.created_at) return 1;
    return 0;
  });

  // const filteredUsers = filteredUserFormatter({
  //   users: usersRaw,
  //   teams,
  //   query,
  // });

  // const userTeamFilter = (users) => {
  //   let filteredUsers = [...users];
  //   if (isWeakManager || userTeamId) {
  //     const teamsWithUsers = teamsWithUsersFormatter({
  //       users: filteredUsers,
  //       teams,
  //       query,
  //     });

  //     const usersOfRelatedTeam = teamsWithUsers
  //       .filter(
  //         ({ id: thisTeamId, manager }) =>
  //           manager?.id === user?.id || thisTeamId === userTeamId // this will output a team of which the currently logged-in user is a part
  //       )
  //       .map(({ users }) => users)
  //       .flat()
  //       .filter(({ name }) => name); // for filtering pending status user (email only user)

  //     filteredUsers = usersOfRelatedTeam;
  //   }
  //   return filteredUsers;
  // };

  // const usersTeam = userTeamFilter(usersRaw)?.map((user) => {
  //   return { ...user, roles: user.roles ?? [] };
  // });

  // Sort alphabetically
  const sortName = (array) => {
    const result = array.sort((prev, curr) => {
      const prevName = prev.name || prev.email;
      const currName = curr.name || curr.email;
      return prevName.localeCompare(currName);
    });
    return result;
  };

  // usersTeam.sort((prev, curr) => {
  //   const prevName = prev.name || prev.email;
  //   const currName = curr.name || curr.email;

  //   if (prevName > currName) return 1;
  //   if (prevName < currName) return -1;
  //   return 0;
  // });

  const usersOther = usersRaw.filter(
    (user) => user.name || user.status !== "invited"
  );
  const usersPending = usersRaw.filter(
    (user) => !user.name || user.status === "invited"
  );

  const sortedUsersOther = sortName(usersOther);
  const sortedUsersPending = sortName(usersPending); // invited taro bawah smua

  const users = deepRemoveDuplicates(
    [...sortedUsersOther, ...sortedUsersPending],
    "id"
  );

  const filteredUsers = filteredUserFormatter({
    users,
    teams,
    query,
  });

  const teamsWithUsersUnfiltered = teamsWithUsersFormatter({
    users,
    teams,
    query,
    usersRaw,
  });

  const myTeam = teamsWithUsersUnfiltered.filter(({ users }) =>
    users.find((user) => user.id === userId)
  );

  const myManagedTeams = teamsWithUsersUnfiltered.filter(
    ({ manager }) => manager?.name === userName
  );

  const teamsWithUsers = deepRemoveDuplicates(
    [
      ...myTeam,
      ...sortName(myManagedTeams),
      ...sortName(teamsWithUsersUnfiltered),
    ],
    "id"
  );

  const unassignedUsers = users.filter(({ team_id }) => !team_id);
  const cleanUsers = users.filter(({ id: userId }) => {
    return !unassignedUsers.find(
      ({ id: unassignedUserId }) => userId === unassignedUserId
    );
  });

  const teamDetail = teamsWithUsers.find(({ id }) => id === teamId) ?? {};

  const loadingGeneral = loadingUsers || loadingTeams || loadingRequestedUsers;

  const isTeamDetailPage = !isEmpty(teamDetail);

  const unformattedUsers = usersRaw;

  const refetchPeopleData = () => {
    refetchTeams();
    refetchRequestedUsers();
  };

  return {
    users: filteredUsers,
    teams,
    usersRaw,
    cleanUsers,
    teamDetail,
    refetchUsers,
    refetchTeams,
    loadingUsers,
    loadingTeams,
    teamsWithUsers,
    loadingGeneral,
    unassignedUsers,
    unformattedUsers,
    isTeamDetailPage,
    refetchPeopleData,
    requestedUsers,
    loadingRequestedUsers,
    refetchRequestedUsers,
  };
};

export const useMainModalContent = () => {
  const contents = ["team_specifications", "add_members", "add_manager"];

  const [isAgreedAdminPolicy, setIsAgreedAdminPolicy] = useState(false);
  const [contentIndex, setContentIndex] = useState(0);
  const [dedicate, setDedicate] = useState("");
  const [teamDetails, setTeamDetails] = useState({});
  const [changesReference, setChangesReference] = useState({});

  const { users } = useConstants();
  const teamMembers = teamDetails?.members ?? [];
  const newTeamMembers = teamMembers.filter(({ id }) => !id);
  const newAdminTeamMembers = newTeamMembers.filter(({ roles }) =>
    roles.find(({ name }) => name === "partner_admin")
  );
  const currentUsers = teamMembers.filter(({ id }) => id);
  const currentUserIds = currentUsers.map(({ id }) => id);
  const oldUsersRemapped = (users ?? [])
    .filter(({ id }) => currentUserIds.includes(id))
    .map(({ id, roles }) => ({
      [id]: roles.find(({ name }) => name.includes("partner"))?.name ?? "",
    }));

  const hasOldUsersWhoAreChangedToAdmin = oldUsersRemapped.some((oldUser) => {
    const oldUserId = Number(Object.keys(oldUser)[0]);
    const oldUserRole = Object.values(oldUser)[0];
    const wasAdmin = oldUserRole === "partner_admin";

    const currentUserRole = currentUsers
      .find(({ id }) => id === oldUserId)
      .roles.find(({ name }) => name.includes("partner")).name;

    const isChangedToAdmin = currentUserRole === "partner_admin";

    if (!wasAdmin && isChangedToAdmin) return true;
    return false;
  });

  const hasAdminNewMembers = newAdminTeamMembers.length > 0;
  const hasNewAdmins = hasOldUsersWhoAreChangedToAdmin || hasAdminNewMembers;

  const teamManager = teamDetails?.manager ?? {};
  const isNewMemberTeamManager = !teamManager.id;
  const teamManagerRole =
    (teamManager.roles ?? []).find(({ name }) => name.includes("partner"))
      ?.name ?? "";
  const oldTeamManagerRole =
    (users ?? [])
      .find(({ id }) => id === teamManager.id)
      ?.roles?.find(({ name }) => name.includes("partner"))?.name ?? "";
  const hasTeamManagerWhoIsChangedToAdmin =
    oldTeamManagerRole !== "partner_admin" &&
    teamManagerRole === "partner_admin";
  const isNewAdminTeamManager =
    isNewMemberTeamManager && teamManagerRole === "partner_admin";

  const hasNewAdminTeamManager =
    hasTeamManagerWhoIsChangedToAdmin || isNewAdminTeamManager;

  const isDedicatedMode = !!dedicate;
  const selectedMembers = teamDetails.members ?? [];
  const selectedManager = teamDetails.manager ?? {};
  const selectedManagers = isEmpty(selectedManager) ? [] : [selectedManager];

  const createTeamStep = contentIndex + 1;

  const isTeamDetailStep = contentIndex === 0;
  const isAddMembersStep = contentIndex === 1;
  const isAddManagerStep = contentIndex === 2;
  const isConfirmationStep = contentIndex === 3;
  const isInviteUser = contentIndex === 1 && dedicate === "invite_user";

  const couldDetectTeamDetailsChanges = isDedicatedMode && isTeamDetailStep;
  const couldDetectAddMembersChanges = isDedicatedMode && isAddMembersStep;
  const couldDetectAddManagerChanges = isDedicatedMode && isAddManagerStep;
  const couldDetectInviteUserChanges = isDedicatedMode && isInviteUser;

  const isDifferentTeamName = teamDetails.name !== changesReference.name;
  const isDifferentTeamColor = teamDetails.color !== changesReference.color;
  const isAlreadyAddingMembers = teamDetails.members?.length > 0;
  const isDifferentManager =
    teamDetails.manager?.email !== changesReference.manager?.email;
  const isDifferentManagerRole =
    teamDetails.manager?.roles &&
    changesReference.manager?.roles &&
    teamDetails.manager?.roles[0].id !== changesReference.manager?.roles[0].id;

  const isDetectedTeamDetailsChanges =
    couldDetectTeamDetailsChanges &&
    (isDifferentTeamName || isDifferentTeamColor);
  const isDetectedAddMembersChanges =
    couldDetectAddMembersChanges && isAlreadyAddingMembers;
  const isDetectedAddManagerChanges =
    couldDetectAddManagerChanges &&
    (isDifferentManager || isDifferentManagerRole);
  const isDetectedInviteUserChanges =
    couldDetectInviteUserChanges && isAlreadyAddingMembers;
  const isDetectedCreateTeamChanges = !isDedicatedMode && !!teamDetails.name;

  const isDetectedChanges =
    isDetectedAddManagerChanges ||
    isDetectedAddMembersChanges ||
    isDetectedTeamDetailsChanges ||
    isDetectedCreateTeamChanges ||
    isDetectedInviteUserChanges;

  const nextContent = () => setContentIndex((prev) => prev + 1);
  const prevContent = () => setContentIndex((prev) => prev - 1);

  const reset = () => {
    setContentIndex(0);
    setDedicate("");
    setChangesReference({});
    setIsAgreedAdminPolicy(false);
  };

  const delayedReset = () => setTimeout(reset, 400);

  useEffect(() => {
    if (dedicate && !isEmpty(teamDetails) && isEmpty(changesReference)) {
      setChangesReference(structuredClone(teamDetails));
    }

    if (dedicate && teamDetails) {
      const contentIndex = contents.findIndex(
        (content) => content === dedicate
      );

      const isFoundIndex = contentIndex >= 0;

      if (isFoundIndex) return setContentIndex(contentIndex);

      const isInviteUser = dedicate === "invite_user";

      if (isInviteUser) return setContentIndex(1);
    }
  }, [dedicate, teamDetails, changesReference]);

  return {
    contents,
    dedicate,
    teamDetails,
    contentIndex,
    hasNewAdmins,
    isInviteUser,
    createTeamStep,
    isDedicatedMode,
    selectedMembers,
    selectedManager,
    selectedManagers,
    isTeamDetailStep,
    isAddMembersStep,
    isAddManagerStep,
    changesReference,
    isDetectedChanges,
    isConfirmationStep,
    isAgreedAdminPolicy,
    hasNewAdminTeamManager,
    isDetectedAddMembersChanges,
    isDetectedAddManagerChanges,
    isDetectedCreateTeamChanges,
    isDetectedInviteUserChanges,
    isDetectedTeamDetailsChanges,
    reset,
    nextContent,
    prevContent,
    setDedicate,
    delayedReset,
    setTeamDetails,
    setContentIndex,
    setIsAgreedAdminPolicy,
  };
};

export const useRoles = () => {
  const { rolesData: rolesRaw, rolesLoading: loadingRoles } = useConstants();

  const { isAdmin } = getUserRole();

  const excludedRoles = ["partner_approver", "partner_card_user"];

  const roles = rolesRaw
    .filter(({ name }) => !excludedRoles.includes(name))
    .map((role) => ({
      ...role,
      label: role.label === "Book Keeper" ? "Bookkeeper" : role.label,
      description: roleDescriptionDecider(role.name),
    }));

  const rolesWoBusinessOwner = [...roles].filter(({ name }) => {
    const isAdminRole = name === "partner_admin";
    const isBusinessOwnerRole = name === "partner_super_admin";

    if (isAdmin) return !isAdminRole && !isBusinessOwnerRole;
    return !isBusinessOwnerRole;
  });

  return { roles, allRoles: rolesRaw, rolesWoBusinessOwner, loadingRoles };
};

export const useDeleteTeam = () => {
  const [teamToDelete, setTeamToDelete] = useState({});

  const { refetchTeamsData, removeTeamFromUsers } = useConstants();

  const { push } = useRouter();

  const { successSnackBar } = ToasterHook();

  const { mutation: deleteTeam, loading: loadingDeleteTeam } = useMutation({
    url: `/teams/${teamToDelete.id}`,
    method: "delete",
    afterSuccess: async () => {
      refetchTeamsData();
      removeTeamFromUsers({ teamId: teamToDelete?.id });
      successSnackBar({ msg: `${teamToDelete.name} team has been deleted` });
      setTeamToDelete({});
      await push("/people-management");
    },
  });

  return { teamToDelete, loadingDeleteTeam, deleteTeam, setTeamToDelete };
};

export const useUserLog = ({ user = {} }) => {
  const { data, loading, refetch } = fetch({
    url: `/business_users/user_histories/${user?.id}`,
    formatter: (res) => res?.histories,
  });
  return { logs: data, loading, refetch };
};
