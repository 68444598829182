import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors, jackColors } from "../assets/colors";
import ChecklistIcon from "../assets/images/checklist-nonbg.svg";
import infoIcon from "../assets/images/info-icon.svg";
import negoRateBannerImg from "../assets/images/card-nego-rate.png";
import emptyTrxImg from "../assets/images/transaction.svg";
import { JackIcons } from "../assets/jackIcons/parent";
import { ButtonJack } from "../components/ButtonsJack/parent";
import { useModalHook } from "../components/Modals";
import { GothamMedium, GothamRegular } from "../components/Text";
import { formatCurrency } from "../components/tools";
import {
  SelectionBusinessType,
  SelectionTypeCrossBorder,
} from "./crossBorder/selection/business";
import { SelectDeliveryMethod } from "./crossBorder/selection/modal";
import CustomTooltip from "../components/Tooltip";

export const InformationDetails = ({ data, handleDetail }) => {
  return (
    <>
      {data.map((item, index) => {
        const { firstText, secondText, boldText, isButton } = item;
        return (
          <div
            key={index}
            className="d-flex align-items-center"
            style={{ marginBottom: 8 }}
          >
            <img src={ChecklistIcon} className="mr-3" />
            <GothamRegular style={{ color: colors.grey72, marginBottom: 0 }}>
              {firstText}{" "}
              <span
                style={{ fontFamily: "GothamMedium", color: colors.grey25 }}
              >
                {boldText}
              </span>{" "}
              {secondText}
              {/* {isButton && (
                <DetailButton onClick={handleDetail}>Detail</DetailButton>
              )} */}
            </GothamRegular>
          </div>
        );
      })}
    </>
  );
};

export const TotalPaymentAndSend = ({
  data,
  country,
  totalAmount,
  useFormObj,
  loadingCalculator,
  handleSend,
  disabled,
}) => {
  const { push } = useRouter();
  const { currency } = country || {};
  const { destination_currency } = data || {};

  const isMatch = currency === destination_currency;
  const loading = loadingCalculator || !isMatch;

  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ marginBottom: 32 }}
    >
      <div style={{ height: 48 }}>
        <GothamRegular
          className="font12 mb-1"
          style={{ color: jackColors.neutral700 }}
        >
          Total Payment
        </GothamRegular>
        <GothamMedium
          style={{
            fontSize: 20,
            color: jackColors.neutral900,
            fontWeight: "bold",
          }}
        >
          {totalAmount}
        </GothamMedium>
      </div>
      <div className="d-flex align-items-center" style={{ gap: 16 }}>
        <ButtonJack
          type="outline"
          children="Back"
          style={{ minWidth: 65 }}
          onClick={() => push("/cross-border")}
          leftIcon={
            <JackIcons
              name="arrow-back-outline"
              fill="black"
              style={{ width: 20, height: 20 }}
            />
          }
        />
        <ButtonJack
          isLoading={loading}
          type="filled"
          children="Next"
          style={{ minWidth: 60 }}
          onClick={useFormObj.handleSubmit(handleSend)}
          rightIcon={
            <JackIcons
              name="arrow-forward"
              fill={disabled ? jackColors.neutral600 : jackColors.neutral900}
              style={{ width: 20, height: 20 }}
            />
          }
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const CrossBorderTrxDetails = ({
  data,
  loading,
  isConfirmation = false,
}) => {
  const [show, setShow] = useState(false);
  const handleExpand = () => {
    setShow((p) => !p);
  };
  return (
    <div
      style={{
        marginLeft: isConfirmation ? 8 : 24,
        marginRight: isConfirmation ? 0 : 16,
        borderLeft: !isConfirmation && "2px solid #e6e6e8",
      }}
    >
      {data.map((item, index) => {
        const {
          left,
          right,
          icon,
          spacer,
          expand,
          horizontal,
          underscore,
          tooltipRate,
        } = item || {};
        const indexHide = index < 4 && index != 0;
        const hideComponents = !show && indexHide && !isConfirmation;
        const animation = !show && !indexHide;
        const classAnimation = animation ? "slide-down" : "slide-up";
        if (isConfirmation && index === 0) return null;
        if (hideComponents) return null;
        if (horizontal)
          return (
            <hr
              key={index}
              className={classAnimation}
              style={{
                margin: "0px 0px 12px 16px",
                borderTop: "2px dotted #C2C2C2",
              }}
            />
          );
        if (spacer) return <div style={{ height: 12 }} key={index}></div>;
        return (
          <div
            key={index}
            className={`d-flex justify-content-between ${classAnimation}`}
            style={{
              position: "relative",
              paddingBottom: 12,
              paddingLeft: 15,
              zIndex: indexHide ? 0 : 1,
              backgroundColor: "transparent",
            }}
          >
            <div>
              {!!icon ? (
                icon
              ) : (
                <div
                  style={{
                    position: "absolute",
                    left: -4,
                    top: 8,
                    width: 6,
                    height: 6,
                    borderRadius: "50%",
                    backgroundColor: jackColors.neutral900,
                  }}
                />
              )}
              <WrapperTooltip tooltip={tooltipRate}>
                <GothamRegular
                  style={{
                    color: underscore
                      ? jackColors.neutral900
                      : jackColors.neutral700,
                    borderBottom:
                      underscore && `1px solid ${jackColors.neutral900}`,
                  }}
                >
                  {left}
                </GothamRegular>
              </WrapperTooltip>
            </div>
            {loading ? (
              <Skeleton
                width={100}
                height={14}
                style={{ position: "absolute", top: 0, right: 0 }}
              />
            ) : (
              <WrapperTooltip tooltip={tooltipRate}>
                <GothamRegular
                  style={{
                    color: jackColors.neutral900,
                    borderBottom:
                      underscore && `1px solid ${jackColors.neutral900}`,
                  }}
                >
                  {right}
                </GothamRegular>
              </WrapperTooltip>
            )}
            {expand && !isConfirmation && (
              <div
                style={{
                  position: "absolute",
                  right: -20,
                  top: 1,
                  cursor: "pointer",
                }}
              >
                <JackIcons
                  name="chevron-down"
                  fill={jackColors.neutral900}
                  className={`rotate-icon ${show ? "down" : ""}`}
                  onClick={handleExpand}
                  style={{ width: 16, height: 16 }}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const WrapperTooltip = ({ tooltip, children }) => {
  if (tooltip) {
    return (
      <CustomTooltip
        text={
          <div className="text-left">
            <GothamRegular
              className="font12"
              style={{ color: jackColors.neutral800 }}
            >
              This is an{" "}
              <span style={{ fontFamily: "GothamMedium" }}>
                indicative rate
              </span>{" "}
              that serves as an estimation. You can negotiate this rate by
              contacting us.
            </GothamRegular>
          </div>
        }
        placement="top"
        style={{
          backgroundColor: "white",
          color: jackColors.black34,
          padding: 12,
          boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
          maxWidth: 240,
        }}
        popperClassName="white-arrow"
      >
        {children}
      </CustomTooltip>
    );
  }
  return <div>{children}</div>;
};

export const BannerNegoRate = ({}) => {
  return (
    <div
      style={{
        marginBottom: 32,
        position: "relative",
        zIndex: 1,
        height: 56,
      }}
    >
      <div
        style={{
          height: 4,
          width: "100%",
          backgroundColor: "black",
          marginTop: -2,
        }}
      />
      <img
        src={negoRateBannerImg}
        style={{ position: "absolute", width: "100%", marginTop: -4 }}
      />
      <GothamRegular
        className="font12"
        style={{
          position: "absolute",
          zIndex: 2,
          color: jackColors.neutral100,
          padding: "10px 12px 12px 52px",
          marginTop: -2,
        }}
      >
        Sending amount equivalent to USD 5,000 or more? Reach our team via
        WhatsApp group to negotiate a rate that works for you.
      </GothamRegular>
    </div>
  );
};

export const CrossBorderEstimate = ({ data, loading }) => {
  return (
    <div style={{ marginTop: 24 }}>
      {data?.map((item, index) => {
        const isLast = index === data.length - 1;
        const { textLeft = "", bold, textRight = "" } = item || {};
        return (
          <div
            className="d-flex justify-content-center"
            style={{ marginBottom: isLast ? 0 : 4 }}
          >
            {loading ? (
              <Skeleton width={320} height={16} />
            ) : (
              <GothamRegular
                style={{
                  color: jackColors.neutral900,
                  textAlign: "center",
                  lineHeight: "20px",
                }}
              >
                {textLeft}
                <span
                  style={{
                    color: jackColors.neutral900,
                    fontFamily: "GothamMedium",
                  }}
                >
                  {bold}
                </span>
                {textRight}
              </GothamRegular>
            )}
          </div>
        );
      })}
    </div>
  );
};

export const CrossBorderAlert = ({ data, bankFee }) => {
  const { isLocal, isSwiftSha, isSwiftOur } = useFeeLabelDecider(data);
  const dataRender = () => {
    if (isSwiftOur)
      return {
        title: "SWIFT OUR is advised if",
        details: [
          "You require a MT-103 (globally accepted Proof of Payment)",
          "You want to trace or recall your payments",
          "You will pay the entire fee and beneficiary will receive in full amount",
          `Includes additional bank fees of ${bankFee}`,
        ],
      };
    if (isSwiftSha)
      return {
        title: "When using SWIFT SHA:",
        details: [
          "You can request a MT-103 (globally accepted proof of payment)",
          "You can trace or recall your payments",
          "Beneficiary will not receive the full amount as the charges (from intermediary bank) will be deducted from the transfer amount",
        ],
      };

    return {
      title: "When using Local Transfer:",
      details: [
        "Beneficiary will receive the full amount",
        "You pay the smallest fees possible",
      ],
    };
  };
  const alertData = dataRender();
  const { title, details } = alertData;
  return (
    <div style={{ padding: 16, backgroundColor: "#E5F9FC", borderRadius: 8 }}>
      <div className="d-flex justify-content-between">
        <GothamMedium style={{ color: colors.blue10, fontSize: 12 }}>
          {title}
        </GothamMedium>
        <img src={infoIcon} alt="info" />
      </div>
      <ul style={{ paddingInlineStart: 20, fontSize: 12, marginBottom: 0 }}>
        {details?.map((item, index) => (
          <li key={index} style={{ lineHeight: "12px" }}>
            <GothamRegular style={{ color: colors.grey33, fontSize: 12 }}>
              {item}
            </GothamRegular>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const ErrorTextMinMaxAmount = ({
  minAmount,
  maxAmount,
  destAmount,
  destCurr,
}) => {
  if (Number(destAmount) > Number(maxAmount))
    return `The maximum amount you can send is ${destCurr} ${formatCurrency(
      maxAmount
    )}`;
  if (Number(destAmount) < Number(minAmount))
    return `The minimum amount you can send is ${destCurr} ${formatCurrency(
      minAmount
    )}`;
  return ``;
};

export const useMaxMinAmountDecider = ({
  data,
  isB2B,
  deliveryMethod,
  isSwiftCustomCurrency = false,
}) => {
  const { swift_amount } = data || {};
  const {
    local_b2b_max,
    local_b2c_max,
    local_b2b_min,
    local_b2c_min,
    swift_sha_b2b_max,
    swift_sha_b2b_min,
    swift_sha_b2c_max,
    swift_sha_b2c_min,
    swift_our_b2b_max,
    swift_our_b2b_min,
    swift_our_b2c_max,
    swift_our_b2c_min,
  } = swift_amount || {};
  const { isLocal, isSwiftOur, isSwiftSha } =
    useFeeLabelDecider(deliveryMethod);

  const Decider = () => {
    if (isSwiftCustomCurrency)
      return {
        maxAmount: isB2B
          ? +swift_our_b2b_max || +swift_sha_b2b_max
          : +swift_our_b2c_max || +swift_sha_b2c_max,
        minAmount: isB2B
          ? +swift_our_b2b_min || +swift_sha_b2b_min
          : +swift_our_b2c_min || +swift_sha_b2c_min,
      };
    if (isSwiftSha)
      return {
        maxAmount: isB2B ? +swift_sha_b2b_max : +swift_sha_b2c_max,
        minAmount: isB2B ? +swift_sha_b2b_min : +swift_sha_b2c_min,
      };
    if (isSwiftOur)
      return {
        maxAmount: isB2B ? +swift_our_b2b_max : +swift_our_b2c_max,
        minAmount: isB2B ? +swift_our_b2b_min : +swift_our_b2c_min,
      };
    return {
      maxAmount: isB2B ? +local_b2b_max : +local_b2c_max,
      minAmount: isB2B ? +local_b2b_min : +local_b2c_min,
    };
  };

  const { maxAmount, minAmount } = Decider();
  return { maxAmount, minAmount };
};

export const defaultValue = ({ data }) => {
  const { fee, swift_our_fee, swift_sha_fee } = data || {};
  if (Boolean(fee) || fee === 0) return "Local Bank Network";
  if (Boolean(swift_our_fee) || swift_our_fee === 0) return "SWIFT OUR";
  if (Boolean(swift_sha_fee) || swift_sha_fee === 0) return "SWIFT SHA";
};

export const useFeeLabelDecider = (data) => {
  const isLocal = data === "Local Bank Network";
  const isSwiftSha = data === "SWIFT SHA";
  const isSwiftOur = data === "SWIFT OUR";
  const PaymentChannel = () => {
    if (isSwiftOur) return `swift_our`;
    if (isSwiftSha) return `swift_sha`;
    return `local`;
  };
  const payment_channel = PaymentChannel();
  return { isLocal, isSwiftOur, isSwiftSha, payment_channel };
};

export const useTimeDescDecider = ({
  deliveryMethod,
  deliverySla,
  isSwiftCustomCurrency = false,
}) => {
  const { isLocal, isSwiftOur, isSwiftSha } =
    useFeeLabelDecider(deliveryMethod);
  const { localSla, shaSla, ourSla } = deliverySla || {};
  if (isSwiftCustomCurrency) return ourSla || shaSla;
  if (isSwiftSha) return shaSla;
  if (isSwiftOur) return ourSla;
  return localSla;
};

export const useFeeDecider = ({ deliveryFee, deliveryMethod }) => {
  const { isLocal, isSwiftOur, isSwiftSha } =
    useFeeLabelDecider(deliveryMethod);
  const { fee, swift_our_fee, swift_sha_fee } = deliveryFee || {};

  const bankFeeDecider = () => {
    if (isSwiftOur) return Number(swift_our_fee) - Number(swift_sha_fee);
    return 0;
  };

  const bankFee = bankFeeDecider();

  const feeDecider = (isMinusBankFee) => {
    if (!deliveryMethod) return 0;
    if (isSwiftSha) return Number(swift_sha_fee);
    if (isMinusBankFee && isSwiftOur) return Number(swift_our_fee) - bankFee;
    if (isSwiftOur) return Number(swift_our_fee);
    return fee;
  };

  const transfezFee = feeDecider(true);
  const transfezFeeRaw = feeDecider();

  return { bankFee, transfezFee, transfezFeeRaw };
};

export const CrossBorderDeliveryMethod = ({
  deliveryMethod,
  deliverySla,
  setDeliveryMethod,
  activeOption,
  loading,
  watchDestinationCountry,
}) => {
  const { localSla, ourSla, shaSla } = deliverySla || {};
  const [hover, setHover] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const { swift_our_fee, swift_sha_fee, fee } = activeOption || {};
  const isLocalActive = Boolean(fee) || fee === 0;
  const isSwiftShaActive = Boolean(swift_sha_fee) || swift_sha_fee === 0;
  const isSwiftOurActive = Boolean(swift_our_fee) || swift_our_fee === 0;

  const { isOpen, toggle, close } = useModalHook();

  const handleClick = (val) => {
    const { title } = val || {};
    setSelectedData(val);
    setDeliveryMethod(title);
    close();
  };

  const globalProof = `Global proof of payment (MT-103)`;
  const customNotes = `Customize notes`;

  const dataModal = [
    ...(isLocalActive
      ? [
          {
            title: "Local Bank Network",
            desc: "Beneficiary will receive in full amount",
            fee: `Fee IDR ${formatCurrency(Number(fee))}`,
            icon: "Bank",
            items: [
              { point: customNotes, xicon: true },
              { point: globalProof, xicon: true },
              { point: "Should arrive", pointBold: localSla },
            ],
          },
        ]
      : []),
    ...(isSwiftShaActive
      ? [
          {
            title: "SWIFT SHA",
            desc: `You will split the bank transfer fees with recipient. As a result, the recipient will not receive the full amount.`,
            fee: `Fee IDR ${formatCurrency(Number(swift_sha_fee))}`,
            icon: "SwiftSha",
            items: [
              { point: customNotes },
              { point: globalProof },
              { point: "Should arrive", pointBold: shaSla },
            ],
          },
        ]
      : []),
    ...(isSwiftOurActive
      ? [
          {
            title: "SWIFT OUR",
            desc: `You are paying all transaction fees and the recipient will receive in full amount`,
            fee: `Fee IDR ${formatCurrency(Number(swift_our_fee))}`,
            icon: "SwiftOur",
            items: [
              { point: customNotes },
              { point: globalProof },
              { point: "Should arrive", pointBold: ourSla },
            ],
          },
        ]
      : []),
  ];

  const getFullData = dataModal?.filter(
    ({ title }) => title === selectedData
  )[0];

  const { title: titleDefault, icon: iconDefault } = getFullData || {};
  const { title: titleSelected, icon: iconSelected } = selectedData || {};
  const title = titleDefault || titleSelected;
  const icon = iconDefault || iconSelected;

  useEffect(() => {
    setSelectedData(false);
    setDeliveryMethod("");
  }, [watchDestinationCountry?.label]);

  // useEffect(() => {
  //   const filteredData = dataModal
  //     ?.filter(({ title }) => title === deliveryMethod)
  //     .pop();
  //   setSelectedData(filteredData);
  // }, [deliveryMethod]);

  return (
    <div style={{ marginBottom: 32 }}>
      <SelectionTypeCrossBorder
        isOpen={isOpen}
        title={title}
        toggle={toggle}
        optionTitle="Settlement Method"
        placeholder="Select Settlement Method"
        loading={loading}
      />
      {isOpen && (
        <SelectDeliveryMethod
          close={close}
          data={dataModal}
          selectedData={selectedData}
          handleClick={handleClick}
        />
      )}
    </div>
  );
};

export const EmptyScreenSearchCountry = ({
  title = "Country still unavailable",
  desc = "Sorry, money transfer to this country are still unavailable. Please wait for the following update.",
  smallPadding = false,
}) => {
  const padding = smallPadding ? "0px 20px" : "0px 50px";
  return (
    <div className="text-center">
      <img
        src={emptyTrxImg}
        alt="option empty"
        style={{ marginBottom: 24, marginTop: 24 }}
      />
      <div style={{ padding }}>
        <GothamMedium className="mb-1">{title}</GothamMedium>
        <GothamRegular>{desc}</GothamRegular>
      </div>
    </div>
  );
};

export const SelectionBusinessAndDelivery = ({
  deliveryMethod,
  deliverySla,
  setDeliveryMethod,
  activeOption,
  loading,
  data,
  setBusinessType,
  watchDestinationCountry,
}) => {
  return (
    <div
      style={{
        position: "relative",
        marginBottom: 24,
      }}
    >
      <SelectionBusinessType
        data={data}
        setBusinessType={setBusinessType}
        loading={loading}
        watchDestinationCountry={watchDestinationCountry}
      />
      <CrossBorderDeliveryMethod
        loading={loading}
        setDeliveryMethod={setDeliveryMethod}
        deliveryMethod={deliveryMethod}
        deliverySla={deliverySla}
        activeOption={activeOption}
        watchDestinationCountry={watchDestinationCountry}
      />
    </div>
  );
};
