import React, { useState } from "react";
import { Modal } from "reactstrap";
import { jackColors } from "../../assets/colors";
import cleanUpImg from "../../assets/images/clean-up-vector.svg";
import { JackIcons } from "../../assets/jackIcons/parent";
import { useModalWidth } from "../Modals";
import { GothamMedium, GothamRegular } from "../Text";
import { ButtonJack } from "./parent";

export const ConfirmationModalJack = ({
  toggle,
  modal,
  onClick,
  img = cleanUpImg,
  title = "Are you sure?",
  text = "You will delete this account from the list.",
  alignLeft,
  reverseButton,
  buttonTextLeft,
  buttonTextRight,
  loading,
  isCentered,
  isCenteredTitle,
  customContentClassName,
  woToggle,
  modalRef,
  image,
  isOnlyOneButton,
  onClickLeft,
  onClickRight,
  woCloseButton = false,
}) => {
  if (!modal) return null;
  // DEFAULT MODAL >>> https://ibb.co/ypZYs05
  const [disabled, setIsDisabled] = useState(false);

  const modalWidth = useModalWidth(438);

  const leftButtonDecider = () => {
    if (buttonTextLeft) return buttonTextLeft;
    else return "Cancel";
  };

  const rightButtonDecider = () => {
    if (buttonTextRight) return buttonTextRight;
    else return "Confirm";
  };

  const style = isCentered
    ? {
        width: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        textAlign: "center",
      }
    : {};
  return (
    <Modal
      toggle={() => !woToggle && toggle()}
      isOpen={modal}
      className="my-0"
      centered
      contentClassName={`modal-transfez ${customContentClassName}`}
    >
      <div
        ref={modalRef}
        style={{
          position: "relative",
          width: modalWidth,
          borderRadius: 8,
          padding: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: alignLeft ? "start" : "center",
          }}
        >
          {image ? (
            image
          ) : (
            <img src={img} alt="image" style={{ marginBottom: 32 }} />
          )}
          <GothamMedium
            className="font24"
            style={{
              color: jackColors.neutral900,
              marginBottom: 16,
              textAlign: isCenteredTitle ? "center" : "left",
            }}
          >
            {title}
          </GothamMedium>
          <GothamRegular
            style={{
              color: jackColors.neutral800,
              marginBottom: 32,
              textAlign: !alignLeft && "center",
              ...style,
            }}
          >
            {text}
          </GothamRegular>
        </div>
        {isOnlyOneButton ? (
          <div style={{ width: "100%" }}>
            <ButtonJack
              style={{ width: "100%" }}
              type="outline"
              isLoading={loading}
              onClick={toggle}
            >
              {leftButtonDecider()}
            </ButtonJack>
          </div>
        ) : (
          <div
            className={`d-flex justify-content-between ${
              reverseButton && "flex-row-reverse"
            }`}
            style={{
              width: "100%",
              gap: 16,
            }}
          >
            <ButtonJack
              style={{ width: "100%" }}
              type="outline"
              isLoading={loading}
              onClick={() => {
                if (onClickLeft) onClickLeft();
                return toggle();
              }}
            >
              {leftButtonDecider()}
            </ButtonJack>
            <ButtonJack
              style={{ width: "100%" }}
              isLoading={loading}
              disabled={disabled}
              onClick={() => {
                if (onClickRight) return onClickRight();
                onClick();
                toggle();
                setIsDisabled(true);
              }}
            >
              {rightButtonDecider()}
            </ButtonJack>
          </div>
        )}
        {!woCloseButton && (
          <div
            className="hover-600"
            style={{
              width: 32,
              height: 32,
              border: "1px solid #E6E6E8",
              borderRadius: 4,
              display: "grid",
              placeItems: "center",
              position: "absolute",
              cursor: "pointer",
              right: 10,
              top: 10,
            }}
            onClick={toggle}
          >
            <JackIcons
              name="close_Outline"
              fill="#343434"
              style={{ width: 20, height: 20 }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export const ConfirmModalJackOneButton = ({
  isOpen,
  toggle,
  onClick,
  loading,
  title = "title",
  img = cleanUpImg,
  styleDescription,
  buttonText = "Cancel",
  buttonType = "outline",
  fontSizeTitle = "font20",
  description = "description",
}) => {
  const modalWidth = useModalWidth(438);

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={`modal-transfez modal-border-radius4`}
    >
      <div
        style={{
          position: "relative",
          width: modalWidth,
          borderRadius: 8,
          padding: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={img} alt="image" style={{ marginBottom: 32 }} />
          <GothamMedium
            className={`${fontSizeTitle}`}
            style={{
              color: jackColors.neutral900,
              marginBottom: 16,
              textAlign: "center",
            }}
          >
            {title}
          </GothamMedium>
          <GothamRegular
            style={{
              color: jackColors.neutral800,
              marginBottom: 32,
              textAlign: "center",
              ...styleDescription,
            }}
          >
            {description}
          </GothamRegular>
        </div>
        <div style={{ width: "100%" }}>
          <ButtonJack
            style={{ width: "100%" }}
            type={buttonType}
            isLoading={loading}
            onClick={onClick}
          >
            {buttonText}
          </ButtonJack>
        </div>
        <div
          className="hover-600"
          style={{
            width: 32,
            height: 32,
            border: "1px solid #E6E6E8",
            borderRadius: 4,
            display: "grid",
            placeItems: "center",
            position: "absolute",
            cursor: "pointer",
            right: 10,
            top: 10,
          }}
          onClick={toggle}
        >
          <JackIcons
            name="close_Outline"
            fill="#343434"
            style={{ width: 20, height: 20 }}
          />
        </div>
      </div>
    </Modal>
  );
};
