import {
  SINGLE,
  BATCH,
  DATA_ERROR,
  SYSTEM_ERROR,
  MIXED_ERROR,
  SINGULAR_DATA_ERROR,
  SINGULAR_SYSTEM_ERROR,
  SINGULAR_MIXED_ERROR,
  PLURAL_DATA_ERROR,
  PLURAL_SYSTEM_ERROR,
  PLURAL_MIXED_ERROR,
} from "../constants";

const retryResubmitBannerTitleDecider = ({
  trxType,
  warningType,
  failedTrxCount,
}) => {
  switch (trxType) {
    case SINGLE:
      switch (warningType) {
        case DATA_ERROR:
          return `This ${SINGULAR_DATA_ERROR}`;
        case SYSTEM_ERROR:
          return `This ${SINGULAR_SYSTEM_ERROR}`;
        case MIXED_ERROR:
          return `This ${SINGULAR_MIXED_ERROR}`;
        default:
          return "-";
      }

    case BATCH:
      const hasOneFailedTrx = failedTrxCount === 1;

      switch (warningType) {
        case DATA_ERROR:
          if (hasOneFailedTrx)
            return `${failedTrxCount} ${SINGULAR_DATA_ERROR}`;
          return `${failedTrxCount} ${PLURAL_DATA_ERROR}`;
        case SYSTEM_ERROR:
          if (hasOneFailedTrx)
            return `${failedTrxCount} ${SINGULAR_SYSTEM_ERROR}`;
          return `${failedTrxCount} ${PLURAL_SYSTEM_ERROR}`;
        case MIXED_ERROR:
          if (hasOneFailedTrx)
            return `${failedTrxCount} ${SINGULAR_MIXED_ERROR}`;
          return `${failedTrxCount} ${PLURAL_MIXED_ERROR}`;
        default:
          return "-";
      }

    default:
      return "-";
  }
};

export default retryResubmitBannerTitleDecider;
