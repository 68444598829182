import { isEmpty } from "lodash";
import { GothamRegular, TextInlineMedium } from "../../../../components/Text";
import { customDateFormatter } from "../../../../components/tools";
import { spesificDateFormat } from "../../../../pageComponents/scheduledPaymentComponents/formatter";

export const scheduledTextFormatter = (scheduleArr) => {
  if (isEmpty(scheduleArr)) return [];

  return scheduleArr?.map((schedule, index) => {
    const isLast = index == scheduleArr?.length - 1;
    const { name, date: scheduleDate, created_at, action } = schedule;
    const formattedDate = spesificDateFormat(scheduleDate);
    const isCanceled = action == "cancel";
    const isInsufficientBalance = action == "insufficient_balance";
    const isRescheduled = action == "reschedule";
    const isPaid = action == "pay_immediately";
    const delayed_now = isInsufficientBalance && isLast;
    const date = delayed_now ? "" : customDateFormatter(created_at);

    const noCustomTextArr = ["paid", "canceled"];
    const textDecider = () => {
      if (isInsufficientBalance) {
        if (isLast) {
          return (
            <GothamRegular className="font12">
              Payment delayed due to insufficient balance <br />
              <TextInlineMedium className="font12">
                Need action:
                <br />{" "}
              </TextInlineMedium>
              Reschedule or pay now
            </GothamRegular>
          );
        }
        return "Payment delayed due to insufficient balance";
      }

      if (isRescheduled) {
        return (
          <GothamRegular className="font12">
            <TextInlineMedium className="font12">{name}</TextInlineMedium>{" "}
            rescheduled the payment for{" "}
            <TextInlineMedium className="font12">
              {formattedDate}
            </TextInlineMedium>
          </GothamRegular>
        );
      }

      if (isCanceled) return `Canceled by ${name}`;
      if (isPaid) return `Payment has been released by ${name}`;
      return (
        <GothamRegular className="font12">
          <TextInlineMedium className="font12">{name}</TextInlineMedium>{" "}
          approved and scheduled the payment for{" "}
          <TextInlineMedium className="font12">
            {formattedDate}
          </TextInlineMedium>
        </GothamRegular>
      );
    };
    const text = textDecider();
    return {
      text,
      date,
      isActive: true,
      isRejected: isCanceled,
      isCustomText: !noCustomTextArr.includes(action),
    };
  });
};

export const useSchedulePaymentTransaction = (transaction, lineArray = []) => {
  const { activity_state, scheduled_payment, schedule_tracker_summary } =
    transaction || {};
  const { date_and_time } = scheduled_payment || {};

  const isScheduledTransaction = Boolean(schedule_tracker_summary);
  const isScheduled = activity_state == "scheduled";
  const isPaymentDelayed = activity_state == "payment_delayed";
  const lastSchedulePaymentAction =
    schedule_tracker_summary?.[schedule_tracker_summary?.length - 1];
  const { action: lastAction } = lastSchedulePaymentAction || {};

  const isRescheduleActive = lastAction == "reschedule";
  const isScheduleCancelled = lastAction == "cancel";
  const isPayImmediately = lastAction == "pay_immediately";
  const scheduleArray = scheduledTextFormatter(schedule_tracker_summary);
  const slicedApproverLine = lineArray?.slice(0, -1);
  const scheduledLineArray = isScheduledTransaction
    ? [...slicedApproverLine, ...scheduleArray]
    : lineArray;

  const queuedObj = {
    text: (
      <GothamRegular className="font12">
        Transactions queued for automatic payment on{" "}
        <TextInlineMedium className="font12">
          {customDateFormatter(date_and_time)}
        </TextInlineMedium>
      </GothamRegular>
    ),
    isActive: true,
    isCustomText: true,
  };

  return {
    isScheduled,
    isScheduledTransaction,
    isRescheduleActive,
    isPaymentDelayed,
    isPayImmediately,
    scheduledLineArray,
    isScheduleCancelled,
    queuedObj,
  };
};
