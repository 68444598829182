import { useRouter } from "next/router";
import { GothamRegular } from "../../../../components/Text";
import { dataSorter, toLabelValue } from "../../../../components/tools";
import {
  getUserButtonBooleans,
  getUserRole,
} from "../../../../contexts/AuthContext";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { fetch } from "../../../../tools/api";
import {
  CategoryIcon,
  CategoryLabel,
} from "../../../categoryComponents/general/components";
import { useCategoryFlag } from "../../../categoryComponents/general/hooks";
import { transactionsBooleans } from "./booleans";
import { jackColors } from "../../../../assets/colors";

export const useTransactionFiltersArray = (type) => {
  const { isMine, isCompany } = transactionsBooleans(type);

  const { pathname } = useRouter();

  const { isSuperAdmin, isAdmin, isBookKeeper, isApprover, isPayroll } =
    getUserRole();

  const { isRequiredCategory, isNotRequiredCategory } = useCategoryFlag();

  const { canSendMoney, canWithdraw, canTopup, canViewCards } =
    getUserButtonBooleans();

  const canPayroll = isPayroll || isAdmin || isSuperAdmin || isBookKeeper;

  const canRefund = isAdmin || isSuperAdmin || isBookKeeper;

  const employeeWithNoActivities =
    !canSendMoney && !canWithdraw && !canTopup && !canPayroll && !canRefund;

  const canSeeCreateBy = isAdmin || isSuperAdmin || isBookKeeper || isApprover;

  const canShowSendMoney = canSendMoney || isBookKeeper;

  const canSelectTopup = canTopup || isBookKeeper;

  const canSelectWithdraw = canWithdraw || isBookKeeper;

  const canSeeVa = isSuperAdmin || isAdmin || isBookKeeper;

  const canSelectCategory =
    (isRequiredCategory || isNotRequiredCategory) &&
    pathname !== "/category/details/[id]";

  const {
    teamsData,
    teamsLoading,
    users,
    categories,
    loadingCategories,
    loadingUsers,
  } = useConstants();

  return [
    !employeeWithNoActivities && {
      label: "Transaction Type",
      icon: "category",
      name: "product",
      labelPill: "Type",
      type: "status",
      search: true,
      array: [
        canShowSendMoney && {
          label: "International Transfer",
          value: "cross_border",
        },
        canShowSendMoney && {
          label: "Local transfer",
          value: "local",
        },
        canPayroll && { label: "Payroll", value: "payroll" },
        canViewCards !== undefined && {
          label: "Card",
          value: "card",
        },
        canSelectTopup && { label: "Top up", value: "top_up" },
        canRefund && { label: "Refund", value: "refund_transaction" },
        canSelectWithdraw && {
          label: "Withdrawal",
          value: "withdrawal",
        },
        {
          label: "Bill Payment",
          value: "invoice",
        },
        {
          label: "Reimbursement",
          value: "reimbursement",
        },
        canSeeVa && {
          label: "Virtual Account",
          value: "collection",
        },
        {
          label: "Between Jack",
          value: "balance_transfer",
        },
      ],
    },

    {
      label: "Date",
      name: "date",
      type: "date",
      icon: "calendar",
      queryNames: ["from_date", "to_date"],
    },
    // { label: "Keywords", type: "keywords", name: "keywords" },
    isCompany && {
      label: "Teams",
      search: true,
      type: "status",
      name: "team",
      icon: "people",
      array: teamsData,
      loading: teamsLoading,
    },

    {
      label: "Status",
      name: "status",
      type: "status",
      icon: "loader-outline",
      array: [
        {
          label: "Success",
          value: "success",
          desc: "All completed transactions",
        },
        {
          label: "In progress",
          value: "in_progress",
          desc: "All approved/paid transactions that are currently being transferred to recipient",
        },
        {
          label: "Scheduled",
          value: "scheduled",
          desc: "All transactions that are currently queued for automatic payment",
        },
        {
          label: "Pending Approval",
          value: "pending",
          desc: "All requests that are waiting for payment/approval",
        },
        {
          label: "Payment Delayed",
          value: "payment_delayed",
          desc: "All scheduled transactions that are delayed due to insufficient balance",
        },
        {
          label: "Draft",
          value: "draft",
          desc: "All transactions that are currently paused and can be edited",
        },
        {
          label: "Failed",
          value: "failed",
          desc: "All unsuccessful transactions",
        },
        {
          label: "Partially Failed",
          value: "partial_failed",
          desc: "All batch transactions experienced partial failure",
        },
        {
          label: "Cancelled",
          value: "cancelled",
          desc: "All transactions canceled by you",
        },
      ],
    },
    {
      icon: "account_balance",
      label: "Source Account",
      type: "status",
      name: "source_account",
      array: [
        {
          label: "Company Balance",
          value: "main_balance",
          desc: "All in and out transactions from company’s main balance ",
        },
        {
          label: "Direct Payment",
          value: "direct_payment",
          desc: "All direct payments from your bank account",
        },
        {
          label: "E-wallet",
          value: "e_wallet",
          desc: "All transactions from employees’ team wallet",
        },
        {
          label: "Card",
          value: "card",
          desc: "All transactions from employees’ cards",
        },
        {
          label: "Refund",
          value: "refund",
          desc: "All returned balances due to failed transactions",
        },
      ],
    },
    { label: "Cards", type: "card", name: "card", icon: "corporate card" },
    {
      icon: "wallet",
      label: "Team Wallet",
      type: "fetch",
      name: "e_wallet_user",
      urlFunc: (page, text) =>
        `/business_users?page=${page}&q[wallet_state_eq]=active&per_page=10&q[email_or_name_or_mobile_cont]=${text.replace(
          "0",
          ""
        )}`,
      isDoku: true,
    },
    {
      label: "Amount",
      type: "amount",
      name: "amount",
      icon: "hash",
      queryNames: [
        "amount_type",
        "specific_amount",
        "minimum_amount",
        "maximum_amount",
      ],
    },
    canSeeCreateBy &&
      !isMine && {
        label: "Created by",
        search: true,
        type: "status",
        name: "created_by",
        icon: "person-outline",
        array: dataSorter(
          users.map((item) => {
            const { name, email } = item || {};
            const label = name || email;
            return { ...item, label };
          }),
          "label"
        ),
        loading: loadingUsers,
      },
    canSelectCategory && {
      label: "Categories",
      search: true,
      type: "status",
      name: "category",
      icon: "coin",
      array: categories.map((item) => {
        const { id, name, color, icon, account_code } = item || {};
        const label = (
          <CategoryLabel
            icon={icon}
            color={color}
            upperText={name}
            lowerText={account_code}
            style={{
              marginTop: "-8px",
            }}
          />
        );
        return {
          ...item,
          label,
          value: id,
          labelStyle: { overflow: "visible" },
          containerStyle: {
            display: "flex",
            alignItems: "flex-start",
          },
        };
      }),
      loading: loadingCategories,
    },
    {
      icon: "attach",
      label: "Attachment",
      type: "radio",
      name: "attachment",
      array: [
        // { label: "All", value: "" },
        { label: "Has attachment", value: "has_attachment" },
        {
          label: "No attachment",
          value: "no_attachment",
        },
      ],
    },
  ];
};
