import React, { useEffect } from "react";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../../components/Modals/RightModal/parent";
import {
  BackButton,
  BannerQuotaTransferServices,
  CardsTransferService,
  MainCardTransferService,
  ModalInfoQuota,
  TransferServiceTitle,
} from "./components";
import { OptionUltimateTransfer, OptionValueTransfer } from "./option";
import { useModalHook } from "../../../../components/Modals";
import { useBooleanDanamonProduct } from "../DanamonReleasePayment/boolean";
import { CustomBannerBanking } from "../DanamonReleasePayment/sourceOfPayment";
import { getTotalTrxCalculateFee } from "./logic";
import { eventsTracker } from "../../../../universalFunctions/events";

export const TransferServiceModal = ({
  data,
  tasks = [],
  isMultiRelease,
  isOpen,
  toggle,
  optionRules,
  dataHandlingFee,
  activeTransferService,
  activeTransferBalance,
  setActiveService = () => {},
}) => {
  const { isCollectionPocket } = useBooleanDanamonProduct(
    activeTransferBalance?.value
  );

  // Transfez Service Option
  const {
    option: optionUltimate,
    ObjOptimized,
    isPayroll,
    isVirtualAccount,
    objRulesBiFast,
    objRulesOptimized,
  } = OptionUltimateTransfer({
    optionRules,
    isMultiRelease,
    data,
  });

  // Modal stuff
  const { isOpen: isOpenInfoQuota, toggle: toggleInfoQuota } = useModalHook();
  const { toToggle, toListener } = useSmoothRightModal();

  const openInfoQuota = () => {
    eventsTracker("open_info_quota_release_payment");
    toggleInfoQuota();
  };

  const onSubmitChangeService = (service) => {
    eventsTracker("choose_transfer_service_release_payment");
    setActiveService(service);
    toToggle();
  };

  useEffect(() => {
    // change balance to pocket -> service automaticly optimized_route
    if (isCollectionPocket) {
      setActiveService(ObjOptimized);
    } else {
      setActiveService({});
    }
  }, [activeTransferBalance?.value]);

  const propsOption = {
    isPayroll,
    isVirtualAccount,
    array: optionUltimate,
    activeTransferService,
    setActive: onSubmitChangeService,
  };

  // quota & limit
  const { quota, remaining, used, outOfQuota, partialFree } =
    getTotalTrxCalculateFee({ dataHandlingFee });
  const propsLimitQuota = {
    quota,
    remaining,
    used,
    outOfQuota,
    partialFree,
  };

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        noPadding={true}
        width={[420]}
        listener={toListener}
        woClose
        array={[
          <div style={{ padding: 16 }}>
            <BackButton onClick={() => toToggle()} />
            <TransferServiceTitle isPayroll={isPayroll} />

            {partialFree || outOfQuota ? (
              <div onClick={openInfoQuota} style={{ cursor: "pointer" }}>
                <CustomBannerBanking
                  title={
                    partialFree
                      ? `Heads up! You only have ${remaining} free transfers left`
                      : "You ran out of free transfer quota"
                  }
                  msg={
                    partialFree
                      ? "Regular fees will apply after you exceed this number."
                      : "Regular fees will apply until the quota resets next month."
                  }
                />
              </div>
            ) : (
              <BannerQuotaTransferServices onClick={openInfoQuota} />
            )}
            <MainCardTransferService {...propsOption} />
          </div>,
        ]}
      />
      <ModalInfoQuota
        toggle={toggleInfoQuota}
        isOpen={isOpenInfoQuota}
        propsLimitQuota={propsLimitQuota}
      />
    </>
  );
};
