import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { useModalWidth } from "../../components/Modals";
import { GothamMedium } from "../../components/Text";
import { contentModalCustomPayroll } from "./constant";
import { windowDimension } from "../../components/tools";
import TemplateModalTalenta from "./TemplateModalTalenta";

const GuideTemplateModalJack = ({ isOpen, toggle }) => {
  const [indexModal, setIndexModal] = useState(0);
  const modalWidth = useModalWidth(906);
  const { height: heightWindow } = windowDimension();

  const onChange = (index) => {
    setIndexModal(index);
  };

  const selectedContent = contentModalCustomPayroll(onChange);

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName={"modal-transfez"}
    >
      <div
        style={{
          position: "relative",
          width: modalWidth,
          borderRadius: 8,
          flexDirection: "column",
          justifyContent: "start",
        }}
        className="d-flex"
      >
        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
            borderBottomWidth: 1,
            borderColor: jackColors.greyE6,
            alignItems: "center",
          }}
        >
          <GothamMedium className="font20">Upload Guide</GothamMedium>
          <div
            className="hover-600"
            style={{
              width: 40,
              height: 40,
              border: "1px solid #E6E6E8",
              borderRadius: 4,
              display: "grid",
              placeItems: "center",
              position: "absolute",
              cursor: "pointer",
              right: 20,
              top: 15,
            }}
            onClick={toggle}
          >
            <JackIcons
              name="close_Outline"
              fill="#343434"
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
        <TemplateModalTalenta
          selectedContent={selectedContent[indexModal]}
          onChange={onChange}
          modalWidth={modalWidth}
          heightWindow={heightWindow}
          indexModal={indexModal}
        />
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%", gap: 16, padding: 20 }}
        >
          <ButtonJack
            style={{ width: "100%" }}
            type="outline"
            onClick={() => toggle()}
          >
            Close
          </ButtonJack>
          <ButtonJack style={{ width: "100%" }} onClick={() => toggle()}>
            Got it!
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

export default GuideTemplateModalJack;
