import { Chip, FormControl, TextField } from "@material-ui/core";
import { titleCase } from "change-case";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { colors, jackColors } from "../../../../../../assets/colors";
import questionIcon from "../../../../../../assets/images/question-icon-pink.svg";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { AdvancedHoverInfo } from "../../../../../../components/HoverInfo";
import { CustomTextFieldError } from "../../../../../../components/inputs";
import { GothamMedium } from "../../../../../../components/Text";
import {
  removeDuplicates,
  stringCutter,
} from "../../../../../../components/tools";

export const isEmailChecker = (email) => {
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return regexEmail.test(email);
};

export const InputChip = ({
  name = "",
  label: labelRaw = "",
  isSingle = false,
  type = "text",
  defaultValues: defaultValuesRaw = [],
  listener = () => {},
  info = "",
  limit,
  isReset,
  disabled,
  showError = true,
  ...props
}) => {
  const defaultValues = defaultValuesRaw.filter((item) => item);
  const label = labelRaw || titleCase(name);
  const [values, setValues] = useState(defaultValues);
  const [tempValue, setTempValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!isReset) return;
    setValues(defaultValues);
    setTempValue("");
    setError("");
  }, [isReset]);

  useEffect(() => {
    if (!tempValue) return;
    if (isEmailChecker(tempValue)) return setError("");
    setError("Please enter a valid email address");
  }, [tempValue]);

  let obj = {};
  obj[name] = { values, tempValue };

  useEffect(() => {
    listener(obj);
  }, [values.length, tempValue]);

  const isEmail = type === "email";

  const handleDelete = (index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
  };

  const handleKeyDown = (e) => {
    if (!e) return;
    const { key } = e || {};
    const isEnter = key === "Enter";
    const isBackspace = key === "Backspace";
    const isDelete = !tempValue && isBackspace;

    if (isDelete) return handleDelete(values.length - 1);

    if (!isEnter) return;
    if (!tempValue) return;

    const isNotEmail = !isEmailChecker(tempValue);

    if (isEmail && isNotEmail) {
      listener({ ...obj, error: true });
      return setError("Please enter a valid email address");
    }

    setTempValue("");
    setError("");
    setValues(removeDuplicates([...values, tempValue]));
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="d-flex align-items-center mb-2" style={{ height: 16 }}>
        <GothamMedium className="font12">{label}</GothamMedium>
        {!!info && (
          <AdvancedHoverInfo
            style={{ marginBottom: 4 }}
            className="ml-1"
            text={info}
          >
            <JackIcons
              name="question-mark-circle-outline"
              fill={jackColors.black34}
              style={{ width: 12, height: 12 }}
            />
          </AdvancedHoverInfo>
        )}
      </div>
      <div
        style={{
          position: "relative",
          marginBottom: "12px",
        }}
      >
        <FormControl
          style={{
            display: "flex",
            flexDirection: isSingle ? "row" : "column",
            // flexDirection: "row",
            gap: "8px",
            border: `1px solid ${colors.greye6}`,
            padding: 8,
            borderRadius: 4,
            flex: "auto",
            width: "100%",
            minHeight: 40,
            backgroundColor: disabled ? colors.greyea : "",
          }}
        >
          {!isEmpty(values) && (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {values.map((value, index) => (
                <Chip
                  key={index}
                  label={stringCutter(value)}
                  size="small"
                  style={{
                    marginRight: 5,
                    width: "fit-content",
                    borderRadius: "4px",
                    backgroundColor: colors.greye6,
                    color: colors.grey6c,
                    fontSize: "12px",
                  }}
                  onDelete={() => handleDelete(index)}
                />
              ))}
            </div>
          )}
          <TextField
            disabled={disabled}
            name={name}
            value={tempValue}
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: 14,
                fontFamily: "GothamBook",
                lineHeight: "20px",
                paddingBottom: 0,
              },
            }}
            type={type}
            style={{ width: "100%" }}
            onChange={(e) => {
              if (isSingle && !isEmpty(values)) return;
              if (limit && limit <= values.length) return;
              setTempValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
            {...props}
          />
        </FormControl>
        <CustomTextFieldError
          errorStyle={{ fontSize: 12 }}
          message={error}
          show={!!error && showError}
          pointerBottom
        />
      </div>
    </div>
  );
};
