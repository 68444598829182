import { useRouter } from "next/router";
import React from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { useLayoutSidebars } from "../../logics";
import { SidebarDropdown } from "./dropdown";
import { SidebarList } from "./list";
import { GothamMedium } from "../../../../components/Text";
import { useLayout } from "../../parent";

export const SidebarComponent = ({
  array,
  accountArray,
  onClick = () => {},
}) => {
  const { hasSidebar, isSetting } = useLayoutSidebars();
  const { push } = useRouter();
  const { onClose } = useLayout();

  const { pathname } = useRouter();

  const totallyHideSidebar = [
    "/login-demo",
    "/create-password",
    "/kyb/basis",
    "/login",
    "/forgot-password",
    "/register",
    "/email-redirect",
    "/tnc-login",
    "/tnc-subscription",
    "/terms-and-conditions/employee",
    "/terms-and-conditions/admin",
    "/register/success",
    "/new-register",
    "/new-register/success",
  ].includes(pathname);

  return (
    <>
      {/* FEINT */}
      <div
        style={{
          width: hasSidebar ? 240 : 0,
          transition: "all 0.4s linear",
        }}
      />
      {/* FEINT */}
      <div
        style={{
          position: "absolute",
          top: 0,
          marginLeft: 0,
          zIndex: 10,
          width: totallyHideSidebar ? 0 : 240,
          opacity: totallyHideSidebar ? 0 : 1,
          translate: hasSidebar ? "0px" : "-240px 0px",
          transition: "all 0.4s linear",
          height: "100vh",
          backgroundColor: "white",
          borderRight: `1px solid ${jackColors.greyE6}`,
          flexDirection: "column",
        }}
        className="d-flex justify-content-between"
      >
        <div>
          <div
            style={{
              height: 60,
              padding: 12,
              paddingLeft: 32,
            }}
            className="d-flex"
          >
            <JackIcons
              name="fullsize"
              className="hover"
              onClick={() => push("/dashboard")}
            />
          </div>
          {isSetting && (
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                paddingLeft: 24,
                paddingRight: 16,
                paddingBottom: 8,
                paddingTop: 40,
                borderTop: `1px solid ${jackColors.greyE6}`,
              }}
            >
              <GothamMedium>Settings</GothamMedium>
              <JackIcons
                styleDiv={{
                  backgroundColor: "rgba(144, 144, 144, 0.15)",
                  width: 24,
                  height: 24,
                  borderRadius: 12,
                }}
                style={{ width: 16, height: 16 }}
                name="close"
                fill="black"
                onClick={onClose}
                className="hover"
              />
            </div>
          )}
          <SidebarList array={array} onClick={onClick} />
        </div>
      </div>
      {!isSetting && !totallyHideSidebar && (
        <SidebarDropdown
          array={accountArray}
          onClick={onClick}
          hasSidebar={hasSidebar}
        />
      )}
    </>
  );
};
