import { jackColors } from "../../../../../../assets/colors";
import { GothamRegular } from "../../../../../../components/Text";
import CategoryIcon from "../../CategoryIcon";
import styles from "./Category.module.css";

const Category = ({
  isSimple = false,
  isSelected = false,
  category = {},
  onSelect = () => {},
}) => {
  return (
    <div
      className={styles["category"]}
      style={{ backgroundColor: isSelected ? jackColors.neutral500 : "" }}
      onClick={() => onSelect(category)}
    >
      <div>
        <CategoryIcon
          name={category.icon}
          fill={category.color}
          style={{ scale: "0.8" }}
        />
        <GothamRegular
          woFontColor
          style={{
            color: jackColors.neutral900,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "90%",
          }}
        >
          {category.name}
        </GothamRegular>
      </div>
      {!isSimple && (
        <GothamRegular
          woFontColor
          className="font12"
          style={{
            color: jackColors.neutral700,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {category.description}
        </GothamRegular>
      )}
    </div>
  );
};

export default Category;
