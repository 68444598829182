import { jackColors } from "../../../../assets/colors";
import { GothamRegular } from "../../../../components/Text";

const OverLimit = ({ over = 0 }) => {
  return (
    <GothamRegular
      woFontColor
      className="font12"
      style={{ color: jackColors.redE7 }}
    >
      Please remove {over} file(s) to continue.
    </GothamRegular>
  );
};

export default OverLimit;
