import { useState } from "react";
import { RadioOption } from "../../paymentCycleComponents/modal";
import { PaymentCycleSelection, SpesificDateForm } from "./forms";

export const RescheduleForm = ({
  product,
  invoiceDueDate,
  state,
  defaultValue,
}) => {
  const [rescheduleItem, setItem] = state;

  const { type } = rescheduleItem;
  const isPayImmediately = type == "pay_immediately";
  const isSpesificDate = type == "spesific_date";
  const isPaymentCycle = type == "payment_cycle";

  const handleSet = (type, payload) => setItem({ type, payload });
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <RadioOption
        label="Pay Immediately"
        isActive={isPayImmediately}
        handleClick={() => {
          setItem({ type: "pay_immediately" });
        }}
        containerStyle={{ alignItems: "center" }}
      />
      <RadioOption
        label="Specific Date & Time"
        isActive={isSpesificDate}
        handleClick={() => setItem({ type: "spesific_date" })}
        containerStyle={{ alignItems: "center" }}
      />
      {isSpesificDate && (
        <SpesificDateForm
          setItem={(payload) => handleSet("spesific_date", payload)}
          defaultValue={defaultValue}
        />
      )}
      <RadioOption
        label="Payment Cycle"
        isActive={isPaymentCycle}
        handleClick={() => setItem({ type: "payment_cycle" })}
        containerStyle={{ alignItems: "center" }}
      />
      {isPaymentCycle && (
        <PaymentCycleSelection
          product={product}
          invoiceDueDate={invoiceDueDate}
          setItem={(payload) => handleSet("payment_cycle", payload)}
          defaultValue={defaultValue}
        />
      )}
    </div>
  );
};
