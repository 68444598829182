import { sentenceCase, titleCase } from "change-case";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { colors } from "../assets/colors";
import { GothamRegular } from "./Text";

const IconButton = ({ isActive, src, onMouseDown: onMouseDownProps }) => {
  const onMouseDown = (e) => {
    e.preventDefault();
    onMouseDownProps();
  };

  const iconContainerStyle = {
    width: "16px",
    height: "16px",
    display: "grid",
    placeItems: "center",
    backgroundColor: isActive && colors.greyC2,
    border: "none",
    borderRadius: "2px",
  };

  return (
    <div
      className="outline"
      style={iconContainerStyle}
      //if use onClick, it will lose focus on editor
      onMouseDown={(e) => {
        onMouseDown(e);
      }}
    >
      <img src={src} />
    </div>
  );
};

const DragIndicator = ({ inputHeight, setInputHeight }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [lastY, setLastY] = useState(null);
  const [currentHeight, setCurrentHeight] = useState(inputHeight);

  const onMouseDown = (event) => {
    setIsDragging(true);
    setLastY(event.clientY);
    setCurrentHeight(inputHeight);
  };

  const onMouseMove = (event) => {
    if (isDragging) {
      const diff = event.clientY - lastY;
      const result = currentHeight + diff;
      setInputHeight(result);
    }
  };

  const onMouseUpLeave = () => {
    setIsDragging(false);
  };

  const dragIndicatorStyle = {
    cursor: "grab",
    position: "absolute",
    width: 40,
    height: 40,
    backgroundColor: "transparent",
    bottom: 0,
    right: 4,
  };

  const iconStyle = {
    position: "absolute",
    right: 0,
    bottom: 4,
  };

  return (
    <div
      style={dragIndicatorStyle}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUpLeave}
      onMouseLeave={onMouseUpLeave}
    >
      <img src="/images/enlarge.svg" style={iconStyle} />
    </div>
  );
};

export const CustomRichTextArea = ({
  useFormObj,
  name,
  defaultValue,
  label,
  error,
  placeholder = "Type here...",
  helperText = "",
  required = false,
  maxChar = 1000,
  disabled = false,
  height,
  style,
  isUnlimited = false,
  containerStyle,
  forwardRef,
  isOptional,
}) => {
  //to make sure document is defined

  const [src, setSrc] = useState({});

  useEffect(async () => {
    const res = await import("draft-js");
    const { stateToHTML } = await import("draft-js-export-html");

    setSrc({ ...res, stateToHTML });
  }, []);

  if (isEmpty(src)) return null;

  if (typeof window === "undefined") return null;

  return (
    <CustomRichTextAreaReal
      useFormObj={useFormObj}
      name={name}
      defaultValue={defaultValue}
      label={label}
      error={error}
      placeholder={placeholder}
      helperText={helperText}
      required={required}
      maxChar={maxChar}
      disabled={disabled}
      height={height}
      style={style}
      isUnlimited={isUnlimited}
      containerStyle={containerStyle}
      forwardRef={forwardRef}
      isOptional={isOptional}
      src={src}
    />
  );
};

const CustomRichTextAreaReal = ({
  useFormObj: { control, setValue, errors },
  name,
  defaultValue,
  label,
  error,
  placeholder = "Type here...",
  helperText = "",
  required = false,
  maxChar = 1000,
  disabled = false,
  height,
  style,
  isUnlimited = false,
  containerStyle,
  forwardRef,
  isOptional,
  src,
}) => {
  const {
    Editor,
    EditorState,
    RichUtils,
    ContentState,
    convertFromHTML,
    stateToHTML,
  } = src;

  const MINIMUM_INPUT_HEIGHT = 92;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [currentMinimumInput, setCurrentMinimumInput] = useState(
    height || MINIMUM_INPUT_HEIGHT
  );
  const [inputHeight, setInputHeight] = useState(currentMinimumInput);
  const [charCount, setCharCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const editorRef = useRef(null);
  const controllerContainerRef = useRef(null);
  const currentContentHeight = controllerContainerRef.current?.clientHeight;

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setValue(name, newState);
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const isActiveChecker = (type, category) => {
    const isBlock = category === "block";
    if (isBlock)
      return (
        editorState
          .getCurrentContent()
          .getBlockForKey(editorState.getSelection().getStartKey())
          .getType() === type
      );
    return editorState.getCurrentInlineStyle().has(type);
  };

  const inlineStyleHandler = (type) => {
    const newState = RichUtils.toggleInlineStyle(editorState, type);
    setEditorState(newState);
  };

  const blockStyleHandler = (type) => {
    const newState = RichUtils.toggleBlockType(editorState, type);
    setEditorState(newState);
  };

  const styleButtons = [
    {
      type: "BOLD",
      category: "inline",
      icon: "/images/bold-icon.svg",
      onMouseDown: () => inlineStyleHandler("BOLD"),
    },
    {
      type: "ITALIC",
      category: "inline",
      icon: "/images/italic-icon.svg",
      onMouseDown: () => inlineStyleHandler("ITALIC"),
    },
    {
      type: "UNDERLINE",
      category: "inline",
      icon: "/images/underline-icon.svg",
      onMouseDown: () => inlineStyleHandler("UNDERLINE"),
    },
    {
      type: "STRIKETHROUGH",
      category: "inline",
      icon: "/images/strikethrough-icon.svg",
      onMouseDown: () => inlineStyleHandler("STRIKETHROUGH"),
    },
    {
      type: "unordered-list-item",
      category: "block",
      icon: "/images/bullet-point-icon.svg",
      onMouseDown: () => blockStyleHandler("unordered-list-item"),
    },
    {
      type: "ordered-list-item",
      category: "block",
      icon: "/images/number-point-icon.svg",
      onMouseDown: () => blockStyleHandler("ordered-list-item"),
    },
  ];

  const maxCharReached = charCount >= maxChar && !isUnlimited;
  const maxCharError = maxCharReached && "Max character limit reached";
  const errorMessage = errors[name]?.message || maxCharError;
  const isError = Boolean(error || errorMessage || maxCharError);

  const handleBeforeInput = () => {
    if (maxCharReached) return "handled";
    return "not-handled";
  };

  const onEditorChange = (newState) => {
    const contentState = newState.getCurrentContent();
    const currentCharCount = contentState.getPlainText().length;
    setCharCount(currentCharCount);
    setEditorState(newState);
  };

  const errorString = () => {
    if (typeof error == "string" && error) return error;
    if (errorMessage) return sentenceCase(errorMessage);
    return getLabel + " cannot be empty";
  };

  const getLabel = label || titleCase(name);
  const updatesFromHTML = (stringHTML) => {
    const { contentBlocks } = convertFromHTML(stringHTML);
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  };

  useEffect(() => {
    if (defaultValue) return updatesFromHTML(defaultValue);
    return;
  }, []);

  useEffect(() => {
    const isEmptyRichText = !editorState.getCurrentContent().hasText();
    if (isEmptyRichText) return setValue(name, "");
    setValue(name, stateToHTML(editorState.getCurrentContent()));
  }, [editorState]);

  useEffect(() => {
    if (inputHeight < currentMinimumInput) setInputHeight(currentMinimumInput);
  }, [inputHeight, currentMinimumInput]);

  useEffect(() => {
    const bottomMenuHeight = 60;
    setCurrentMinimumInput(currentContentHeight + bottomMenuHeight);
  }, [currentContentHeight]);

  useEffect(() => {
    if (currentContentHeight < MINIMUM_INPUT_HEIGHT)
      setCurrentMinimumInput(MINIMUM_INPUT_HEIGHT);
  }, [currentMinimumInput]);

  const labelContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    marginBottom: "4px",
  };

  const labelStyle = {
    color: isError ? colors.redE7 : colors.neutral900,
    fontSize: "12px",
  };

  const optionalStyle = {
    fontSize: "10px",
    color: colors.neutral700,
  };

  const borderColorDecider = () => {
    if (isError) return colors.redE7;
    if (isHovered && !isFocused) return colors.neutral600;
    return colors.neutral500;
  };

  const borderColor = borderColorDecider();

  const inputContainerStyle = {
    backgroundColor: disabled ? colors.neutral400 : colors.neutral100,
    padding: "6px 8px",
    border: `1px solid ${borderColor}`,
    borderRadius: "8px",
    height: inputHeight,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "space-between",
    fontSize: "14px",
    ...containerStyle,
  };

  const bottomMenuStyle = {
    position: "relative",
  };

  const buttonContainerStyle = {
    display: "flex",
    gap: "20px",
  };

  const maxCharStyle = {
    color: isFocused ? colors.neutral900 : colors.neutral600,
    fontSize: "10px",
    position: "absolute",
    bottom: "-6px",
    right: "6px",
    transition: "all 0.2s ease-in-out",
  };

  const errorStyle = { color: colors.redE7, fontSize: "10px" };

  const helperStyle = {
    color: colors.neutral700,
    fontSize: "12px",
  };

  return (
    <div
      onMouseEnter={() => {
        if (disabled || isFocused) return;
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (disabled || isFocused) return;
        setIsHovered(false);
      }}
      onMouseDown={() => {
        if (disabled) return;
        setIsFocused(true);
      }}
      onClick={() => editorRef.current.focus()}
      style={style}
      ref={forwardRef}
    >
      <div style={labelContainerStyle}>
        <GothamRegular style={labelStyle}>
          {required ? `${getLabel}*` : getLabel}
        </GothamRegular>
        {isOptional && (
          <GothamRegular style={optionalStyle}>(optional)</GothamRegular>
        )}
      </div>
      <div style={inputContainerStyle}>
        <div ref={controllerContainerRef}>
          <Controller
            name={name}
            control={control}
            defaultValue={editorState}
            render={() => {
              return (
                <Editor
                  ref={editorRef}
                  editorState={editorState}
                  onChange={onEditorChange}
                  handleKeyCommand={handleKeyCommand}
                  placeholder={placeholder}
                  onMouseDown={() => editorRef.current.focus()}
                  onBlur={() => {
                    setIsFocused(false);
                  }}
                  handleBeforeInput={handleBeforeInput}
                  readOnly={disabled}
                />
              );
            }}
          />
        </div>
        <div style={bottomMenuStyle}>
          <div style={buttonContainerStyle}>
            {styleButtons.map(({ type, category, icon, onMouseDown }) => {
              const isActive = isActiveChecker(type, category);
              const placeholder = document.querySelector(
                ".public-DraftEditorPlaceholder-root"
              );
              if (isActive && placeholder) {
                document.querySelector(
                  ".public-DraftEditorPlaceholder-root"
                ).style.display = "none";
              }
              return (
                <IconButton
                  isActive={isActive}
                  type={type}
                  src={icon}
                  onMouseDown={onMouseDown}
                />
              );
            })}
          </div>
          {!isUnlimited && (
            <GothamRegular style={maxCharStyle}>
              {charCount}/{maxChar}
            </GothamRegular>
          )}
        </div>
        <DragIndicator
          inputHeight={inputHeight}
          setInputHeight={setInputHeight}
        />
      </div>
      {isError ? (
        <GothamRegular style={errorStyle}>{errorString()}</GothamRegular>
      ) : helperText && !!defaultValue ? (
        <GothamRegular style={helperStyle}>{helperText}</GothamRegular>
      ) : null}
    </div>
  );
};
