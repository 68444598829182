import { get } from "lodash";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ToasterContext } from "../../contexts/ToasterContext";
import { apiBusiness, decryptToken, errorApiDecider } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";

const getUserInfo = () => {
  const isInculdes = (string) => navigator.userAgent.includes(string);

  // Get OS info
  const osInfo = isInculdes("Win")
    ? "Windows"
    : isInculdes("Mac")
    ? "Mac"
    : isInculdes("Linux")
    ? "Linux"
    : "Unknown";

  // Get browser info
  let browserInfo = "Unknown";

  if (isInculdes("Chrome")) {
    browserInfo = "Chrome";
  } else if (isInculdes("Safari")) {
    browserInfo = "Safari";
  } else if (isInculdes("Firefox")) {
    browserInfo = "Firefox";
  } else if (isInculdes("OPR") || isInculdes("Opera")) {
    browserInfo = "Opera";
  } else if (isInculdes("MSIE") || isInculdes("Trident")) {
    browserInfo = "Internet Explorer";
  }

  // Extract browser version
  const version = navigator.userAgent.match(
    /(Chrome|Safari|Firefox|OPR|Opera|MSIE|Trident)[\/\s](\d+\.\d+)/
  );
  const finalVersion = version ? `version ${version[2]}` : "Unknown";

  browserInfo = `${browserInfo} ${finalVersion}`;

  // Construct the object
  const userInfo = {
    device_info: osInfo,
    browser_info: browserInfo,
  };

  return userInfo;
};

export const getSalus = () => {
  const salus = decryptToken(localStorage.getItem("salus"));
  return { salus };
};

export const useLoginHook = (setIsLoggedIn, toggle, eventPayload = {}) => {
  const [loading, setLoading] = useState(false);
  const [isInvalidData, setIsInvalidData] = useState(false);

  const { authorize } = useContext(AuthContext);
  const { errorToasterApi } = useContext(ToasterContext);

  const { query } = useRouter();
  const { create_password } = query;

  const additional = getUserInfo();

  const login = async (val, whenError, handleRedirect) => {
    try {
      setLoading(true);

      const { salus } = getSalus();

      const { data } = await apiBusiness.post("/authenticate", {
        ...val,
        ...additional,
        salus,
      });
      const token = get(data, "auth_token");
      const { email, password } = val;
      //this timeout for the animation to work
      setIsLoggedIn && setIsLoggedIn(true);

      setTimeout(async () => {
        await authorize({
          token,
          setIsLoggedIn,
          tempPassword: create_password && password,
          handleRedirect,
        });
        eventsTracker("login", { email, ...eventPayload });
      }, 1000);
      toggle && toggle();
    } catch (error) {
      setIsLoggedIn && setIsLoggedIn(false);
      const { invalidData, isUnauthorized } = errorApiDecider(error);
      if (isUnauthorized || invalidData) {
        setIsInvalidData(true);
      } else {
        errorToasterApi(error);
      }
      typeof whenError == "function" && whenError(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, login, isInvalidData, setIsInvalidData };
};
