var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e3222281d879339db09921510479420810f689a4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  environment: process.env.NEXT_PUBLIC_TRANSFEZ_APP_ENV,
  dsn:
    SENTRY_DSN ||
    "https://fab74c05085d4a52a97b56936534b4db@o862143.ingest.sentry.io/5833127",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
  beforeSend(event) {
    const isLogin = String(event?.request?.url).includes("/login");

    const defaultException =
      event.exception && event.exception.values && event.exception.values[0];

    const unhandled =
      defaultException &&
      event.exception.values[0].mechanism?.type === "onunhandledrejection";

    const prototype =
      defaultException &&
      String(event.exception.values[0]?.value).includes("prototype");

    const isUnimportant = (unhandled && isLogin) || prototype;

    if (isUnimportant) return null;

    return event;
  },

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
