import { useForm } from "@tools";
import { useReactPDF } from "contexts/GoogleDrivePreviewContext/viewer";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useRef, useState } from "react";
import { colors } from "../../assets/colors";
import {
  ConfirmationModal,
  CustomButton,
  OutlineButton,
} from "../../components/Buttons";
import { FileInputText } from "../../components/FileInputText/parent";
import {
  CustomDateInputv2,
  CustomTextArea,
  CustomTextField,
  SelectionForm,
} from "../../components/inputs";
import LoadingSpinner from "../../components/Loading";
import { PDFOrImageViewer } from "../../components/PDFPreview";
import { SmallScreenWrapper } from "../../components/ScreenWrappers";
import { StatusLabel } from "../../components/Status";
import {
  TextInlineBold,
  TextInlineMedium,
  TypeFezNeueBold,
  TypeFezNeueLight,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../components/Text";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
  isProduction,
  unformatCurrency,
  useDebounce,
  windowDimension,
} from "../../components/tools";
import { useConstants } from "../../contexts/ConstantsContext/parent";
import { ToasterContext, ToasterHook } from "../../contexts/ToasterContext";
import { fileSize } from "../../modals/smartActivityModal/invoicePayment/supportingDocs";
import { apiBusiness, fetch, useMutation } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import { BackNavigation } from "../accountDashboardComponents";
import { CustomBottonWithToolTip } from "./buttons";
import {
  attachmentDataFormatter,
  dateToIsoDateFormatter,
  invoiceFormatter,
  pluralize,
} from "./data/formatter";
import { invoicesValidation } from "./data/parent";
import { ElipsisTextHover } from "./table";

export const SubmitInvoicePage = ({ submittedData }) => {
  const { query, push } = useRouter();
  const wording = (data) => {
    const moreThanOne = data?.length > 1;
    if (moreThanOne) return `${data?.length} Invoices !`;
    return `${data?.length} invoice !`;
  };
  const ids = submittedData?.map((item) => item.id);

  useEffect(() => {
    if (submittedData)
      eventsTracker("invoice_submit_success_screen_submit_invoices", {
        submittedInvoices: submittedData?.length,
        ids,
      });
  }, []);
  return (
    <SmallScreenWrapper
      woBack
      containerStyle={{ width: 700 }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <div
        className="align-items-center d-flex"
        style={{
          flexDirection: "column",
          paddingBottom: 32,
          gap: 16,
          width: "100%",
        }}
      >
        <img src={"/images/success-checklist-green.svg"} />
        <TypeFezNeueMedium
          className="font24"
          style={{ textAlign: "center", width: 900 }}
        >
          You have submitted {wording(submittedData)}
        </TypeFezNeueMedium>
        <TypeFezNeueRegular>
          You may check the progress through Invoice Payment page.
        </TypeFezNeueRegular>
      </div>
      <div
        style={{
          width: "88%",
          display: "flex",
          flexDirection: "column",
          gap: 24,
          marginBottom: 44,
          maxHeight: 276,
          padding: "5px 5px",
          height: "auto",
          overflowY: "scroll",
        }}
      >
        {submittedData?.map((item, index) => {
          const { invoiceNumber, totalAmount } = item;
          return (
            <div
              key={index}
              className="w-100"
              style={{
                padding: 16,
                backgroundColor: "#FFFFFF",
                boxShadow:
                  "0px 0px 2px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(88, 88, 88, 0.06)",
                borderRadius: 8,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: 12 }}>
                <img src={"/images/invoice-icon-new.svg"} />
                <div>
                  {/* <TypeFezNeueBold>{invoiceNumber}</TypeFezNeueBold> */}
                  <ElipsisTextHover isBold={true} length={23}>
                    {invoiceNumber}
                  </ElipsisTextHover>
                  <StatusLabel status="pending" />
                </div>
              </div>
              <TypeFezNeueRegular>{totalAmount}</TypeFezNeueRegular>
            </div>
          );
        })}
      </div>
      <CustomButton
        style={{ height: 48, width: "88%" }}
        onClick={() => {
          push(`/invoice-payment`);
        }}
      >
        Back to Invoice Payment
      </CustomButton>
    </SmallScreenWrapper>
  );
};

export const SupportingDocuments = ({ array }) => {
  return (
    <div>
      {array?.map((item, index) => {
        const { name, type, size } = item;
        const icon =
          type == "image/jpeg" ? "/images/jpg.svg" : "/images/pdf.svg";
        return (
          <div
            key={index}
            style={{
              border: `1px solid ${colors.greye6}`,
              padding: 8,
              paddingLeft: 16,
              paddingRight: 16,
              borderRadius: 8,
              marginBottom: 8,
            }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="d-flex">
              <img src={icon} />
              <div style={{ marginLeft: 12 }}>
                <TypeFezNeueRegular>{name}</TypeFezNeueRegular>
                <TypeFezNeueRegular>{fileSize(size)}</TypeFezNeueRegular>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const PdfViewer = React.memo(
  ({ containerHeight, buttonTabMargin, maxWidth, pdfUrl, loading }) => {
    const { Document, Page } = useReactPDF();

    const [scale, setScale] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState(null);
    const pdfHeight = containerHeight * 0.84;
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#f4f4f4",
          height: containerHeight,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          alignContent: "center",
        }}
      >
        <div
          className="mx-auto"
          style={{
            overflowY: "scroll",
            overFlowX: "scroll",
            height: `${0.84 * containerHeight}px`,
            maxWidth: maxWidth,
            // width: "100%",
          }}
        >
          <Document
            loading={loading}
            className="my-auto"
            file={{
              url: pdfUrl,
            }}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          >
            <Page height={pdfHeight} scale={scale} pageNumber={pageNumber} />
          </Document>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            // backgroundColor: "#FFFFFF",
            padding: "0px 12px",
          }}
          className="font12 d-flex py-1 align-items-center justify-content-center"
        >
          <div style={{ display: "flex" }}>
            <div
              onClick={() =>
                setPageNumber((p) => {
                  if (p > 1) return p - 1;
                  return p;
                })
              }
              style={{ cursor: "pointer" }}
            >
              <img
                src={"/images/paginate-previous.svg"}
                width={6}
                height={10}
              />
            </div>
            <div className="mx-2">
              {pageNumber} of {numPages}
            </div>
            <div
              onClick={() =>
                setPageNumber((p) => {
                  if (p < numPages) return p + 1;
                  return p;
                })
              }
              style={{ cursor: "pointer" }}
            >
              <img src={"/images/paginate-next.svg"} width={6} height={10} />
            </div>
          </div>
          <div>{`${Math.round(scale * 100)} %`}</div>
          <div>
            <button
              onClick={() => {
                setScale((p) => {
                  if (p < 2) return p + 0.1;
                  return p;
                });
              }}
            >
              +
            </button>
            <button
              onClick={() => {
                setScale((p) => {
                  if (p > 1) return p - 0.1;
                  return p;
                });
              }}
            >
              -
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export const BackHeader = ({ style, title, onClick }) => {
  return (
    <div style={{ ...style }}>
      <BackNavigation
        style={{ marginBottom: 8 }}
        onClick={() => {
          onClick({ type: "back" });
        }}
      />
      <TypeFezNeueBold style={{ fontSize: 24, color: "#102C57" }}>
        {title ? title : ""}
      </TypeFezNeueBold>
    </div>
  );
};

export const BottomButtonTab = ({
  isDraft,
  handleClick,
  data,
  handleSubmit,
  submitLoading,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const errorCounter = (data) => {
    const { is_duplicate } = data;
    const invalidCount = (data || [])?.filter((item) => item.invalid)?.length;
    const invalid_mismatch_count = (data || [])?.filter(
      (item) => item.invalid_mismatch
    ).length;

    const duplicateCount = (data || [])?.filter(
      (item) => item.is_duplicate
    ).length;
    return { invalidCount, invalid_mismatch_count, duplicateCount };
  };
  const { invalidCount, invalid_mismatch_count, duplicateCount } =
    errorCounter(data);

  const isEmpty = (data || []).length == 0;
  const getTotalAmount = () => {
    const totalAmountArray = data?.map(({ total_amount }) => total_amount);
    const totalAmount = (totalAmountArray || [])?.reduce((a, b) => a + b, 0);
    const totalAmountString = `IDR ${formatCurrencyNoDecimal(totalAmount)}`;

    return totalAmountString;
  };

  const totalInvoice = `${data?.length} `;
  const disableSubmit = Boolean(
    !(invalidCount == 0) || isEmpty || duplicateCount > 0
  );
  return (
    <div
      style={{
        width: "100%",
        height: 72,
        borderRadius: 12,
        boxShadow:
          "0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)",
        padding: "16px 24px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        justifySelf: "end",
      }}
    >
      <div style={{ display: "flex", gap: 24, alignItems: "center" }}>
        {!isDraft && (
          <OutlineButton
            style={{
              fontSize: 14,
              height: 40,
              padding: "10px 16px",
              color: colors.pink,
              border: "1px solid #E6E6E8",
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
            onClick={() => {
              handleClick({ type: "upload" });
            }}
            leftIcon={
              <img
                src={"/images/plus-pink-icon.svg"}
                style={{ height: 20, width: 20 }}
              />
            }
          >
            <TextInlineMedium style={{}}>Upload Invoices</TextInlineMedium>
          </OutlineButton>
        )}
        {/* <CustomButton
          style={{
            fontSize: 14,
            height: 40,
            padding: "10px 16px",
            backgroundColor:
              !(invalidCount == 0) || isEmpty || duplicateCount > 0
                ? "#BBBBC0"
                : colors.pink,
          }}
          isLoading={submitLoading}
          onClick={() => setConfirmModalOpen(true)}
          disabled={!(invalidCount == 0) || isEmpty || duplicateCount > 0}
        >
          Submit All
        </CustomButton> */}
        <CustomBottonWithToolTip
          disabled={disableSubmit}
          buttonStyle={{
            fontSize: 14,
            height: 40,
            padding: "10px 16px",
            backgroundColor: disableSubmit ? "#BBBBC0" : colors.pink,
          }}
          buttonLoading={submitLoading}
          buttonOnClick={() => setConfirmModalOpen(!disableSubmit)}
          tooltipComponent={
            <div
              style={{
                padding: 16,
                width: 320,
                height: 64,
                backgroundColor: "white",
                color: "#6C6C71",
                zIndex: 55,
                display: disableSubmit ? "flex" : "none",
                alignItems: "center",
                gap: 10,
              }}
            >
              <img src={"images/information-blue.svg"} height={20} />
              <TypeFezNeueLight
                style={{ fontSize: 12, textAlign: "left", lineHeight: 1.4 }}
              >
                Please <TextInlineBold>complete the data</TextInlineBold> before
                submitting invoice for payment.
              </TypeFezNeueLight>
            </div>
          }
          tooltipStyle={{
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
            opacity: 1,
          }}
        >
          Submit All
        </CustomBottonWithToolTip>
        <div
          style={{
            width: 2,
            height: 48,
            borderRadius: 4,
            backgroundColor: "#E6E6E8",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 150,
          }}
        >
          <TypeFezNeueRegular style={{ fontSize: 12, color: "#6C6C71" }}>
            Number of Invoice(s)
          </TypeFezNeueRegular>
          <TypeFezNeueMedium style={{ color: "#2C4A77", fontSize: 16 }}>
            {totalInvoice + pluralize(data?.length, "Invoice")}
          </TypeFezNeueMedium>
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: 150 }}>
          <TypeFezNeueRegular style={{ fontSize: 12, color: "#6C6C71" }}>
            Total Amount
          </TypeFezNeueRegular>
          <TypeFezNeueMedium style={{ color: "#2C4A77", fontSize: 16 }}>
            {getTotalAmount()}
          </TypeFezNeueMedium>
        </div>
        {/* {isDraft && (
          <CustomButton
            style={{ fontSize: 14, height: 40, padding: "10px 16px" }}
          >
            Delete
          </CustomButton>
        )} */}
      </div>
      {/* {isDraft && (
        <TypeFezNeueRegular style={{ color: "#6C6C71", fontSize: 12 }}>
          0 selected document
        </TypeFezNeueRegular>
      )} */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "right",
        }}
      >
        {/* {invalid_mismatch_count != 0 && (
          <TypeFezNeueRegular
            style={{ color: "#FCB037", fontSize: 12, lineHeight: 1.3 }}
          >
            {invalid_mismatch_count} Mismatched bank account name
          </TypeFezNeueRegular>
        )} */}
        {invalidCount != 0 && (
          <TypeFezNeueRegular
            style={{ color: "#E73126", fontSize: 12, lineHeight: 1.3 }}
          >
            {invalidCount} Incomplete {pluralize(invalidCount, "invoice")}
          </TypeFezNeueRegular>
        )}
        {duplicateCount != 0 && (
          <TypeFezNeueRegular
            style={{ color: "#E73126", fontSize: 12, lineHeight: 1.3 }}
          >
            {duplicateCount} Duplicate {pluralize(duplicateCount, "invoice")}
          </TypeFezNeueRegular>
        )}
      </div>
      <ConfirmationModal
        modal={confirmModalOpen}
        title="Before we go further..."
        text="Please make sure your invoice details are correct. After this, you are unable to make any more changes."
        buttonTextLeft="Back"
        buttonTextRight="Submit"
        onClick={() => {
          handleClick({ type: "submit" });
        }}
        toggle={() => setConfirmModalOpen(false)}
      />
    </div>
  );
};

export const LocalRecipientErrorBox = ({
  isInvalid,
  localRecipient,
  validData,
  reset,
}) => {
  const {
    is_valid: isValid,
    name: validDataName,
    registered_name: registeredNameValidData,
    account_number: accountNumberValidData,
    payer_id: payerIdValidData,
  } = validData || {};
  const {
    is_valid,
    name: lrName,
    registered_name: lrRegisteredName,
    account_number: lrAccNumber,
    payer_id: lrPayerId,
  } = localRecipient || {};
  //const registered_name = isValid ? registeredNameValidData : "-";
  //const name = isValid ? validDataName : " ";
  const Reset = () => (
    <TypeFezNeueRegular
      style={{
        justifySelf: "end",
        color: "#DA649F",
        fontSize: 12,
        textDecorationLine: "underline",
        cursor: "pointer",
      }}
      onClick={reset}
    >
      Reset
    </TypeFezNeueRegular>
  );
  if (isInvalid)
    return (
      <div
        style={{
          width: "100%",
          height: 64,
          backgroundColor: "rgba(231, 49, 38, 0.05)",
          borderRadius: 8,
          padding: 12,
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between ",
          alignItems: "center",
        }}
      >
        <div className="d-flex">
          <img src={"/images/warning-red.svg"} style={{ marginRight: 14 }} />
          <div className="d-flex-column">
            <TypeFezNeueRegular style={{ fontSize: 13 }}>
              "We can't find the bank account. Please check and re-enter the
              bank account details."
            </TypeFezNeueRegular>
          </div>
        </div>
      </div>
    );

  if (registeredNameValidData) {
    if (registeredNameValidData == validDataName) {
      return (
        <div
          style={{
            width: "100%",
            height: "auto",
            padding: "8px 14px",
            display: "flex",
            backgroundColor: "rgba(6, 172, 78, 0.1)",
            borderRadius: 8,
            gap: 10,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
            <img
              src={"/images/checklist-green-circle.svg"}
              style={{ height: 20 }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                lineHeight: 0.8,
              }}
            >
              <TypeFezNeueLight style={{ fontSize: 12, color: "#6C6C71" }}>
                Bank Account Name
              </TypeFezNeueLight>
              <TypeFezNeueRegular style={{ color: "#333334" }}>
                {registeredNameValidData}
              </TypeFezNeueRegular>
              <TypeFezNeueRegular style={{ fontSize: 12, color: "#333334" }}>
                This invoice is ready for approval and payment.
              </TypeFezNeueRegular>
            </div>
          </div>
          <Reset />
        </div>
      );
    }
    return (
      <div
        style={{
          width: "100%",
          height: "auto",
          padding: "10px 14px",
          display: "flex",
          backgroundColor: "rgba(255, 238, 207, 0.4)",
          borderRadius: 8,
          gap: 10,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="d-flex align-items-center">
          <img
            src={"/images/warning-yellow.svg"}
            style={{ height: 20, marginRight: 10 }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 370,
            }}
          >
            <TypeFezNeueLight
              style={{
                fontSize: 10,
                color: "#6C6C71",
                lineHeight: 0.8,
              }}
            >
              Bank Account Name
            </TypeFezNeueLight>
            <TypeFezNeueRegular style={{ color: "#333334" }}>
              {registeredNameValidData}
            </TypeFezNeueRegular>
            <TypeFezNeueRegular
              style={{
                fontSize: 12,
                color: "#333334",
                lineHeight: 1.2,
              }}
            >
              Is it already correct?
              <br /> You can ignore this if you still wish to proceed.
            </TypeFezNeueRegular>
          </div>
        </div>
        <Reset />
      </div>
    );
  }
  return (
    <div
      style={{
        width: "100%",
        height: 48,
        backgroundColor: "rgba(230, 230, 232, 0.4)",
        borderRadius: 8,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className="d-flex-column" style={{ marginLeft: 40 }}>
        <TypeFezNeueLight
          style={{ fontSize: 12, color: "#6C6C71", lineHeight: 1 }}
        >
          Bank Account Name
        </TypeFezNeueLight>
        <TypeFezNeueLight style={{ color: "#6C6C71" }}>
          Vendor’s bank account name will be shown here.{" "}
        </TypeFezNeueLight>
      </div>
    </div>
  );
};

export const uploadFilesFormatter = (prevDataRaw, upload_fileRaw) => {
  const prevData = prevDataRaw || [];
  const upload_file = upload_fileRaw || [];

  const uploadFileIds = upload_file.map(({ id }) => id);
  const deletedFileIds = prevData
    .filter(({ id }) => !uploadFileIds.includes(id))
    .map(({ id }) => id);
  const uploadFileNew = upload_file.filter(({ url }) => !url);

  return { deletedFileIds, uploadFileNew };
};

export const InvoiceDetail = ({ isDrafted, draftRefetch }) => {
  const router = useRouter();

  const { push, query } = router;
  const { invoiceId } = query;
  const [selected, setSelected] = useState(invoiceId);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { screenRealHeight, mainComponentWidth } = windowDimension();
  const { localTransferBanks } = useConstants();

  const bankPicker = (id) => localTransferBanks.filter((bank) => bank.id == id);
  const [invoiceData, setInvoiceData] = useState({});

  const {
    local_recipient: localRecipient,
    state: invoiceState,
    pdf: invoicePdf,
    reference_number: invoiceReferenceNumber,
    invoice_number: invoiceNumber,
    issued_date: invoiceIssuedDate,
    due_date: invoiceDueDate,
    description: invoiceDescription,
    amount: invoiceAmount,
    tax: invoiceTax,
    total_amount: invoiceTotalAmount,
    transfer_notes: invoiceTransferNotes,
  } = invoiceData || {};

  const {
    id: lrId,
    is_valid: isLrValid,
    registered_name: lrRegisteredName,
    name: lrName,
    is_match_name: isLrMatchName,
    payer_id: lrPayerId,
    account_number: lrAccountNumber,
  } = localRecipient || {};

  //error flag
  const { errorToaster } = useContext(ToasterContext);

  //empty input flag

  //fetch invoice by id
  const {
    data: invoice,
    loading: invoiceLoading,
    refetch,
  } = fetch({
    url: `/invoice_transactions/${selected}`,
    formatter: ({ data, ...rest }) => {
      const [newData] = invoicesValidation(invoiceFormatter([data]));
      return { newData, ...rest };
    },
    woInit: true,
  });

  const { attachment_data, newData } = invoice || {};
  const { supporting_documents_files } = newData || {};

  const newAttachmentData = attachmentDataFormatter(
    (attachment_data || []).map((item, index) => {
      const url = (supporting_documents_files || [])[index];
      return { ...item, url };
    })
  );
  // supporting_documents_file

  const [selectionDefaultValue] = bankPicker(lrPayerId);
  const isDraft = invoiceState === "drafted";

  const [page, setPage] = useState(1);

  const invoicesUrl = isDrafted
    ? `/invoice_transactions/filtered/drafted?page=${page}`
    : `/invoice_transactions/filtered/created?page=${page}`;

  // const {
  //   data: invoices,
  //   loading: invoicesLoading,
  //   refetch: refetchInvoices,
  // } = fetch({
  //   url: invoicesUrl,
  //   afterSuccess: (res) =>
  //     setPage((p) => {
  //       const { total_page } = res || {};

  //       if (!total_page) return p + 1;

  //       const canSubmit = total_page > p;
  //       if (canSubmit) return p + 1;

  //       return p;
  //     }),
  //   woInit: true,
  // });

  // useEffect(refetchInvoices, [page]);

  // const { data: invoicesData } = invoices;

  //update invoice
  const useFormObj = useForm({});
  //error flag for inputs
  const { handleSubmit, getValues, setValue, reset, watch } = useFormObj;
  const useFormLocalRecipient = useForm({});
  const {
    handleSubmit: handleVerify,
    watch: watchLocalRecipient,
    getValues: getLrValue,
    setValue: setLrValue,
    reset: resetLrValue,
  } = useFormLocalRecipient;

  const invNumberIsEmpty = watch("invoice_number")?.length == 0;
  const issuedDateIsEmpty = !Boolean(getValues("issued_date"));
  const dueDateIsEmpty = !Boolean(getValues("due_date"));

  const nameIsEmpty =
    watchLocalRecipient("name")?.length == 0 ||
    !Boolean(watchLocalRecipient("name"));
  const banksIsEmpty = !Boolean(watchLocalRecipient("banks"));

  const accountNumIsEmpty = watchLocalRecipient("account_number")?.length == 0;

  const totalAmountIsEmpty =
    watch("total_amount")?.length == 0 || watch("total_amount") == "IDR 0";
  const amountIsEmpty =
    watch("amount")?.length == 0 || watch("amount") == "IDR 0";
  const taxIsEmpty =
    unformatCurrency(watch("tax")) == "IDR 0" ||
    unformatCurrency(watch("tax"))?.length == 0 ||
    unformatCurrency(watch("tax") == 0);

  const watchFile = watch("upload_file");
  const filesIsEmpty = Boolean(watchFile?.length == 0);
  //handle decimal amount, VAT, total_amount
  const {
    amount: amountOnForm,
    tax: taxOnForm,
    total_amount: totalAmountOnForm,
  } = getValues();

  const getAmount = useDebounce(amountOnForm, 50);
  const getTax = useDebounce(taxOnForm, 100);
  const getTotalAmount = useDebounce(totalAmountOnForm, 50);

  useEffect(
    () => setValue("amount", `IDR ${formatCurrency(getAmount)}`),
    [getAmount]
  );
  useEffect(
    () => setValue("tax", `       IDR ${formatCurrency(getTax)}`),
    [getTax]
  );
  useEffect(
    () => setValue("total_amount", `IDR ${formatCurrency(getTotalAmount)}`),
    [getTotalAmount]
  );

  const [updateLoading, setUpdateLoading] = useState(false);
  const { mutation: updateInvoice } = useMutation({
    url: `/invoice_transactions/${selected}`,
    method: "put",
    afterSuccess: () => {
      reset("upload_file");
      if (isDrafted) draftRefetch();
    },
  });

  //ref payment
  const paymentRef = useRef(null);
  const navigateToPayment = () =>
    paymentRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

  //verify local recipient
  const { successToaster } = ToasterHook();

  const { mutation: verifyLocalRecipient, loading: verifyLoading } =
    useMutation({
      method: "put",
      url: `/local_recipients/${lrId}`,
      afterSuccess: ({ data }) => {
        // const { data: responseData } = data;
        // const { registered_name, is_valid } = responseData;
        // refetch();
        // if (!is_valid || !Boolean(registered_name)) {
        //   return errorToaster(
        //     "Invalid Account Number !",
        //     "Please input correct Bank Account Number"
        //   );
        // }
        // setLrValue("registered_name", registered_name);
        // successToaster({
        //   title: "Validated!",
        //   msg: "Bank account number is registered and verified as a valid account.",
        // });
        // setIsEdit(false);
        setValidBankAccount({});
        setIsNotValid(false);
      },
      handleError: (err) => console.log(err),
    });

  const verifyUrl = isProduction
    ? "/local_recipients/update_for_validate/26404"
    : "/local_recipients/update_for_validate/26635";

  const [isNotValid, setIsNotValid] = useState(false);
  const [validBankAccount, setValidBankAccount] = useState({});
  const watchSelectionBank = watchLocalRecipient("banks")?.id || null;
  const isVerifyButtonDisabled = Boolean(watchSelectionBank);
  const { mutation: checkAccount, loading: checkLoading } = useMutation({
    method: "put",
    url: verifyUrl,
    handleError: (err) => console.log(err),
    afterSuccess: ({ data }) => {
      const { data: checkResult } = data;
      const {
        is_valid,
        registered_name,
        account_number,
        payer_id,
        name,
        is_match_name,
      } = checkResult;
      if (!is_valid) setIsNotValid(true);
      if (is_valid) {
        setIsNotValid(false);
        return setValidBankAccount({
          is_valid,
          name,
          registered_name,
          account_number,
          payer_id,
          is_match_name,
        });
      }
    },
  });
  const verify = (val) => {
    const { name, account_number, banks } = val;
    const { id } = banks;
    const verifyData = {
      name,
      account_number,
      payer_id: id,
    };
    checkAccount(verifyData);
  };
  const onSubmit = async (val) => {
    const { is_valid, registered_name } = localRecipient;

    const {
      name: vaName,
      account_number: vaAccountNumber,
      payer_id: vaPayerId,
      registered_name: varegisteredName,
      is_valid: vaIsValid,
    } = validBankAccount;

    const isEmptyString = (string = "") => string.trim().length === 0;
    const {
      invoice_number,
      total_amount: totalAmountRaw,
      due_date,
      issued_date,
      amount: amountRaw,
      description,
      tax: taxRaw,
      upload_file = [],
      transfer_notes,
    } = val;

    if (invoice_number?.length == 0 || isEmptyString(invoice_number)) {
      return errorToaster(
        "Submit Failed !",
        "Please Input Invoice Number Correctly"
      );
    }
    if (dueDateIsEmpty) {
      return errorToaster(
        "Submit Failed !",
        "Please Input Invoice's Due Date Correctly"
      );
    }
    if (issuedDateIsEmpty) {
      return errorToaster(
        "Submit Failed !",
        "Please Input Invoice's Issued Date Correctly"
      );
    }
    if (!vaIsValid) {
      navigateToPayment();
      return errorToaster(
        "Error !",
        "Please verify Invoice Bank Account Number before submit"
      );
    }

    if (due_date < issued_date) {
      return errorToaster(
        "Submit Failed !",
        "Please Recheck Invoice's Due Date"
      );
    }

    if (val?.upload_file.length > 5) {
      return errorToaster(
        "Submit Failed !",
        "You can only upload up to 5 supporting documents"
      );
    }

    const total_amount = unformatCurrency(totalAmountRaw);
    const amount = unformatCurrency(amountRaw);
    const tax = unformatCurrency(taxRaw);

    if (total_amount < 10000) {
      return errorToaster(
        "Submit Failed !",
        "Total Amount must be more than or equal to IDR 10.000"
      );
    }
    if (amountIsEmpty)
      return errorToaster(
        "Submit Failed !",
        "Please Input Invoice's Amount Correctly"
      );
    if (amount < 0) {
      return errorToaster("Submit Failed !", "Amount can not be negative!");
    }
    if (tax < 0) {
      return errorToaster("Submit Failed !", "Tax can not be negative!");
    }

    const payLoadLr = {
      name: vaName,
      account_number: vaAccountNumber,
      payer_id: vaPayerId,
    };

    setUpdateLoading(true);
    //const name = getLrValue("name") || "";

    await verifyLocalRecipient(payLoadLr);
    // await apiBusiness.put(
    //   `/local_recipients/${invoiceData?.local_recipient?.id}`,
    //   payLoadLr
    // );

    // return;

    const payload = {
      recipient_name: vaName,
      invoice_number,
      due_date,
      issued_date,
      total_amount,
      description,
      amount,
      tax,
      transfer_notes,
    };
    await updateInvoice(payload);

    const { deletedFileIds, uploadFileNew } = uploadFilesFormatter(
      newAttachmentData,
      upload_file
    );
    for (let i = 0; i < uploadFileNew.length; i++) {
      const file = uploadFileNew[i];
      const formData = new FormData();
      formData.append("supporting_documents", file);
      await updateInvoice(formData);
    }

    for (let i = 0; i < deletedFileIds.length; i++) {
      const deletedId = deletedFileIds[i];
      await apiBusiness.delete(
        `invoice_transactions/${selected}/attachment/${deletedId}`
      );
    }
    eventsTracker("edit_invoice_page_save_edited_invoice");
    //await refetch();
    setUpdateLoading(false);
    successToaster({
      title: "Success!",
      msg: "Invoice payment details has been saved!",
    });
    backToList();
  };

  const bankInputErrorObject = (local_recipient, validBankAccount) => {
    const { is_valid, registered_name, account_number } = local_recipient || {};
    const { account_number: accNumberValid } = validBankAccount || {};
    const accountNumberOnForm = watchLocalRecipient("account_number");

    const defaultErrorObj = {
      error: false,
      msg: "",
    };

    const filledButNotYetVerified = Boolean(
      !accountNumIsEmpty && !accNumberValid
    );

    // const inputChanged = Boolean(
    //   is_valid && account_number != accountNumberOnForm
    // );

    // if (is_valid) {
    //   if (inputChanged)
    //     return {
    //       error: true,
    //       msg: "Please check the bank account validity first",
    //     };
    //   return defaultErrorObj;
    // }

    if (accountNumIsEmpty)
      return { error: true, msg: "Please fill this field" };

    if (isNotValid) return { error: true, msg: " " };

    if (filledButNotYetVerified) {
      return {
        error: true,
        msg: "Please check the bank account validity first",
      };
    }

    return defaultErrorObj;
  };

  const { error: isBankInputError, msg: bankInputErrorMsg } =
    bankInputErrorObject(localRecipient, validBankAccount);

  const handleReset = async () => {
    // await verifyLocalRecipient({
    //   account_number: "-",
    //   payer_id: 0,
    // });
    // refetch();
    setValidBankAccount({});
    setLrValue("account_number", null);
    setLrValue("name", null);
    setIsNotValid(false);
    resetLrValue("banks");
    resetLrValue("name");
    resetLrValue("account_number");
  };

  const disableBankInput = Boolean(validBankAccount?.account_number);
  const backToList = async () => {
    if (isDrafted) {
      await draftRefetch();
      return push({
        pathname: "/invoice-payment",
        query: { pageType: "draft" },
      });
    }
    push({ pathname: "/invoice-payment/create", query: { id: query.id } });
  };

  //delete invoice
  const { mutation: deleteInvoice } = useMutation({
    url: `/invoice_transactions/${selected}`,
    method: "delete",
    afterSuccess: ({ data }) => {
      const { message } = data;
      successToaster({
        title: "Succes Delete Invoice!",
        msg: message,
      });
      backToList();
    },
  });

  //save as draft
  const { mutation: saveAsDraft } = useMutation({
    url: `/invoice_transactions/${selected}/change_state`,
    method: "put",
    afterSuccess: ({ data }) => {
      const { data: draftData } = data;
      successToaster({
        title: "Succes Change State!",
        msg: `Invoice ${draftData?.invoice_number} has been sent to draft`,
      });
      backToList();
    },
  });

  const redStarLabel = (label) => (
    <>
      {label}
      <TextInlineBold style={{ color: "red" }}>*</TextInlineBold>
    </>
  );

  //styling

  const misMatchStyle = {
    backgroundColor: "rgba(252, 176, 55, 0.05)",
    border: "1px solid #FCB037",
  };
  const emptyStyle = {
    backgroundColor: "rgba(231, 49, 38, 0.05)",
    border: " 1px solid #E73126",
  };

  const vendorNameStyleDecider = (isEmpty, validBankAccount) => {
    const { name, registered_name } = validBankAccount;

    if (isEmpty) return emptyStyle;

    if (name != registered_name) return misMatchStyle;

    return {};
  };

  useEffect(() => {
    if (!selected) return;
    refetch();
  }, [selected]);

  useEffect(() => {
    if (!invoiceId) return;
    if (!(selected == invoiceId)) setSelected(invoiceId);
  }, [invoiceId]);

  useEffect(() => {
    if (Array.isArray(invoice)) return;
    if (invoice) {
      const { newData } = invoice;

      const { local_recipient } = newData;

      const {
        name,
        account_number,
        payer_id,
        is_valid,
        registered_name,
        is_match_name,
      } = local_recipient;

      if (is_valid) {
        const validData = {
          name,
          account_number,
          payer_id,
          is_valid,
          registered_name,
          is_match_name,
        };
        setLrValue("banks", bankPicker(payer_id));
        setValidBankAccount(validData);
      }
    }

    if (invoice) setInvoiceData(invoice?.newData);
  }, [invoice]);

  if (invoiceLoading)
    return (
      <div
        style={{
          display: "flex",
          height: screenRealHeight,
          width: mainComponentWidth,
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner />
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        height: screenRealHeight,
        width: mainComponentWidth,
        position: "absolute",
      }}
    >
      {/* <div
        style={{ padding: "0px 27px 0px 24px", width: "11.7%" }}
        className="d-flex-column"
      >
        <OutlineButton
          style={{ width: 86, height: 36, marginBottom: 20 }}
          onClick={backToList}
        >
          Back
        </OutlineButton>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            overflowY: "scroll",
            height: screenRealHeight - 56,
            //56 equal button height plus margin
            alignItems: "center",
          }}
        >
          {invoicesData?.map((invoice, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setIsEdit(false);
                  setSelected(invoice.id);
                  push({ query: { ...query, invoiceId: invoice?.id } });
                }}
                style={{
                  width: 64,
                  height: 64,
                  padding: 4,
                  backgroundColor: "#E6E6E8",
                  display: "flex",
                  justifyContent: "center",
                  border: invoice.id == selected ? "1px solid #DA649F" : "",
                  borderRadius: invoice.id == selected ? 4 : 0,
                }}
              >
                <img
                  src={
                    invoice?.thumbnail_invoice
                      ? `${process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL}` +
                        `${invoice.thumbnail_invoice}`
                      : pdfIcon
                  }
                  style={{ height: "100%", width: 54 }}
                />
              </div>
            );
          })}
        </div>
      </div> */}
      <div
        className="d-flex"
        style={{ width: "50%", maxHeight: screenRealHeight, paddingLeft: 24 }}
      >
        <PDFOrImageViewer url={invoicePdf} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "50%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            gap: 24,
            width: "100%",
            height: "100%",
            zIndex: 12,
            padding: "20px 32px 0px 24px",
            overflowY: "scroll",
          }}
        >
          <OutlineButton
            style={{
              width: 90,
              padding: `8px 14px`,
              border: "1px solid #E6E6E8",
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
            leftIcon={<img src={"/images/left-arrow-back.svg"} />}
            onClick={() => setOpenConfirmationModal(true)}
          >
            Back
          </OutlineButton>
          <TypeFezNeueBold style={{ fontSize: 20 }}>
            {invoiceReferenceNumber}
          </TypeFezNeueBold>
          <div
            className="w100"
            style={{
              height: 64,
              backgroundColor: "#E1EEFF",
              borderRadius: 8,
              padding: "12px 12px",
              display: "flex",
              alignItems: "center",
              gap: 30,
            }}
          >
            <div className="d-flex align-items-center">
              <img
                src={"/images/lightbulb.svg"}
                height={16}
                style={{ marginRight: 8 }}
              />
              <TypeFezNeueRegular>Tips</TypeFezNeueRegular>
            </div>
            <TypeFezNeueRegular style={{ fontSize: 14, lineHeight: 1.2 }}>
              Make sure the details are already matched with the original
              document.
            </TypeFezNeueRegular>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TypeFezNeueMedium style={{ fontSize: 16, marginBottom: 12 }}>
              Invoice Overview
            </TypeFezNeueMedium>
            <div>
              <CustomTextField
                name="invoice_number"
                useFormObj={useFormObj}
                label={"Invoice Number"}
                maxLength={30}
                defaultValue={invoiceNumber}
                placeholder="Input invoice's Number"
                error={invNumberIsEmpty}
                style={invNumberIsEmpty ? emptyStyle : {}}
                emptyField="Please fill this field"
              />
              <div
                className="d-flex justify-content-between"
                style={{ marginBottom: 16 }}
              >
                <div style={{ width: "49%" }}>
                  <CustomDateInputv2
                    useFormObj={useFormObj}
                    label="Issued Date"
                    name="issued_date"
                    inputProps={{ placeholder: "Input Issued Date" }}
                    defaultValue={dateToIsoDateFormatter(invoiceIssuedDate)}
                    error={issuedDateIsEmpty}
                    style={issuedDateIsEmpty ? emptyStyle : {}}
                    emptyField="Please fill this field"
                  />
                </div>
                <div style={{ width: "49%" }}>
                  <CustomDateInputv2
                    containerStyle={{ zIndex: 2 }}
                    useFormObj={useFormObj}
                    label="Due Date"
                    name="due_date"
                    inputProps={{ placeholder: "Input Due Date" }}
                    minDate={dateToIsoDateFormatter(watch("issued_date"))}
                    defaultValue={
                      Boolean(invoiceDueDate)
                        ? dateToIsoDateFormatter(invoiceDueDate)
                        : dateToIsoDateFormatter(invoiceIssuedDate)
                    }
                    error={dueDateIsEmpty}
                    emptyField="Please fill this field"
                    style={dueDateIsEmpty ? emptyStyle : {}}
                  />
                </div>
              </div>
              <div style={{ position: "relative", zIndex: 0 }}>
                <TypeFezNeueRegular
                  style={{
                    position: "absolute",
                    color: colors.grey72,
                    fontSize: 11,
                    top: 0,
                    left: 9,
                    zIndex: 1,
                  }}
                >
                  Description
                </TypeFezNeueRegular>
                <CustomTextArea
                  maxLength={255}
                  useFormObj={useFormObj}
                  label="Description"
                  name="description"
                  placeholder="Memo to recipient"
                  defaultValue={invoiceDescription}
                  style={{ color: "black" }}
                />
              </div>
            </div>
          </div>
          <div
            style={{ diplay: "flex", flexDirection: "column" }}
            ref={paymentRef}
          >
            <TypeFezNeueMedium style={{ fontSize: 16, marginBottom: 12 }}>
              Vendor Details
            </TypeFezNeueMedium>
            <div>
              <div className="d-flex-column justify-content-between">
                <CustomTextField
                  useFormObj={useFormLocalRecipient}
                  label={"Vendor Name"}
                  disabled={disableBankInput}
                  name="name"
                  placeholder="Company that demand the payment"
                  labelStyle={{ width: "100%" }}
                  defaultValue={validBankAccount.name}
                  style={vendorNameStyleDecider(nameIsEmpty, validBankAccount)}
                  error={nameIsEmpty}
                  emptyField="Please fill this field"
                />
                <SelectionForm
                  label={`Bank Name*`}
                  woTitleCase
                  disabled={disableBankInput}
                  name="banks"
                  labelStyle={{
                    fontSize: 10,
                    alignSelf: "start",
                    paddingTop: 2,
                  }}
                  useFormObj={useFormLocalRecipient}
                  options={localTransferBanks}
                  placeholder="Search Bank Name"
                  defaultValue={
                    Boolean(validBankAccount.payer_id)
                      ? bankPicker(validBankAccount.payer_id)
                      : undefined
                  }
                  error={banksIsEmpty}
                  containerStyle={banksIsEmpty ? emptyStyle : {}}
                  customErrorMessage="Please fill this field"
                />
                <div style={{ width: "100%", zIndex: 0, position: "relative" }}>
                  <CustomTextField
                    shrink
                    disabled={disableBankInput}
                    useFormObj={useFormLocalRecipient}
                    label="Bank Account Number"
                    name="account_number"
                    type="number"
                    placeholder="Vendor's bank account number"
                    defaultValue={validBankAccount.account_number}
                    error={isBankInputError}
                    style={isBankInputError ? emptyStyle : {}}
                    emptyField={bankInputErrorMsg}
                  />
                  <CustomButton
                    disabled={disableBankInput}
                    style={{
                      minWidth: 114,
                      height: 24,
                      position: "absolute",
                      right: 5,
                      top: 10,
                      fontSize: 12,
                      zIndex: 99,
                    }}
                    onClick={handleVerify(verify)}
                    isLoading={checkLoading}
                  >
                    Check Account
                  </CustomButton>
                </div>
                <LocalRecipientErrorBox
                  isLoading={checkLoading}
                  isInvalid={isNotValid}
                  localRecipient={localRecipient}
                  validData={validBankAccount}
                  reset={handleReset}
                />
              </div>
            </div>
          </div>
          <div>
            <TypeFezNeueMedium style={{ fontSize: 16, marginBottom: 12 }}>
              Transaction Details
            </TypeFezNeueMedium>
            <CustomTextField
              useFormObj={useFormObj}
              label="Subtotal (Invoice Amount)"
              name="amount"
              defaultValue={Number(invoiceAmount || 0)}
              error={amountIsEmpty}
              emptyField="Please fill this field"
              style={amountIsEmpty ? emptyStyle : {}}
            />
            <div style={{ position: "relative" }}>
              <CustomTextField
                useFormObj={useFormObj}
                label="VAT"
                name="tax"
                required={false}
                defaultValue={Number(invoiceTax || 0)}
                emptyField="Please fill this field"
              />
              <img
                src={"/images/plus-circle.svg"}
                style={{ position: "absolute", top: 20, left: 8 }}
              />
            </div>
            <CustomTextField
              useFormObj={useFormObj}
              label="Total Amount"
              name="total_amount"
              defaultValue={Number(invoiceTotalAmount || 0)}
              containerStyle={{
                marginBottom: totalAmountIsEmpty ? 12 : 0,
              }}
              error={totalAmountIsEmpty}
              style={totalAmountIsEmpty ? emptyStyle : {}}
              emptyField="Please fill this field"
            />
            {!totalAmountIsEmpty ? (
              <TypeFezNeueLight
                style={{ color: "#909098", marginTop: 4, marginBottom: 12 }}
              >
                This is the amount the recipient will get.
              </TypeFezNeueLight>
            ) : null}
            <div style={{ position: "relative", zIndex: 0 }}>
              <TypeFezNeueRegular
                style={{
                  position: "absolute",
                  color: colors.grey72,
                  fontSize: 11,
                  top: 0,
                  left: 9,
                  zIndex: 1,
                }}
              >
                Transfer Notes
              </TypeFezNeueRegular>
              <CustomTextArea
                maxLength={255}
                useFormObj={useFormObj}
                label="Transfer Notes"
                name="transfer_notes"
                placeholder="Notes to recipient"
                style={{ color: "black", marginBottom: 0 }}
                defaultValue={invoiceTransferNotes}
              />
              <TypeFezNeueLight style={{ color: "#909098" }}>
                This note will appear on the bank transfer receipt.
              </TypeFezNeueLight>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <TypeFezNeueBold style={{ fontSize: 16 }}>
              Supporting Documents
            </TypeFezNeueBold>
            <div
              style={{
                display: filesIsEmpty ? "flex" : "",
                alignItems: "center",
              }}
            >
              {filesIsEmpty ? <img src={"/images/files-upload.svg"} /> : null}
              <FileInputText
                text="Add Attachment +"
                useFormObj={useFormObj}
                fileTypes={["image/jpeg", "application/pdf", "image/png"]}
                editFilename={async (values) => {
                  const { file_name, id: detailId } = values || {};
                  await apiBusiness.put(
                    `/invoice_transactions/${selected}/attachment/${detailId}`,
                    { name: file_name }
                  );
                }}
                defaultValue={newAttachmentData}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: 64,
            boxShadow:
              "0px 0px 5px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)",
            position: "sticky",
            bottom: 1,
            zIndex: 3,
            padding: "18px 24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="d-flex-column">
            <TypeFezNeueRegular>Total Amount</TypeFezNeueRegular>
            <TypeFezNeueBold style={{ fontSize: 16 }}>
              {getTotalAmount}
            </TypeFezNeueBold>
          </div>
          <div className="d-flex justify-content-end">
            <div style={{ display: "flex", alignItems: "center" }}>
              <CustomButton
                style={{ height: 32, width: 85, height: 40 }}
                onClick={handleSubmit(onSubmit)}
                isLoading={updateLoading}
              >
                Save
              </CustomButton>
            </div>
          </div>
          {/* {isEdit ? (
            <div className="d-flex justify-content-end">
              <div style={{ display: "flex", gap: 16 }}>
                <OutlineButton
                  style={{ height: 32 }}
                  onClick={() => {
                    reset("supporting_document");
                    resetLrValue("banks");
                    setIsEdit(false);
                  }}
                >
                  Discard
                </OutlineButton>
                <CustomButton
                  style={{ height: 32 }}
                  onClick={handleSubmit(onSubmit)}
                  isLoading={updateLoading}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 24,
              }}
            >
              {!duplicateFlag && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 10,
                    padding: 8,
                  }}
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  className="darkhover"
                >
                  <img src={EditIcon} />
                  <TypeFezNeueRegular style={{ fontSize: 12 }}>
                    Edit
                  </TypeFezNeueRegular>
                </div>
              )}
              {!duplicateFlag && !isDraft && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 10,
                    padding: 8,
                  }}
                  className="darkhover"
                  onClick={() => saveAsDraft({ state: "draft" })}
                >
                  <img src={invoiceDraftLogo} />
                  <TypeFezNeueRegular style={{ fontSize: 12 }}>
                    Save as Draft
                  </TypeFezNeueRegular>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 10,
                  padding: 8,
                }}
                className="darkhover"
                onClick={() => deleteInvoice()}
              >
                <img src={DeleteIcon} />
                <TypeFezNeueRegular style={{ fontSize: 12 }}>
                  Delete
                </TypeFezNeueRegular>
              </div>
            </div>
          )} */}
        </div>
      </div>
      <ConfirmationModal
        modal={openConfirmationModal}
        toggle={() => setOpenConfirmationModal(false)}
        alignLeft
        onClick={() => backToList()}
        title="Discard changes ?"
        text="Changes you made so far will not be saved."
        buttonTextLeft="Cancel"
        buttonTextRight="Discard"
      />
    </div>
  );
};
