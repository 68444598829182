import React, { useEffect, useState } from "react";
import { apiBusiness, useMutation } from "../../../tools/api";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { eventsTracker } from "../../../universalFunctions/events";

export const useDeleteRow = ({ page, deleteId, afterSuccess }) => {
  const { successSnackBar, errorToasterApi } = ToasterHook();

  const urlIndex = {
    local_transfer: "/local_transaction_batches",
    reimbursement: "/reimbursements",
    bill_payment: "/invoice_transactions",
    payroll: "/payrolls",
  };

  const { mutation: mutationDeleteDraft } = useMutation({
    url: `${urlIndex[page]}/${deleteId}`,
    method: "delete",
    afterSuccess: () => {
      eventsTracker("draft_item_delete_success");
      successSnackBar({
        msg: "1 transaction has been deleted",
      });
      afterSuccess && afterSuccess();
    },
  });

  const bulkDelete = async (deleteIds = []) => {
    try {
      deleteIds.forEach(async (item) => {
        await apiBusiness.delete(`${urlIndex[page]}/${item}`);
      });
      eventsTracker("draft_bulk_delete_success");
      successSnackBar({
        msg: `${deleteIds.length} transactions has been deleted`,
      });
    } catch (error) {
      errorToasterApi(error);
    }
  };

  return { mutationDeleteDraft, bulkDelete };
};
