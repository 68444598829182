import { noCase, titleCase } from "change-case";
import {
  containsOnlyDigits,
  formatCurrencyNoDecimal,
  stringCutter,
  unformatCurrencyDecimal,
} from "../../../../components/tools";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { FormatterOptionsBank } from "../../../PayrollCreateEnhancement/create/formatter";
import { TooltipIncorrect } from "../../../PayrollCreateEnhancement/create/tooltip";
import deleteIcon from "../../../../assets/images/delete-icon-pink.svg";
import incorrectIcon from "../../../../assets/images/incorrect-icon.svg";
import duplicateIcon from "../../../../assets/images/duplicate-icon.svg";
import { CheckBox } from "../../../../components/Checkbox";
import { useEffect, useState } from "react";
import {
  EmailInfoLocalTransfer,
  EmailTextLocalTransfer,
} from "./inputs/email/parent";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { PlaceholderLocalTable } from "./components";

export const ObjLabelValue = ({ dataRaw }) => {
  const data = (dataRaw || []).map((item, index) => {
    let temp = [];
    for (const obj in item) {
      const object = {
        label: titleCase(obj),
        name: obj,
        value: item[obj],
      };
      temp.push(object);
    }
    return temp;
  });
  return data;
};

export const GetBankDetails = ({ array, option, compareId = false }) => {
  const getBank = array.filter(({ name }) => name === "bank")[0];
  const getPayerId = array.filter(({ name }) => name === "payer_id")[0];
  const getNewBank = option?.filter(({ name, id }) => {
    if (compareId) return id == getBank?.value;
    return name == getBank?.value || id == getPayerId?.value;
  })[0];
  return getNewBank;
};

export const checkerEmptyWhenUpload = ({ data }) => {
  const newData = data?.map((item, index) => {
    const newItem = item?.map((sub, i) => {
      const { isRequired, isName } = useBooleanTableLocal({ name: sub?.name });

      // not able to enter only numbers
      const invalidFormat = isName && containsOnlyDigits(sub?.value);

      // required fields cannot empty
      const invalid = isRequired && !Boolean(sub?.value) ? true : false;
      return {
        ...sub,
        invalid,
        invalidFormat,
      };
    });
    return newItem;
  });
  return newData;
};

export const removeAllEmptyField = ({ data: datas }) => {
  if (datas.length === 1) return datas;

  let tempArray = [];

  for (let data of datas) {
    for (let item of data) {
      if (!!item.value) {
        tempArray.push(data);
        break;
      }
    }
  }
  return tempArray;
};

export const isAllDataCompleted = ({ data }) => {
  let counter = 0;
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].length; j++) {
      const item = data[i][j];
      if (!!item?.invalid || !!item?.invalidFormat) {
        counter++;
      }
    }
  }

  return counter === 0;
};

export const UseLocalTransferTable = ({
  data: dataRaw,
  headerArray,
  optionsBank,
}) => {
  const dataObj = ObjLabelValue({ dataRaw });
  const formattedOptionsBank = FormatterOptionsBank({ options: optionsBank });

  const data = (dataObj || []).map((item) => {
    const bankData = GetBankDetails({
      array: item,
      option: formattedOptionsBank,
    });

    const objBank = {
      name: "bank",
      value: bankData?.value,
      id: bankData?.id,
      label: "Bank",
      details: bankData,
    };

    return [
      objBank,
      { name: "delete" },
      { name: "number" },
      { name: "action" },
      ...item,
    ];
  });
  const result = data.map((item) => {
    const getLocalId = item?.filter(
      ({ name }) => name === "id" || name === "local_recipient_id"
    );
    const newArr = headerArray.map(({ name }) => {
      const bodyArrNames = item.map(({ name }) => name);
      const index = bodyArrNames
        .map((nameChild, index) => {
          if (nameChild == name) return index;
        })
        .filter((item) => typeof item == "number")[0];

      const result = item[index];
      return result;
    });
    return [...newArr, ...getLocalId];
  });

  const returnData = checkerEmptyWhenUpload({ data: result });

  return returnData;
};

export const useLocalTransferMixFunc = ({ data, search }) => {
  // FOR FILTER SEARCH
  const filteredData = data?.filter((item, index) => {
    if (!item) return;
    // index 2 for data name
    let str = "";
    for (let i = 0; i < item?.length; i++) {
      str += item[i]?.value;
    }
    const filtered = str?.toLowerCase().includes(search.toLowerCase());
    return filtered;
  });
  // FOR FILTER SEARCH

  // DATA FOR FLOATING BUTTON
  let totalAmount = 0;
  let totalTrx = 0;
  const newValues = (data || []).map((item, index) => {
    totalTrx += 1;
    const newData = item?.map((sub, i) => {
      if (sub?.name === "amount") {
        totalAmount += Number(unformatCurrencyDecimal(sub.value));
      }
    });
    return newData;
  });

  const dataFloatingButton = { totalAmount, totalTrx };
  // DATA FOR FLOATING BUTTON

  // FOR KICKER
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender)
      return setTimeout(() => {
        setIsFirstRender(false);
      }, 500);
  }, [isFirstRender]);

  const isKick = data.length === 0 && !isFirstRender;
  // FOR KICKER

  const isEmptyData = filteredData?.length === 0;

  return { filteredData, dataFloatingButton, isKick, isEmptyData };
};

export const renderedValue = ({
  item,
  showDeleteRow,
  index,
  isActive,
  handleClickItem,
  options,
  isHover,
  isAnythingSelected,
}) => {
  const { name, value, invalid, duplicate, invalidFormat } = item || {};
  const {
    isNumber,
    isDelete,
    isEmail,
    isAmount,
    isAction,
    isBank,
    isAccNumber,
    isName,
    isNotes,
  } = useBooleanTableLocal({
    name,
  });
  const isValueEmpty = !Boolean(value);
  const bankValue = options.filter((item) => item.name === value)[0];

  const IncorrectDiv = (children) => {
    return (
      <div className="d-flex" style={{ gap: 4 }}>
        <TooltipIncorrect item={item}>
          <img src={incorrectIcon} />
        </TooltipIncorrect>
        {children}
      </div>
    );
  };

  const DuplicateDiv = (children) => {
    return (
      <div className="d-flex" style={{ gap: 4 }}>
        <TooltipIncorrect item={item}>
          <img src={duplicateIcon} />
        </TooltipIncorrect>
        {children}
      </div>
    );
  };

  if (isNumber) return index + 1;
  if (isDelete) {
    if (isHover || isAnythingSelected) {
      return (
        <td>
          <CheckBox
            nameCustom={isActive ? "checkmark-square-2" : "square"}
            fillCustom={
              isActive ? jackColors.neutral900 : jackColors.neutral600
            }
            isActive={isActive}
            onClick={() => handleClickItem(index, isActive)}
            style={{ width: 20, height: 20 }}
          />
        </td>
      );
    }
    return "";
  }

  if (isAmount) {
    if (invalid) {
      return IncorrectDiv(`IDR ${formatCurrencyNoDecimal(value)}`);
    }
    if (isValueEmpty) return PlaceholderLocalTable(name);
    return `IDR ${formatCurrencyNoDecimal(value)}`;
  }

  if (isEmail) {
    if (invalid) return "-";
    if (isValueEmpty) return PlaceholderLocalTable(name);
    const [email, ...additional_emails] = value.split(",");
    return (
      <EmailInfoLocalTransfer
        additional_emails={additional_emails}
        email={email}
      >
        <EmailTextLocalTransfer
          additional_emails={additional_emails}
          email={stringCutter(email)}
        />
      </EmailInfoLocalTransfer>
    );
  }

  if (isAction) {
    if (isHover) {
      return (
        <JackIcons
          name="delete-outline"
          fill={jackColors.neutral800}
          style={{ width: 20, height: 20 }}
        />
      );
    }
    return "";
  }

  if (isBank) {
    if (invalid) return IncorrectDiv("-");
    if (duplicate) return DuplicateDiv(bankValue?.label);
    if (!bankValue) return PlaceholderLocalTable(name);
    return bankValue?.label;
  }

  if (isAccNumber || isName) {
    if (invalidFormat) return IncorrectDiv(value);
    if (invalid) return IncorrectDiv("-");
    if (duplicate) return DuplicateDiv(value);
    if (isValueEmpty) return PlaceholderLocalTable(name);
  }

  if (isNotes) {
    if (invalid) return "-";
    if (isValueEmpty) return PlaceholderLocalTable(name);
  }
  // if (invalid) return IncorrectDiv(PlaceholderLocalTable(name));
  // if (isValueEmpty) return PlaceholderLocalTable(name);
  return value;
};

const MinimumLocalAmount = 10000;
export const cleanNumber = (value) => Number(unformatCurrencyDecimal(value));

export const useCheckerErrorAddRow = ({ data }) => {
  let counter = 0;
  const newData = data?.map((item, index) => {
    const newItem = item?.map((sub, i) => {
      const { isRequired, isSalary, isAmount } = useBooleanTableLocal({
        name: sub?.name,
      });
      if (isAmount && cleanNumber(sub?.value) < MinimumLocalAmount)
        return counter++;
      if (isRequired && sub?.value.length === 0) return counter++;
      return;
    });
    return newItem;
  });

  if (counter > 0) return true;
  return false;
};

export const ErrorMessageGeneratorLocal = ({ name, value }) => {
  const { isBank, isAmount, isName } = useBooleanTableLocal({ name });

  const isBelowMinimumAmount =
    isAmount && cleanNumber(value) < MinimumLocalAmount;

  const isContainOnlyNumber = isName && containsOnlyDigits(value);

  const msgDecider = () => {
    if (isBelowMinimumAmount) {
      return `Minimum amount: IDR ${formatCurrencyNoDecimal(
        MinimumLocalAmount
      )}.`;
    }
    if (isBank) return `Choose a Bank`;
    if (isContainOnlyNumber)
      return `Include alphabetic characters, not just numeric.`;
    return `Please fill out this field.`;
  };

  const msg = msgDecider();
  return { msg, isBelowMinimumAmount, isContainOnlyNumber };
};

export const OnSubmitLocalCreateFormatter = (data = []) => {
  const newData = data?.map((array) => {
    let value = {};
    array.forEach((item) => {
      const { name, value: valueChild, options, details } = item || {};
      const isAmount = name == "amount";
      const isBank = name == "bank";
      const isName = name == "name";
      const isAccNumber = name == "account_number";
      if (valueChild === undefined) return;
      if (isName) return (value["recipient_name"] = valueChild);
      if (isAccNumber) return (value["bank_account_number"] = valueChild);
      if (isBank)
        return (value[name] = {
          ...details,
          value: details?.id,
        });
      if (isAmount)
        return (value[name] = Number(unformatCurrencyDecimal(valueChild)));
      value[name] = valueChild;
    });
    return value;
  });
  return newData;
};

export const AddRowExistingListFormatter = ({
  data,
  formattedOptionsBank,
  defaultData,
  dummyData,
}) => {
  const isDummySameAsIndexZero =
    JSON.stringify(dummyData) === JSON.stringify(defaultData[0]);
  const isOnlyOneEmptyData = defaultData.length === 1 && isDummySameAsIndexZero;
  let formatted = isOnlyOneEmptyData ? [] : [...defaultData];

  const dataFormatted = data?.map((item) => {
    const { id, name, bank_name, account_number, email } = item || {};

    const bankData = formattedOptionsBank.filter(
      ({ name }) => name === bank_name
    )[0];

    const itemRaw = [
      { name: "delete" },
      { name: "number" },
      { name: "name", value: name, label: "Name" },
      {
        name: "bank",
        value: bankData?.name,
        label: "Bank",
        id: bankData?.id,
        details: bankData,
      },
      {
        name: "account_number",
        value: account_number,
        label: "Bank Account Number",
      },
      { name: "notes", value: "", label: "Notes" },
      { name: "email", value: email, label: "Email" },
      { name: "amount", value: "", label: "Amount" },
      { name: "local_recipient_id", value: id, label: "Local Recipient Id" },
      { name: "action" },
    ];
    formatted.push(itemRaw);
  });

  return formatted;
};

export const useBooleanTableLocal = ({ name }) => {
  const isDelete = name === "delete";
  const isNumber = name === "number";
  const isName = name === "name";
  const isRecipient = name === "recipient";
  const isBank = name === "bank";
  const isAccNumber = name === "account_number";
  const isAmount = name === "amount";
  const isNotes = name === "notes";
  const isEmail = name === "email";
  const isAction = name === "action";
  const isTotalAmount = name === "total_amount";
  const isPayerId = name === "payer_id";
  const isBankCode = name === "bank_code";
  const isId = name === "id";
  const isLocalId = name === "local_recipient_id";
  const isRecipientEmail = name === "recipient_email";
  const isAdditionalEmail = name === "additional_email";

  const isRequired = isName || isBank || isAccNumber || isAmount;
  const isSecondRender = isNotes || isEmail;
  const isFirstRender = isBank || isAccNumber || isAmount;
  const isBorderRight = isName || isAmount || isEmail;
  return {
    isDelete,
    isNumber,
    isName,
    isAction,
    isAmount,
    isEmail,
    isBank,
    isAccNumber,
    isNotes,
    isAmount,
    isPayerId,
    isBankCode,
    isRequired,
    isFirstRender,
    isSecondRender,
    isId,
    isLocalId,
    isTotalAmount,
    isBorderRight,
    isRecipientEmail,
    isAdditionalEmail,
    isRecipient,
  };
};

export const DeleteStringDecider = (length) => {
  if (length > 1) return `${length} transactions have been deleted.`;
  return `1 transaction has been deleted.`;
};

export const CheckerDataDuplicate = ({ data: datas = [] }) => {
  const bankAccountMap = {}; // Objek untuk melacak kombinasi "bank" dan "account_number"

  // Iterasi melalui setiap elemen data
  datas.forEach((entry) => {
    const bankItem = entry.find((item) => item.name === "bank");
    const accountNumberItem = entry.find(
      (item) => item.name === "account_number"
    );

    const bankValue = bankItem.value;
    const accountNumberValue = accountNumberItem.value;

    const validValue = !!bankValue && !!accountNumberValue;

    const key = `${bankValue}_${accountNumberValue}`; // Membuat kunci kombinasi

    if (!bankAccountMap[key]) {
      bankAccountMap[key] = [];
    }

    if (validValue) {
      bankAccountMap[key].push({ entry, bankItem, accountNumberItem });
    }
  });

  // Menandai duplikat dan non-duplikat
  Object.values(bankAccountMap).forEach((duplicates) => {
    const isDuplicate = duplicates.length > 1;
    duplicates.forEach((duplicate) => {
      duplicate.bankItem.duplicate = isDuplicate;
      duplicate.accountNumberItem.duplicate = isDuplicate;
    });
  });

  return datas;
};
