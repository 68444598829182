export const updatesFormatter = ({
  updates: updatesRaw = [],
  isOpenSeeAll,
}) => {
  let updates = updatesRaw;

  const isMoreThanFour = updates.length > 4;
  const isNormal = !(isMoreThanFour && !isOpenSeeAll);

  const activeIndex = updates
    .map(({ isActive }, index) => {
      if (isActive) return index;
      return null;
    })
    .filter((index) => index)
    .pop();

  if (isNormal) return { updates, isMoreThanFour, activeIndex };

  const sliceLength = (first, last) => updates.slice(first, last).length;

  const stepsBefore = sliceLength(1, activeIndex);
  const stepsAfter = sliceLength(activeIndex, updates.length - 1);

  const tempUpdates = [];
  const pushTemp = (obj) => tempUpdates.push(obj);

  pushTemp({ ...updates[0], isNoLine: !!stepsBefore });

  const defaultObj = { isSteps: true, isNoLine: true };

  if (stepsBefore)
    pushTemp({
      text: `${stepsBefore} completed steps`,
      ...defaultObj,
    });

  pushTemp({
    ...updates[activeIndex],
    isNoLine: !!stepsAfter,
  });

  if (stepsAfter)
    pushTemp({
      text: `${stepsAfter} more steps`,
      ...defaultObj,
    });

  updates = tempUpdates;

  const activeIndexDecider = (updates) => {
    if (isMoreThanFour) {
      const trueActiveIndex = updates.findIndex(
        (update) => !update.isSteps && update !== updates[0]
      );
      return trueActiveIndex;
    }

    return activeIndex;
  };

  const trueActiveIndex = activeIndexDecider(updates);

  return { updates, isMoreThanFour, activeIndex: trueActiveIndex };
};
