import {
  schemaGenerator,
  unformatCurrency,
} from "../../../../../components/tools";
import * as yup from "yup";
import { isEmailChecker } from "../../../../localTransferEnhancement/create/table/inputs/email/chip";
import {
  formatTaxesForSubmit,
  formatTransactionItemsForSubmit,
} from "../../../../../modals/smartActivityModal/invoicePayment/editDetailModal/logic";
export const recipientResolver = schemaGenerator({
  stringArr: [
    "name",
    "account_number",
    {
      name: "vendor_emails",
      yup: yup
        .array()
        .of(
          yup
            .string()
            .test("is-email", "Please input correct email", (val) =>
              isEmailChecker(val)
            )
        ),
    },
    {
      name: "banks",
      yup: yup.object({
        id: yup.number(),
      }),
    },
  ],
});

export const taxSelectionResolver = schemaGenerator({
  stringArr: [
    {
      name: "selection",
      yup: yup.object({
        value: yup.string().required(),
      }),
    },
  ],
});

export const approverFormResolver = schemaGenerator({
  stringArr: [
    {
      name: "amount",
      yup: yup
        .string()
        .required("Please fill out this field")
        .test(
          "is-ok",
          "Please fill out this field",
          (val) => Number(unformatCurrency(val)) > 0
        )
        .test(
          "is-above-10k",
          `Minimum amount: IDR 10,000`,
          (val) => unformatCurrency(val) >= 10000
        ),
    },
    {
      name: "transaction_items_attributes",
      yup: yup.array().test("is-not-empty", "Items cannot be empty.", (val) => {
        const formattedItems = formatTransactionItemsForSubmit(val);
        return formattedItems?.length > 0;
      }),
    },
    // {
    //   name: "transaction_taxes_attributes",
    //   yup: yup.array().test("is-not-empty", "Taxes cannot be empty.", (val) => {
    //     const formattedItems = formatTaxesForSubmit(val);
    //     return formattedItems?.length > 0;
    //   }),
    // },
  ],
});
