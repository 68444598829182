import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "@tools";
import { Modal } from "reactstrap";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../components/Avatar";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { SimpleTextAreaJack } from "../../../../components/inputs/simpleTextArea";
import { TopUpModal, useModalHook } from "../../../../components/Modals";
import { RightModal } from "../../../../components/Modals/RightModal/parent";
import { PDFOrImageViewer } from "../../../../components/PDFPreview";
import PINModal from "../../../../components/PINModal/parent";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
  TextInlineBold,
} from "../../../../components/Text";
import { CloseButton } from "../../../../pageComponents/invoiceComponents/createPage/uploadPage";
import {
  getTotalAmountSummary,
  idrStringFormatter,
} from "../../../../pageComponents/invoiceComponents/data/formatter";
import {
  DetailsModal,
  crossBorderSingleWorkflowComponents,
} from "../../crossBorderSingleModal/components";
import {
  DescriptionAccordion,
  EditedTitle,
  PreviewComponent,
} from "../../invoicePayment/parent";
import {
  ApproverSummary,
  ReimbursementReceiptDetail,
  ReimbursementTableView,
  ReviewModalHeader,
  ReviewTable,
  SubmitSummaryContent,
} from "./components";
import { ReviewModalUpdateTracker } from "./updateTrackers";
import * as yup from "yup";
import { schemaGenerator } from "../../../../components/tools";
import backIcon from "../../../../assets/images/back-invoice-modal.svg";
import {
  extractPayloadDefaultValue,
  formatSummaryModalData,
  supportingdocHook,
} from "../logics";
import { ActionButtonSupportingDoc } from "../../invoicePayment/supportingDocs";
import { eventsTracker } from "../../../../universalFunctions/events";
import CategorySelection from "../../../../pageComponents/categoryComponents/general/components/CategorySelection";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { useMutation } from "../../../../tools/api";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { batchCategoryChangePayloadFormatter } from "../../../../pageComponents/categoryComponents/general/helpers";
import { createReimbursementChildSummary } from "../../invoicePayment/hooks/logics";
import { InvoiceFormSummary } from "../../../../pageComponents/invoiceComponents/createPage/modals/formComponents/component";
import { EditSummaryModal } from "../../invoicePayment/editDetailModal";
import { useModalHookData } from "../../../../pageComponents/scheduledPaymentComponents/dashboardComponent/hooks";
import { EditButton } from "../../invoicePayment/hooks/components/buttons";
import { useSubmitInvoiceDetails } from "../../invoicePayment/editDetailModal/logic";
import { isEmpty } from "lodash";
import { confirmIcon } from "../../../../pageComponents/reimbursementComponents/data/constant";
import { PDFLeftComponent } from "../../../../pageComponents/invoiceComponents/createPage/modals/formEdit";
import { ReleasePaymentModalJack } from "../../crossBorderSingleModal/ReleasePaymentModal";
import {
  useBankingHooks,
  useHandlingFee,
} from "../../crossBorderSingleModal/DanamonReleasePayment/logic";
import { useDetailsExtractor } from "../../crossBorderSingleModal/logics";
import { useActiveModules } from "contexts/AuthContext";
export const ReimbursementDetailModal = ({
  data,
  batchId,
  toggle,
  isOpen,
  detailId = null,
  onClickRow,
  hideButtons = true,
  refetch,
  workflowObjs,
  isApprover,
  setAllData,
  isDrafter,
  dataState,
  dataParent,
  allData,
  listener = () => {},
}) => {
  const detail = useMemo(() => {
    if (!detailId) return data?.[0] ?? {};

    return data?.filter(({ id }) => id == detailId).pop();
  }, [detailId, data]);
  return (
    <RightModal
      isOpen={isOpen}
      width={[480, 420, 420]}
      toggle={toggle}
      array={[
        <ReimbursementTableView
          data={data}
          activeId={detail?.id}
          onClick={onClickRow}
          toggle={toggle}
        />,
        <ReimbursementReceiptDetail
          isApprover={isApprover}
          detailId={detailId}
          data={data}
          setAllData={setAllData}
          batchId={batchId}
          hideButtons={hideButtons}
          refetch={refetch}
          workflowObjs={workflowObjs}
          isDrafter={isDrafter}
          dataState={dataState}
          dataParent={dataParent}
          allData={allData}
          listener={listener}
        />,
        <PreviewComponent
          file_url={detail?.file_url}
          is_duplicate={detail?.is_duplicate}
          product="receipt"
        />,
      ]}
      noPadding={true}
    />
  );
};

export const CancelReceiptModal = ({
  isOpen,
  toggle,
  merchant,
  amount,
  onSubmit,
  loading,
  isReject = false,
  defaultValue,
}) => {
  const useFormObj = useForm();

  const { handleSubmit, watch, errors } = useFormObj;
  const currValue = watch("rejection_note");
  const isEmpty = currValue?.trim()?.length == 0;

  const disableSubmit = !currValue || isEmpty;
  const textDecider = () => {
    if (isReject)
      return {
        headline: "Reject this expense?",
        text: `Rejected expense will be deducted from the reimbursement total amount.`,
        buttonText: "Reject Expense",
        label: "Reason of Rejection*",
      };
    return {
      headline: "Cancel this expense?",
      text: `Canceled expense will be deducted from the reimbursement total amount.`,
      buttonText: "Cancel Expense",
      label: "Reason for Cancelation*",
    };
  };

  const { headline, text, buttonText, label } = textDecider();

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          width: 480,
          height: "auto",
          borderRadius: 8,
          backgroundColor: "#FFFFFF",
        }}
      >
        <div
          className="d-flex justify-content-between"
          style={{
            padding: "20px 20px 16px 20px",
            borderBottom: "1px solid #E6E6E8",
            height: 106,
          }}
        >
          <div className="d-flex-column">
            <GothamBold
              style={{
                fontSize: 20,
                marginBottom: 4,
                color: jackColors.black34,
              }}
            >
              {headline}
            </GothamBold>
            <GothamRegular style={{ color: jackColors.grey90 }}>
              {text}
            </GothamRegular>
          </div>
          <CloseButton onClick={toggle} />
        </div>
        <div style={{ height: 290, padding: "16px 20px 32px 20px" }}>
          <div
            className="py-2 px-3 d-flex mb-4 borderE6 align-items-center"
            style={{ height: 66 }}
          >
            <Avatar
              size="medium"
              mainIcon={
                <JackIcons
                  name="reimbursement"
                  style={{ width: "auto", height: 18 }}
                />
              }
            />
            <div className="d-flex-column ml-2" style={{ width: "80%" }}>
              <GothamMedium
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {merchant ?? "-"}
              </GothamMedium>
              <GothamRegular>{amount ?? "-"}</GothamRegular>
            </div>
          </div>
          <SimpleTextAreaJack
            useFormObj={useFormObj}
            label={label}
            placeholder="E.g., this expense contains incorrect data"
            maxLength={1000}
            name="rejection_note"
            style={{ height: 120, marginBottom: 0 }}
            defaultValue={defaultValue}
          />
        </div>
        <div
          style={{
            padding: 20,
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid #E6E6E8",
          }}
        >
          <ButtonJack
            style={{ backgroundColor: "transparent", width: 212 }}
            type="outline"
            onClick={toggle}
          >
            Back
          </ButtonJack>
          <ButtonJack
            style={{ width: 212 }}
            type="filled"
            disabled={disableSubmit}
            isLoading={loading}
            onClick={handleSubmit((val) => onSubmit(val))}
          >
            {buttonText}
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

export const ReimbursementExpenseReview = ({
  isOpen,
  toggle,
  data,
  onSubmit,
  submitLoading,
  taskId,
  isPay = false,
  setData,
  dataParent,
  allData,
  refetch,
  listener = () => {},
}) => {
  const [category, setCategory] = useState(null);

  const { isOpen: openPIN, toggle: togglePIN } = useModalHook();
  const { isOpen: openSummary, toggle: toggleSummary } = useModalHook();
  const { isOpen: back, toggle: toggleBack } = useModalHook();
  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();
  const {
    isOpen: isOpenReleasePaymentModal,
    toggle: toggleReleasePaymentModal,
  } = useModalHook();

  const handleBackPIN = (payerMode = false) => {
    togglePIN();
    if (payerMode) {
      toggleReleasePaymentModal();
    }
  };

  const {
    reimbursement_child,
    name,
    approval_tasks,
    local_recipient_data: localRecipient,
    fee,
    id: reimbursement_id,
  } = data;
  const [detailId, setDetailId] = useState(null);
  const [payload, setPayload] = useState([]);

  const { categories } = useConstants();

  const { approval_task_items } = useMemo(() => {
    if (!taskId) return {};
    return approval_tasks?.filter(({ id }) => id == taskId)?.pop();
  }, [taskId]);

  const detail = useMemo(() => {
    if (!detailId) return reimbursement_child?.[0] ?? {};

    const filteredChild = reimbursement_child
      ?.filter(({ id }) => id == detailId)
      ?.pop();
    const approval_task_child = approval_task_items?.filter(
      ({ originator_id }) => originator_id == filteredChild?.id
    );
    const data = { ...filteredChild, approval_task_child };
    const categoryObj = (categories ?? []).find(
      ({ id }) => id === data?.category_id
    );
    setCategory(categoryObj);
    return data;
  }, [detailId, data]);

  const {
    merchant,
    purchasedDateShort,
    memo_text,
    file_url,
    amountIDR,
    id,
    attachment_data,
    category_id,
    total_amount,
    notes_from_editing,
    edited_by_user_id,
    edited_at,
  } = detail ?? {};

  // useEffect(() => {
  //   if (category_id && !category) {
  //     const category = (categories ?? []).find(({ id }) => id === category_id);
  //     setCategory(category);
  //     console.log({ category_id });
  //   }
  // }, [category, category_id, categories, id]);

  const formattedReimbursementChild = useMemo(() => {
    return reimbursement_child?.map((child) => {
      const approval_task_child = approval_task_items
        ?.filter(({ originator_id }) => originator_id == child?.id)
        ?.pop();

      return { ...child, approval_task_child };
    });
  }, [reimbursement_child]);

  const payloadDefaultValue = useMemo(
    () => extractPayloadDefaultValue(formattedReimbursementChild),
    [formattedReimbursementChild]
  );

  const getOption = (id) => {
    return payload?.filter(({ originator_id }) => id == originator_id)?.pop();
  };

  useEffect(() => {
    if (!isEmpty(payloadDefaultValue)) return setPayload(payloadDefaultValue);
  }, [payloadDefaultValue]);
  const childLength =
    formattedReimbursementChild?.filter(({ state }) => state != "cancelled")
      ?.length ?? 0;

  const payloadLength = payload?.filter(({ state }) => state)?.length;
  const disableContinue = payloadLength < childLength;
  const totalDataLength = payload?.length;
  const totalRejectedLength = payload?.filter(
    ({ state }) => state == "rejected"
  )?.length;
  const isFullReject = totalDataLength == totalRejectedLength;
  const payerMode = isPay && !isFullReject;

  const {
    hide,
    title: sdTitle,
    onClickSupportingDoc,
  } = supportingdocHook(attachment_data);

  const { successSnackBar } = ToasterHook();

  const [payment, setPayment] = useState({ type: "default" });

  // banking stuff
  const {
    isUserJackUltimate,
    activeTransferBalance,
    activeTransferService,
    propsTransferService: propsBanking,
    showDetailsFee,
    payloadBanking,
    payloadHandlingFee,
    canCaluclateHandlingFee,
  } = useBankingHooks();

  const {
    data: dataHandlingFee,
    calculateFee,
    loadingCalculateFee,
  } = useHandlingFee();

  const propsTransferService = {
    taskId: [taskId],
    dataHandlingFee,
    ...propsBanking,
  };

  const { sortedData, summaryData } = formatSummaryModalData(
    formattedReimbursementChild,
    payload
  );

  const approvedIdReimbursement = summaryData?.reimbursement_child.map(
    ({ receiptId }) => receiptId
  );

  const dataWithSorted = { ...data, formatted_reimbursement_child: sortedData };

  useEffect(() => {
    const payload = {
      ...payloadHandlingFee,
      task_ids: [taskId],
      receipt_ids: approvedIdReimbursement,
    };
    // if (canCaluclateHandlingFee) {
    calculateFee(payload);
    // }
  }, [activeTransferBalance.value, activeTransferService.value]);

  const detailData = useDetailsExtractor({
    transactionDetails: dataWithSorted,
    isReleaser: true,
    canApprove: true,
    isUserJackUltimate,
    propsTransferService,
    loadingCalculateFee,
    isSchedulePayment: payment?.type !== "default",
  });
  // banking stuff

  useEffect(() => {
    if (detailId) return;
    const firstId = reimbursement_child?.[0]?.id;
    return setDetailId(firstId);
  }, [data]);

  const receiptUrl = `/reimbursement_receipts/${id}`;

  const { mutation: updateCategory } = useMutation({
    url: receiptUrl,
    afterSuccess: (_, payload) => {
      const categoryId = payload?.category_id;

      const batchPayload = batchCategoryChangePayloadFormatter({
        dataParent,
        categories,
        categoryId,
        data: allData,
        dataDetail: detail,
      });

      listener(batchPayload);

      successSnackBar({ msg: "Transaction category has been updated." });

      setData((prev) => {
        const reimbursementChild = prev.reimbursement_child.find(
          ({ id }) => id === detailId
        );
        reimbursementChild.category_id = categoryId;
        return prev;
      });
    },
  });

  const summary = createReimbursementChildSummary(detail);
  const {
    isOpen: isOpenEditSummary,
    open: openEditSummary,
    close: closeEditSummary,
    data: defaultValue,
  } = useModalHookData();

  const saveProgressPayloadFormatter = (payload) =>
    payload?.map(({ id, rejection_note, state }) => ({
      approval_task_item_id: id,
      rejection_note,
      state,
    }));
  const { mutation: saveProgress, loading: saveProgressLoading } = useMutation({
    url: `/approval_tasks/${taskId}/save_approval_reviews`,
    afterSuccess: refetch,
  });

  const handleClickApproverSummary = (type, payload) => {
    const isUndo = type == "undo";
    const isChangeRejectNote = type == "change-reject-note";

    if (isUndo) {
      const { id } = payload;
      setPayload((p) =>
        p?.map((item) => {
          if (item?.originator_id == id) {
            return { ...item, state: null };
          }
          return item;
        })
      );
    }

    if (isChangeRejectNote) {
      const { rejection_note, id } = payload;
      setPayload((p) =>
        p?.map((item) => {
          if (item?.originator_id == id) {
            return { ...item, rejection_note };
          }
          return item;
        })
      );
    }
  };

  const { isActiveReimbursement } = useActiveModules();
  const { isReleaser } = crossBorderSingleWorkflowComponents({
    transaction: data,
  });
  const isDisabledReimbursementRelease = !isActiveReimbursement && isReleaser;
  const isDisabledContinue = disableContinue || isDisabledReimbursementRelease;

  return (
    <>
      <Modal
        className="my-0"
        contentClassName="modal-full"
        isOpen={isOpen}
        toggle={toggle}
        keyboard={false}
        style={{ borderRadius: 0 }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "calc(100% - 132px)",
              display: "flex",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                width: "40%",
                minHeight: "100%",
                overflowY: "auto",
                borderRight: "1px solid #E6E6E8",
                padding: 16,
                display: "flex",
                flexDirection: "column",
                gap: 32,
              }}
            >
              <ReviewModalHeader batchName={name} />
              <ReviewTable
                bodyArr={formattedReimbursementChild}
                activeRowId={detailId}
                onClick={({ id }) => {
                  setDetailId(id);
                }}
                setPayload={setPayload}
                setState={setPayload}
                getOption={getOption}
              />
            </div>
            <div
              style={{
                width: "30%",
                height: "100%",
                padding: 16,
                display: "flex",
                flexDirection: "column",
                gap: 32,
                overflowY: "auto",
              }}
            >
              <div
                className="w-100 d-flex-column"
                style={{ textAlign: "center" }}
              >
                <GothamRegular className="mb-1">{merchant}</GothamRegular>
                <GothamBold style={{ fontSize: 24, marginBottom: 0 }}>
                  {idrStringFormatter(total_amount)}
                </GothamBold>
              </div>
              <ApproverSummary
                detail={detail}
                getOption={getOption}
                setPayload={setPayload}
                onClick={handleClickApproverSummary}
              />
              <DetailsModal
                array={[
                  { title: "Purchase date", value: purchasedDateShort },
                  { title: "Merchant", value: merchant },
                ]}
              />
              <CategorySelection
                isForTrxDetails
                showAllCategories
                selectedCategory={category ?? {}}
                style={{ marginBottom: "0px", width: "100%" }}
                onSelect={(category) => {
                  setCategory(category);
                  updateCategory({ category_id: category?.id });
                }}
              />
              {/* partial takeout */}
              {/* <InvoiceFormSummary
                data={summary}
                isReimbursement
                rightHeaderComponent={
                  <EditButton
                    hideButton={detail?.activity_state == "cancelled"}
                    onClick={() => openEditSummary(detail)}
                  />
                }
                isCustomTitle={Boolean(edited_by_user_id)}
                title={
                  <EditedTitle
                    editBy={edited_by_user_id}
                    notes={notes_from_editing}
                    editAt={edited_at}
                    label="Notes on Editing"
                  />
                }
              /> */}
              <DescriptionAccordion description={memo_text} />
              {!hide ? (
                <ActionButtonSupportingDoc
                  iconName="layers_outline"
                  title={sdTitle}
                  text="See all supporting documents"
                  onClick={onClickSupportingDoc}
                />
              ) : null}
            </div>
            <div
              style={{
                width: "30%",
                height: "100%",
                borderLeft: "1px solid #E6E6E8",
                padding: 16,
              }}
            >
              <PDFOrImageViewer
                url={file_url}
                noZoom={true}
                enablePreview={true}
                leftHeaderComponent={
                  <PDFLeftComponent
                    is_duplicate={detail?.is_duplicate}
                    product="receipt"
                    pdf={detail?.file_url}
                  />
                }
              />
            </div>
          </div>
          <div style={{ width: "100%", height: 132 }}>
            <div
              style={{
                width: "100%",
                height: 52,
                backgroundColor: isDisabledContinue
                  ? jackColors.redFF
                  : jackColors.green06,
                padding: "8px 11px",
                display: "flex",
                alignItems: "start",
                gap: 11,
              }}
            >
              <JackIcons
                name={
                  isDisabledContinue ? "alert_triangle" : "checkmark_circle_2"
                }
                fill={isDisabledContinue ? jackColors.redDC : "#FFFFFF"}
                style={{ width: 18.32, height: "auto" }}
              />
              <div className="d-flex-column">
                <GothamMedium
                  className="font12"
                  style={{
                    color: isDisabledContinue ? jackColors.black34 : "#FFFFFF",
                    marginBottom: 4,
                  }}
                >
                  {payloadLength}/{childLength} expenses reviewed
                </GothamMedium>
                <GothamRegular
                  className="font12"
                  style={{
                    color: isDisabledContinue ? jackColors.grey6C : "#FFFFFF",
                  }}
                >
                  Click continue to confirm your action.
                </GothamRegular>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: 80,
                backgroundColor: jackColors.black34,
                padding: "20px 16px",
              }}
            >
              <div className="d-flex justify-content-end">
                <ButtonJack
                  type="outline"
                  style={{ backgroundColor: "transparent", marginRight: 16 }}
                  leftIcon={
                    <JackIcons name="arrow_back_outline" fill="#ffffff" />
                  }
                  classNameText="text-white"
                  onClick={toggleBack}
                >
                  Cancel
                </ButtonJack>
                <ButtonJack
                  rightIcon={
                    <JackIcons
                      name="arrow_forward"
                      fill={
                        isDisabledContinue
                          ? jackColors.greyBB
                          : jackColors.black34
                      }
                    />
                  }
                  disabled={isDisabledContinue}
                  onClick={toggleSummary}
                >
                  Continue
                </ButtonJack>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <PINModal
        isLoading={submitLoading}
        isOpen={openPIN}
        toggle={() => handleBackPIN(payerMode)}
        onBack={() => handleBackPIN(payerMode)}
        onSubmit={({ pin }) => {
          const payloadLength = payload?.length;
          const rejectLength = payload?.filter(
            ({ state }) => state == "rejected"
          )?.length;
          const payloadSubmit = payload
            ?.map(({ id, rejection_note, state }) => ({
              id,
              rejection_note,
              state,
            }))
            ?.filter(({ state }) => state);

          const { payload: schedulePayload } = payment;
          const submitType =
            payloadLength == rejectLength ? "reject" : "approve";

          onSubmit(
            {
              pin: Number(pin),
              approval_task_items: payloadSubmit,
              ...schedulePayload,
              ...payloadBanking,
            },
            submitType
          );
          eventsTracker("reimbursement_approve_confirmation", {
            reimbursement_id,
            approval_task_items: payloadSubmit,
            submitType,
          });
        }}
      />
      <ReimbursementSummaryModal
        data={formattedReimbursementChild}
        payload={payload}
        fee={fee}
        isOpen={
          openSummary &&
          !openPIN &&
          !isOpenTopUpModal &&
          !isOpenReleasePaymentModal
        }
        toggle={toggleSummary}
        isFullReject={isFullReject}
        isPay={isPay}
        localRecipient={localRecipient}
        onClick={() => {
          // BANKING
          if (payerMode) {
            return toggleReleasePaymentModal();
          }
          togglePIN();
        }}
        onClickTopUp={toggleTopUpModal}
        schedulePaymentState={[payment, setPayment]}
      />
      <ReleasePaymentModalJack
        isOpen={isOpenReleasePaymentModal}
        toggle={toggleReleasePaymentModal}
        onClick={() => {
          toggleReleasePaymentModal();
          togglePIN();
        }}
        onClickTopUp={toggleTopUpModal}
        data={data}
        detailData={detailData}
        schedulePaymentState={[payment, setPayment]}
        {...propsTransferService}
      />
      <TopUpModal
        modal={isOpenTopUpModal}
        toggle={toggleTopUpModal}
        back
        paid
      />
      <ConfirmationModalJack
        buttonTextLeft="Cancel"
        buttonTextRight="Quit"
        img={confirmIcon.pintuExit}
        title="Quit review expense?"
        text="All of your progress will be saved and you need to continue the review later on."
        toggle={toggleBack}
        loading={saveProgressLoading}
        onClick={async () => {
          const savePayload = {
            approval_task_item_reviews: saveProgressPayloadFormatter(payload),
          };
          if (!isEmpty(payload)) await saveProgress(savePayload);
          setPayload([]);
          toggle();
        }}
        modal={back}
      />
      <ReimbursementEditDetailModal
        defaultValue={defaultValue}
        isOpen={isOpenEditSummary}
        toggle={closeEditSummary}
        afterSuccess={(newData) => {
          setData((p) => {
            const { reimbursement_child, ...rest } = p;
            const newRC = reimbursement_child?.map((child) => {
              if (child?.id != newData?.id) return child;

              return { ...child, ...newData };
            });

            return { reimbursement_child: newRC, ...rest };
          });
          closeEditSummary();
        }}
      />
    </>
  );
};

export const ReimbursementSummaryModal = ({
  isOpen,
  toggle,
  data,
  onClick,
  isPay,
  localRecipient,
  isFullReject,
  fee = 0,
  onClickTopUp,
  schedulePaymentState,
  payload,
}) => {
  const { sortedData, summaryData } = formatSummaryModalData(data, payload);
  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      width={[500]}
      array={[
        <SubmitSummaryContent
          data={sortedData}
          toggle={toggle}
          onClick={onClick}
          isFullReject={isFullReject}
          isPay={isPay}
          localRecipient={localRecipient}
          fee={fee}
          onClickTopUp={onClickTopUp}
          schedulePaymentState={schedulePaymentState}
          summaryData={summaryData}
        />,
      ]}
    />
  );
};

const ReimbursementEditDetailModal = ({
  isOpen,
  toggle,
  defaultValue,
  afterSuccess,
}) => {
  const useFormObj = useForm();
  const { handleSubmit } = useFormObj;

  const { submit, loading } = useSubmitInvoiceDetails({
    url: `/reimbursement_receipts/${defaultValue?.id}`,
    afterSuccess,
  });
  return (
    <EditSummaryModal
      isOpen={isOpen}
      toggle={toggle}
      useFormObj={useFormObj}
      defaultValue={defaultValue}
      onSubmit={handleSubmit(submit)}
      submitLoading={loading}
      isReimbursement
    />
  );
};
