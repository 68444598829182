import styles from "./CategoryArticle.module.css";

const CategoryArticle = ({
  url = "https://support.itsjack.com/en/support/solutions/articles/150000142739-all-about-categories-feature",
}) => {
  return (
    <a className={styles.categoryArticle} href={url} target="_blank">
      this article.
    </a>
  );
};

export default CategoryArticle;
