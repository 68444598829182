import { Modal } from "reactstrap";
import { JackIcons } from "../../assets/jackIcons/parent";
import { jackColors } from "../../assets/colors";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import styles from "./SimpleCenteredModal.module.css";

const SimpleCenteredModal = ({
  isOpen = false,
  woClose = false,
  isHidden = false,
  isLoading = false,
  isDisabled = false,
  woLineDividers = false,
  children = null,
  customButtons = null,
  modalClassName = null,
  leftHeaderComponent = null,
  buttonText = "Okay",
  toggle = () => {},
  onClick = () => {},
}) => {
  const contentClassName = modalClassName ?? styles["simple-centered-modal"];

  const buttons = customButtons ?? (
    <ButtonJack
      disabled={isDisabled}
      onClick={onClick}
      isLoading={isLoading}
      style={{ width: "100%" }}
    >
      {buttonText}
    </ButtonJack>
  );

  return (
    <Modal
      centered
      hidden={isHidden}
      isOpen={isOpen}
      contentClassName={contentClassName}
      toggle={toggle}
    >
      <div
        className={styles["modal-header"]}
        style={{
          borderBottom: woLineDividers
            ? "none"
            : `1px solid ${jackColors.neutral500}`,
        }}
      >
        {leftHeaderComponent || <div />}
        {!woClose && <ModalCloseButton onClick={toggle} />}
      </div>
      {children}
      <div
        className={styles["modal-footer"]}
        style={{
          borderTop: woLineDividers
            ? "none"
            : `1px solid ${jackColors.neutral500}`,
        }}
      >
        {buttons}
      </div>
    </Modal>
  );
};

export const ModalCloseButton = ({ onClick }) => (
  <button className={styles["close-btn-container"]} onClick={onClick}>
    <JackIcons name="close-outline" fill={jackColors.neutral900} />
  </button>
);

export default SimpleCenteredModal;
