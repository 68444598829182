import { isEmpty } from "lodash";
import Skeleton from "react-loading-skeleton";
import { jackColors } from "../../../assets/colors";
import bigImg from "../../../assets/images/balance-dashboard-big.svg";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../components/Modals/RightModal/parent";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { WrapperModalButtons } from "../../../pageComponents/homeComponents/topup/components";
import { ScheduledPaymentOptions } from "../../../pageComponents/scheduledPaymentComponents/paymentOptions";
import { ModalCardLeftRight } from "../payroll/rejectModal";
import { useBooleanDanamonProduct } from "./DanamonReleasePayment/boolean";
import {
  SourceOfPaymentParent,
  TransferServiceParent,
} from "./DanamonReleasePayment/components";

export const ApproveBackButton = ({
  onClick,
  onClickBack,
  disableButton,
  rightIcon,
  rightText,
  hideLeftIcon,
  containerStyle,
}) => {
  const rightButtonText = rightText ?? "Pay";
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: 12, paddingRight: 16, ...containerStyle }}
    >
      <ButtonJack
        style={{ width: "50%", backgroundColor: "transparent" }}
        type="outline"
        onClick={onClickBack}
        leftIcon={
          <JackIcons name="arrow_back_outline" fill={jackColors.black34} />
        }
      >
        Back
      </ButtonJack>
      <ButtonJack
        style={{ width: "50%" }}
        type="filled"
        onClick={onClick}
        disabled={disableButton}
        leftIcon={
          !hideLeftIcon && (
            <JackIcons
              name={"checkmark_circle_2"}
              fill={disableButton ? jackColors.greyBB : jackColors.black34}
            />
          )
        }
        rightIcon={
          rightIcon && (
            <JackIcons
              name={rightIcon}
              fill={disableButton ? jackColors.greyBB : jackColors.black34}
            />
          )
        }
      >
        {rightButtonText}
      </ButtonJack>
    </div>
  );
};

export const CompanyBalanceAndTopUp = ({
  companyBalanceIdr,
  onClickTopUp,
  refresh,
  loading = false,
  style,
}) => {
  return (
    <div
      style={{
        width: "100%",
        top: -64,
        left: -1,
        position: "absolute",
        height: 64,
        padding: "12px 32px 12px 16px",
        backgroundColor: "#000000",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        ...style,
      }}
    >
      <div className="d-flex-column" style={{ lineHeight: 1 }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
          <GothamRegular className="font12 text-white">
            Company Balance
          </GothamRegular>
          <JackIcons
            name="refresh"
            style={{ height: 14, cursor: "pointer" }}
            className="refreshButton"
            onClick={refresh}
          />
        </div>
        {loading ? (
          <Skeleton width={120} height={20} className="mb-0 p-0" />
        ) : (
          <GothamBold className="font14 text-white mb-0">
            {companyBalanceIdr}
          </GothamBold>
        )}
        <img
          src={bigImg}
          style={{
            position: "absolute",
            right: 0,
            top: 3,
          }}
        />
      </div>
      <ButtonJack
        style={{
          backgroundColor: jackColors.black34,
          height: 32,
          minWidth: 85,
          zIndex: 10,
          color: jackColors.greenB9,
        }}
        isSmall={true}
        classNameText="font12"
        leftIcon={
          <JackIcons
            name="top_up"
            fill={jackColors.greenB9}
            style={{ width: 16.67, height: "auto" }}
          />
        }
        onClick={onClickTopUp}
      >
        Top Up
      </ButtonJack>
    </div>
  );
};

export const ReleasePaymentModalJack = ({
  isOpen,
  toggle,
  data,
  detailData,
  onClick,
  onClickTopUp,
  schedulePaymentState,
  isRetry,
  ...propsTransferService
}) => {
  // bankingRelated
  const {
    isUserJackUltimate,
    activeTransferService,
    activeTransferBalance,
    setTransferServiceRules,
    transferServiceRules,
    loadingSubscriptionUsage,
  } = propsTransferService || {};
  const { transaction_flag } = transferServiceRules || {};
  const { all_non_direct, all_to_danamon, all_to_ewallet } =
    transaction_flag || {};
  const hideServices = all_to_danamon || all_to_ewallet || all_non_direct;

  const balance = activeTransferBalance?.balance;
  const isSelectedService = !!activeTransferService?.value;

  const {
    isDanamonBalance,
    isBankTransfer,
    isSelectedBalance,
    isCollectionPocket,
  } = useBooleanDanamonProduct(activeTransferBalance?.value);

  // balance details
  const { totalPaymentRaw, titleModalHeader, title, originator } =
    detailData ?? {};
  const titleModal = !!titleModalHeader ? titleModalHeader : "Confirm to pay";
  const remainingBalance = +balance - totalPaymentRaw;
  const isInsufficientWithFee = remainingBalance < 0;
  const isSufficient = remainingBalance >= 0;

  // single product boolean
  const isInternationalTransfer = title === "International Transfer";
  const isBalanceTransfer = title === "Balance Transfer";
  const isJackTransfer = title === "Transfer Between Jack";
  const isWithdrawal = title === "Withdrawal";
  const productWoService =
    isInternationalTransfer ||
    isBalanceTransfer ||
    isWithdrawal ||
    isJackTransfer;

  const showTransferService =
    (isUserJackUltimate &&
      isDanamonBalance &&
      !productWoService &&
      !hideServices) ||
    false;

  //modalStuff
  const { toToggle, toListener } = useSmoothRightModal();

  //schedulepaymentrelated
  const spProducts = [
    "InvoiceTransaction",
    "Reimbursement",
    "LocalTransfer",
    "Payroll",
  ];
  const [payment, setPayment] = schedulePaymentState ?? [];
  const { type, payload: schedulePayload } = payment ?? {};
  const isPayloadEmpty = isEmpty(schedulePayload);
  const isPayImmediately = type == "pay_immediately";
  const { due_date: invoiceDueDate } = data;
  const canUseSchedulePayment =
    spProducts?.includes(originator) && !!schedulePaymentState;

  const disableButton = () => {
    // Order is quite crucial
    if (isUserJackUltimate) {
      if (isCollectionPocket) {
        if (isInsufficientWithFee) return true;
        return false;
      }
      if (isSelectedBalance) {
        if (isBankTransfer) return false; // international only
        if (isInsufficientWithFee) return true;
        if (productWoService || hideServices) return false;
        if (!isSelectedService) return true;
      }
      if (!isSelectedBalance) return true;
      return isInsufficientWithFee;
    }
    if (!isSelectedBalance) return true;
    if (isSelectedBalance && isBankTransfer) return false;
    if (isSelectedBalance && (isPayloadEmpty || isPayImmediately))
      return !isSufficient;
    return false;
  };

  const customButtonText = !(isPayloadEmpty || isPayImmediately)
    ? "Approve & Schedule"
    : null;

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      noPadding={true}
      width={[420]}
      woClose={true}
      listener={toListener}
      array={[
        <WrapperModalButtons
          childrenButton={
            <ApproveBackButton
              rightText={customButtonText}
              onClick={onClick}
              onClickBack={toToggle}
              disableButton={disableButton()}
            />
          }
        >
          <div
            style={{
              padding: 16,
              width: "100%",
              minHeight: "calc(100vh - 180px)",
              height: "auto",
              overflowY: "auto",
            }}
          >
            <GothamMedium
              style={{
                fontSize: 24,
                color: "#000000",
                marginBottom: 32,
                marginTop: 0,
              }}
            >
              {titleModal}
            </GothamMedium>
            <ModalCardLeftRight
              data={detailData}
              containerStyle={{ marginBottom: 32 }}
              mainDivStyle={{ borderRadius: 8 }}
            />

            {loadingSubscriptionUsage ? (
              <>
                <Skeleton width={"100%"} height="60px" />
                <Skeleton width={"100%"} height="60px" className="mt-4" />
              </>
            ) : (
              <>
                {!isUserJackUltimate && canUseSchedulePayment && (
                  <div style={{ marginBottom: 32 }}>
                    <ScheduledPaymentOptions
                      product={originator}
                      payment={payment}
                      setPayment={setPayment}
                      invoiceDueDate={invoiceDueDate}
                    />
                  </div>
                )}
                <SourceOfPaymentParent
                  data={data}
                  detailData={detailData}
                  {...propsTransferService}
                />

                {showTransferService && (
                  <TransferServiceParent
                    data={data}
                    transferServiceRules={transferServiceRules}
                    setTransferServiceRules={setTransferServiceRules}
                    {...propsTransferService}
                  />
                )}
              </>
            )}
          </div>
        </WrapperModalButtons>,
      ]}
    />
  );
};
