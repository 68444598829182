import { useRouter } from "next/router";
import React from "react";
import { colors, jackColors } from "../assets/colors";
import { GothamBold, GothamMedium, GothamRegular } from "../components/Text";
import coinIcon from "../assets/images/transaction-details-icon.svg";
import profileIcon from "../assets/images/profile-icon-noshadow.svg";
import cardIcon from "../assets/images/card-icon-noshadow.svg";
import equalsIcon from "../assets/images/equals-icon.svg";
import divideIcon from "../assets/images/divide-icon.svg";
import minusIcon from "../assets/images/minus-icon.svg";
import checkBulletGreen from "../assets/images/check_bullet.svg";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
  toBase64,
} from "../components/tools";
import { universeFunc } from "../components/RecipientDetailsModal";
import { eventsTracker } from "../universalFunctions/events";
import {
  CrossBorderAlert,
  CrossBorderTrxDetails,
} from "./crossBorderSingleIndex";
import { UniverseDataGenerator } from "./crossBorder/universeData";
import { JackIcons } from "../assets/jackIcons/parent";
import { headerCase, capitalCase, noCase, titleCase } from "change-case";
import { useFileViewer } from "../contexts/FilePreviewContext/parent";
import { MaskingLabelCurrency } from "../components/CalculatorInputs";

const ObjectTitleValue = (array, invoices) => {
  let arr = [];

  const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1);
  };

  for (const key in array) {
    let data = {
      title: capitalizeFirstLetter(noCase(key)),
      value: array[key],
    };
    arr.push(data);
  }

  if (!!invoices.length) {
    let data = {
      title: "Attachment",
      value: invoices,
    };
    arr.unshift(data);
  }

  return arr;
};

const HeaderPerSection = ({ title, change, icon, iconStyle }) => {
  const { push } = useRouter();

  const isRecipient = change == "recipient";
  const isPayment = change == "payment";
  const isAdditional = change == "additional";

  const propsDecider = () => {
    if (isRecipient)
      return {
        url: "/cross-border/single/recipient",
        name: "change_recipient",
      };
    if (isPayment)
      return {
        url: "/cross-border/single/payment",
        name: "change_payment_method",
      };
    if (isAdditional)
      return {
        url: "/cross-border/single/additional",
        name: "change_additional_information",
      };
    return { url: "/cross-border/single", name: "reset_transaction" };
  };

  const handleClick = () => {
    const { url, name } = propsDecider();
    eventsTracker("cross_border_single_confirmation_change", { name });
    push(url);
  };

  return (
    <div className="d-flex" style={{ marginBottom: 8 }}>
      <div className="d-flex align-items-center">
        <JackIcons name={icon} fill={jackColors.neutral900} />
        <GothamMedium style={{ fontSize: 16, margin: "0px 8px 0px 8px" }}>
          {title}
        </GothamMedium>
      </div>
      <div
        className="hover-600"
        style={{
          border: `1px solid ${jackColors.neutral500}`,
          borderRadius: 32,
          cursor: "pointer",
          padding: "4px 8px",
        }}
      >
        <GothamMedium
          className="font12"
          style={{ color: jackColors.neutral900 }}
          onClick={handleClick}
        >
          Edit
        </GothamMedium>
      </div>
    </div>
  );
};

export const useAlertDecider = ({ data = "" }) => {
  const isLocal = data === "local";
  const isSwiftSha = data === "swift_sha";
  const isSwiftOur = data === "swift_our";
  if (isSwiftSha) return "SWIFT SHA";
  if (isSwiftOur) return "SWIFT OUR";
  return "Local Bank Network";
};

export const DetailsBold = ({ title = "", value = "", withDash }) => {
  return (
    <div
      className="d-flex justify-content-between mb-2"
      style={{
        borderTop: withDash && "2px dashed #BBBBC0",
        paddingTop: withDash && 12,
      }}
    >
      <GothamMedium>{title}</GothamMedium>
      <GothamMedium>{value}</GothamMedium>
    </div>
  );
};

export const DetailsLeftRight = ({ data }) => {
  const { left, right } = data || {};
  const style = {
    color: colors.grey6c,
  };
  return (
    <div className="d-flex justify-content-between mb-2">
      <GothamRegular style={style}>{left}</GothamRegular>
      <GothamRegular style={style}>{right}</GothamRegular>
    </div>
  );
};

export const LittleDot = ({ style }) => {
  return (
    <div
      style={{
        margin: 5,
        width: 6,
        height: 6,
        borderRadius: "50%",
        backgroundColor: jackColors.neutral900,
        ...style,
      }}
    />
  );
};

export const TransactionDetails = ({ data }) => {
  const {
    source,
    fee: feeRaw,
    source_currency,
    destination,
    destination_currency,
    payment_channel,
    bankFee,
    inverse_rate,
  } = data || {};

  const currAfterMasking = MaskingLabelCurrency({
    currency: destination_currency,
    noSwift: true,
  });

  const rateText = `1 ${currAfterMasking} = ${source_currency} ${formatCurrency(
    inverse_rate
  )}`;
  const handlingFeeRaw = Number(feeRaw) - Number(bankFee);
  const handlingFee = `${source_currency} ${formatCurrencyNoDecimal(
    handlingFeeRaw
  )}`;
  const fee = `${source_currency} ${formatCurrencyNoDecimal(feeRaw)}`;
  const bankFeeText = `${source_currency} ${formatCurrency(bankFee)}`;
  const deliveryMethod = useAlertDecider({ data: payment_channel });
  const payText = `${source_currency} ${formatCurrency(source)}`;
  const recipientGetsText = `${currAfterMasking} ${formatCurrency(
    destination
  )}`;

  const array = [
    {
      left: "You pay",
      right: payText,
      rightBold: true,
    },
    {
      left: "Settlement method",
      right: deliveryMethod,
    },
    {
      left: "Handling fee",
      leftDot: true,
      right: handlingFee,
    },
    {
      left: "Bank fee",
      leftDot: true,
      right: bankFeeText,
    },
    {
      spacerNotFull: true,
    },
    {
      left: "Total fee",
      customIcon: "minus-circle",
      right: fee,
    },
    {
      left: "Exchange rate",
      customIcon: "divide-circle",
      right: rateText,
    },
    { spacer: true },
    {
      left: "Recipient gets",
      right: recipientGetsText,
      rightBold: true,
    },
  ];

  return (
    <div>
      <HeaderPerSection
        title="Transaction Details"
        icon="coin"
        change="calculator"
        iconStyle={{ width: 20, height: 20 }}
      />
      <ConfirmTrxDetails array={array} />
      {/* <DetailsBold title="You Pay" value={payText} />
      <DetailsLeftRight data={deliveryMethodObj} />
      <CrossBorderTrxDetails data={TrxDetailsArray} isConfirmation />
      <DetailsBold title="Recipient Gets" value={recipientGetsText} withDash /> */}
      {/* <CrossBorderAlert data={deliveryMethod} bankFee={bankFeeText}/> */}
    </div>
  );
};

export const RecipientDetails = ({ data }) => {
  const { universeData } = UniverseDataGenerator({ data });
  return (
    <div>
      <HeaderPerSection
        title="Recipient Details"
        icon="person-outline"
        change="recipient"
      />
      <CardListConfirmation array={universeData} />
    </div>
  );
};

export const AdditionalInformationDetails = ({ data }) => {
  const { complianceLabel, invoices } = data || {};
  const array = ObjectTitleValue(complianceLabel, invoices);

  return (
    <div>
      <HeaderPerSection
        title="Additional Information"
        icon="file-add-outline"
        change="additional"
      />
      <CardListConfirmation array={array} />
    </div>
  );
};

export const CardListConfirmation = ({ array }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gridColumnGap: 16,
      }}
    >
      {array?.map((item, index) => {
        const { title, value: valueRaw } = item || {};
        const isAttachment = title === "Attachment";
        const isAccNumber = title === "Account Number";
        const isName = title === "Name";
        const value = isName ? titleCase(valueRaw || "") : valueRaw;

        if (isAttachment) return <AttachmentPreview key={index} item={item} />;
        return (
          <div key={index} style={{ marginTop: 16 }}>
            <GothamRegular
              style={{ color: jackColors.neutral800, marginBottom: 4 }}
            >
              {title}
            </GothamRegular>
            <GothamRegular
              style={{ color: jackColors.neutral900, wordBreak: "break-word" }}
            >
              {value}
            </GothamRegular>
          </div>
        );
      })}
    </div>
  );
};

export const PaymentSummary = ({ data }) => {
  const { source_currency, balance, source, remaining_balance, isWallet } =
    data;
  const isIdr = source_currency == "IDR";
  const paymentMethod = isWallet ? "From Balance" : "Transfer to Bank";

  const formattedBalance = isIdr
    ? formatCurrencyNoDecimal(balance)
    : formatCurrency(balance);
  const formattedSourceAmount = isIdr
    ? formatCurrencyNoDecimal(source)
    : formatCurrency(source);
  const formattedRemainingBalance = isIdr
    ? formatCurrencyNoDecimal(remaining_balance)
    : formatCurrency(remaining_balance);
  const dataDetails = [
    {
      title: "Payment Method",
      value: paymentMethod,
    },
    ...(isWallet
      ? [
          {
            title: "Available Balance",
            value: `${source_currency} ${formattedBalance}`,
          },
        ]
      : []),
    {
      title: "Amount to Pay",
      value: `${source_currency} ${formattedSourceAmount}`,
    },
    ...(isWallet
      ? [
          {
            title: "Remaining Balance",
            value: `${source_currency} ${formattedRemainingBalance}`,
          },
        ]
      : []),
  ];
  return (
    <div>
      <HeaderPerSection
        title="Payment Summary"
        icon={cardIcon}
        change="payment"
      />
      {dataDetails.map(({ title, value }, index) => {
        const isLast = dataDetails.length - 1 == index;
        const borderLast = isLast ? `1px dashed #C2C2C2` : `none`;
        return (
          <div key={index}>
            {isLast && isWallet && <hr style={{ border: borderLast }} />}
            <div
              className="d-flex justify-content-between"
              style={{ marginBottom: 12 }}
            >
              <GothamRegular
                style={{
                  color: colors.grey72,
                  marginBottom: 0,
                  lineHeight: "22px",
                }}
              >
                {title}
              </GothamRegular>
              <GothamMedium style={{ marginBottom: 0 }}>{value}</GothamMedium>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ConfirmTrxDetails = ({ array }) => {
  return (
    <div style={{ marginTop: 16 }}>
      {array?.map((item, index) => {
        const {
          left,
          rightBold,
          leftDot,
          right,
          spacer,
          spacerNotFull,
          customIcon,
        } = item || {};

        if (spacer || spacerNotFull)
          return (
            <hr
              key={index}
              style={{
                borderTop: `2px dotted ${jackColors.neutral600}`,
                marginLeft: spacerNotFull && 24,
              }}
            />
          );

        return (
          <div key={index} style={{ marginBottom: leftDot ? 12 : 12 }}>
            <div className="d-flex justify-content-between">
              {leftDot ? (
                <div className="d-flex align-items-center" style={{ gap: 8 }}>
                  <LittleDot />
                  <GothamRegular style={{ color: jackColors.neutral800 }}>
                    {left}
                  </GothamRegular>
                </div>
              ) : (
                <div className="d-flex align-items-center" style={{ gap: 8 }}>
                  {customIcon && (
                    <JackIcons
                      name={customIcon}
                      fill={jackColors.neutral900}
                      style={{ width: 16, height: 16 }}
                    />
                  )}
                  <GothamRegular style={{ color: jackColors.neutral800 }}>
                    {left}
                  </GothamRegular>
                </div>
              )}
              <GothamRegular
                style={{ fontFamily: rightBold && "GothamMedium" }}
              >
                {right}
              </GothamRegular>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const AttachmentPreview = ({ item, key }) => {
  const { show } = useFileViewer();
  const { title, value: arrayInvoices } = item || {};

  return (
    <div key={key} style={{ gridColumn: "1 / span 2", marginTop: 16 }}>
      <GothamRegular style={{ color: jackColors.neutral800, marginBottom: 4 }}>
        {title}
      </GothamRegular>
      {arrayInvoices?.map((item, index) => {
        const isFirst = index === 0;
        const { name, size } = item || {};
        return (
          <div
            key={index}
            style={{
              border: "1px solid #343434",
              borderRadius: 4,
              padding: 12,
              marginTop: !isFirst && 8,
            }}
            className="d-flex align-items-center justify-content-between"
          >
            <div className="d-flex align-items-center">
              <img src={checkBulletGreen} className="mr-2" />
              <div>
                <GothamMedium
                  className="font12"
                  style={{ color: jackColors.neutral900, marginBottom: 2 }}
                >
                  {name}
                </GothamMedium>
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.neutral700 }}
                >
                  {size}
                </GothamRegular>
              </div>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() =>
                show({
                  file: item,
                })
              }
            >
              <JackIcons name="eye-outline" fill={jackColors.neutral900} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
