import { FieldBoolean } from "../add/boolean";
import { fieldBankDetails, fieldRecipientDetails } from "../add/constant";

export const toTitleValue = (obj, array, isBankDetails) => {
  if (!obj) return;
  const keys = Object.keys(obj);

  const removeReceiving = (string = "") => {
    const isIncludeReceiving = string?.includes("receiving");
    const isIncludeIndividuName =
      string?.includes("first_name") || string?.includes("last_name");

    if (isIncludeReceiving) {
      if (isIncludeIndividuName)
        return string?.replace("receiving_", "")?.replace("_", "");
      return string?.replace("receiving_", "");
    }
    return string;
  };

  let temp = [];
  for (let key of keys) {
    const value = obj[key];
    const newKey = removeReceiving(key);

    const { isBankName, isCountryIsoCode, isNationalityIsoCode } =
      FieldBoolean(key);

    // not render to card dynamic checker
    const fieldNotRender = isCountryIsoCode || isNationalityIsoCode;

    const isInclude = array.includes(newKey) && !fieldNotRender;

    const data = {
      title: newKey,
      value,
    };

    if (isInclude || (isBankDetails && isBankName)) {
      temp.push(data);
    }
  }

  return temp;
};

export const DynamicCheckerCardFormatter = ({ recipientData = {} }) => {
  const arrayRecipient = toTitleValue(
    recipientData,
    fieldRecipientDetails,
    false
  );
  const arrayBankDetails = toTitleValue(recipientData, fieldBankDetails, true);

  const data = [
    {
      title: "Recipient Details",
      array: arrayRecipient,
    },
    {
      title: "Bank Details",
      array: arrayBankDetails,
    },
  ];

  return data;
};
