import { Fragment, useEffect, useState } from "react";
import SimpleCenteredModal from "../../../../../modals/SimpleCenteredModal";
import styles from "./CategorySettingModal.module.css";
import { jackColors } from "../../../../../assets/colors";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../../../components/Text";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { useCategoryFlag } from "../../hooks";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { useModalHook } from "../../../../../components/Modals";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import NextImage from "../../../../../components/NextImage";

const CategorySettingModal = ({ isOpen = false, toggle = () => {} }) => {
  const [hasInteracted, setHasInteracted] = useState(false);
  const [hasToggledSwitch, setHasToggledSwitch] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState("");

  const { user, refetchUser } = useGetAuth();

  const { activateCategory, category_setting, loadingActivateCategory } =
    useCategoryFlag();

  const { successSnackBar } = ToasterHook();

  const {
    isOpen: isOpenConfirmationModal,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useModalHook();

  const isDisabledSave = !hasInteracted && !hasToggledSwitch;

  const handleResetState = () => {
    setSelectedPreference("");
    setHasInteracted(false);
    setHasToggledSwitch(false);
    closeConfirmationModal();
    toggle();
  };

  const handleToggle = () => {
    if (loadingActivateCategory) return;
    handleResetState();
  };

  const handleToggleConfirmation = () => {
    if (loadingActivateCategory) return;
    closeConfirmationModal();
  };

  const handleActivateCategory = async () => {
    await activateCategory({
      setting: selectedPreference,
      afterSuccess: async () => {
        await refetchUser();
        successSnackBar({
          msg: "Categories preferences has been updated.",
        });
      },
    });

    handleResetState();
  };

  const handleSave = () => {
    if (selectedPreference === "deactivated") {
      return openConfirmationModal();
    }

    if (
      category_setting === "deactivated" &&
      selectedPreference !== "deactivated"
    ) {
      return openConfirmationModal();
    }

    handleActivateCategory();
  };

  useEffect(() => {
    if (isOpen) setSelectedPreference(category_setting);
  }, [isOpen, category_setting]);

  return (
    <SimpleCenteredModal
      isHidden={isOpenConfirmationModal}
      modalClassName={styles["category-setting-modal"]}
      isOpen={isOpen}
      toggle={handleToggle}
      buttonText="Save Changes"
      isLoading={loadingActivateCategory}
      onClick={handleSave}
      isDisabled={isDisabledSave}
      leftHeaderComponent={
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <GothamBold
            woFontSize
            className="font20"
            style={{ color: jackColors.neutral900, margin: 0 }}
          >
            Set up preferences
          </GothamBold>
          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: jackColors.neutral700 }}
          >
            Last updated by {user?.category_setting_update_by?.name}.
          </GothamRegular>
        </div>
      }
    >
      <SimpleCenteredModal
        isOpen={isOpenConfirmationModal}
        toggle={handleToggleConfirmation}
        modalClassName={styles["category-setting-modal"]}
        leftHeaderComponent={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <GothamBold
              woFontSize
              className="font20"
              style={{ color: jackColors.neutral900, margin: 0 }}
            >
              {selectedPreference === "deactivated"
                ? "Are you sure to deactivate categories?"
                : "Are you sure to activate categories again?"}
            </GothamBold>
          </div>
        }
        customButtons={
          <Fragment>
            <ButtonJack
              type="outline"
              style={{ width: "100%" }}
              isLoading={loadingActivateCategory}
              onClick={handleToggleConfirmation}
            >
              Cancel
            </ButtonJack>
            <ButtonJack
              style={{ width: "100%" }}
              isLoading={loadingActivateCategory}
              onClick={handleActivateCategory}
            >
              {selectedPreference === "deactivated"
                ? "Yes, deactivate"
                : "Yes, activate"}
            </ButtonJack>
          </Fragment>
        }
      >
        <div className={styles["content"]}>
          <NextImage
            width={476}
            height={200}
            src="/images/category-onboard.png"
            alt="Category Setting Image"
          />
          {selectedPreference === "deactivated" ? (
            <GothamRegular
              woFontColor
              style={{
                color: jackColors.neutral800,
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              Once deactivated, only{" "}
              <span style={{ fontFamily: "GothamMedium" }}>business owner</span>
              , <span style={{ fontFamily: "GothamMedium" }}>admin</span>, and{" "}
              <span style={{ fontFamily: "GothamMedium" }}>bookkeeper</span>{" "}
              will have access to Categories. All employees won’t be able to
              select category for transactions anymore.
            </GothamRegular>
          ) : (
            <GothamRegular
              woFontColor
              style={{
                color: jackColors.neutral800,
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              Once activated,{" "}
              <span style={{ fontFamily: "GothamMedium" }}>all employees</span>{" "}
              can select category for transactions again.
            </GothamRegular>
          )}
        </div>
      </SimpleCenteredModal>
      <div className={styles["content"]}>
        <div className={styles["title-container"]}>
          <div>
            <GothamBold
              woFontSize
              className="font14"
              style={{ color: jackColors.neutral900, margin: 0 }}
            >
              Categories
            </GothamBold>
            <div
              className={styles["switch"]}
              style={{
                justifyContent:
                  selectedPreference === "deactivated"
                    ? "flex-start"
                    : "flex-end",
                backgroundColor:
                  selectedPreference === "deactivated"
                    ? jackColors.neutral700
                    : jackColors.green06,
              }}
              onClick={() => {
                setSelectedPreference((prev) => {
                  setHasToggledSwitch(true);

                  if (category_setting === "deactivated") {
                    if (prev === "deactivated") {
                      return "required";
                    }

                    return "deactivated";
                  }

                  if (prev === "deactivated") {
                    return category_setting;
                  }

                  return "deactivated";
                });
              }}
            >
              <div className={styles["switch-dot"]} />
            </div>
          </div>

          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: jackColors.neutral700 }}
          >
            By activating, category will be applied to all transactions.
          </GothamRegular>
        </div>
        {selectedPreference !== "deactivated" && (
          <Fragment>
            <div className={styles["divider"]} />
            <div className={styles["preference-container"]}>
              <div
                className={styles["preference"]}
                style={{
                  borderColor:
                    selectedPreference === "required" && jackColors.neutral900,
                  gap: !hasInteracted && 0,
                }}
                onClick={() => {
                  setSelectedPreference("required");
                  setHasInteracted(true);
                }}
              >
                <div
                  style={{
                    minWidth:
                      (!hasInteracted || selectedPreference !== "required") &&
                      0,
                    maxHeight:
                      (!hasInteracted || selectedPreference !== "required") &&
                      0,
                    width:
                      (!hasInteracted || selectedPreference !== "required") &&
                      0,
                    height:
                      !hasInteracted ||
                      (selectedPreference !== "required" && 0),
                  }}
                >
                  {hasInteracted && selectedPreference === "required" && (
                    <NextImage
                      width={180}
                      height={120}
                      src="/images/category-required.png"
                      alt="Category Required Image"
                    />
                  )}
                </div>
                <div>
                  <GothamMedium style={{ color: jackColors.neutral900 }}>
                    Set as required
                  </GothamMedium>
                  <GothamRegular
                    woFontColor
                    className="font12"
                    style={{ color: jackColors.neutral700 }}
                  >
                    Every transaction must have a category. Missing category
                    will appear in{" "}
                    <span style={{ fontFamily: "GothamMedium" }}>Task</span>.
                  </GothamRegular>
                </div>
                {selectedPreference === "required" && (
                  <div
                    style={{ position: "absolute", top: "16px", right: "16px" }}
                  >
                    <JackIcons
                      name="checkmark-circle-2"
                      fill={jackColors.neutral900}
                    />
                  </div>
                )}
              </div>
              <div
                className={styles["preference"]}
                style={{
                  borderColor:
                    selectedPreference === "not_required" &&
                    jackColors.neutral900,
                  gap: !hasInteracted && 0,
                }}
                onClick={() => {
                  setSelectedPreference("not_required");
                  setHasInteracted(true);
                }}
              >
                <div
                  style={{
                    minWidth:
                      (!hasInteracted ||
                        selectedPreference !== "not_required") &&
                      0,
                    maxHeight:
                      (!hasInteracted ||
                        selectedPreference !== "not_required") &&
                      0,
                    width:
                      (!hasInteracted ||
                        selectedPreference !== "not_required") &&
                      0,
                    height:
                      !hasInteracted ||
                      (selectedPreference !== "not_required" && 0),
                  }}
                >
                  {hasInteracted && selectedPreference === "not_required" && (
                    <NextImage
                      width={180}
                      height={120}
                      src="/images/category-optional.png"
                      alt="Category Optional Image"
                    />
                  )}
                </div>
                <div>
                  <GothamMedium style={{ color: jackColors.neutral900 }}>
                    Set as optional
                  </GothamMedium>
                  <GothamRegular
                    woFontColor
                    className="font12"
                    style={{ color: jackColors.neutral700 }}
                  >
                    Transaction category is optional. Missing category will not
                    appear in Task.
                  </GothamRegular>
                </div>
                {selectedPreference === "not_required" && (
                  <div
                    style={{ position: "absolute", top: "16px", right: "16px" }}
                  >
                    <JackIcons
                      name="checkmark-circle-2"
                      fill={jackColors.neutral900}
                    />
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </SimpleCenteredModal>
  );
};

export default CategorySettingModal;
