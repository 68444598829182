import { jackColors } from "../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import CustomTooltip from "../../../../components/Tooltip";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { useBooleanDanamonProduct } from "../DanamonReleasePayment/boolean";
import { useWorkingHours } from "../DanamonReleasePayment/hooks";
import { SpanMedium } from "./components";
import { OptionRulesFunc, UnavailableTextDecider } from "./logic";

export const OptionUltimateTransfer = ({
  data,
  optionRules,
  isMultiRelease,
}) => {
  const { ObjFilterFunc, transaction_flag } = OptionRulesFunc({
    option: optionRules,
  });

  const {
    is_payroll: isPayroll,
    is_va: isVirtualAccount,
    is_non_partner: isNonPartner,
  } = transaction_flag || {};
  const optimizedRouteServiceOnly =
    isPayroll || isVirtualAccount || isNonPartner;

  const { type } = data || {};

  const {
    isReimbursement,
    isBillPayment,
    isLocalTransfer,
    isPayroll: isPayrollFee,
  } = useBooleanDanamonProduct(type);

  // obj in array
  const objRulesBiFast = ObjFilterFunc("bifast");
  const objRulesOptimized = ObjFilterFunc("optimized_route");
  const objRulesOnline = ObjFilterFunc("online");
  const objRulesSkn = ObjFilterFunc("skn");
  const objRulesRtgs = ObjFilterFunc("rtgs");

  // title per service
  const titleBIFast = "BI Fast";
  const titleOptimized = "Optimized Route";
  const titleOnline = "Online Payment";
  const titleSKN = "SKN";
  const titleRTGS = "RTGS";

  // fee pptimize route
  const feeOptimizedDecider = () => {
    if (isMultiRelease) return 2500;
    if (isBillPayment) return objRulesOptimized?.fee?.invoice;
    if (isLocalTransfer) return objRulesOptimized?.fee?.local;
    if (isPayrollFee) return objRulesOptimized?.fee?.payroll;
    if (isReimbursement) return objRulesOptimized?.fee?.reimbursement;
    return 2500;
  };
  const feeOptimized = feeOptimizedDecider();
  const isFreeBiFast =
    objRulesBiFast?.limit <= objRulesBiFast?.quota && objRulesBiFast?.limit > 0;
  const isFreeOptimized =
    objRulesOptimized?.limit <= objRulesOptimized?.quota &&
    objRulesOptimized?.limit > 0;

  const ObjBIFast = {
    key: 0,
    icon: "bank",
    value: "bifast",
    title: titleBIFast,
    description: "Transfer to any bank under your company name.",
    fee: objRulesBiFast?.fee,
    transferFee: (
      <TransferFeeComponents
        initialFee={isFreeBiFast ? objRulesBiFast?.fee : false}
        currentFee={isFreeBiFast ? false : objRulesBiFast?.fee}
        isFree={isFreeBiFast}
        withDot
      />
    ),
    transferTime: "24/7 Service",
    transferMaxAmount: "Max. IDR 250,000,000",
    isUnavailable:
      optimizedRouteServiceOnly ||
      !objRulesBiFast?.available ||
      objRulesBiFast?.maintenance ||
      objRulesBiFast?.amount_exceed ||
      !objRulesBiFast?.working_hours,
    unavailableText: UnavailableTextDecider({
      obj: objRulesBiFast,
      transaction_flag,
      isMultiRelease,
    }),
  };

  const ObjOptimized = {
    key: 1,
    icon: "jack-spade",
    value: "optimized_route",
    title: titleOptimized,
    description:
      "Transfer to any bank, e-wallet, or virtual account under Jack's name.",
    fee: feeOptimized,
    transferFee: (
      <TransferFeeComponents
        initialFee={isFreeOptimized ? feeOptimized : false}
        currentFee={isFreeOptimized ? false : feeOptimized}
        isFree={isFreeOptimized}
        withDot
      />
    ),
    transferTime: "24/7 Service",
    transferMaxAmount: "Any Amount",
    isUnavailable:
      !objRulesOptimized?.available ||
      objRulesOptimized?.maintenance ||
      objRulesOptimized?.amount_exceed ||
      !objRulesOptimized?.working_hours,
    unavailableText: UnavailableTextDecider({
      obj: objRulesOptimized,
      isMultiRelease,
    }),
  };

  const ObjOnline = {
    key: 2,
    icon: "swift-sha",
    value: "online",
    title: titleOnline,
    description: "Transfer to any bank under your company name.",
    fee: objRulesOnline?.fee,
    transferFee: (
      <TransferFeeComponents
        initialFee={7000}
        currentFee={objRulesOnline?.fee}
        withDot
      />
    ),
    transferTime: "24/7 Service",
    transferMaxAmount: "Max. IDR 50,000,000",
    isUnavailable:
      optimizedRouteServiceOnly ||
      !objRulesOnline?.available ||
      objRulesOnline?.maintenance ||
      objRulesOnline?.amount_exceed ||
      !objRulesOnline?.working_hours,
    unavailableText: UnavailableTextDecider({
      obj: objRulesOnline,
      isMultiRelease,
    }),
  };

  const ObjSkn = {
    key: 3,
    icon: "resend-callback",
    value: "skn",
    title: titleSKN,
    description: "Transfer to any bank under your company name.",
    fee: objRulesSkn?.fee,
    transferFee: (
      <TransferFeeComponents
        initialFee={2900}
        currentFee={objRulesSkn?.fee}
        withDot
      />
    ),
    transferTime: "08:00 - 13:45 WIB",
    transferMaxAmount: "Max. IDR 1,000,000,000",
    isUnavailable:
      optimizedRouteServiceOnly ||
      !objRulesSkn?.available ||
      objRulesSkn?.maintenance ||
      objRulesSkn?.amount_exceed ||
      !objRulesSkn?.working_hours,
    unavailableText: UnavailableTextDecider({
      obj: objRulesSkn,
      isMultiRelease,
    }),
    outsideServiceHours: !useWorkingHours("08:00", "13:45"),
  };

  const ObjRtgs = {
    key: 4,
    icon: "swift-our",
    value: "rtgs",
    title: titleRTGS,
    description: "Transfer to any bank under your company name.",
    fee: objRulesRtgs?.fee,
    transferFee: (
      <TransferFeeComponents
        initialFee={25000}
        currentFee={objRulesRtgs?.fee}
        withDot
      />
    ),
    transferTime: "08:00 - 13:45 WIB",
    transferMaxAmount: "Any Amount",
    isUnavailable:
      optimizedRouteServiceOnly ||
      !objRulesRtgs?.available ||
      objRulesRtgs?.maintenance ||
      objRulesRtgs?.amount_exceed ||
      !objRulesRtgs?.working_hours,
    unavailableText: UnavailableTextDecider({
      obj: objRulesRtgs,
      isMultiRelease,
    }),
    outsideServiceHours: !useWorkingHours("08:00", "13:45"),
  };

  const OptionDecider = () => {
    if (isPayroll || isVirtualAccount)
      return [ObjOptimized, ObjBIFast, ObjOnline, ObjSkn, ObjRtgs];
    // return [ObjRtgs, ObjSkn, ObjOnline, ObjOptimized, ObjBIFast];
    return [ObjBIFast, ObjOptimized, ObjOnline, ObjSkn, ObjRtgs];
  };

  // sort based on key => order problem
  const sortedOption = OptionDecider().sort((a, b) => b.key - a.key);

  // sort for available first, then unavailable
  const option = sortedOption.sort((a, b) =>
    a.isUnavailable === false ? -1 : 1
  );

  return {
    option,
    ObjOptimized,
    isPayroll,
    isVirtualAccount,
    objRulesBiFast,
    objRulesOptimized,
  };
};

export const FreeComponents = ({ dataHandlingFee }) => {
  const { all_transactions_total_fee, fee_removed } = dataHandlingFee || {};
  const feeDifference = all_transactions_total_fee - fee_removed;
  const isNoDiscount = fee_removed == 0;
  const isFree = feeDifference == 0;
  const isZeroFee = all_transactions_total_fee == 0 && fee_removed == 0; // << all danamon / all balance transfer multiple release

  const Layout = ({ children }) => (
    <div className="d-flex align-items-center justify-content-end">
      {children}
    </div>
  );

  if (isZeroFee)
    return (
      <Layout>
        <span className="font12 ml-2">Free</span>
      </Layout>
    );
  if (isNoDiscount)
    return (
      <Layout>
        <span className="font12 ml-2">
          IDR {formatCurrencyNoDecimal(all_transactions_total_fee)}
        </span>
      </Layout>
    );

  return (
    <div className="d-flex align-items-center justify-content-end">
      {isFree ? (
        <div
          style={{
            padding: "2px 4px",
            borderRadius: 4,
            backgroundColor: jackColors.greenB9,
          }}
        >
          <GothamMedium
            className="font10"
            style={{ color: jackColors.neutral900 }}
          >
            Free
          </GothamMedium>
        </div>
      ) : (
        <span className="font12 ml-2" style={{ color: jackColors.green06 }}>
          IDR {formatCurrencyNoDecimal(feeDifference)}
        </span>
      )}
      <span className="font12 ml-2" style={{ textDecoration: "line-through" }}>
        IDR {formatCurrencyNoDecimal(all_transactions_total_fee)}
      </span>
    </div>
  );
};

export const TransferFeeComponents = ({
  initialFee = false,
  currentFee = false,
  isFree = false,
  withDot = false,
}) => (
  <div className="d-flex align-items-center">
    {withDot && (
      <span
        className="mx-1"
        style={{ color: jackColors.neutral700, lineHeight: "12px" }}
      >
        •
      </span>
    )}
    {initialFee && (
      <GothamRegular
        className="font10 mr-1"
        style={{ color: jackColors.neutral700, textDecoration: "line-through" }}
      >
        IDR {formatCurrencyNoDecimal(initialFee)}
      </GothamRegular>
    )}
    {currentFee && (
      <GothamMedium className="font10">
        IDR {formatCurrencyNoDecimal(currentFee)}
      </GothamMedium>
    )}
    {isFree && (
      <CustomTooltip
        text={
          <>
            <SpanMedium>Exclusive benefit</SpanMedium> as you use Primary Bank
            Account for payment!
          </>
        }
        // popperClassName="white-arrow"
        style={{
          fontSize: "12px",
          lineHeight: "16px",
          width: 250,
          textAlign: "left",
          backgroundColor: jackColors.neutral900,
        }}
      >
        <div
          style={{
            padding: "2px 4px",
            borderRadius: 4,
            marginRight: 4,
            backgroundColor: jackColors.greenB9,
          }}
        >
          <GothamMedium
            className="font10"
            style={{ color: jackColors.neutral900 }}
          >
            Free
          </GothamMedium>
        </div>
      </CustomTooltip>
    )}
  </div>
);
