const ReimbursementImage = () => {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M176.67 9.62949V190.38C176.67 192.669 174.81 194.529 172.52 194.529H41.18C38.9 194.529 37.03 192.669 37.03 190.38V59.4795H23.79C23.53 59.4795 23.33 59.2695 23.33 59.0195V12.7995C23.33 8.79949 26.56 5.52949 30.56 5.48949C30.59 5.48949 30.62 5.47949 30.65 5.47949H172.53C174.82 5.47949 176.67 7.33949 176.67 9.62949Z"
        fill="white"
      />
      <path
        d="M34.2 6.40949C33.15 5.81949 31.94 5.47949 30.64 5.47949C30.61 5.47949 30.58 5.48949 30.55 5.48949C26.55 5.52949 23.32 8.79949 23.32 12.7995V59.0095C23.32 59.2595 23.52 59.4695 23.78 59.4695H37.5C37.75 59.4695 37.96 59.2595 37.96 59.0095V12.7995C37.97 10.0595 36.44 7.65949 34.2 6.40949ZM37.04 58.5495H24.25V12.7995C24.25 9.27949 27.11 6.40949 30.64 6.40949C34.16 6.40949 37.03 9.27949 37.03 12.7995V58.5495H37.04Z"
        fill="black"
      />
      <path
        d="M176.67 9.6293V190.379C176.67 192.669 174.81 194.529 172.52 194.529H41.18C38.9 194.529 37.03 192.669 37.03 190.379V12.7993C37.03 12.5493 37.24 12.3393 37.49 12.3393C37.75 12.3393 37.95 12.5493 37.95 12.7993V190.369C37.95 192.149 39.39 193.589 41.17 193.589H172.51C174.29 193.589 175.73 192.149 175.73 190.369V9.6293C175.73 7.8493 174.29 6.4093 172.51 6.4093H37.5C37.25 6.4093 37.04 6.1993 37.04 5.9493C37.04 5.6893 37.25 5.4893 37.5 5.4893H172.53C174.82 5.4793 176.67 7.3393 176.67 9.6293Z"
        fill="black"
      />
      <path
        d="M152.94 19.6194C141.8 15.1394 129.23 20.2294 124.92 30.9694C120.61 41.7094 126.16 54.0794 137.3 58.5594C140.05 59.6594 142.88 60.1894 145.65 60.1894C149.89 60.1894 154 58.9694 157.45 56.7294C160.9 54.4994 163.71 51.2494 165.33 47.2094C169.64 36.4594 164.08 24.0894 152.94 19.6194ZM164.47 46.8494C160.35 57.1094 148.32 61.9694 137.65 57.6894C126.99 53.4094 121.66 41.5694 125.78 31.3094C128.89 23.5794 136.48 18.9194 144.6 18.9194C147.25 18.9194 149.97 19.4194 152.6 20.4794C163.26 24.7594 168.59 36.5894 164.47 46.8494Z"
        fill="black"
      />
      <path
        d="M148.85 36.5191L144.43 35.8991C143.92 35.8391 143.63 35.4791 143.63 34.9391C143.64 34.4091 143.93 34.0591 144.44 34.0591L151.36 34.1091C151.95 34.1091 152.43 33.6391 152.44 33.0491L152.46 29.5191C152.46 28.9291 151.99 28.4391 151.4 28.4391L148.52 28.4191L148.53 26.8191C148.54 26.2291 148.06 25.7391 147.47 25.7391L143.16 25.7091C142.57 25.6991 142.08 26.1791 142.08 26.7691L142.07 28.4291C138.83 28.6991 136.92 30.4691 136.89 33.5691L136.87 36.6891C136.85 40.0191 138.65 41.2191 141.44 41.6591L145.9 42.3091C146.4 42.3791 146.67 42.6391 146.66 43.3291C146.66 43.7991 146.39 44.1891 145.89 44.1791L137.9 44.1191C137.31 44.1191 136.82 44.5891 136.82 45.1791L136.8 48.6591C136.79 49.2491 137.27 49.7291 137.86 49.7391L141.93 49.7591L141.92 51.3691C141.91 51.9591 142.39 52.4391 142.98 52.4491L147.29 52.4791C147.88 52.4791 148.36 51.9991 148.37 51.4191L148.38 49.7591C151.53 49.4591 153.42 47.6591 153.44 44.6191L153.46 41.4991C153.48 38.1591 151.64 36.9591 148.85 36.5191Z"
        fill="#B9FC00"
      />
      <path
        d="M37.97 5.93973C37.97 6.18973 37.77 6.39973 37.51 6.39973H30.65C30.39 6.39973 30.19 6.18973 30.19 5.93973C30.19 5.70973 30.34 5.52973 30.56 5.47973C30.59 5.47973 30.62 5.46973 30.65 5.46973H37.51C37.76 5.47973 37.97 5.67973 37.97 5.93973Z"
        fill="black"
      />
      <path d="M115.68 65.5H50.47V66.43H115.68V65.5Z" fill="black" />
      <path d="M162.84 86.6299H50.47V87.5599H162.84V86.6299Z" fill="black" />
      <path d="M162.84 107.75H50.47V108.68H162.84V107.75Z" fill="black" />
      <path d="M162.84 128.87H50.47V129.8H162.84V128.87Z" fill="black" />
      <path d="M162.84 149.989H50.47V150.919H162.84V149.989Z" fill="black" />
      <path d="M162.84 171.12H50.47V172.05H162.84V171.12Z" fill="black" />
    </svg>
  );
};

export default ReimbursementImage;
