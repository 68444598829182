import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { bankLists } from "../../assets/data";
import { toFormData } from "../../components/tools";
import { ToasterHook } from "../../contexts/ToasterContext";
import { apiBusiness, fetch, useMutation } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import { BankAccountFormModal, useAddBank } from "./bankAccountFormModal";
import { BankSelectionModal } from "./bankSelectionModal";
import {
  banksFormatter,
  FinalUploadReceiptModal,
} from "./finalUploadReceiptModal";
import { useUploadReceiptScreenType } from "./logics";

const getUploadUrl = ({ urlData }) => {
  const { query, push } = useRouter();
  const { id: idRaw, transactionId, type, wallet } = query;

  const { type: typeProps, id: idProps } = urlData || {};

  const isSinglePayment = typeProps === "single_payment";

  const { isBatchPayment, isTopUp, isLocalTransfer } =
    useUploadReceiptScreenType();

  const id = idRaw || transactionId || idProps;

  if (isLocalTransfer)
    return {
      url: `/local_transaction_batches/${id}`,
      redirection: () =>
        push({ pathname: `/local-transfer/${id}/success`, query: { type } }),
    };

  if (isTopUp)
    return {
      url: `/local_wallet/update_topup/${id}`,
      redirection: () =>
        push({ pathname: "/account/success", query: { id, type, wallet } }),
    };

  if (isSinglePayment)
    return {
      url: `/update_single_transaction/${id}`,
      redirection: () => push(`/success/?type=single_transaction&id=${id}`),
    };

  if (isBatchPayment)
    return {
      url: `/business_transactions/${id}`,
      redirection: () =>
        push({
          pathname: `/history`,
          query: {
            id,
            page_type_details: "batch_payment",
            page_type: "batch",
          },
        }),
    };

  return { redirection: () => {} };
};

export const SmartUploadReceiptModal = ({ isOpen, toggle, bank, urlData }) => {
  const { data, setData } = fetch({
    url: "/partner_bank_accounts?q[main_acc_eq]=false",
    formatter: banksFormatter,
  });
  // submits upload receipt
  const { query, push, pathname } = useRouter();
  const { reference_id, type } = query;

  const { successSnackBar } = ToasterHook();

  const { url, redirection } = getUploadUrl({ urlData });

  const afterSuccess = () => {
    const { loading, ...trackedQueries } = query;
    eventsTracker("upload_receipt", { ...trackedQueries });
    successSnackBar({
      msg: "File(s) successfully uploaded",
      showClose: true,
    });
    push({ query: { ...query, success: true } });
    toggle();
    setTimeout(redirection, 200);
  };

  const { mutation, loading } = useMutation({
    url,
    afterSuccess,
  });

  useEffect(() => {
    push({ query: { ...query, loading } });
  }, [loading]);

  const isTopup = type == "top_up";
  const isBatch = pathname.includes("batch-payment");

  const handleUpload = (val) => {
    const { upload_file, ...rest } = val;
    const { reference_id: partner_bank_account_id } = val;
    const transfer_to = bank;

    const { bank: bank_name } = bankLists.filter(
      ({ bank }) => bank == transfer_to
    )[0];

    const reference_type = `${val?.reference_type}-${bank_name}`;

    const result = toFormData(
      isBatch
        ? {
            batch_receipts: upload_file,
            partner_bank_account_id,
            transfer_to,
            ...rest,
          }
        : isTopup
        ? {
            image: upload_file,
            transfer_to,
            ...rest,
            reference_type,
            type: "topup_request",
          }
        : {
            receipts: upload_file,
            transfer_to,
            partner_bank_account_id,
            ...rest,
          }
    );
    mutation(result);
  };
  // submits upload receipt

  // adds bank
  const { loadingAddBank, onSubmitAddBank } = useAddBank({ setData });
  // adds bank

  // bank selection
  const onSubmit = (id) => push({ query: { ...query, reference_id: id } });
  // bank selection

  const hasSelectedBank = data.length == 1 || reference_id;

  if (hasSelectedBank)
    return (
      <FinalUploadReceiptModal
        isClose
        id={reference_id || data[0]?.id}
        isOpen={isOpen}
        toggle={toggle}
        handleUpload={handleUpload}
      />
    );

  const hasBank = data.length;

  if (hasBank)
    return (
      <BankSelectionModal
        isOpen={isOpen}
        toggle={toggle}
        data={data}
        setData={setData}
        onSubmit={onSubmit}
      />
    );

  return (
    <BankAccountFormModal
      isClose
      isOpen={isOpen}
      toggle={toggle}
      onSubmit={onSubmitAddBank}
      loading={loadingAddBank}
    />
  );
};
