import { get } from "lodash";
import { useRouter } from "next/router";
import noCase from "no-case";
import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import titleCase from "title-case";
import { colors, jackColors } from "../assets/colors";
import deleteIcon from "../assets/images/blue-icon-delete.svg";
import trashIcon from "../assets/images/trash.svg";
import { JackIcons } from "../assets/jackIcons/parent";
import { apiBusiness } from "../tools/api";
import { eventsTracker } from "../universalFunctions/events";
import { ConfirmationModal, DialogButton } from "./Buttons";
import { ButtonJack } from "./ButtonsJack/parent";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "./CardModal";
import { GetFlag } from "./inputs";
import LoadingSpinner from "./Loading";
import { RightModal } from "./Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "./Text";
import { WrapperModalButtons } from "../pageComponents/homeComponents/topup/components";
import { UniverseDataGenerator } from "../pageComponents/crossBorder/universeData";
import { toTitleValue } from "../pageComponents/crossBorder/single/recipient/dynamicChecker/logic";
import {
  fieldBankDetails,
  fieldRecipientDetails,
} from "../pageComponents/crossBorder/single/recipient/add/constant";

export const universeFunc = (string, item) =>
  get(item, `user_detail.universe_data.${string}`, "");

export const RecipientDetailsContent = ({ title, msg }) => {
  const isMsgEmpty = msg === "" || msg === undefined;
  return (
    <>
      {!isMsgEmpty && (
        <div className="mt-3">
          <GothamRegular style={{ fontSize: 14, color: colors.grey72 }}>
            {title}
          </GothamRegular>
          <GothamMedium style={{ fontSize: 14, color: colors.grey25 }}>
            {msg}
          </GothamMedium>
        </div>
      )}
    </>
  );
};

export const RecipientDetailsHeader = ({
  data,
  closeModal: afterDelete,
  loading: mainLoading,
}) => {
  const { recipient_type, country, id, name } = data || {};
  const iso = noCase(get(country, "iso", ""));
  const curr = get(country, "currency", "");
  const [loading, setLoading] = useState(false);
  const handleDelete = async (id) => {
    try {
      setLoading(true);
      await apiBusiness.delete(`/user_recipients/${id}`);
      afterDelete(id);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };
  if (mainLoading) {
    return <LoadingSpinner style={{ height: 75 }} />;
  }
  return (
    <div className="d-flex justify-content-between align-items-center mt-5">
      <div className="d-flex">
        <GetFlag iso={iso} width={32} height={32} />
        <div>
          <GothamMedium
            style={{ fontSize: 20, color: colors.grey25 }}
            className="mb-1"
          >
            {name}
          </GothamMedium>
          <GothamRegular
            style={{ fontSize: 14, color: colors.grey72 }}
            className="my-0"
          >
            {`${universeFunc("receiving_country", data) || curr} ${titleCase(
              recipient_type
            )} Account`}
          </GothamRegular>
        </div>
      </div>
      {loading ? (
        <FaSpinner className="icon-spin " size={20} />
      ) : (
        <DialogButton
          className="fake-button"
          woClassName
          onClick={() => {
            handleDelete(id);
          }}
        >
          <img src={trashIcon} className="darkhover" />
        </DialogButton>
      )}
    </div>
  );
};

export const NewRecipientDetailsModal = ({
  isOpen,
  data,
  recipients,
  handleClose,
  toggleConfirmDelete,
}) => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useRouter();
  const isCrossBorder = pathname.includes("cross-border/single");

  const {
    name: nameRaw,
    iso,
    id: idRecipient,
    numbers,
    bankText,
    user_detail,
  } = data || {};
  const { universe_data } = user_detail || {};
  const name = titleCase(nameRaw || "");

  const personalInformation = toTitleValue(
    universe_data,
    fieldRecipientDetails,
    false
  );
  const bankInformation = toTitleValue(universe_data, fieldBankDetails, true);

  const isOneRecipientsOnly = recipients?.length === 1;

  useEffect(() => {
    if (!isOpen) return;
    eventsTracker("open_recipient_details_modal", { id: idRecipient });
  }, [isOpen]);

  const components = [
    <WrapperModalButtons
      hideButtons={isOneRecipientsOnly}
      childrenButton={
        <ButtonJack
          type="outline"
          children="Delete"
          onClick={toggleConfirmDelete}
          style={{ width: "100%" }}
          isLoading={loading}
        />
      }
    >
      <GothamMedium className="font24" style={{ marginBottom: 32 }}>
        Recipient Details
      </GothamMedium>
      <div
        className="d-flex"
        style={{
          padding: "16px 12px",
          backgroundColor: jackColors.neutral500,
          borderRadius: 4,
          marginBottom: 32,
        }}
      >
        <GetFlag iso={iso} style={{ marginLeft: 0, width: 32, height: 32 }} />
        <div style={{ marginLeft: 16 }}>
          <GothamMedium className="font12">{name}</GothamMedium>
          <GothamRegular
            style={{
              color: jackColors.neutral700,
              fontSize: 12,
              lineHeight: "16px",
              marginTop: 2,
            }}
          >
            {bankText} {bankText && numbers && "-"} {numbers}
          </GothamRegular>
        </div>
      </div>
      <WrapperDetails title="Recipient Details" array={personalInformation} />
      <WrapperDetails title="Bank Details" array={bankInformation} />
    </WrapperModalButtons>,
  ];

  return <RightModal isOpen={isOpen} toggle={handleClose} array={components} />;
};

export const ActionDivSimple = ({ data, onClick }) => {
  const [onHover, setOnHover] = useState(false);
  return (
    <>
      {data.map((item, index) => {
        const { title, value, icon } = item;
        const hovered = onHover === index;
        const filterHover = hovered ? `brightness(93%)` : "none";
        return (
          <div
            key={index}
            onMouseEnter={() => setOnHover(index)}
            onMouseLeave={() => setOnHover(false)}
            onClick={() => {
              if (onClick) return onClick(item);
              if (href) return push({ pathname: href, query });
            }}
            className="text-center"
            style={{ cursor: "pointer", marginLeft: 20 }}
          >
            <img
              src={icon}
              style={{ width: 24, marginBottom: 4, filter: filterHover }}
            />
            <GothamMedium
              style={{
                fontSize: 10,
                color: colors.grey72,
                marginBottom: 0,
              }}
            >
              {title}
            </GothamMedium>
          </div>
        );
      })}
    </>
  );
};

const WrapperDetails = ({ title = "", array = [] }) => {
  return (
    <div style={{ marginBottom: 32 }}>
      <GothamMedium style={{ marginBottom: 16 }}>{title}</GothamMedium>
      <div className="borderE6" style={{ padding: 12 }}>
        {array?.map((item, index) => {
          const { title, value: valueRaw } = item || {};
          const isLast = index === array.length - 1;
          const styleText = { fontSize: 12, lineHeight: "16px" };
          const isName = title === "Name";
          const value = isName ? titleCase(valueRaw || "") : valueRaw;

          if (!valueRaw) return;
          return (
            <div key={index} style={{ marginBottom: isLast ? 0 : 16 }}>
              <GothamRegular
                style={{
                  color: jackColors.neutral800,
                  marginBottom: 4,
                  ...styleText,
                }}
              >
                {titleCase(title)}
              </GothamRegular>
              <GothamRegular
                style={{ color: jackColors.neutral900, ...styleText }}
              >
                {value}
              </GothamRegular>
            </div>
          );
        })}
      </div>
    </div>
  );
};
