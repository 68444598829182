import retryResubmitBooleans from "./retryResubmitBooleans";

const retryResubmitButtonTextDecider = ({
  user,
  drafter,
  releaser,
  warningType,
}) => {
  const { isRetry, isResubmit, isReminder } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  if (isReminder) {
    const drafterName = drafter?.name || drafter?.email || "-";
    const releaserName = releaser?.name || releaser?.email || "-";
    if (isRetry) return `Remind ${releaserName} to Retry`;
    if (isResubmit) return `Remind ${drafterName} to Resubmit`;
    return "-";
  }

  if (isRetry) return "Retry Transaction";
  if (isResubmit) return "Resubmit Transaction";
  return "-";
};

export default retryResubmitButtonTextDecider;
