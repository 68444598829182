import getState from "../helpers/getState";

const SorryImage = ({ state = "" }) => {
  const { isOverdue, isSuspended, isSuspendCard } = getState(state);

  if (isOverdue)
    return <img src="/images/overdue.svg" style={{ marginTop: 16 }} />;
  if (isSuspended)
    return <img src="suspended-image-1.svg" style={{ marginTop: 16 }} />;
  if (isSuspendCard)
    return <img src="suspended-card-image.svg" style={{ marginTop: 16 }} />;

  return <img src="suspended-image-1.svg" style={{ marginTop: 16 }} />;
};

export default SorryImage;
