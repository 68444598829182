//react template
import React, { useEffect, useState } from "react";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import { JackDanamonInfoComponent } from "./components/danamonAccountInfo";
import { GothamMedium, GothamRegular } from "components/Text";
import { jackColors } from "assets/colors";
import styles from "./addFunds.module.css";
import { JackIcons } from "assets/jackIcons/parent";
import { TopUpModal } from "components/Modals";
import useSubscriptionUsage from "tools/hooks/useSubscriptionUsage";

const AddFundsModal = ({ isOpen, toggle, defaultVA = false }) => {
  const { isUltimate, loadingSubscriptionUsage } = useSubscriptionUsage();
  // const { loadingSubscriptionUsage } = useSubscriptionUsage();
  // const isUltimate = true;
  const [isVAPocket, setIsVAPocket] = useState(false);
  const [selectedBankVA, setSelectedBankVA] = useState(false);

  useEffect(() => {
    if (isUltimate && !defaultVA) {
      setIsVAPocket(false);
    } else {
      setIsVAPocket(true);
    }
  }, [isUltimate, defaultVA]);

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      isLoading={loadingSubscriptionUsage}
      array={[
        <div>
          {!selectedBankVA && (
            <>
              <GothamMedium className="font24 mb-5">
                How to add funds?
              </GothamMedium>
              {isUltimate && (
                <ToggleAddFunds
                  isVAPocket={isVAPocket}
                  setIsVAPocket={setIsVAPocket}
                />
              )}
            </>
          )}
          {isVAPocket ? (
            <div style={{ margin: selectedBankVA ? 0 : "32px 0px" }}>
              {!selectedBankVA && (
                <>
                  <GothamMedium>Select payment method</GothamMedium>
                  <GothamRegular style={{ color: jackColors.neutral800 }}>
                    Choose how you would like to top up your balance.
                  </GothamRegular>
                </>
              )}
              <TopUpModal
                isShowBack
                noModal
                title=""
                noIcon
                woSubtitle
                // customCloseText={<></>}
                customHeader={<div style={{ marginTop: "-32px" }} />}
                isUltimate={isUltimate}
                onSelectBank={setSelectedBankVA}
              />
            </div>
          ) : (
            <JackDanamonInfoComponent />
          )}
        </div>,
      ]}
    />
  );
};

export default AddFundsModal;

const ToggleAddFunds = ({ isVAPocket, setIsVAPocket }) => {
  const color = (isActive) =>
    isActive ? jackColors.neutral900 : jackColors.neutral600;

  return (
    <div className={styles.customToggle}>
      <div className={styles.switchBackground}>
        <div
          className={styles.switchSlider}
          style={{ left: isVAPocket ? "50%" : "0" }}
        ></div>
        <div
          className={styles.label}
          style={{ right: "50%" }}
          onClick={(e) => {
            e.stopPropagation();
            setIsVAPocket(false);
          }}
        >
          <div style={{ display: "inline-flex", marginTop: 6 }}>
            <JackIcons
              name="bank"
              fill={color(!isVAPocket)}
              style={{ width: 16, height: 16 }}
            />
            <GothamMedium
              className="font12 ml-2"
              style={{ color: color(!isVAPocket) }}
            >
              Primary Bank Account
            </GothamMedium>
          </div>
        </div>
        <div
          className={styles.label}
          style={{ left: "50%" }}
          onClick={(e) => {
            e.stopPropagation();
            setIsVAPocket(true);
          }}
        >
          <div style={{ display: "inline-flex", marginTop: 6 }}>
            <JackIcons
              name="accountBalanceWallet"
              fill={color(isVAPocket)}
              style={{ width: 16, height: 16 }}
            />
            <GothamMedium
              className="font12 ml-2"
              style={{ color: color(isVAPocket) }}
            >
              VA Pocket
            </GothamMedium>
          </div>
        </div>
      </div>
    </div>
  );
};
