const bankProcedureDecider = (bank) => {
  const CIMB = {
    "OCTO Clicks": [
      "Login to OCTO Clicks",
      "Select “Pay Bills”",
      "Select your “Source Account” and “Payment Type”",
      "Select “Virtual Account”",
      "Input the 16-digit virtual account number listed above",
      "Input payment amount",
      "Check data on your screen",
      "Enter your mPIN and click “Submit”",
      "Payment succeed",
    ],
    "OCTO Mobile": [
      "Login to OCTO Mobile",
      "Select “Transfer” > “Transfer to Other CIMB Niaga Account”",
      "Select your source of funds (CASA or “Rekening Ponsel”)",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Check data on your screen",
      "Enter your OCTO mobile PIN",
      "Payment succeed",
    ],
    ATM: [
      "Select “Payment“ > “Continue” > “Virtual Account”",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Check the data on your screen. If correct, choose “OK” to continue",
      "Payment succeed",
    ],
  };

  const PERMATA = {
    PermataNet: [
      "Login to PermataNet",
      "Select “Pay Bills” > “Virtual Account”",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Check the data on your screen. If correct, choose “YES” to continue",
      "Enter your Response Code and click “Next”",
      "Payment succeed",
    ],
    "Permata Mobile": [
      "Login to Permata Mobile",
      "Select “Bill Payment” > “Virtual Account”",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Check data on your screen",
      "Enter your transaction authentication or token",
      "Payment succeed",
    ],
    ATM: [
      "Select “Other Transactions” > “Payment” > “Other Payment” > “Virtual Account”",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Select your source of funds",
      "Check the data on your screen. If correct, choose “YES” to continue",
      "Payment succeed",
    ],
  };

  const BRI = {
    BRImo: [
      "Login to BRImo",
      "Select “Payment” > “BRIVA”",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Select your source of funds",
      "Check data on your screen",
      "Enter your mobile banking PIN",
      "Payment succeed",
    ],
    // "M-Banking BRI": [
    //   "Login to BRI Mobile and select Mobile Banking BRI",
    //   "Select 'Info' menu",
    //   "Select 'BRIVA' menu",
    //   "Enter your Transfez BRI Virtual Account number",
    //   "Enter your Mobile/SMS Banking PIN",
    //   "You will get your payment notification via SMS",
    // ],
    ATM: [
      "Select “Other Transaction” > “Payment” > “Other Payment” > “BRIVA”",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Check the data on your screen. If correct, choose “YES” to continue",
      "Select your source of funds",
      "Payment succeed",
    ],
  };

  const MANDIRI = {
    "Livin by Mandiri": [
      "Login to Livin by Mandiri app",
      "Select “Payment”",
      "Search and select Transfez as service provider (Code: 89528)",
      "Input the 15-digit virtual account number",
      "Input payment amount",
      "Check data on your screen. If all the data are correct, choose “Next” > “Send Money”",
      "Input your PIN",
      "Payment succeeds",
    ],
    "Mandiri Internet Banking": [
      "Login to Mandiri Internet Banking",
      "Select “Payment” > “Multi-Payment”",
      "Select your preferred Source of Fund",
      "Search and select Transfez as service provider (Code: 89528)",
      "Input the 15-digit virtual account number",
      "Input payment amount",
      "Follow further instructions to complete the payment",
    ],
    ATM: [
      "Select “Payment” > “Other” > “Multi-Payment”",
      "Search and select Transfez as service provider (Code: 89528)",
      "Input the 15-digit virtual account number",
      "Input payment amount",
      "Check data on your screen. If correct, input “1” and choose “YES” to continue",
      "Payment succeeds",
    ],
    "Mandiri Cash Management (MCM)": [
      "Login to Mandiri Cash Management",
      "Select “Payment” > “Single Bill Payment”",
      "Select your preferred Source of Fund",
      "Input Transfez Biller code (89528)",
      "Input Jack Account Number then click “INQUIRY”",
      "Check the data on your screen. Ensure the recipient and total payment are correct",
      "Follow further instructions to complete the payment",
      "Payment succeed",
    ],
  };

  const BNI = {
    "BNI Internet Banking": [
      "Login to BNI Internet Banking",
      "Select “Transaction” > “Virtual Account Billing”",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Select your source of funds and click “Continue”",
      "Check data on your screen",
      "Enter your Token Authentication Code",
      "Payment succeed",
    ],
    "BNI Mobile Banking": [
      "Login to BNI Mobile Banking app",
      "Select “Transfer” > “Virtual Account Billing”",
      "Select your source of funds",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Check the data on your screen",
      "Enter your transaction password",
      "Payment succeed",
    ],
    ATM: [
      "Select “Other Transaction” > “Transfer”",
      "Select your source of funds",
      "Select “Virtual Account Billing”",
      "Input the 16-digit virtual account number",
      "Input payment amount",
      "Check the data on your screen. If correct, choose “YES” to continue",
      "Payment succeed",
    ],
  };

  const BTPN = {
    BTPN_ONLINE: [
      "Enter the following address: https://ibank.bni.co.id and click “enter”",
      "Insert your User ID and Password.",
      "Select VIRTUAL ACCOUNT",
      "Input 16 digit virtual account number XXXXXXXXXXXXXXXX.",
      "Select the type of account you are using to transfer. And press “CONTINUE”.",
      "Reconfirm the transaction",
      "Input your i-Banking token.",
      "Payment succeed.",
    ],
    BTPN_MOBILE: [
      "Access BNI Mobile Banking from your phone, and insert your user ID and password",
      "Select TRANSFER",
      "Select VIRTUAL ACCOUNT and then choose debit account",
      "Input 16 digit virtual account number XXXXXXXXXXXXXXXX, on the menu INPUT NEW",
      "Confirm your billing",
      "Input your password",
      "Payment succeed.",
    ],
    BTPN_ATM: [
      "Input your ATM card and PIN.",
      "Select OTHER TRANSACTION",
      "Select TRANSFER",
      "Select the type of account you are using to transfer (i.e from Savings account)",
      "Select VIRTUAL ACCOUNT",
      "Input 16 digit virtual account number XXXXXXXXXXXXXXXX.",
      "Input payment amount.",
      "Check your transaction again, then choose YES to proceed.",
      "Payment succeed.",
    ],
  };

  const BCA = {
    KlikBCA: [
      "Login to KlikBCA",
      "Select “Fund Transfer” > “Transfer to BCA Account”",
      "Input Transfez account number",
      "Input the exact transfer amount with the last 3 digits",
      "Check the data on your screen. Ensure the recipient's name is PT INDOKOALA REMITTANCE",
      `Input your KeyBCA Response and click "Submit"`,
    ],
    "BCA Mobile": [
      "Login to Mobile BCA",
      "Select “m-Transfer” > “Antar Rekening”",
      "Input Transfez account number",
      "Input the exact transfer amount with the last 3 digits",
      "Check data on your screen. Ensure the recipient's name is PT INDOKOALA REMITTANCE",
      "Input your m-BCA PIN and click “OK”",
    ],
    ATM: [
      "Select “Transfer”",
      "Input Transfez account number",
      "Input the exact transfer amount with the last 3 digits",
      "Check the data on your screen. Ensure the recipient's name is PT INDOKOALA REMITTANCE",
      "If correct, select YES",
    ],
  };

  const OTHER = {
    "Transfer from Other Banks": [
      "Open and sign-in to your preferred mobile/internet banking apps",
      "Select Transfer to other banks",
      "Enter the virtual account number",
      "Enter the amount you wish to top-up",
      "Follow the app instructions to complete the transaction",
      "Payment Succeed",
    ],
  };

  const OTHERMANUAL = {
    "Transfer from Other Banks": [
      "Open and sign-in to your preferred mobile/internet banking apps",
      "Select Transfer to other banks",
      "Enter the account number",
      "Enter the amount you wish to top-up",
      "Follow the app instructions to complete the transaction",
      "Payment Succeed",
    ],
  };

  const isBankBRI = "Bank BRI".toLowerCase() === bank.toLowerCase();
  const isBankPermata = "Bank Permata".toLowerCase() === bank.toLowerCase();
  const isBankCIMBNiaga =
    "Bank CIMB Niaga".toLowerCase() === bank.toLowerCase();
  const isBankMandiri = "Bank Mandiri".toLowerCase() === bank.toLowerCase();
  const isBankBNI = "Bank BNI".toLowerCase() === bank.toLowerCase();
  const isBankBTPN = "Bank BTPN".toLowerCase() === bank.toLowerCase();
  const isBankBCA = "Bank BCA".toLowerCase() === bank.toLowerCase();

  if (isBankBRI) return { ...BRI, ...OTHER };
  if (isBankPermata) return { ...PERMATA, ...OTHER };
  if (isBankCIMBNiaga) return { ...CIMB, ...OTHER };
  if (isBankMandiri) return { ...MANDIRI };
  if (isBankBNI) return BNI;
  if (isBankBTPN) return { ...BTPN, ...OTHER };
  if (isBankBCA) return { ...BCA, ...OTHERMANUAL };
};

export default bankProcedureDecider;
