import { format } from "date-fns";
import { isEmpty } from "lodash";
import { booleanState } from "../../../../components/Status";
import { GothamRegular, TextInlineBold } from "../../../../components/Text";
import {
  idrStringFormatter,
  pluralize,
} from "../../../invoiceComponents/data/formatter";
import { gmt7 } from "../../../reimbursementComponents/data";

export const dateUrlMaker = (date) => {
  if (!date || isEmpty(date)) return "";
  if (date == "delayed_payment")
    return "/scheduled_payments?q[state_eq]=waiting_for_payment&q[is_overdue_eq]=true";

  const inputDateString = date; // Your input date string

  // Set the time to 00:00:00
  const inputDate = new Date(gmt7(inputDateString));

  // Calculate one day before and after the input date
  const oneDayBefore = new Date(inputDate);
  //oneDayBefore.setDate(inputDate.getDate() - 1);

  const oneDayAfter = new Date(inputDate);
  oneDayAfter.setDate(inputDate.getDate() + 1);

  const formatDate = (date) => format(date, "yyyy-MM-dd");
  // Format the results as yyyy-MM-dd
  const before = formatDate(oneDayBefore);
  const after = formatDate(oneDayAfter);

  return `/scheduled_payments?q[date_and_time_lteq]=${after}&q[date_and_time_gteq]=${before}&q[state_eq]=waiting_for_payment&q[is_overdue_eq_any][]=nil&q[is_overdue_eq_any][]=false`;
};

const categoryDecider = (originator_type) => {
  const isLocal = originator_type == "LocalTransactionBatch";
  const isInvoice = originator_type == "InvoiceTransaction";
  const isReimbursement = originator_type == "Reimbursement";
  const isPayroll = originator_type == "Payroll";

  if (isLocal) return "local_disbursement_batch";
  if (isInvoice) return "invoice";
  if (isReimbursement) return "reimbursement";
  if (isPayroll) return "payroll";
  return "";
};

export const extractDetail = (originator) => {
  if (isEmpty(originator)) return {};
  const length = originator?.length;
  const totalTransactions = `${length} ${pluralize(length, "Transaction")}`;
  let totalAmount = 0;
  let totalFee = 0;

  let reimbursementFee = null;
  let invoiceFee = null;
  let localTransferFee = null;
  let payrollFee = null;

  originator?.forEach(({ amount, fee, category }) => {
    const { isInvoice, isReimbursement, isLocalDisbursementBatch, isPayroll } =
      booleanState(category);
    totalAmount += Number(amount);
    totalFee += Number(fee);

    if (isInvoice) invoiceFee += Number(fee);
    if (isReimbursement) reimbursementFee += Number(fee);
    if (isLocalDisbursementBatch) localTransferFee += Number(fee);
    if (isPayroll) payrollFee += Number(fee);
  });

  const array = [
    {
      leftText: "Reimbursement fee",
      rightText: reimbursementFee,
      isDot: true,
      isGreyText: true,
    },
    {
      leftText: "Payroll fee",
      rightText: payrollFee,
      isDot: true,
      isGreyText: true,
    },
    {
      leftText: "Bill Payment fee",
      rightText: invoiceFee,
      isDot: true,
      isGreyText: true,
    },
    {
      leftText: "Local Transfer fee",
      rightText: localTransferFee,
      isDot: true,
      isGreyText: true,
    },
  ]?.filter(({ rightText }) => rightText != null);

  return {
    totalTransactions,
    totalAmount,
    totalFee,
    totalPayment: totalAmount + totalFee,
    details: array,
  };
};

export const formatScheduleData = (data) => {
  if (isEmpty(data)) return [];
  return data?.map((childData) => {
    const {
      originator: oldOriginator,
      originator_type,
      total_amount_of_originator,
      total_fee_of_originator,
      total_pay_of_originator,
      ...rest
    } = childData;

    const category = categoryDecider(originator_type);
    const newOriginator = {
      ...oldOriginator,
      total_amount: total_pay_of_originator,
      amount: total_amount_of_originator,
      fee: total_fee_of_originator,
      category,
      type: category,
    };

    return {
      originator: newOriginator,
      originator_type,
      ...rest,
    };
  });
};

export const modalDataFormatter = (resData) => {
  const { data, ...restData } = resData;
  const newData = formatScheduleData(data);
  const originators = newData?.map(({ originator }) => originator);
  const defaultSummary = extractDetail(originators);
  return { data: newData, ...restData, defaultSummary };
};

export const bannerDate = (date) => {
  if (!date) return "";
  const newDate = new Date(gmt7(date));

  return format(newDate, "eeee, d MMMM yyyy");
};

export const bannerText = (date) => {
  if (!date || isEmpty(date)) return "";
  const isDelayed = date == "delayed_payment";
  if (isDelayed)
    return "You can either reschedule or pay immediately by selecting them. ";
  const dateFormat = bannerDate(date);
  // return `These transactions are scheduled for ${dateFormat}. You can either reschedule or pay immediately by selecting them. `;
  return (
    <GothamRegular>
      These transactions are scheduled for{" "}
      <TextInlineBold>{dateFormat}</TextInlineBold> You can either reschedule or
      pay immediately by selecting them.
    </GothamRegular>
  );
};
