import React, { useRef, useState } from "react";
import { deepUseIdsTable } from "../../../../components/Checkbox";
import { useModalHook } from "../../../../components/Modals";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import { useBooleanTable } from "./logic";
import prevIcon from "../../../../assets/images/arrow-prev-circle-pink.svg";
import nextIcon from "../../../../assets/images/arrow-next-circle-pink.svg";
import { TheadTablePayroll, TbodyTablePayroll } from "./components";
import { useEffect } from "react";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { windowDimension } from "../../../../components/tools";
import { dummyDataPayroll } from "../modal";
import { eventsTracker } from "../../../../universalFunctions/events";

export const TableCreatePayroll = ({
  data,
  style,
  setData,
  noBorder,
  isEmptyData,
  afterAddRow,
  headerArray,
  filteredData,
  showDeleteRow,
  funcPropsTable,
  isScreenDuplicate,
  isFirstScreenTable,
  isScreenIncomplete,
  setSelectedIdsDelete,
}) => {
  const [modalIndex, setModalIndex] = useState([]);
  const [indexDelete, setIndexDelete] = useState(false);

  const { successSnackBar, errorToaster } = ToasterHook();
  const { isOpen, close, toggle } = useModalHook();
  const {
    isOpen: isOpenDelete,
    close: closeDelete,
    toggle: toggleDelete,
  } = useModalHook();

  const refClick = useRef(null);
  const refTable = useRef(null);
  const { clientWidth } = refTable.current || {};
  const rightOffset = clientWidth - 1075;
  useClickOutside({ ref: refClick, clickOutside: close });

  const {
    handleClickItem,
    handleUncheckedAll,
    handleClickAll,
    isAllChecked,
    selectedIds,
  } = funcPropsTable || {};

  const isAnythingSelected = !!selectedIds?.length;

  const handleClick = (data, item, index, childIndex) => {
    const { name } = item;
    const { isAction, isNumber, isDelete } = useBooleanTable({ name });

    if (isNumber || isDelete) return;
    if (isAction) {
      setIndexDelete(index);
      toggleDelete();
      return;
    }
    return handleEdit(item, index, childIndex);
  };

  const handleEdit = (item, index, childIndex) => {
    toggle();
    setModalIndex([index, childIndex]);
  };

  const handleDelete = () => {
    const isOnlyOneData = data?.length === 1;
    const filteredData = data.filter((item, index) => index !== indexDelete);
    const deletedData = data.filter((_, index) => index == indexDelete);
    // toggleDelete();

    successSnackBar({
      title: "Deleted!",
      msg: "1 transaction has been deleted.",
    });
    handleUncheckedAll();
    eventsTracker("payroll_table_delete_single_recipient", deletedData);
    if (isOnlyOneData) return setData([dummyDataPayroll]);
    setData(filteredData);
  };

  useEffect(() => {
    setSelectedIdsDelete && setSelectedIdsDelete(selectedIds);
  }, [selectedIds]);

  const refScrollBottom = useRef(null);

  useEffect(() => {
    if (afterAddRow) {
      refScrollBottom?.current?.scrollTo({ behavior: "smooth", top: 9999999 });
    }
  }, [afterAddRow]);

  if (isEmptyData) return null;

  return (
    <div
      ref={refScrollBottom}
      style={{
        // paddingBottom: isScreenIncomplete ? 440 : 320,
        // overflowY: "scroll",
        position: "relative",
        ...style,
      }}
    >
      <table
        ref={refTable}
        style={{
          width: "100%",
          position: "relative",
          tableLayout: "fixed",
          wordBreak: "break-word",
          border: "1px solid #e6e6e8",
          backgroundColor: "white",
          borderCollapse: "separate",
          padding: "8px 8px 0px 8px",
          borderRadius: 8,
          // marginBottom: 200,
        }}
        cellSpacing={0}
      >
        <TheadTablePayroll
          headerArray={headerArray}
          isAllChecked={isAllChecked}
          showDeleteRow={showDeleteRow}
          handleClickAll={handleClickAll}
          renderFirst={isFirstScreenTable}
          isAnythingSelected={isAnythingSelected}
          isScreenIncomplete={isScreenIncomplete}
        />
        <TbodyTablePayroll
          close={close}
          handleClick={handleClick}
          toggleDelete={toggleDelete}
          confirmDelete={handleDelete}
          data={data}
          isOpen={isOpen}
          setData={setData}
          noBorder={noBorder}
          modalIndex={modalIndex}
          selectedIds={selectedIds}
          headerArray={headerArray}
          isOpenDelete={isOpenDelete}
          filteredData={filteredData}
          showDeleteRow={showDeleteRow}
          renderFirst={isFirstScreenTable}
          handleClickItem={handleClickItem}
          isScreenDuplicate={isScreenDuplicate}
          isAnythingSelected={isAnythingSelected}
          isScreenIncomplete={isScreenIncomplete}
        />
      </table>
    </div>
  );
};
