import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/colors";
import { GothamMedium } from "../Text";
import { windowDimension } from "../tools";
import { PageTogglerMultiDefault } from "./default";
import { PhonePageTogglerMulti } from "./phone";

export const pageTogglerHooks = (array, isDefaultValueLast, woDefaultValue) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const titleArray = array ? array : [];
  const { query } = useRouter();
  const { pageTogglerValue: pageTogglerValueParams } = query;

  const [pageTogglerValue, setPageTogglerValue] = useState(
    pageTogglerValueParams || titleArray[0]?.value
  );

  useEffect(() => {
    if (woDefaultValue) return;
    if (isFirstRender) return setIsFirstRender(false);
    setPageTogglerValue(
      array[isDefaultValueLast ? array.length - 1 : 0]?.value
    );
  }, [isFirstRender]);

  return { pageTogglerValue, setPageTogglerValue, titleArray };
};

export const PageTogglerMulti = ({
  selected,
  setSelected,
  titleArray,
  simple = false,
  withId,
  woAbsolute = false,
}) => {
  const { isTabOrPhone } = windowDimension();
  const { push } = useRouter();

  const onClick = (value) => {
    if (!withId) push({});
    setSelected && setSelected(value);
  };

  const props = { selected, titleArray, simple, onClick, woAbsolute };

  if (isTabOrPhone) return <PhonePageTogglerMulti {...props} />;

  return <PageTogglerMultiDefault {...props} />;
};

export const TypeSelectionPage = ({
  selected,
  setSelected,
  withId,
  titleArray = [],
}) => {
  const { push } = useRouter();
  return (
    <>
      <div className="d-flex align-items-center">
        <GothamMedium style={{ color: colors.grey72 }} className="m-0">
          Type
        </GothamMedium>
        {titleArray.map((item, index) => {
          const { label, value } = item;
          let labelFix = "";
          if (label === "Single") labelFix = "API";
          else labelFix = label;
          const isActive = selected == value;

          const onClick = () => {
            if (isActive) return;
            if (!withId) push({});
            setSelected && setSelected(value);
          };

          return (
            <GothamMedium
              onClick={onClick}
              key={index}
              style={{
                paddingBottom: 6,
                paddingTop: 6,
                paddingLeft: 12,
                paddingRight: 12,
                backgroundColor: isActive ? "#FDD7F5" : "transparent",
                color: isActive ? colors.pink : colors.greyC2,
                border: `1px solid ${isActive ? "#FDD7F5" : colors.greyC2}`,
                marginLeft: 8,
                borderRadius: 100,
              }}
              className="mb-0 hover"
            >
              {labelFix}
            </GothamMedium>
          );
        })}
      </div>
    </>
  );
};
