import { upperCase } from "lodash";
import { useRef, useState } from "react";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { deepRemoveDuplicates, removeDuplicates } from "../../tools";

// this function has 2 purposes:
// 1. merge current values with new values
// 2. removeDuplicate between them
const mergedFiles = (obj, array) => {
  // for single OR empty value
  const isObj = !Array.isArray(array);
  if (isObj) return obj;
  // for single OR empty value

  let objFromArray = {};
  const keys = Object.keys(obj);
  const lastKey = keys[keys.length - 1];

  array.forEach((item, index) => {
    const key = index + 1 + lastKey;
    objFromArray[key] = item;
  });

  const mergedObjAndArray = { ...obj, ...objFromArray };

  const mergedKeys = Object.keys(mergedObjAndArray);

  const resultArray = deepRemoveDuplicates(
    mergedKeys.map((key) => mergedObjAndArray[key]),
    "name"
  );

  let resultObj = {};

  resultArray.forEach((file, index) => {
    resultObj[index] = file;
  });

  return resultObj;
};

export const useSetsValueFileInput = ({
  name,
  useFormObj,
  maxFiles,
  multiple,
  maxSizeMB,
  fileTypes,
  errorWithToaster,
}) => {
  const ref = useRef();
  const { setValue, watch } = useFormObj;
  const value = watch(name);

  const [errors, setErrors] = useState({});
  const { errorSnackBar } = ToasterHook();

  const setsValue = (e) => {
    const files = mergedFiles(
      e?.dataTransfer?.files || e?.target?.files || {},
      value
    );
    if (!files) return;
    const keys = Object.keys(files);

    const isTooBig = !!keys.filter((key) => {
      const file = files[key];
      return file.size > maxSizeMB * 1024 * 1024;
    }).length;

    const isTooMuchFiles = !!(keys.length > maxFiles);

    const isTypeNotMatch = !!keys.filter((key) => {
      const file = files[key];

      if (file?.id) return false;

      return !fileTypes.includes(file.type);
    }).length;

    const array = keys.map((index) => files[index]);

    const hasError = isTooMuchFiles || isTooBig || isTypeNotMatch;

    if (hasError) {
      if (isTypeNotMatch) {
        errorSnackBar({
          msg: "Upload failed. Please provide a supported file type.",
          showClose: true,
        });
      }

      if (isTooBig) {
        errorSnackBar({
          msg: `File size exceeds maximum limit (${maxSizeMB} MB)`,
          showClose: true,
        });
      }

      if (isTooMuchFiles) {
        errorSnackBar({
          msg: `You’ve reached max ${maxFiles} files. Please remove any extra file(s) to continue.`,
          showClose: true,
        });
      }

      return setErrors({
        isTooMuchFiles,
        isTooBig,
        isTypeNotMatch,
      });
    }

    setErrors({});

    const valueDecider = () => {
      if (!multiple) return files[0];
      if (maxFiles) return array.slice(0, maxFiles);
      return array;
    };
    setValue(name, valueDecider());
  };

  const reset = () => {
    setValue(name, "");
    if (ref.current) ref.current.value = "";
  };

  return { setsValue, errors, reset, ref };
};

const fileTypesTranslator = (mime) => {
  const text = (mime || "").split("/")[1];

  const isDocx =
    text == "vnd.openxmlformats-officedocument.wordprocessingml.document";
  const isXlsx =
    text == "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const isXls = text == "vnd.ms-excel";

  if (isXlsx) return "XLSX";
  if (isDocx) return "DOCX";
  if (isXls) return "XLS";
  return upperCase(text);
};

export const fileTypesTranslatorArrayToString = (array) => {
  const result = removeDuplicates(array)
    .map((string, index) => {
      const isLast = index == array.length - 1;
      const isUseAnd = array.length >= 3;
      const type = fileTypesTranslator(string);
      if (isLast && isUseAnd) return ` and ${type}`;
      return ` ${type}`;
    })
    .join(",");
  return result;
};
