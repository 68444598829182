import { colors } from "../../../assets/colors";

export const customStatusColors = [
  {
    statuses: ["ACTIVE"],
    color: colors.green06,
    customTextLabel: "Active",
  },
  {
    statuses: ["TEMP_BLOCK"],
    color: colors.salmonFE,
    customTextLabel: "Temporary Blocked",
  },
  {
    statuses: ["REJECTED"],
    color: colors.salmonFE,
    customTextLabel: "Rejected",
  },
  {
    statuses: ["P_BLOCK"],
    color: colors.redDC,
    customTextLabel: "Permanent Blocked",
  },
  {
    statuses: ["SUSPENDED"],
    color: colors.redDC,
    customTextLabel: "Suspended",
  },
  {
    statuses: ["REQUEST_ACTIVE"],
    color: colors.blue60,
    customTextLabel: "Activating",
  },
  {
    statuses: ["REQUEST_NEW_CARD", "NOT_ACTIVE"],
    color: colors.yellowFF,
    customTextLabel: "On Request",
  },
  {
    statuses: ["REQUEST_TEMP_BLOCK", "REQUEST_P_BLOCK"],
    color: colors.yellowFF,
    customTextLabel: "Requesting Block",
  },
  {
    statuses: ["REQUEST_UNBLOCK"],
    color: colors.yellowFF,
    customTextLabel: "Request Unblock",
  },
  {
    statuses: ["DELIVERED"],
    color: colors.neutral600,
    customTextLabel: "Not Active",
  },
  {
    statuses: ["REQUEST_LIMIT"],
    color: colors.yellowFF,
    customTextLabel: "Request Limit",
  },
  {
    statuses: ["PENDING"],
    color: "#C3C3C8",
    customTextLabel: "Pending",
  },
];

export const customStatusColorsStatement = [
  {
    statuses: ["paid"],
    color: colors.green06,
    customTextLabel: "Paid",
  },
  {
    statuses: ["generated", "waiting_for_payment"],
    color: colors.redDC,
    customTextLabel: "Unpaid",
  },
];

export const customStatusColorsTransaction = [
  {
    statuses: ["approved"],
    color: colors.green06,
    customTextLabel: "Success",
  },
  {
    statuses: ["returned"],
    color: colors.green06,
    customTextLabel: "Returned",
  },
  {
    statuses: ["on_progress"],
    color: colors.yellowF4,
    customTextLabel: "In Progress",
  },
  {
    statuses: ["failed", "declined"],
    color: colors.redDC,
    customTextLabel: "Failed",
  },
  {
    statuses: ["cancelled"],
    color: colors.redDC,
    customTextLabel: "Cancelled",
  },
];

export const customStatusColorsBilling = [
  {
    statuses: ["waiting_for_payment"],
    color: colors.yellowFFC,
    customTextLabel: "Waiting for Payment",
  },
  {
    statuses: ["paid"],
    color: colors.green06,
    customTextLabel: "Success",
  },
  {
    statuses: ["scheduled"],
    color: colors.blue60,
    customTextLabel: "Scheduled",
  },
  {
    statuses: ["payment_delayed"],
    color: colors.orangeFF,
    customTextLabel: "Payment Delayed",
  },
];
