import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { jackColors } from "../../../assets/colors";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import { ProgressBarJack } from "../../../components/Stepper";
import { SummarySheet } from "../../../components/SummarySheet/parent";
import { DropdownLeftGenerator } from "../../../components/Table/dropdown/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { unformatCurrency } from "../../../components/tools";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useHeader } from "../../../contexts/Layout/parent";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { apiBusiness, useMutation } from "../../../tools/api";
import { DataValidationHeaderArray } from "./constants";
import { useMismatchOrInvalid, validationToArrayOfObject } from "./hooks";
import { LocalTransferValidationTable } from "./table/parent";
import ImgSkipModal from "../../../assets/images/ignore-validation.svg";
import { stepArrayLocalFunc } from "../../localTransferEnhancement/create/parent";
import { ModalConfirmationCloseLocal } from "../../localTransferEnhancement/create/modal";

const objConstructor = (array, title) => {
  const length = (array || []).length;
  if (!length) return null;
  return {
    title,
    msg: `${length} Transaction(s)`,
  };
};

const changeState = async (id, state) =>
  await apiBusiness.put(`local_transaction_batches/${id}/change_state`, {
    state,
  });

export const DataValidationPage = ({
  defaultValues: defaultValuesRaw,
  onUpdate,
  batchId,
  isPayroll = false,
}) => {
  const {
    mismatchdArr,
    setMismatchdArr,
    invalidArr,
    setInvalidArr,
    changedValues,
    defaultValues,
    array,
    deletedIds,
    mergedValuesArr,
  } = useMismatchOrInvalid(defaultValuesRaw);

  const { setHeader } = useHeader();
  const { isOpen: isOpenClose, toggle: toggleClose } = useModalHook();

  const { validationHeaderArray, validationInvalidHeaderArray } =
    DataValidationHeaderArray({ isPayroll });

  const { query, push } = useRouter();

  const isResubmit = query.action === "resubmit";

  useEffect(() => {
    if (batchId && !isResubmit) return;
    setHeader({
      type: "close",
      onCloseProps: () => toggleClose(),
      middleComponent: (
        <ProgressBarJack
          arrayStep={stepArrayLocalFunc(isResubmit ? 1 : 3, isResubmit)}
        />
      ),
    });
  }, []);

  const { isOpen: isOpenSkipModal, toggle: toggleSkipModal } = useModalHook();
  const [isOpen, setIsOpen] = useState(null);

  const isOpenProps = (name) => ({
    name,
    isOpen,
    setIsOpen,
  });

  const summarySheetHeight = 24 + 140;

  const invalidObj = objConstructor(invalidArr, "Account Not Found");
  const mismatchObj = objConstructor(mismatchdArr, "Mismatch Account Name");

  const { id: idQuery } = query;
  const id = batchId || idQuery;

  const defaultPush = (state) => {
    push({ query: { ...query, id: idQuery, state } });
    setHeader({});
  };

  const { mutation: skip, loading: loadingSkip } = useMutation({
    url: `local_transaction_batches/${id}/update_transaction`,
    afterSuccess: () => defaultPush("confirmation"),
  });

  const [loadingValidate, setLoadingValidate] = useState(false);

  const loading = loadingSkip || loadingValidate;

  const { user } = useGetAuth();

  const { errorToasterApi } = ToasterHook();

  const onSubmit = async (type) => {
    const isSkip = type == "skip";

    const updatePayload = validationToArrayOfObject(changedValues);

    if (isSkip) {
      const transactions = validationToArrayOfObject(defaultValues).map(
        ({ id }) => ({ local_transaction_id: id })
      );
      const payload = { invalid_action: "continue_anyway", transactions };
      return skip(payload);
    }

    try {
      setLoadingValidate(true);

      await changeState(id, "draft");

      // deletes
      if (deletedIds.length)
        await apiBusiness.put(
          `local_transaction_batches/${id}/bulk_trx_destroy`,
          { ids: deletedIds }
        );
      // deletes

      // updates
      if (onUpdate) {
        await onUpdate(
          validationToArrayOfObject(mergedValuesArr).filter(
            ({ id }) => !deletedIds.includes(id)
          )
        );
      } else {
        for (let i = 0; i < updatePayload.length; i++) {
          const item = updatePayload[i];

          const {
            id: itemId,
            name,
            account_number,
            name_check_result,
            bank,
          } = item;
          const defaultObj =
            array.filter(({ id: defaultId }) => defaultId == itemId)[0] || {};

          const { amount, notes, recipient } = defaultObj;

          const payload = {
            mismatched_action: "replace",
            transaction: {
              amount: unformatCurrency(amount),
              id: itemId,
              notes,
              local_recipient_attributes: {
                account_number,
                name,
                partner_id: user?.partner?.id,
                payer_id: bank,
                registered_name: name_check_result || null,
              },
              recipient: {
                email: recipient?.email || "",
                name,
              },
            },
          };
          await apiBusiness.put(
            `local_transaction_batches/${id}/update_single_transaction/${itemId}`,
            payload
          );
        }
      }
      // updates

      await changeState(id, "validate_recipient");

      if (type === "submit") defaultPush("validating");
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoadingValidate(false);
    }
  };

  useEffect(() => {
    if (mergedValuesArr.length) return;
    onSubmit();
  }, [mergedValuesArr.length]);

  const textDecider = () => {
    const youCanEdit = " You can choose to ignore or edit the details first.";

    const invalid = `There are still ${invalidArr.length} invalid account number`;

    const mismatchShort = `${mismatchdArr.length} mismatched name`;

    if (invalidArr.length && mismatchdArr.length)
      return `${invalid} and ${mismatchShort}. ${youCanEdit}`;

    if (invalidArr.length) return `${invalid}.${youCanEdit}`;

    if (mismatchdArr.length)
      return `There are still ${mismatchShort}.${youCanEdit}`;

    return "";
  };

  return (
    <div style={{ marginLeft: 32, marginRight: 32 }}>
      <GothamMedium className="font24" style={{ color: jackColors.black34 }}>
        Fix and validate bank details
      </GothamMedium>
      <GothamRegular
        style={{ marginTop: 8, marginBottom: 32, color: jackColors.grey6C }}
      >
        Some of the data is invalid. To continue, please edit the data by
        hovering over the field and clicking on it.
      </GothamRegular>
      <div
        className="d-flex"
        style={{
          flexDirection: "column",
          gap: 24,
          backgroundColor: "transparent",
        }}
      >
        <LocalTransferValidationTable
          data={invalidArr}
          setData={setInvalidArr}
          dropdownProps={isOpenProps("invalid")}
          headerArray={validationInvalidHeaderArray}
          leftComponent={
            <DropdownLeftGenerator
              fill={jackColors.redE7}
              backgroundColor={jackColors.redFF}
              number={invalidArr.length}
              title={"Account Not Found"}
            />
          }
          isInvalid
        />

        <LocalTransferValidationTable
          data={mismatchdArr}
          setData={setMismatchdArr}
          dropdownProps={isOpenProps("mismatch")}
          headerArray={validationHeaderArray}
          leftComponent={
            <DropdownLeftGenerator
              fill={jackColors.yellowFC}
              backgroundColor="#FFF6E6"
              number={mismatchdArr?.length}
              title={"Mismatch Account Name"}
            />
          }
        />
      </div>
      <div style={{ height: summarySheetHeight }} />
      <SummarySheet
        array={[invalidObj, mismatchObj]}
        buttons={[
          <ButtonJack
            type="outline"
            style={{ backgroundColor: "transparent", color: "white" }}
            onClick={toggleSkipModal}
            isLoading={loading}
          >
            Ignore & Continue
          </ButtonJack>,
          <ButtonJack onClick={() => onSubmit("submit")} isLoading={loading}>
            Re-validate
          </ButtonJack>,
        ]}
      />
      <ConfirmationModalJack
        modal={isOpenSkipModal}
        toggle={toggleSkipModal}
        onClick={() => onSubmit("skip")}
        buttonTextRight="Ignore & Continue"
        buttonTextLeft="Back to Edit"
        img={ImgSkipModal}
        text={textDecider()}
        title="Ignore invalid details?"
        customContentClassName="modal-border-radius4"
      />
      <ModalConfirmationCloseLocal
        toggle={toggleClose}
        isOpen={isOpenClose}
        product={isPayroll ? "payroll" : "local"}
        isAfterDraft
        batchId={batchId}
      />
    </div>
  );
};
