import { useRouter } from "next/router";
import { useState } from "react";
import {
  aDay,
  aWeek,
  dateConstructor,
  dateFilterConstructor,
  usePushQuery,
} from "../../../../../../../components/tools";
import { useRunQueryStringNames } from "../amount/logics";
import { TransactionFiltersRadioComponent } from "../amount/radio";
import {
  TransactionFilterDropdownLayout,
  transactionFilterLayoutProps,
} from "../layout";
import { DateInputQuery } from "./input";
import { queryDate } from "./logics";
import { addDays, endOfMonth, startOfMonth } from "date-fns";

export const TransactionFilterDate = (props) => {
  const { query } = useRouter();
  const { pushQuery } = usePushQuery();

  const minDate = queryDate(query, "from_date");
  const maxDate = queryDate(query, "to_date");

  const [bulletValue, setBulletValue] = useState("");

  const disabled = bulletValue !== "custom";

  const valueAndDesc = (time) => {
    const desc = dateConstructor(time);
    return { desc, value: dateFilterConstructor(time) };
  };

  const array = [
    { label: "Last 7 Days", ...valueAndDesc(aDay * 7) },
    { label: "Last 30 Days", ...valueAndDesc(aDay * 30) },
    { label: "Last 90 Days", ...valueAndDesc(aDay * 90) },
    { label: "Custom Date", value: "custom" },
  ];

  const resetQuery = () => {
    pushQuery("from_date", "");
    pushQuery("to_date", "");
  };

  const resetAll = () => {
    resetQuery();
    setBulletValue("");
  };

  const counter = minDate || maxDate ? 1 : 0;

  const { listener } = useRunQueryStringNames(["from_date", "to_date"]);

  return (
    <TransactionFilterDropdownLayout
      listener={listener}
      counter={counter}
      reset={bulletValue && resetAll}
      {...transactionFilterLayoutProps(props)}
      component={() => (
        <div>
          <TransactionFiltersRadioComponent
            array={array}
            currValue={bulletValue}
            onClick={(val) => {
              const isCustom = val == "custom";
              setBulletValue(String(val));
              if (isCustom) return resetQuery();
              pushQuery("from_date", val);
            }}
            name={"from_date"}
            style={{ gap: 12 }}
          />
          <div style={{ height: 12 }}></div>

          <DateInputQuery
            queryName="from_date"
            label="Start Date"
            maxDate={maxDate}
            placeholder="1 Jan 2022"
            disabled={disabled}
          />
          <DateInputQuery
            queryName="to_date"
            label="End Date"
            minDate={minDate}
            placeholder="31 Jan 2022"
            disabled={disabled}
          />
        </div>
      )}
    />
  );
};
export default TransactionFilterDate;

export const TransactionFilterDateCustom = (props) => {
  const { query } = useRouter();
  const { pushQuery } = usePushQuery();
  const { groupStartDate, isThisMonth } = props || {};

  const { sub_from_date, sub_to_date } = query || {};

  const minDate = startOfMonth(groupStartDate);
  const maxDate = endOfMonth(groupStartDate);

  const selectedStartDate = sub_from_date || minDate;

  const endDateMin = addDays(
    new Date(selectedStartDate),
    !!sub_from_date ? 1 : 0
  );

  const [bulletValue, setBulletValue] = useState("");

  const disabled = bulletValue !== "custom";

  const valueAndDesc = (time) => {
    const desc = dateConstructor(time);
    return { desc, value: dateFilterConstructor(time) };
  };

  const array = [
    // { label: "Last 30 Days", ...valueAndDesc(aDay * 30) },
    // { label: "Last 90 Days", ...valueAndDesc(aDay * 90) },
    { label: "Custom Date", value: "custom" },
  ];

  if (isThisMonth) {
    array.unshift({ label: "Last 7 Days", ...valueAndDesc(aDay * 7) });
  }

  const resetQuery = () => {
    pushQuery("sub_from_date", "");
    pushQuery("sub_to_date", "");
  };

  const resetAll = () => {
    resetQuery();
    setBulletValue("");
  };

  const counter = sub_from_date || sub_to_date ? 1 : 0;

  const { listener } = useRunQueryStringNames(["sub_from_date", "sub_to_date"]);

  return (
    <TransactionFilterDropdownLayout
      listener={listener}
      counter={counter}
      reset={bulletValue && resetAll}
      {...transactionFilterLayoutProps(props)}
      component={() => (
        <div>
          <TransactionFiltersRadioComponent
            array={array}
            currValue={bulletValue}
            onClick={(val) => {
              const isCustom = val == "custom";
              setBulletValue(String(val));
              if (isCustom) return resetQuery();
              pushQuery("sub_from_date", val);
            }}
            name={"from_date"}
            style={{ gap: 12 }}
          />
          <div style={{ height: 12 }}></div>

          <DateInputQuery
            queryName="sub_from_date"
            label="Start Date"
            maxDate={maxDate}
            minDate={minDate}
            placeholder="1 Jan 2022"
            disabled={disabled}
          />
          <DateInputQuery
            queryName="sub_to_date"
            label="End Date"
            minDate={endDateMin}
            maxDate={maxDate}
            placeholder="31 Jan 2022"
            disabled={disabled}
          />
        </div>
      )}
    />
  );
};
