import React from "react";
import { colors } from "../assets/colors";
import { GothamMedium } from "../components/Text";
import { nameInitial } from "./tools";
import mail from "../assets/images/mail-blue-rounded.svg";
import { upperCase } from "lodash";

export const NameAvatar = ({ children, is36 = false }) => {
  const getName = nameInitial(String(children));
  return (
    <div
      style={{
        color: colors.blue10,
        width: is36 ? 36 : 40,
        height: is36 ? 36 : 40,
        backgroundColor: colors.bluee1,
        borderRadius: 20,
      }}
      className="d-flex align-items-center justify-content-center"
    >
      {getName ? <GothamMedium>{getName}</GothamMedium> : <img src={mail} />}
    </div>
  );
};

export const Avatar = ({
  name = "",
  size = "large",
  type = "circle",
  mainIcon = null,
  bottomRightIcon = null,
  bottomRightStyle,
  customSize = null,
  style,
  textStyle,
}) => {
  const initialName = nameInitial(upperCase(name));
  const isSquare = type === "square";

  const getSize = () => {
    if (customSize) return customSize;

    switch (size) {
      case "extra-large":
        return {
          width: 60,
          height: 60,
          borderRadius: isSquare ? 8 : 120,
          fontSize: 20,
        };
      case "large":
        return {
          width: 40,
          height: 40,
          borderRadius: isSquare ? 8 : 100,
          fontSize: 16,
        };

      case "medium":
        return {
          width: 32,
          height: 32,
          borderRadius: isSquare ? 8 : 100,
          fontSize: 14,
        };

      case "small":
        return {
          width: 24,
          height: 24,
          borderRadius: isSquare ? 4 : 100,
          fontSize: 12,
        };
      case "smallest":
        return {
          width: 20,
          height: 20,
          borderRadius: isSquare ? 4 : 100,
          fontSize: 10,
        };
    }
  };

  const sizeStyle = getSize();
  const { fontSize } = sizeStyle || {};

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    backgroundColor: colors.neutral900,
    color: colors.neutral100,
    ...sizeStyle,
    ...style,
  };

  const bottomRightIconStyle = {
    position: "absolute",
    bottom: -4,
    right: -4,
    ...bottomRightStyle,
  };

  return (
    <div style={containerStyle}>
      {mainIcon ? (
        mainIcon
      ) : (
        <GothamMedium style={{ color: "white", fontSize, ...textStyle }}>
          {initialName}
        </GothamMedium>
      )}
      <div style={bottomRightIconStyle}>{bottomRightIcon}</div>
    </div>
  );
};
