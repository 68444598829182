import { JackIcons } from "../../../assets/jackIcons/parent";
import { CheckBox } from "../../../components/Checkbox";
import { useModalHook } from "../../../components/Modals";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import styles from "./style.module.css";

export const ChangeButton = ({ onClick }) => (
  <div
    style={{
      height: 20,
      width: 56,
      borderRadius: 4,
      border: "1px solid #BBBBC0",
      display: "grid",
      placeItems: "center",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <GothamRegular className="font12" style={{ color: "#6C6C71" }}>
      Change
    </GothamRegular>
  </div>
);

export const DefaultOption = ({
  onClick,
  flag,
  label,
  subTitle,
  iconName,
  woChevronIcon = true,
  children,
}) => {
  return (
    <div
      className={styles.option}
      onClick={onClick}
      style={{
        backgroundColor: flag && "#E6E6E8",
        justifyContent: "space-between",
      }}
    >
      <div className="d-flex" style={{ gap: 12 }}>
        <JackIcons
          name={iconName}
          fill="#343434"
          style={{ width: 20, height: 20 }}
        />

        <div className="d-flex-column">
          <GothamMedium className="font12" style={{ color: "#343434" }}>
            {label}
          </GothamMedium>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {subTitle}
          </GothamRegular>
        </div>
      </div>
      {!woChevronIcon && (
        <JackIcons
          name="arrow_forward"
          fill="#343434"
          style={{ width: 20, height: 20 }}
        />
      )}
      {children}
    </div>
  );
};

export const OptionWithButton = ({ topText, bottomText, onClickButton }) => {
  return (
    <div
      style={{
        height: 58,
        width: "100%",
        padding: "1px 8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#E6E6E8",
      }}
      className={styles.option}
    >
      <div>
        <div className="d-flex-column font12">
          <GothamBold style={{ fontSize: 12, color: "#343434" }}>
            {topText}
          </GothamBold>
          <GothamRegular className="font12">{bottomText}</GothamRegular>
        </div>
      </div>
      <ChangeButton onClick={onClickButton} />
    </div>
  );
};

export const DefaultTag = () => (
  <div className="d-flex align-items-center">
    <div
      style={{
        width: 8,
        height: 8,
        backgroundColor: "#B9FC00",
        borderRadius: 100,
        marginRight: 6,
      }}
    />
    <GothamRegular className="font10 text-black34">Default</GothamRegular>
  </div>
);

const optionProperties = {
  default: {
    title: "Pay Now",
    text: "Pay directly after you click “Approve & Pay”.",
    icon: "icon_paper_plane",
  },
  spesific_date: {
    title: "Spesific Date & Time",
    text: "Set payment date based on your preference.",
    icon: "magic_wand",
  },
  payment_cycle: {
    title: "Payment Cyle",
    text: "Select payment cycle created by admin.",
    icon: "payment_cycle_2",
  },
};

export const ModalOption = ({ children, isActive = false, type, onClick }) => {
  const { title, text, icon } = optionProperties?.[type] || {};
  return (
    <div
      style={{
        borderRadius: 8,
        border: `1px solid ${isActive ? "black" : "#e6e6e8"}`,
        height: isActive && children ? 136 : 72,
        // overflow: "hidden",
        transition: "height 150ms ease-in-out",
      }}
    >
      <div
        style={{
          padding: "16px 12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ marginBottom: 4, display: "flex", gap: 8 }}>
            <GothamMedium>{title}</GothamMedium>
            <JackIcons name={icon} fill="#343434" />
          </div>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            {text}
          </GothamRegular>
        </div>
        <CheckBox
          isActive={isActive}
          type="circle"
          onClick={(e) => {
            e.stopPropagation();
            if (isActive) return;
            onClick("type", type);
          }}
        />
      </div>
      {isActive && children && (
        <div
          style={{
            backgroundColor: "#f1f1f1",
            height: 62,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            padding: 12,
            borderTop: "1px solid #e6e6e8",
          }}
          className="smoothIn"
        >
          {children}
        </div>
      )}
    </div>
  );
};
