import axios from "axios";
import { AES, enc } from "crypto-js";
import { isEmpty, has } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useConstants } from "../contexts/ConstantsContext/parent";
import { ToasterContext, ToasterHook } from "../contexts/ToasterContext";
import { AuthContext, LSUserDeletion } from "contexts/AuthContext";
import { useRouter } from "next/router";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

const AESPassword = process.env.NEXT_PUBLIC_FINGERPRINT;
export const SecretKeyPayload = process.env.NEXT_PUBLIC_ENCRYPT_PAYLOAD;

const localStorageBooleans = () => {
  const storage = localStorage.getItem("env_type");
  const original = !storage;
  const prod = storage == "production";
  const internal = storage == "internal_production";
  const internal2 = storage == "internal_production_2";
  const internalIo = storage == "internal_production_io";
  const sandbox = storage == "sandbox";
  const staging = storage == "staging";
  const prod3 = storage == "prod3";
  const api2 = storage == "api2";

  return {
    original,
    prod,
    internal,
    internal2,
    internalIo,
    prod3,
    api2,
    sandbox,
    staging,
  };
};

export const getUrlEnv = (env = "") => {
  if (env == "production") return "https://api.enterprise.transfez.com/api/v1";
  if (env == "internal_production")
    return "https://inprod.api.business.transfez-jack-engineering.xyz/api/v1";
  if (env == "internal_production_2")
    return "https://api.enterprise.transfez.app/api/v1/";
  if (env == "internal_production_io")
    return "https://inprod.api.business.transfez.io/api/v1";
  if (env == "prod3") return "https://prod3.business.transfez.app/api/v1";
  if (env == "api2") return "https://api2.enterprise.transfez.app/api/v1";
  if (env == "sandbox")
    return "https://sandbox.api.business.transfez.tech/api/v1";
  if (env == "staging")
    return "https://staging.api.business.transfez.tech/api/v1";
  return process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL;
};

export const urlDecider = (defaultUrl) => {
  const isDefaultUrl =
    defaultUrl == process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL;

  const isDownload =
    defaultUrl == process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL;

  const {
    internal,
    sandbox,
    internal2,
    internalIo,
    prod,
    staging,
    prod3,
    api2,
  } = localStorageBooleans();

  const defaultUrlFunc = () => {
    if (prod) return getUrlEnv("production");
    if (internal) return getUrlEnv("internal_production");
    if (internal2) return getUrlEnv("internal_production_2");
    if (internalIo) return getUrlEnv("internal_production_io");

    if (prod3) return getUrlEnv("prod3");
    if (api2) return getUrlEnv("api2");

    if (sandbox) return getUrlEnv("sandbox");
    if (staging) return getUrlEnv("staging");

    return process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL;
  };

  if (isDefaultUrl) return defaultUrlFunc();

  if (isDownload) return defaultUrlFunc().replace("/api/v1", "");

  return defaultUrl;
};

export const encryptToken = (token) => {
  const safeToken = token || "";
  const encryptedToken = AES.encrypt(safeToken, AESPassword).toString();
  return encryptedToken;
};

export const decryptToken = (encryptedToken) => {
  if (!encryptedToken) return encryptedToken;
  const bytes = AES.decrypt(encryptedToken, AESPassword);
  const result = bytes.toString(enc.Utf8);
  return result;
};
const createObject = (baseURL, timeout = 30000) => ({
  baseURL,
  timeout,
  CancelToken: axios.CancelToken,
});

const base64UrlSafe = (base64) => {
  if (!base64) return "";
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

export const EncryptPin = (pin, secretKey) => {
  const encrypted = AES.encrypt(`${pin}`, secretKey).toString();
  const base64 = enc.Base64.stringify(enc.Utf8.parse(encrypted));
  return base64UrlSafe(base64);
};

export const EncryptPinPayload = (payloadRaw, secretKey, salus) => {
  const { pin, visitorId, new_pin, ...rest } = payloadRaw;

  let payload = pin
    ? { ...rest, pin: EncryptPin(pin, secretKey), salus }
    : { ...rest };

  if (new_pin) {
    const encryptedNewPin = EncryptPin(new_pin, secretKey);
    payload = { ...payload, new_pin: encryptedNewPin, salus };
  }

  return payload;
};

const defaultHeader = async (config) => {
  const getToken = localStorage.getItem("token");
  const decryptedToken = getToken ? decryptToken(getToken) : "";
  config.baseURL = urlDecider(config.baseURL);
  config.headers = { Authorization: decryptedToken };
  return config;
};

const defaultHeaderV2 = async (config) => {
  const getToken = localStorage.getItem("token");
  const decryptedToken = getToken ? decryptToken(getToken) : "";
  config.baseURL = changeV1toV2(urlDecider(config.baseURL));
  config.headers = { Authorization: decryptedToken };
  return config;
};

const pinLSKey = "pin-remaining-attempt";
const otpLSKey = "OTL";

const responseInterceptor = (res) => {
  const { config, status } = res || {};

  const { data: dataRaw } = config || {};
  const isFormData = dataRaw instanceof FormData;

  const isPinGet = String(config.url).includes("pin=");

  if (isPinGet) {
    localStorage.removeItem(pinLSKey);

    return res;
  }

  if (!dataRaw) return res;

  const isSuccess = status === 200;

  if (!isSuccess) return res;
  if (isFormData) return res;

  const data = JSON.parse(dataRaw) || {};

  const { pin, otp_code } = data;

  if (pin) localStorage.removeItem(pinLSKey);

  if (otp_code) localStorage.removeItem(otpLSKey);

  return res;
};

const responseFunc = (res) => {
  return responseInterceptor(res);
};

const changeV1toV2 = (url) => url?.replace("v1", "v2");

export const errorApiDecider = (error) => {
  const stringErr = String(error || "");
  const isNoInternet = stringErr.includes("Network Error");
  const isServerError = stringErr.includes("500");
  const invalidData = stringErr.includes("422");
  const isUnauthorized = stringErr.includes("401") || stringErr.includes("404");

  return { isNoInternet, isServerError, invalidData, isUnauthorized };
};

const errorInterceptor = (error) => {
  const defaultReturn = Promise.reject(error);

  const { data, url } = error?.config || {};
  const isFormData = data instanceof FormData;

  if (isFormData) return defaultReturn;

  const { pin: pinRaw, otp_code } = data ? JSON.parse(data) : {};

  const pin = pinRaw || String(url).includes("pin=");

  if (!pin && !otp_code) return defaultReturn;

  const parentResponseData = error?.response?.data;
  const attemptsCountObj = parentResponseData.hasOwnProperty(
    "remaining_attempt"
  )
    ? parentResponseData
    : parentResponseData?.data.hasOwnProperty("remaining_attempt")
    ? parentResponseData?.data
    : {};

  const attemptsCount = attemptsCountObj?.remaining_attempt;

  const hasAttemptProperty =
    attemptsCountObj.hasOwnProperty("remaining_attempt");

  const isSuspended = !attemptsCount && hasAttemptProperty;

  const LSKey = otp_code ? otpLSKey : pinLSKey;

  if (!isSuspended) {
    localStorage.setItem(LSKey, encryptToken(`${attemptsCount}`));
    return defaultReturn;
  }

  const isAuthorized = Boolean(localStorage.getItem("token"));

  if (isAuthorized) LSUserDeletion();

  const path = otp_code ? "/login" : "/login?maxInvalidPINReached=true";

  localStorage.removeItem(LSKey);

  window.location.href = path;

  return Promise.reject(error);
};

const errorFunc = (error) => {
  const { url } = error?.config || {};

  const isAuthenticate = url == "/authenticate";

  if (isAuthenticate) return Promise.reject(error);

  return errorInterceptor(error);
};

export const apiBusiness = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL)
);

apiBusiness.interceptors.request.use(defaultHeader);
apiBusiness.interceptors.response.use(responseFunc, errorFunc);

export const apiBusinessV2 = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL)
);

apiBusinessV2.interceptors.request.use(defaultHeaderV2);
apiBusinessV2.interceptors.response.use(responseFunc, errorFunc);

export const apiBusinessDownload = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL)
);

apiBusinessDownload.interceptors.request.use(defaultHeader);
apiBusinessDownload.interceptors.response.use(responseFunc, errorFunc);

export const apiBusinessWoHeaderDownload = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL)
);

apiBusinessWoHeaderDownload.interceptors.request.use(async (config) => {
  config.baseURL = urlDecider(config.baseURL);
  return config;
});
apiBusinessWoHeaderDownload.interceptors.response.use(responseFunc, errorFunc);

export const apiBusinessLong = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL, 300000)
);

apiBusinessLong.interceptors.request.use(defaultHeader);
apiBusinessLong.interceptors.response.use(responseFunc, errorFunc);

const strapi = axios.create(createObject("https://strapi.transfez.com"));

const apiDecider = (type) => {
  if (type == "axios") return axios;
  if (type == "strapi") return strapi;
  if (type == "long") return apiBusinessLong;
  if (type == "v2") return apiBusinessV2;
  return apiBusiness;
};

const isUserDetailsChecker = (path) => {
  // Define the regular expression pattern
  const pattern = /^\/business_users\/\d+$/;

  // Test the path against the pattern and return the result
  return pattern.test(path);
};

const getUser = async (users, id) => {
  const user = users.find((item) => item.id == id);

  if (!isEmpty(user)) return user;

  const { data } = await apiBusiness.get(
    `/business_users/all_users?q[id_eq]=${id}`
  );

  const deletedUser = data?.data[0];

  return deletedUser;
};

export const fetch = ({
  url,
  formatter = (val) => val,
  type,
  noToaster = true,
  defaultValue = [],
  woInit,
  defaultLoading,
  afterSuccess,
  params = {},
  errorHandler = () => {},
  isLocalApi,
  additionalFetch = () => {},
}) => {
  const { errorToasterApi } = useContext(ToasterContext);
  const [loading, setLoading] = useState(
    defaultLoading ? defaultLoading : !woInit
  );

  const { users } = useConstants();

  const [data, setData] = useState(defaultValue);

  const isUserDetails = isUserDetailsChecker(url);

  const getData = async () => {
    try {
      setLoading(true);
      const api = isLocalApi ? axios : apiDecider(type);

      const afterFetch = async (data, secondData) => {
        setData((prev) => formatter(data, prev, secondData));
        afterSuccess && (await afterSuccess(data, secondData));
      };

      const secondData = await additionalFetch();

      if (isUserDetails) {
        // needs to put this coz in `business_users/:id` if the user is deleted it cant access the deleted users
        if (isEmpty(users)) return setLoading(false);
        const id = url.split("/business_users/").pop();

        await afterFetch({ data: await getUser(users, id) }, secondData);
        return;
      }

      const { data } = await api.get(url, { params });

      // if there is user_id it automatically adds user obj here
      const user_id = data?.data?.user_id;

      if (user_id) {
        data.data = {
          ...(data?.data || {}),
          user: await getUser(users, user_id),
        };
      }

      await afterFetch(data, secondData);
    } catch (err) {
      errorHandler();
      const is401 = err?.response?.data?.status === 401;
      if (is401) {
        errorToasterApi(err);
        setLoading(false);
        return;
      }
      if (noToaster) return;
      errorToasterApi(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (woInit) return;
    if (isUserDetails) return;
    getData();
  }, []);

  useEffect(() => {
    if (!isUserDetails) return;
    if (isEmpty(users)) return;
    getData();
  }, [isEmpty(users)]);

  return { data, loading, refetch: getData, setData };
};

export const localFetch = async (url, params) => {
  if (process.env.NODE_ENV == "development") {
    url = `/api/${url}`;
  } else {
    url = `/.netlify/functions/${url}`;
  }
  const res = await axios.get(url, { params });
  return res;
};

export const useLocalApi = ({ url, params, formatter }) => {
  if (process.env.NODE_ENV == "development") {
    url = `/api/${url}`;
  } else {
    url = `/.netlify/functions/${url}`;
  }

  const res = fetch({ url, params, formatter, isLocalApi: true });
  return res;
};

const containsUnsafeString = (obj) => {
  const unsafeStrings = ["script", "<", ">"];
  const jsonString = String(JSON.stringify(obj)).toLowerCase();

  return unsafeStrings.every((unsafe) => jsonString.includes(unsafe));
};

const payloadMaker = async ({ payload, getData, method, url }) => {
  const isScript = containsUnsafeString(payload);
  if (isScript) return { errorCustom: "Internal server error (420)" };

  const { pin, data, new_pin } = payload || {};
  const { pin: nestedPin } = data || {};
  const hasNestedPin = nestedPin && method == "delete";

  const isPinModified = pin || hasNestedPin || new_pin;

  if (isPinModified) {
    let salus = payload?.salus;
    let visitorId = payload?.visitorId;

    const generateSalus = !(salus && visitorId);

    if (generateSalus) {
      const { visitorId: visitorIdGenerated, requestId } = await getData();

      salus = requestId;
      visitorId = visitorIdGenerated;
    }

    const secretKey = `${SecretKeyPayload}${visitorId}`;

    const toEncrypt = async (obj) =>
      await EncryptPinPayload(obj, secretKey, salus);

    if (!hasNestedPin) return await toEncrypt(payload);

    return { data: await toEncrypt(payload?.data) };
  }

  return payload;
};

export const useMutation = ({
  type,
  defaultValue = {},
  method = "put",
  url,
  resultFormatter = (data) => data,
  afterSuccess,
  withError = true,
  handleError,
  defaultLoading = false,
  beforeApiCall = () => {},
}) => {
  const [loading, setLoading] = useState(defaultLoading);
  const [result, setResult] = useState(defaultValue);

  const { errorToasterApi, errorToaster } = ToasterHook();
  const { getData } = useVisitorData(
    { ignoreCache: true },
    { immediate: false }
  );

  const mutation = async (value) => {
    try {
      setLoading(true);

      const payloadMutation = await payloadMaker({
        payload: value,
        getData,
        method,
        url,
      });

      if (payloadMutation?.errorCustom)
        return errorToaster("", payloadMutation?.errorCustom);

      const api = apiDecider(type);
      await beforeApiCall(payloadMutation);
      const response = await api[method](url, payloadMutation);
      const result = resultFormatter(response);
      const { token, ...payload } = payloadMutation || {};

      setResult((prev) => resultFormatter(response, prev, payload));

      typeof afterSuccess == "function" &&
        (await afterSuccess(result, payload, response, value));
    } catch (error) {
      handleError && handleError(error, setResult);

      if (withError) errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutation, result, setResult };
};

export const handleDownloadRaw = ({
  data,
  type = "application/pdf",
  fileName = "",
}) => {
  const file = new Blob([data], {
    type,
  });
  const targetUrl = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = targetUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode && link.parentNode.removeChild(link);
};

export const downloadApi = async ({
  type = "application/pdf",
  url,
  fileName = "Jack-Service-Agreement-and-Policy.pdf",
  jsonHandler,
  woV1 = false,
  errorToasterApi,
  afterSuccess,
  isAxios,
  woHeader = false,
  isPut = false,
  payloadPut,
  longTimeout = false,
}) => {
  try {
    const apiDecider = () => {
      if (woHeader) return apiBusinessWoHeaderDownload;
      if (isAxios) return axios;
      if (woV1) return apiBusinessDownload;
      if (longTimeout) return apiBusinessLong;
      return apiBusiness;
    };
    const apiDownload = apiDecider();

    const Method = () => {
      if (isPut)
        return apiDownload.put(url, payloadPut, {
          responseType: "blob",
        });
      return apiDownload.get(url, {
        responseType: "blob",
      });
    };

    const { data } = await Method();
    const { type: responseType } = data;
    const isJson = responseType == "application/json";

    if (isJson && jsonHandler) return jsonHandler(url, fileName);
    handleDownloadRaw({ data, fileName, type });

    afterSuccess && afterSuccess(url, fileName);
  } catch (err) {
    errorToasterApi && errorToasterApi(err);
  }
};

export const useDownload = () => {
  const [loading, setLoading] = useState(false);
  const { errorToasterApi } = ToasterHook();

  const handleDownload = async ({
    type = "application/pdf",
    url,
    fileName = "Service-Agreement-and-Policy.pdf",
    jsonHandler,
    afterSuccess,
    isPut,
    payloadPut,
    longTimeout,
  }) => {
    setLoading(true);
    await downloadApi({
      type,
      fileName,
      url,
      jsonHandler,
      errorToasterApi,
      afterSuccess,
      isPut,
      payloadPut,
      longTimeout,
    });
    setLoading(false);
  };

  return { handleDownload, loading };
};

const usePINandOTPError = () => {
  const setPINTriesLeft = (key, num) => localStorage.setItem(key, num);
  const { unauthorize } = useContext(AuthContext);
  const { push } = useRouter();
  const pinLSKey = "pin-remaining-attempt";
  const otpLSKey = "OTL";

  const changeTriesLeft = ({ triesLeft, isPinMutation, isOTPMutation }) => {
    const isSuspended = !triesLeft;
    const localStorageKey = isPinMutation
      ? pinLSKey
      : isOTPMutation
      ? otpLSKey
      : "";

    if (isSuspended) {
      const isAuthorized = Boolean(localStorage.getItem("token"));
      if (isAuthorized) unauthorize();
      const path = isPinMutation
        ? "/login?maxInvalidPINReached=true"
        : "/login";
      localStorage.removeItem(localStorageKey);
      push(path);
    }
    const stringNum = `${triesLeft}`;

    setPINTriesLeft(localStorageKey, encryptToken(stringNum));
  };

  const handleSuccessMutation = ({ isPinMutation, isOTPMutation }) => {
    if (isPinMutation) return localStorage.removeItem(pinLSKey);
    if (isOTPMutation) return localStorage.removeItem(otpLSKey);
  };

  return { changeTriesLeft, handleSuccessMutation };
};
