import { jackColors } from "assets/colors";
import { Fragment, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { isEmpty } from "lodash";
import { JackIcons } from "assets/jackIcons/parent";
import { GothamBold, GothamMedium, GothamRegular } from "components/Text";
import {
  CategoryIcon,
  ContainedCategoryIcon,
} from "pageComponents/categoryComponents/general/components";
import SimpleCenteredModal from "modals/SimpleCenteredModal";
import { useModalHook } from "components/Modals";
import { formatCurrencyNoDecimal } from "@tools";
import { useFetchCompanyBalance } from "pageComponents/moveBalanceComponents/logics";
import { FaSpinner } from "react-icons/fa";
import { useSubscriptionUsage } from "pageComponents/subscriptionsJackComponents/logics/general-hooks";
import AddFundsModal from "modals/smartActivityModal/jackDanamon/addFunds";
import {
  J2J_DANAMON_EVENT_CLICK_SELECT_AND_CONTINUE,
  J2J_DANAMON_EVENT_SELECT_PRIMARY_BANK_ACCOUNT,
  J2J_DANAMON_EVENT_SELECT_VA_POCKET,
} from "pageComponents/jackTransferComponents/constants";
import { eventsTracker } from "universalFunctions/events";

export const DANAMON_BALANCE_VALUE = "danamon_balance";
export const MAIN_BALANCE_VALUE = "main_balance";

const SourceOfFundsSelection = ({
  amountNeeded = 0,
  selectedSourceOfFund: selectedSourceOfFundProp = {},
  style = {},
  onSelect = () => {},
  listener = () => {},
}) => {
  const [modalSelectedSourceOfFund, setModalSelectedSourceOfFund] = useState(
    {}
  );

  const ref = useRef();

  const { isOpen, open, close } = useModalHook();
  const {
    isOpen: isOpenAddFundsModal,
    open: openAddFundsModal,
    close: closeAddFundsModal,
  } = useModalHook();

  const { isUltimate, loadingSubscriptionUsage: isLoadingSubscriptionUsage } =
    useSubscriptionUsage();
  const {
    danamonBalance: danamonBalanceRaw,
    vaPocketBalance,
    loading: isLoadingCompanyBalance,
  } = useFetchCompanyBalance();
  const danamonBalance = Number(danamonBalanceRaw);

  const isLoading = isLoadingCompanyBalance || isLoadingSubscriptionUsage;
  const isDisabled = !isUltimate && !isLoading;

  const isInsufficientDanamonBalance =
    amountNeeded > danamonBalance && !isLoadingCompanyBalance;
  const isInsufficientVAPocketBalance =
    amountNeeded > vaPocketBalance && !isLoadingCompanyBalance;

  const options = [
    {
      icon: "bank",
      label: "Primary Bank Account",
      sublabel: isInsufficientDanamonBalance
        ? `Insufficient: IDR ${formatCurrencyNoDecimal(danamonBalance)}`
        : `Current Balance: IDR ${formatCurrencyNoDecimal(danamonBalance)}`,
      value: DANAMON_BALANCE_VALUE,
      sublabelColor: isInsufficientDanamonBalance
        ? jackColors.redE7
        : jackColors.neutral700,
      sublabelOutside: `Primary Bank Account: IDR ${formatCurrencyNoDecimal(
        danamonBalance
      )}`,
    },
    {
      icon: "wallet",
      label: "VA Pocket",
      sublabel: isInsufficientVAPocketBalance
        ? `Insufficient: IDR ${formatCurrencyNoDecimal(vaPocketBalance)}`
        : `Current Balance: IDR ${formatCurrencyNoDecimal(vaPocketBalance)}`,
      value: MAIN_BALANCE_VALUE,
      sublabelColor: isInsufficientVAPocketBalance
        ? jackColors.redE7
        : jackColors.neutral700,
      sublabelOutside: `VA Pocket: IDR ${formatCurrencyNoDecimal(
        vaPocketBalance
      )}`,
    },
  ];

  const selectedSourceOfFund =
    options.find(
      (option) => option.value === selectedSourceOfFundProp?.value
    ) ?? {};

  const hasSourceOfFund = !isEmpty(selectedSourceOfFund);
  const isEnabled = !isDisabled && !isLoading;

  const handleSelect = (source) => {
    if (source?.value === DANAMON_BALANCE_VALUE) {
      eventsTracker(J2J_DANAMON_EVENT_SELECT_PRIMARY_BANK_ACCOUNT, {
        value: "source_of_funds_popup",
      });
      setModalSelectedSourceOfFund(source);
      return;
    }

    if (source?.value === MAIN_BALANCE_VALUE) {
      eventsTracker(J2J_DANAMON_EVENT_SELECT_VA_POCKET, {
        value: "source_of_funds_popup",
      });
      setModalSelectedSourceOfFund(source);
      return;
    }

    setModalSelectedSourceOfFund(source);
  };
  const handleCloseModal = () => {
    close();
    setModalSelectedSourceOfFund({});
  };
  const handleOpenModal = () => {
    if (isDisabled) return;
    open();
    setModalSelectedSourceOfFund(selectedSourceOfFund);
  };
  const handleContinue = () => {
    eventsTracker(J2J_DANAMON_EVENT_CLICK_SELECT_AND_CONTINUE, {
      value: "source_of_funds_popup",
    });
    onSelect(modalSelectedSourceOfFund);
    handleCloseModal();
  };

  useEffect(() => {
    if (isLoading) return;
    listener({
      isUltimate,
      danamonBalance,
      vaPocketBalance,
      isLoading,
    });
  }, [danamonBalance, vaPocketBalance, isUltimate, isLoading]);
  useEffect(() => {
    if (!isDisabled) return;
    onSelect(options[1]);
  }, [isDisabled]);

  return (
    <div
      ref={ref}
      className={styles["category-selection"]}
      style={{
        ...style,
        backgroundColor: isEnabled
          ? jackColors.neutral100
          : jackColors.neutral400,
      }}
    >
      <div
        className={styles["input"]}
        onClick={isOpen ? handleCloseModal : handleOpenModal}
        style={{
          cursor: isEnabled ? "pointer" : "not-allowed",
        }}
      >
        <div className={styles["left-section"]}>
          <ContainedCategoryIcon
            name={hasSourceOfFund ? selectedSourceOfFund?.icon : "money"}
            color={jackColors.neutral900}
            style={{ borderRadius: "100px" }}
          />
          <div
            className={styles["text-container"]}
            style={{ marginTop: !hasSourceOfFund && "-4px" }}
          >
            <div className={styles["title"]}>
              <GothamMedium
                className="font12"
                style={{ color: jackColors.neutral900 }}
              >
                Source of Funds
              </GothamMedium>
            </div>
            <GothamRegular
              woFontColor
              className="font12"
              style={{
                color: !hasSourceOfFund
                  ? jackColors.redE7
                  : jackColors.neutral600,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "280px",
              }}
            >
              {selectedSourceOfFund?.sublabelOutside ??
                "Please select your source of fund"}
            </GothamRegular>
          </div>
        </div>
        {isLoading && (
          <FaSpinner className="icon-spin" fill={jackColors.neutral700} />
        )}
        {isEnabled && (
          <JackIcons
            name={isOpen ? "close-circle" : "edit-outline"}
            fill={jackColors.neutral900}
          />
        )}
      </div>
      <SimpleCenteredModal
        isOpen={isOpen}
        toggle={handleCloseModal}
        modalClassName={styles.selectionModal}
        buttonText="Select & Continue"
        leftHeaderComponent={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <GothamBold
              woFontSize
              className="font20"
              style={{ color: jackColors.neutral900, margin: 0 }}
            >
              Select source of fund
            </GothamBold>
          </div>
        }
        onClick={handleContinue}
        isDisabled={isEmpty(modalSelectedSourceOfFund)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "32px 20px",
          }}
        >
          {options.map((option) => {
            const isSelected = modalSelectedSourceOfFund.value === option.value;

            return (
              <div
                style={{
                  height: "72px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: `1px solid ${jackColors.neutral500}`,
                  borderRadius: "8px",
                  padding: "16px",
                  cursor: "pointer",
                  backgroundColor: isSelected
                    ? jackColors.neutral400
                    : jackColors.neutral100,
                }}
                onClick={() => handleSelect(option)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      width: "32px",
                      height: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: jackColors.neutral900,
                      borderRadius: "8px",
                    }}
                  >
                    <CategoryIcon
                      name={option.icon}
                      fill="white"
                      style={{ scale: "0.8" }}
                    />
                  </div>
                  <div>
                    <GothamMedium style={{ color: jackColors.neutral900 }}>
                      {option.label}
                    </GothamMedium>
                    <GothamRegular
                      woFontColor
                      className="font12"
                      style={{
                        color: option.sublabelColor,
                        marginTop: "4px",
                      }}
                    >
                      {option.sublabel}
                    </GothamRegular>
                  </div>
                </div>
                <div
                  style={{
                    width: "115px",
                    height: "32px",
                    display: "flex",
                    alignItems: "center",
                    border: `1px solid ${jackColors.neutral500}`,
                    borderRadius: "8px",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    backgroundColor: jackColors.neutral100,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openAddFundsModal();
                  }}
                >
                  <JackIcons
                    name="plus-circle-outline"
                    fill={jackColors.neutral900}
                    style={{ scale: "0.8" }}
                  />
                  <GothamMedium
                    style={{
                      color: jackColors.neutral900,
                      marginLeft: "4px",
                    }}
                  >
                    Add Funds
                  </GothamMedium>
                </div>
              </div>
            );
          })}
        </div>
      </SimpleCenteredModal>

      <AddFundsModal isOpen={isOpenAddFundsModal} toggle={closeAddFundsModal} />
    </div>
  );
};

export default SourceOfFundsSelection;
