import React from "react";
import { colors } from "../../assets/colors";
import { GothamMedium } from "../Text";

export const PhonePageTogglerMulti = ({
  selected,
  titleArray = [],
  onClick: onClickProps,
}) => (
  <div className="d-flex w-100">
    {titleArray.map(({ label, value }, index) => {
      const isActive = selected == value;

      const onClick = () => {
        if (isActive) return;
        onClickProps(value);
      };

      const width = `${(1 / titleArray.length) * 100}%`;

      return (
        <div
          key={index}
          style={{
            width,
            borderBottom: `1px solid ${colors.greyea}`,
          }}
          className="d-flex justify-content-center pinkhover pt-2 mt-2"
          onClick={onClick}
        >
          <GothamMedium
            style={{
              width: "100%",
              textAlign: "center",
              color: isActive ? colors.pink : colors.blue,
              borderBottom: `${isActive ? "4px" : "0px"} solid ${
                colors.lightPink
              }`,
              transition: "all 0.2s linear",
            }}
            className="font16 pb-2"
          >
            {label}
          </GothamMedium>
        </div>
      );
    })}
  </div>
);
