import { noCase } from "change-case";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { jackColors } from "../../../assets/colors";
import { deepUseIdsTable } from "../../../components/Checkbox";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useHeader } from "../../../contexts/Layout/parent";
import { ProgressBarComponent } from "../../crossBorderComponents";
import imgBack from "../../../assets/images/back-previous-page.svg";
import imgDelete from "../../../assets/images/delete-trash-grey.svg";
import imgDuplicateModal from "../../../assets/images/duplicate-recipient.svg";
import {
  FloatingButtonPayroll,
  MainTitlePayroll,
  SearchBarAndButton,
} from "./components";
import {
  addDataFormatted,
  checkerEmptyWhenUpload,
  dataFormatterFloatingButton,
  DefaultValuesForTable,
  FormatterOptionsBank,
  OnSubmitPayrollCreateFormatter,
} from "./formatter";
import {
  PayrollFuncMix,
  checkerEmptyWhenUploadPayroll,
  filterDataByIncomplete,
  removeAllEmptyFieldPayroll,
} from "./logic";
import TabMenu from "../../../components/TabMenu/parent";
import { HeaderDataTable } from "./table/data";
import { TableCreatePayroll } from "./table/parent";
import { usePageTogglerPayroll } from "./table/toggler";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { useModalHook } from "../../../components/Modals";
import { dummyDataPayroll } from "./modal";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { eventsTracker } from "../../../universalFunctions/events";
import {
  CheckerDataDuplicate,
  DeleteStringDecider,
  isAllDataCompleted,
} from "../../localTransferEnhancement/create/table/logic";
import { MainTitleLocalTable } from "../../localTransferEnhancement/create/table/components";
import {
  EmptyScreenSearchImages,
  EmptyStateSearchImages,
} from "../../crossBorder/components";
import { ProgressBarJack } from "../../../components/Stepper";
import { stepArrayFunc } from "../../payrollCreateComponents/loop/validation/parent";
import { ModalConfirmationCloseLocal } from "../../localTransferEnhancement/create/modal";
import CategorySelection from "../../categoryComponents/general/components/CategorySelection";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../../categoryComponents/general/hooks";
import ResubmitBanner from "../../../modals/smartActivityModal/retry-resubmit/components/ResubmitBanner";

export const CreatePayroll = ({
  defaultValues = [],
  firstValues,
  onSubmit: onSubmitProps = () => {},
  isEmptyTable,
  loading,
}) => {
  const { name: payrollNameBatch } = defaultValues?.batch || {};
  const { payroll_name: payrollName } = firstValues || {};
  const payroll_name = payrollName || payrollNameBatch;
  const { setHeader } = useHeader();
  const { successSnackBar, errorSnackBar } = ToasterHook();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [payrollTitle, setPayrollTitle] = useState("");
  const [afterAddRow, setAfterAddRow] = useState(false);
  const [isFirstScreenTable, setIsFirstScreenTable] = useState(true);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [isErrorCategory, setIsErrorCategory] = useState(false);
  const [isMoveToDraft, setIsMoveToDraft] = useState(false);
  const [selectedIdsDelete, setSelectedIdsDelete] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { isOpen: isOpenBackModal, toggle: toggleOpenBackModal } =
    useModalHook();
  const { isOpen: isOpenDeleteModal, toggle: toggleDeleteModal } =
    useModalHook();
  const { isOpen: isOpenCloseModal, toggle: toggleCloseModal } = useModalHook();
  const { isOpen: isOpenDuplicate, toggle: toggleDuplicate } = useModalHook();

  const { push, query } = useRouter();

  const isResubmit = query.action === "resubmit";

  useEffect(() => {
    setHeader({
      type: "close",
      middleComponent: (
        <ProgressBarJack
          arrayStep={stepArrayFunc(isResubmit ? 0 : 2, isResubmit)}
        />
      ),
      onCloseProps: () => toggleCloseModal(),
    });
  }, []);

  const { headerArray, tableBodyRef } = HeaderDataTable();
  const { localTransferBanks: optionsBank, idBanks } = useConstants();
  const formattedOptionsBank = FormatterOptionsBank({ options: idBanks }); // -> before using optionsBank but somehow the option bank was filtered
  const formattedOptionsBankAddRecipientList = FormatterOptionsBank({
    options: optionsBank,
  }); // -> For Add Recipient List
  const dataFloatingButton = dataFormatterFloatingButton({ data });
  const { isKick } = PayrollFuncMix(data);
  const { id } = query || {};
  const isHasId = Boolean(id);
  const refForScroll = useRef();

  const {
    setScreen,
    isScreenIncomplete,
    dataIncompleteLength,
    isEmptyIncompleteData,
    isScreenDuplicate,
    dataDuplicateLength,
    isEmptyDuplicateData,
  } = usePageTogglerPayroll({ data });

  const {
    handleClickItem,
    handleUncheckedAll,
    handleClickAll,
    isAllChecked,
    selectedIds,
    selectedAmount,
  } = deepUseIdsTable(data);

  const funcPropsTable = {
    handleClickItem,
    handleUncheckedAll,
    handleClickAll,
    selectedAmount,
    isAllChecked,
    selectedIds,
  };

  const handleDeleteSelected = () => {
    const filteredData = data.filter(
      (_, index) => selectedIdsDelete.indexOf(index) === -1
    );
    handleUncheckedAll();
    successSnackBar({
      msg: DeleteStringDecider(selectedAmount),
      showClose: true,
    });
    setData(isAllChecked ? [dummyDataPayroll] : filteredData);
    eventsTracker("payroll_submit_delete_recipient");
  };

  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();
  const isMissingRequiredCategory = Boolean(
    isRequiredCategory && !selectedCategory && hasCategoryList
  );

  const onClick = ({ type }) => {
    const isValidate = type === "validate";
    const isDraft = type === "draft";
    const isBack = type === "back";
    const isBackModal = type === "back_modal";
    const isResubmitBack = type === "resubmit_back";
    const isOpenModalDelete = type === "open_modal_delete";
    const isSubmitDelete = type === "submit_delete";
    const isConfirmDuplicate = type === "confirm_duplicate";

    if (isResubmitBack) {
      localStorage.setItem("autoOpenTrxOrigin", query.id);
      return push({
        pathname: "/transactions",
      });
    }

    const { payroll_name, ...rest } = firstValues || {};
    const batchValues = {
      payroll_name: payrollTitle,
      ...rest,
    };

    if (isSubmitDelete) return handleDeleteSelected();
    if (isOpenModalDelete) return toggleDeleteModal();

    if (isBack) return push("/payroll/create");
    if (isBackModal) return toggleOpenBackModal();

    if (isValidate || isDraft || isConfirmDuplicate) {
      if (isMissingRequiredCategory) {
        return setIsErrorCategory(true);
      }
      const dataChecker = checkerEmptyWhenUploadPayroll({ data });
      const dataClean = removeAllEmptyFieldPayroll({ data: dataChecker });
      const isEmptyData = dataClean.length === 0;
      const dataFinal = !!dataClean.length ? dataClean : [dummyDataPayroll];
      if (isEmptyData) {
        return setData(dataFinal);
      }
      setData(dataFinal);

      const evenstTrack = isValidate
        ? "payroll_table_submit_validate"
        : "payroll_table_save_as_draft";

      const isAllCompleted = isAllDataCompleted({ data: dataClean });
      if (isAllCompleted && (isEmptyDuplicateData || isConfirmDuplicate)) {
        const finalData = OnSubmitPayrollCreateFormatter(dataClean);
        const values = finalData.map((data) => {
          if (selectedCategory) {
            return { ...data, category_id: selectedCategory?.id };
          }
          return data;
        });
        // return;
        onSubmitProps({
          type: "create",
          values: isResubmit
            ? values?.map((value) => {
                delete value.id;
                return value;
              })
            : values,
          batchValues,
          state: isDraft || isMoveToDraft ? "draft" : "validate",
        });
        return eventsTracker(evenstTrack, finalData);
      }
      if (!isEmptyIncompleteData) return setScreen("incomplete");
      if (!isEmptyDuplicateData) {
        setIsMoveToDraft(isDraft);
        return toggleDuplicate();
      }
      setScreen("incomplete");
    }
  };

  const filteredData = data?.filter((item, index) => {
    if (!item) return;
    // index 2 for data name
    let str = "";
    for (let i = 0; i < item?.length; i++) {
      str += item[i]?.value;
    }
    const filtered = str?.toLowerCase().includes(search.toLowerCase());
    return filtered;
  });

  const isEmptyData = filteredData?.length === 0;

  useEffect(() => {
    setPayrollTitle(payroll_name);
  }, [payroll_name]);

  useEffect(() => {
    if (isKick) return push("/payroll/create");
  }, [isKick]);

  useEffect(() => {
    if (isEmptyTable) return setData(defaultValues);

    const dataFormatted = DefaultValuesForTable({
      defaultValues,
      headerArray,
      option: formattedOptionsBank,
      isHasId,
    });
    const FinalData = checkerEmptyWhenUpload({ data: dataFormatted });
    setData(FinalData);
  }, [defaultValues]);

  useEffect(() => {
    if (!afterAddRow) return;
    setTimeout(() => {
      refForScroll?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }, 500);
    setAfterAddRow(false);
  }, [afterAddRow]);

  const { categories } = useConstants();

  useEffect(() => {
    if (defaultValues?.category_id_1) {
      const category = (categories ?? []).find(
        ({ id }) => id === defaultValues?.category_id_1
      );
      setSelectedCategory(category);
      return;
    }

    setSelectedCategory(firstValues?.category);
  }, [defaultValues, firstValues]);

  useEffect(() => {
    if (!!data.length) {
      const dataProcessed = CheckerDataDuplicate({ data });
      setData(dataProcessed);
    }
  }, [data]);

  return (
    <div style={{ margin: "0px 32px 0px 32px", position: "relative" }}>
      <div>
        {isResubmit && <ResubmitBanner batchName={payrollTitle} />}
        <CategorySelection
          isSimple
          isError={isErrorCategory}
          selectedCategory={selectedCategory ?? {}}
          onSelect={(category) => {
            setIsErrorCategory(false);
            setSelectedCategory(category);
          }}
        />
        {!isResubmit && (
          <MainTitleLocalTable
            title={payrollTitle}
            isScreenDuplicate={isScreenDuplicate}
            isScreenIncomplete={isScreenIncomplete}
            dataDuplicateLength={dataDuplicateLength}
            dataIncompleteLength={dataIncompleteLength}
            onClick={onClick}
            onChangeTitle={(title) => setPayrollTitle(title)}
            loading={loading}
            isPayroll
          />
        )}
        <TabMenu
          componentsAboveScreen={
            <div>
              <SearchBarAndButton
                data={data}
                search={search}
                setAfterAdd={(e) => setAfterAddRow(e)}
                headerArray={headerArray}
                filteredData={filteredData}
                isAllChecked={isAllChecked}
                showDeleteRow={showDeleteRow}
                selectedIdsDelete={selectedIdsDelete}
                isScreenDuplicate={isScreenDuplicate}
                isScreenIncomplete={isScreenIncomplete}
                formattedOptionsBank={formattedOptionsBankAddRecipientList}
                setData={setData}
                onChange={(e) => setSearch(e)}
                handleUncheckedAll={handleUncheckedAll}
                setShowDeleteRow={() => setShowDeleteRow((p) => !p)}
              />
              {isEmptyData && <EmptyStateSearchImages />}
              <div ref={tableBodyRef} style={{ position: "relative" }}>
                <TableCreatePayroll
                  setData={setData}
                  setSelectedIdsDelete={setSelectedIdsDelete}
                  headerArray={headerArray}
                  afterAddRow={afterAddRow}
                  isEmptyData={isEmptyData}
                  filteredData={filteredData}
                  showDeleteRow={showDeleteRow}
                  funcPropsTable={funcPropsTable}
                  isScreenDuplicate={isScreenDuplicate}
                  isFirstScreenTable={isFirstScreenTable}
                  isScreenIncomplete={isScreenIncomplete}
                  data={data}
                />
              </div>
            </div>
          }
          menus={[
            {
              screenName: "All Transaction",
              queryName: "all_payroll",
              screen: <></>,
            },
            ...(!!dataIncompleteLength
              ? [
                  {
                    screenName: "Incomplete",
                    queryName: "incomplete",
                    screen: <></>,
                    number: dataIncompleteLength,
                    numberStyle: { background: jackColors.orangeF2 },
                  },
                ]
              : []),
            ...(!!dataDuplicateLength
              ? [
                  {
                    screenName: "Duplicate",
                    queryName: "duplicate",
                    // screen: <Components />,
                    screen: <></>,
                    number: dataDuplicateLength,
                    numberStyle: { background: jackColors.orangeF2 },
                  },
                ]
              : []),
          ]}
        />
        <FloatingButtonPayroll
          array={data}
          loading={loading}
          showDeleteRow={showDeleteRow}
          data={dataFloatingButton}
          funcPropsTable={funcPropsTable}
          isScreenIncomplete={isScreenIncomplete}
          isFirstScreenTable={isFirstScreenTable}
          onClick={onClick}
          onClickOtherFields={setIsFirstScreenTable}
        />
      </div>
      <ConfirmationModalJack
        onClick={() => onClick({ type: isResubmit ? "resubmit_back" : "back" })}
        modal={isOpenBackModal}
        toggle={toggleOpenBackModal}
        title="Go back to the previous page?"
        text="Your progress will be erased and you have to validate the bank details again."
        buttonTextRight="Back"
        img={imgBack}
        customContentClassName="modal-border-radius4"
      />
      <ConfirmationModalJack
        onClick={() => onClick({ type: "confirm_duplicate" })}
        modal={isOpenDuplicate}
        toggle={toggleDuplicate}
        title="Do you want to continue?"
        text={
          <>
            <span style={{ fontFamily: "GothamMedium" }}>
              {dataDuplicateLength} transactions contain duplicate data.
            </span>{" "}
            By clicking{" "}
            <span style={{ fontFamily: "GothamMedium" }}>Continue</span>, you
            acknowledge that the transaction will be sent multiple times to
            recipients with the amount listed in the details.
          </>
        }
        buttonTextRight="Continue"
        buttonTextLeft="Back"
        img={imgDuplicateModal}
        customContentClassName="modal-border-radius4"
      />
      <ConfirmationModalJack
        modal={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        onClick={() => onClick({ type: "submit_delete" })}
        buttonTextRight="Delete"
        buttonTextLeft="Back"
        img={imgDelete}
        title={
          isAllChecked
            ? "Delete all transactions?"
            : "Delete selected transactions?"
        }
        text={
          isAllChecked
            ? "You will delete all transactions and have to start over."
            : "You will delete selected transaction(s) from the list."
        }
      />
      <ModalConfirmationCloseLocal
        toggle={toggleCloseModal}
        isOpen={isOpenCloseModal}
        product="payroll"
      />
      <div ref={refForScroll} style={{ height: 200 }} />
    </div>
  );
};
