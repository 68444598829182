import * as Sentry from "@sentry/nextjs";
import { titleCase } from "change-case";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import { jackColors } from "../assets/colors";
import { JackIcons } from "../assets/jackIcons/parent";
import { GothamRegular } from "../components/Text";
import { decryptToken, errorApiDecider } from "../tools/api";

export const ToasterContext = createContext();

export const ToasterProvider = ({ children }) => {
  const { push } = useRouter();
  const [timer, setTimer] = useState(3000);
  const [errorType, setErrorType] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => setTimeout(() => setShowToast(true), 2000), []);

  const SnackBar = ({
    msg,
    isSuccess = false,
    button,
    showClose = false,
    isWarning,
    icon = null,
  }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: 48,
        height: "auto",
        width: "auto",
        padding: "8px 14px",
      }}
    >
      {icon ? (
        icon
      ) : (
        <JackIcons
          name={isSuccess ? "snackbar_success" : "snackbar_error"}
          style={{ marginRight: 11, height: 17, width: 17 }}
          fill={isWarning ? "white" : jackColors.black34}
        />
      )}
      <GothamRegular
        style={{
          color: isWarning ? "white" : "#343434",
          maxWidth: 400,
        }}
      >
        {msg}
      </GothamRegular>
      {Boolean(button) && <div style={{ marginLeft: 12 }}>{button}</div>}
      {showClose && (
        <JackIcons
          name="snackbar_close"
          fill={"#343434"}
          style={{ marginLeft: 15 }}
        />
      )}
    </div>
  );

  const defaultSetToasters = (errorType, timeout) => {
    setErrorType(errorType);
    setTimer(timeout);
    toast.dismiss();
  };

  const position = { position: "bottom-left" };

  // snackbars
  const errorSnackBar = ({
    msg,
    timeOut = 4000,
    showClose = false,
    buttonComponent,
  }) => {
    defaultSetToasters("error-snackbar", timeOut);

    if (!showToast) return;

    toast.error(
      <SnackBar
        msg={msg}
        isSuccess={false}
        showClose={showClose}
        button={buttonComponent}
      />,
      position
    );
  };

  const successSnackBar = ({
    msg,
    timeOut = 4000,
    showClose = false,
    buttonComponent,
  }) => {
    defaultSetToasters("success-snackbar", timeOut);

    if (!showToast) return;

    toast.success(
      <SnackBar
        isSuccess
        msg={msg}
        showClose={showClose}
        button={buttonComponent}
      />,
      position
    );
  };

  const errorToaster = (title, msg, timeOut = 3000) => {
    defaultSetToasters("error-snackbar", timeOut);

    if (!showToast) return;

    toast.error(<SnackBar msg={msg} isSuccess={false} />, position);
  };

  const successToaster = ({ msg, timeOut = 2000, icon, showClose }) => {
    defaultSetToasters("success-snackbar", timeOut);

    if (!showToast) return;

    toast.success(
      <SnackBar isSuccess msg={msg} showClose={showClose} icon={icon} />,
      position
    );
  };

  const warningToaster = ({ msg, timeOut = 2000 }) => {
    defaultSetToasters("warning-toaster", timeOut);

    if (!showToast) return;
    toast.warning(<SnackBar isWarning isSuccess={false} msg={msg} />, position);
  };
  // snackbars

  const errorToasterApi = (error, timeOut = 3000, woInternetChecker) => {
    const { invalidData, isNoInternet, isServerError, isUnauthorized } =
      errorApiDecider(error);

    if (isNoInternet && !woInternetChecker) return;

    const hasLoggedIn = localStorage.getItem("user");

    const errorAnotherString = error?.message || "";

    const isKeypassChanged = errorAnotherString == "Malformed UTF-8 data";

    const msgDecider = () => {
      const { data, config, status } = error?.response || {};
      const { url, method, data: payload } = config || {};

      const errorString = data?.error?.message || String(data?.message) || "";
      const errorAnotherString = error?.message || "";

      if (isKeypassChanged)
        return "Oops, We're sorry there is a slight hiccup on our system";

      const isArrayString =
        Array.isArray(data?.error) &&
        (data?.error || []).every((msg) => typeof msg == "string");

      const isContactCS = errorString == "[40101] Blocked User Entry";

      if (isContactCS)
        return "Please contact Jack Account Manager for additional information to comply your regulatory requirements";

      const registerError =
        typeof data?.message == "object" && !Array.isArray(data?.message);

      const isDataErrorString = typeof data?.error == "string";

      const errorCaptureString = `Error API\nurl: ${url}\nmethod:${method} \nstatus: ${status}\npayload: ${payload} `;

      // hasLoggedIn && Sentry.captureException(errorCaptureString);

      if (isDataErrorString) return data?.error;

      if (isArrayString) return (data?.error || []).join(" ");

      if (registerError) {
        const object = data?.message;
        const keys = Object.keys(object);

        const messages = keys.map((key) => {
          const value = object[key];
          return `${titleCase(key)} ${value}`;
        });
        const message = messages.flat(1)[0];
        return message;
      }

      if (isNoInternet) return "No Connection";

      if (isServerError)
        return "Oops, We're sorry there is a slight hiccup on our system";

      if (errorString) {
        const isUndefined = errorString == "undefined";
        if (!isUndefined) return errorString;
      }

      if (errorAnotherString) return errorAnotherString;

      if (invalidData)
        return "System cannot process your request. Please contact our Account Manager";

      return "Oops, We're sorry there is a slight hiccup on our system(s)";
    };

    const timeOutDecider = () => {
      if (isNoInternet || isServerError || invalidData) return 10000;
      return timeOut;
    };

    const msg = msgDecider();

    const stringErr = String(error || "");
    const isMyAccount = error?.response?.config?.url.includes("my_account");
    const is404 = stringErr.includes("404");
    const is401 = stringErr.includes("401");

    const isAutoLogout = (is404 && isMyAccount) || isKeypassChanged;

    const kicker = () => {
      const noToken = !localStorage.getItem("token");
      if (noToken) return;
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      push("/login");
    };

    if (isAutoLogout || is401) kicker();

    if (msg == "This is not a authorized request.") return;

    if (msg) {
      if (!showToast) return;
      if ((isAutoLogout || is401) && hasLoggedIn) {
        warningToaster({ msg: "Your session is expired, please login again" });
      } else {
        errorSnackBar({ msg, timeOut: timeOutDecider() });
      }
    }
  };

  return (
    <ToasterContext.Provider
      value={{
        errorToasterApi,
        errorToaster,
        successToaster,
        warningToaster,
        errorSnackBar,
        successSnackBar,
      }}
    >
      {children}
      <ToastContainer
        hideProgressBar
        autoClose={timer}
        position="top-right"
        transition={Slide}
        closeButton={false}
        style={{ width: "auto" }}
        toastClassName={`p-0 ${errorType}`}
      />
    </ToasterContext.Provider>
  );
};

export const ToasterHook = () => {
  const {
    errorToasterApi,
    errorToaster,
    successToaster,
    warningToaster,
    errorSnackBar,
    successSnackBar,
  } = useContext(ToasterContext);

  return {
    errorToasterApi,
    errorToaster,
    successToaster,
    warningToaster,
    errorSnackBar,
    successSnackBar,
  };
};
