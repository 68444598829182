import moment from "moment";
import { BILL, LOCAL, PAYROLL, REIMBURSEMENT } from "../constants";
import retryResubmitBooleans from "./retryResubmitBooleans";

const grayBannerMessageDecider = ({
  trx,
  user,
  drafter,
  releaser,
  warningType,
  resubmittedAt,
}) => {
  const { isRetry, isResubmit, isReminder } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  const isBill = trx?.type === BILL;
  const isReimbursement = trx?.type === REIMBURSEMENT;

  const drafterName = drafter?.name || drafter?.email || "-";
  const releaserName = releaser?.name || releaser?.email || "-";

  if (isReminder && !resubmittedAt) {
    if (isRetry) {
      return `You have reminded ${releaserName} to retry this transaction`;
    }

    if (isResubmit) {
      return `You have reminded ${drafterName} to resubmit this transaction`;
    }

    return "-";
  }

  if (isRetry) {
    if (isBill || isReimbursement) {
      return `${releaserName} has retried this transaction on ${moment(
        resubmittedAt || new Date()
      ).format("dddd, MMMM D YYYY")}.`;
    }

    return `The failed transactions(s) have been retried as a new batch on ${moment(
      resubmittedAt || new Date()
    ).format("dddd, MMMM D YYYY")}.`;
  }

  if (isResubmit) {
    return `The failed transactions(s) have been resubmitted as a new batch on ${moment(
      resubmittedAt || new Date()
    ).format("dddd, MMMM D YYYY")}.`;
  }

  return "-";
};

export default grayBannerMessageDecider;
