import { useState, useEffect } from "react";
import { fetch } from "../../../tools/api";
import { useRouter } from "next/router";
import { rowDuplicateFilter } from "./helpers";

export const useFetchRow = ({ menuForTable, user_id }) => {
  const PER_PAGE = 15;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isMaxedOut, setIsMaxedOut] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const currentPage = `&page=${page}`;

  const queryHandler = () => {
    if (!search_draft && !from_date) return "";
    let nameSearch = "[name_cont]";
    if (menuForTable === "bill_payment") {
      nameSearch = "[invoice_number_cont]";
    }
    const searchQuery = search_draft ? `&q${nameSearch}=${search_draft}` : "";
    const fromDateQuery = from_date ? `&q[created_at_gteq]=${from_date}` : "";
    const toDateQuery = to_date ? `&q[created_at_lteq]=${to_date}` : "";
    const queryUrl = `${searchQuery}${fromDateQuery}${toDateQuery}`;
    return queryUrl;
  };

  let urlIndex = {
    local_transfer: `/local_transaction_batches?per_page=${PER_PAGE}&q[state_matches_any][]=draft&q[state_matches_any][]=validate_recipient&q[transaction_type_not_eq]=payroll&q[user_id_eq]=${user_id}`,
    reimbursement: `/reimbursements?per_page=${PER_PAGE}&q[state_matches_all][]=drafted&q[user_id_eq]=${user_id}`,
    bill_payment: `/invoice_transactions/filtered/drafted?per_page=${PER_PAGE}&q[user_id_eq]=${user_id}`,
    payroll: `/payrolls?per_page=${PER_PAGE}&q[state_matches_any][]=created&q[batch_user_id_eq]=${user_id}`,
  };

  const { query } = useRouter();
  const { search_draft, from_date, to_date, runQuery } = query;

  const filterQuery = queryHandler();
  const [url, setUrl] = useState(
    urlIndex[menuForTable] + filterQuery + currentPage
  );

  const { loading, refetch } = fetch({
    url: url,
    afterSuccess: (res) => {
      if (res.data.length < PER_PAGE) setIsMaxedOut(true);
      setData((prev) => {
        let newArray;
        if (page == 1) {
          newArray = res.data;
        } else {
          newArray = [...prev, ...res.data];
        }
        const uniqueArray = rowDuplicateFilter(newArray);
        return uniqueArray;
      });
    },
  });

  const nextPage = () => {
    if (isMaxedOut) return;
    if (loading) return;
    if (isDeleting) return;
    setPage((p) => p + 1);
  };

  const afterDelete = () => {
    setIsDeleting(true);
    setData([]);
    setPage(1);
    setUrl(urlIndex[menuForTable] + "&page=1");
  };

  useEffect(() => {
    if (!isDeleting) return;
    refetch();
    setIsDeleting(false);
  }, [isDeleting]);

  useEffect(() => {
    if (page == 1) return;
    setUrl(urlIndex[menuForTable] + filterQuery + currentPage);
  }, [page]);

  useEffect(() => {
    if (runQuery === "false" || runQuery == undefined) return;
    setData([]);
    setPage(1);
    setUrl(`${urlIndex[menuForTable]}${queryHandler()}&page=1`);
    setIsMaxedOut(false);
  }, [runQuery]);

  useEffect(() => {
    refetch();
  }, [url]);

  useEffect(() => {
    if (!menuForTable) return;
    setData([]);
    setPage(1);
    setUrl(urlIndex[menuForTable] + filterQuery + "&page=1");
    setIsMaxedOut(false);
  }, [menuForTable]);

  return { data, loading, nextPage, refetch, afterDelete };
};

// Below function is for fetching total rows used in tab counter
export const useFetchTotalRow = (urlProp) => {
  const [totalRow, setTotalRow] = useState();
  const { loading, refetch } = fetch({
    url: urlProp,
    afterSuccess: (res) => {
      setTotalRow(res);
    },
  });

  return { totalRow, refetch };
};
