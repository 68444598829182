import { isEmpty, set } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "@tools";
import Skeleton from "react-loading-skeleton";
import { DateInputJackUseForm } from "../../../components/inputs/date/parent";
import {
  SelectionJack,
  SelectionJackState,
} from "../../../components/inputs/selection";
import { fetch } from "../../../tools/api";
import { redStarLabel } from "../../invoiceComponents/createPage/modals/formEdit";
import { generateTimeOptions, generateTimeOptionsComplex } from "../constant";
import { changeHour, extractTimeForForm, pcDescription } from "../formatter";
import { optionsFormatter } from "../paymentOptions/formatter";

export const SpesificDateForm = ({
  setItem,
  defaultValue,
  containerStyle,
  inputStyle,
}) => {
  const useFormObj = useForm();
  const { watch, setValue } = useFormObj;

  const dateVal = watch("date");
  const timeVal = watch("time")?.value;

  const isComplete = Boolean(dateVal) && Boolean(timeVal);

  const options = generateTimeOptionsComplex(dateVal);
  useEffect(() => {
    if (isComplete) {
      const date_and_time = changeHour(dateVal, timeVal);
      return setItem({ date_and_time, predefined_schedule_id: null });
    }
  }, [dateVal, timeVal]);

  useEffect(() => {
    if (!isEmpty(defaultValue?.payload)) {
      const { type, payload } = defaultValue;
      const isSpesificDate = type == "spesific_date";
      if (isComplete) return;
      if (isSpesificDate) {
        setValue("date", new Date(payload?.date_and_time));
        setValue("time", extractTimeForForm(payload?.date_and_time));
      }
    }
  }, [defaultValue]);

  //useeffect if dateval change timeval should reset
  useEffect(() => {
    if (dateVal && timeVal) {
      setItem({});
      setValue("time", null);
      return;
    }
  }, [dateVal]);

  return (
    <div
      className="d-flex justify-content-between"
      style={{ paddingLeft: 28, height: 64, ...containerStyle }}
    >
      <DateInputJackUseForm
        label={redStarLabel("Date")}
        style={{ width: 172, margin: 0, ...inputStyle }}
        useFormObj={useFormObj}
        minDate={new Date()}
        name="date"
        placeholder="Select date"
        dateFormat="d/MM/yyyy"
      />
      <SelectionJack
        label={redStarLabel("Time (WIB)")}
        required
        options={options}
        containerStyle={{ width: 172, margin: 0, ...inputStyle }}
        useFormObj={useFormObj}
        name="time"
        // defaultValue={dummyOption[0]}
        placeholder="Select time"
      />
    </div>
  );
};

export const PaymentCycleSelection = ({
  product,
  invoiceDueDate,
  setItem,
  defaultValue,
}) => {
  const [paymentCycle, setPaymentCycle] = useState({});
  const {
    data: paymentCycleOptions,
    loading,
    refetch,
  } = fetch({
    url: `/predefined_schedules?q[transaction_type_eq]=${product}`,
    formatter: ({ data: rawData }) =>
      optionsFormatter(rawData, invoiceDueDate).map((pc) => {
        const { id, nearest_date_from_today } = pc;
        const label = pcDescription(pc);
        const value = {
          label,
          value: {
            predefined_schedule_id: id,
            date_and_time: nearest_date_from_today,
          },
        };

        return value;
      }),
  });

  const value = useMemo(() => {
    if (isEmpty(paymentCycle)) return null;
    const { predefined_schedule_id: id } = paymentCycle;
    return paymentCycleOptions
      ?.filter(({ value }) => value?.predefined_schedule_id == id)
      ?.pop();
  }, [paymentCycle, paymentCycleOptions]);

  useEffect(() => {
    if (!isEmpty(paymentCycle)) {
      setItem(paymentCycle);
    }
  }, [paymentCycle]);

  useEffect(() => {
    if (!isEmpty(defaultValue?.payload)) {
      const { type, payload } = defaultValue;
      const isPaymentCycle = type == "payment_cycle";
      if (!isEmpty(paymentCycle)) return;
      if (isPaymentCycle) return setPaymentCycle(payload);
    }
  }, [defaultValue]);
  if (loading)
    return (
      <div style={{ width: "100%", paddingLeft: 28 }}>
        <Skeleton height={40} width="100%" />
      </div>
    );

  return (
    <div style={{ width: "100%", paddingLeft: 28 }}>
      <SelectionJackState
        noLabel
        required
        options={paymentCycleOptions}
        containerStyle={{ width: "100%", margin: 0 }}
        value={value}
        onChange={setPaymentCycle}
        name="time"
        // defaultValue={dummyOption[0]}
        placeholder="Select payment cycle"
      />
    </div>
  );
};
