import { RETRY_TEXT, RESUBMIT_TEXT } from "../constants";
import retryResubmitBooleans from "./retryResubmitBooleans";

const retryResubmitBannerMessageDecider = ({
  warningType,
  user,
  drafter,
  releaser,
}) => {
  const { isRetry, isResubmit, isReminder } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  if (isReminder) {
    const drafterName = drafter?.name || drafter?.email || "-";
    const releaserName = releaser?.name || releaser?.email || "-";
    if (isRetry) return `You can remind ${releaserName} ${RETRY_TEXT}`;
    if (isResubmit) return `You can remind ${drafterName} ${RESUBMIT_TEXT}`;
    return "-";
  }

  if (isRetry) return `You can ${RETRY_TEXT}`;
  if (isResubmit) return `You can ${RESUBMIT_TEXT}`;
  return "-";
};

export default retryResubmitBannerMessageDecider;
