import { getUserRole, useGetAuth } from "../../contexts/AuthContext";

export const cardTypeDecider = (cardType) => {
  const isVirtual = cardType === "virtual";
  const isPhysical = cardType === "physical";
  const isPrepaid = cardType === "virtual_prepaid";

  return { isVirtual, isPhysical, isPrepaid };
};

export const cardStateFormatter = (cardDetail) => {
  const {
    is_adjusting_limit,
    issuance_status: issuanceStatus,
    is_suspended: isSuspended,
    state,
    user,
    one_time_use_limit_used: oneTimeUsed,
  } = cardDetail || {};
  const isAdjustingLimit = is_adjusting_limit?.value;
  // const isUserActive = user?.status === "active";
  const { isNotActive, isPermanentBlock } = cardStateDecider(
    state,
    issuanceStatus
  );

  const isDelivered = issuanceStatus === "delivered" && isNotActive;
  const isRejected = issuanceStatus === "rejected";
  // if (!isUserActive) return "PENDING";
  if (isSuspended) return "SUSPENDED";
  if (isRejected) return "REJECTED";
  if (isAdjustingLimit) return "REQUEST_LIMIT";
  if (isDelivered || (isPermanentBlock && oneTimeUsed)) return "DELIVERED";
  return state;
};

export const limitTypeDecider = (limitType) => {
  switch (limitType) {
    case "MONTHLY_AMOUNT_LIMIT":
      return "Monthly";
    case "DAILY_AMOUNT_LIMIT":
      return "Daily";
    case "YEARLY_AMOUNT_LIMIT":
      return "Yearly";
    case "LIFETIME_AMOUNT_LIMIT":
      return "Lifetime";
    case "ONE_TIME_USE":
      return "One Time";
    default:
      return "Monthly";
  }
};

export const cardStateDecider = (state, issuanceStatus) => {
  const isActive = state === "ACTIVE";
  const isNotActive = state === "NOT_ACTIVE" || state === "REQUEST_NEW_CARD";
  const isTempBlock = state === "TEMP_BLOCK";
  const isPermanentBlock = state === "P_BLOCK";
  const isRequestActive = state === "REQUEST_ACTIVE";
  const isRequestTempBlock = state === "REQUEST_TEMP_BLOCK";
  const isRequestPermanentBlock = state === "REQUEST_P_BLOCK";
  const isRequestUnblock = state === "REQUEST_UNBLOCK";
  const isActivating = state === "REQUEST_ACTIVE";
  const isRejected = issuanceStatus === "rejected";

  return {
    isActive,
    isNotActive,
    isTempBlock,
    isPermanentBlock,
    isRequestActive,
    isRequestTempBlock,
    isRequestPermanentBlock,
    isRequestUnblock,
    isActivating,
    isRejected,
  };
};

export const cardIssuanceStatusDecider = (issuanceStatus) => {
  const isOnRequested = issuanceStatus === "requested";
  const isOnReview = issuanceStatus === "on_review";
  const isOnProgress = issuanceStatus === "on_progress";
  const isDelivering = issuanceStatus === "delivering";
  const isDelivered = issuanceStatus === "delivered";
  const isRejected = issuanceStatus === "rejected";
  const isOnReviewOps = issuanceStatus === "on_review_ops";
  const isApproved = issuanceStatus == "approved";

  return {
    isOnRequested,
    isOnReview,
    isOnProgress,
    isDelivering,
    isDelivered,
    isRejected,
    isOnReviewOps,
    isApproved,
  };
};

export const isCanManageCardDecider = (cardDetail, loggedInUser) => {
  const { user: { id: ownerCardId, team: { id: ownerTeamId } = {} } = {} } =
    cardDetail || {};
  const { isAdminOrSuperAdmin, isManagingTeams } = getUserRole();
  const { id: loggedInUserId, managed_teams: teamsManaged } =
    loggedInUser || {};
  const teamsIdManaged = teamsManaged?.map(({ id }) => id);
  const isCardOwner = ownerCardId === loggedInUserId;
  const isManagerOfOwner =
    isManagingTeams && teamsIdManaged?.includes(ownerTeamId);
  const isCanManageCard =
    isAdminOrSuperAdmin || isCardOwner || isManagerOfOwner;
  return isCanManageCard;
};

export const cardDuplicateFilter = (arr) => {
  const ids = new Set();
  return arr.filter((obj) => {
    const id = obj.id;
    if (ids.has(id)) {
      return false;
    } else {
      ids.add(id);
      return true;
    }
  });
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const billCycleDecider = (date, businessId) => {
  if (!date) return {};

  const rearrangedDate = date?.split("-").reverse();
  const statementYearDate = rearrangedDate[2];
  const monthIndex = rearrangedDate && Number(rearrangedDate[1]) - 1;

  const isAsta = businessId === 89;

  const startDayDate = isAsta ? 14 : 26;
  const endDayDate = isAsta ? 13 : 25;
  const startingMonth = months[monthIndex - 1] || "December";
  const endingMonth = months[monthIndex];
  const startingYear =
    endingMonth == "January" ? +statementYearDate - 1 : statementYearDate;
  const endingYear = statementYearDate;

  return {
    startingDate: `${startingMonth} ${startDayDate}th`,
    endingDate: `${endingMonth} ${endDayDate}th`,
    startingMonth: startingMonth,
    startingYear,
    endingYear,
    endingMonth: endingMonth,
    fullStartingDate: `${startDayDate} ${startingMonth} ${startingYear}`,
    fullEndingDate: `${endDayDate} ${endingMonth} ${endingYear}`,
  };
};

export const currentCycleDecider = (businessId) => {
  const fullDate = new Date();
  const currentMonthIndex = fullDate.getMonth();
  const date = fullDate.getDate();
  const year = fullDate.getFullYear();

  const isAsta = businessId === 89;

  let startingMonthIndex;
  let endingMonthIndex;

  if (date < (isAsta ? 14 : 26)) {
    endingMonthIndex = currentMonthIndex;
    startingMonthIndex = currentMonthIndex - 1;
  } else {
    endingMonthIndex = currentMonthIndex + 1;
    startingMonthIndex = currentMonthIndex;
  }

  return {
    startingMonth: months[startingMonthIndex] || "December",
    endingMonth: months[endingMonthIndex] || "January",
    startingYear: year,
    endingYear:
      months[startingMonthIndex] === "December" && date > 25 ? year + 1 : year,
  };
};

export const expiryStatusDecider = (expiryStatus) => {
  const isActive = expiryStatus === "active";
  const isNeedRenewal = expiryStatus === "need_renewal";
  const isRenewManual = expiryStatus === "renewed_manually";
  const isRenewAuto = expiryStatus === "renewed_automatically";
  const isRenewed = isRenewManual || isRenewAuto;

  return {
    isActive,
    isNeedRenewal,
    isRenewManual,
    isRenewAuto,
    isRenewed,
  };
};

export const cardTransactionStateDecider = (state) => {
  const isSuccess = state === "success" || state === "approved";
  const isCancelled = state === "cancelled";
  const isFailed = state === "failed" || state === "declined";
  const isReturned = state === "returned";
  const isOnProgress = state === "on_progress";

  return {
    isSuccess,
    isCancelled,
    isFailed,
    isReturned,
    isOnProgress,
  };
};

export const paymentMethodDecider = (paymentMethod) => {
  const isMainBalance = paymentMethod === "main_balance";
  const isMoveBalance = paymentMethod === "move_balance";
  const isBackOffice = paymentMethod === "manual by Jack";

  return {
    isMainBalance,
    isMoveBalance,
    isBackOffice,
  };
};

export const transactionTypeDecider = (transactionType) => {
  const isPrepaidTopUp = transactionType === "CREDIT_TOPUP";
  const isWithdrawal = transactionType === "DEBIT_WITHDRAWAL";
  const isCreditTopUp = transactionType === "CREDIT_TOPUP_VC";
  return {
    isPrepaidTopUp,
    isWithdrawal,
    isCreditTopUp,
  };
};

export const useCardDetailFormatter = (cardDetail) => {
  const { user } = useGetAuth();
  const isCanManageCard = isCanManageCardDecider(cardDetail, user);
  const businessId = user?.partner?.id;
  const brojolanCycle = currentCycleDecider(user?.partner?.id);
  const { isAdminOrSuperAdmin } = getUserRole();
  const isLoggedInUser = user?.id === cardDetail?.user_id;
  const period = user?.partner?.get_active_bill?.period;

  return {
    isCanManageCard,
    brojolanCycle,
    isAdminOrSuperAdmin,
    businessId,
    isLoggedInUser,
    period,
  };
};
