import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors, jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { Avatar } from "../../components/Avatar";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { GetFlag } from "../../components/inputs";
import { universeFunc } from "../../components/RecipientDetailsModal";
import { GothamMedium, GothamRegular } from "../../components/Text";
import LoadingSpinner from "../../components/Loading";
import emptyImg from "../../assets/images/oops-search-empty.svg";
import imgEmptyState from "../../assets/images/empty-state-search.svg";
import { titleCase } from "change-case";
import { Line } from "react-bootstrap-icons";

export const Hr32 = ({ style }) => (
  <hr style={{ margin: "32px 0px", ...style }} />
);

export const HrVertical32 = () => (
  <div
    style={{
      border: `0.5px solid ${jackColors.neutral500}`,
      borderColor: jackColors.neutral500,
      marginRight: 32,
      marginLeft: 32,
    }}
  />
);

export const WrapperJackCenter = ({
  title = "Title",
  children,
  loading,
  containerStyle,
  styleTitle,
}) => {
  if (loading) return <ShimmerLoadingCrossBorder title={title} />;
  return (
    <div
      style={{
        paddingTop: 12,
        marginLeft: "auto",
        marginRight: "auto",
        paddingBottom: 24,
        maxWidth: 438,
        position: "relative",
        ...containerStyle,
      }}
    >
      <GothamMedium
        className="font24"
        style={{
          marginBottom: 32,
          textAlign: "left",
          color: jackColors.neutral900,
          ...styleTitle,
        }}
      >
        {title}
      </GothamMedium>
      {children}
    </div>
  );
};

export const RecipientCard = ({
  defaultRecipient,
  isOpen,
  data = false,
  toggle,
  loading,
}) => {
  const [recipient, setRecipient] = useState(false);
  const { iso: isoRecipient, name: nameRaw, country } = recipient || {};
  const name = titleCase(
    nameRaw || universeFunc("business_name", recipient) || ""
  );
  const { iso: isoCountry } = country || {};
  const iso = isoRecipient || isoCountry?.toLowerCase();
  // const registeredName = universeFunc("receiving_registered_name", recipient);
  // const firstName = universeFunc("receiving_first_name", recipient);
  // const lastName = universeFunc("receiving_last_name", recipient);
  // const name = registeredName ? registeredName : `${firstName} ${lastName}`;
  const bankName = universeFunc("bank_name", recipient);
  const iban = universeFunc("iban", recipient);
  const account_number = universeFunc("bank_account_number", recipient);
  const numbers = account_number || `${iban} (IBAN)`;
  const classAnimation = `rotate-icon ${isOpen ? "down" : ""}`;

  useEffect(() => {
    if (!!defaultRecipient) setRecipient(defaultRecipient);
    if (!!data?.name) return setRecipient(data);
  }, [data?.name, defaultRecipient?.name, data?.id]);

  return (
    <div
      className="pinkhover borderE6"
      onClick={toggle}
      style={{ padding: "16px 12px" }}
    >
      {loading ? (
        <Skeleton height={30} width="100%" />
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div style={{ position: "relative" }}>
              <Avatar
                size="medium"
                name={name}
                bottomRightIcon={<GetFlag iso={iso} width={16} height={16} />}
                style={{ position: "relative" }}
                bottomRightStyle={{
                  bottom: -6,
                  right: -12,
                }}
              />
            </div>
            <div
              style={{
                marginLeft: 12,
                maxWidth: "85%",
              }}
            >
              <GothamMedium
                className="font12"
                style={{
                  marginBottom: 2,
                  maxWidth: 340,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {name}
              </GothamMedium>
              <GothamRegular
                className="font10"
                style={{
                  maxWidth: 340,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: colors.grey72,
                }}
              >
                {bankName} {bankName && numbers && "-"} {numbers}
              </GothamRegular>
            </div>
          </div>
          <JackIcons
            style={{ width: 20 }}
            className={`hover-600 ${classAnimation}`}
            name="chevron-down"
            fill={jackColors.neutral900}
          />
        </div>
      )}
    </div>
  );
};

export const RecipientButton = ({
  onClick,
  buttonType,
  disabled = false,
  RightButtonText = "Next",
  containerStyle,
  loading,
}) => {
  return (
    <div
      className="d-flex align-items-center justify-content-end"
      style={{ gap: 16, marginTop: 32, ...containerStyle }}
    >
      <ButtonJack
        type="outline"
        children="Back"
        style={{ minWidth: 65, marginBottom: 32 }}
        onClick={() => onClick({ type: "back" })}
        leftIcon={
          <JackIcons
            name="arrow-back-outline"
            fill="black"
            style={{ width: 20, height: 20 }}
          />
        }
      />
      <ButtonJack
        isLoading={loading}
        disabled={disabled}
        buttonType={buttonType}
        type="filled"
        children={RightButtonText}
        style={{ minWidth: 60, marginBottom: 32 }}
        onClick={() => onClick({ type: "next" })}
        rightIcon={
          <JackIcons
            name="arrow-forward"
            fill={disabled ? jackColors.neutral600 : "black"}
            style={{ width: 20, height: 20 }}
          />
        }
      />
    </div>
  );
};

export const ShimmerLoadingCrossBorder = ({ title }) => {
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 438,
        position: "relative",
      }}
    >
      <GothamMedium
        className="font24"
        style={{
          marginBottom: 32,
          textAlign: "left",
          color: jackColors.neutral900,
        }}
      >
        {title}
      </GothamMedium>
      <Skeleton height={40} width={"100%"} />
      <Skeleton height={20} width={"100%"} style={{ margin: "32px 0px" }} />
      <Skeleton height={64} width={"100%"} />
    </div>
  );
};

export const EmptyScreenSearchRecipients = ({ text = "", customText }) => {
  const style = {
    fontSize: "12px",
    lineHeight: "16px",
    color: jackColors.grey90,
    textAlign: "center",
  };

  return (
    <div style={{ height: 120, position: "relative" }}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          wordBreak: "break-word",
        }}
      >
        {customText ? (
          <GothamRegular style={{ ...style }}>{customText}</GothamRegular>
        ) : (
          <>
            <GothamRegular style={{ ...style }}>
              We can't find "
              <span style={{ fontFamily: "GothamMedium" }}>{text}</span>"
            </GothamRegular>
            <GothamRegular style={{ ...style }}>
              Let's try another keyword.
            </GothamRegular>
          </>
        )}
      </div>
    </div>
  );
};

export const EmptyScreenSearchImages = ({
  text = "",
  img = emptyImg,
  containerStyle,
}) => {
  const style = {
    fontSize: "12px",
    lineHeight: "16px",
    color: jackColors.grey90,
    textAlign: "center",
  };

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        wordBreak: "break-word",
        marginTop: 50,
        ...containerStyle,
      }}
    >
      <img src={img} style={{ marginBottom: 40 }} />
      <GothamMedium style={{ marginBottom: 16 }}>No Result Found</GothamMedium>
      <GothamRegular style={{ ...style }}>
        We can't find "
        <span style={{ fontFamily: "GothamMedium" }}>{text}</span>"
      </GothamRegular>
      <GothamRegular style={{ ...style }}>
        Let's try another keyword.
      </GothamRegular>
    </div>
  );
};

export const EmptyStateSearchImages = ({
  img = imgEmptyState,
  containerStyle,
}) => {
  const style = {
    fontSize: "12px",
    lineHeight: "16px",
    color: jackColors.grey90,
    textAlign: "center",
  };

  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        wordBreak: "break-word",
        marginTop: 50,
        ...containerStyle,
      }}
    >
      <img src={img} style={{ marginBottom: 40 }} />
      <GothamRegular style={{ ...style }}>
        We can’t find what you’re looking for. <br />
        Let’s try another keyword.
      </GothamRegular>
    </div>
  );
};
