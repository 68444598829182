import moment from "moment";
import { useEffect } from "react";
import { colors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { CustomRichTextArea } from "../../../components/CustomRichTextArea";
import { DateInputJackUseForm } from "../../../components/inputs/date/parent";
import { FileInputButtonJack } from "../../../components/inputs/fileInputs/fileInputButton";
import { TextFieldJack } from "../../../components/inputs/textfield";
import { GothamRegular } from "../../../components/Text";
import { redStarLabel } from "../../invoiceComponents/createPage/modals/formEdit";
import { gmt7 } from "../data";
import CategorySelection from "../../categoryComponents/general/components/CategorySelection";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import {
  LineItems,
  TaxLineItems,
} from "../../invoiceComponents/createPage/modals/formComponents";
import {
  createInvoiceSummaryForm,
  formatTransactionItems,
} from "../../../modals/smartActivityModal/invoicePayment/editDetailModal/logic";
import { InvoiceFormSummary } from "../../invoiceComponents/createPage/modals/formComponents/component";
import { unformatCurrency } from "../../../components/tools";
import { useInvoiceFormDebounce } from "../../invoiceComponents/createPage/modals/formLogic";

const rightSideWidth = "calc(100% - 12% - 32px)";

export const ReimbursementFormInput = ({
  useFormObj,
  hideSupportingDoc = false,
  data: reimbursement,
  isErrorCategory,
  setIsErrorCategory,
  fee = 0,
  topRef,
}) => {
  const { setValue, errors, register, watch } = useFormObj;
  const category = watch("category");
  const { memo_text } = reimbursement;
  // useEffect(() => {
  //   register("category");
  //   register("initial_amount");
  //   if (reimbursement?.id) {
  //     const {
  //       merchant,
  //       purchased_date,
  //       amount,
  //       memo_text,
  //       attachment_data,
  //       transaction_taxes: tt,
  //       transaction_items: ti,
  //     } = reimbursement;

  //     const formattedTransactionItems = formatTransactionItems(ti);

  //     const formattedTaxItems = tt?.map((item) => ({
  //       ...item,
  //       _destroy: false,
  //     }));
  //     const defaultValues = {
  //       merchant,
  //       purchased_date: gmt7(purchased_date),
  //       amount,
  //       upload_file: attachment_data,
  //       transaction_items: formattedTransactionItems,
  //       transaction_taxes: formattedTaxItems,
  //       initial_amount: amount,
  //     };

  //     if (memo_text) defaultValues.description = memo_text;
  //     const dvKeys = Object.keys(defaultValues);
  //     dvKeys?.forEach((keys) => setValue(keys, defaultValues[keys]));

  //     if (category_id) {
  //       setValue(
  //         "category",
  //         (categories ?? []).find(({ id }) => id === category_id)
  //       );
  //     }
  //   }
  // }, [reimbursement]);
  const items = watch("transaction_items");
  const taxes = watch("transaction_taxes");
  const subTotal = unformatCurrency(watch("amount"));
  const tax = unformatCurrency(watch("tax"));

  //partial takeout
  // const summary = createInvoiceSummaryForm({
  //   items,
  //   taxes,
  //   fee,
  //   tax,
  //   amount: subTotal,
  // });

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #E6E6E8",
        }}
      >
        <div style={{ width: "12%" }}>
          <GothamRegular className="font12">Expense Overview</GothamRegular>
        </div>
        <div style={{ width: rightSideWidth, paddingBottom: 32 }}>
          <TextFieldJack
            useFormObj={useFormObj}
            name="merchant"
            label={redStarLabel("Merchant")}
            placeholder="Input the merchant name"
          />
          <DateInputJackUseForm
            useFormObj={useFormObj}
            label={redStarLabel("Purchase Date")}
            name="purchased_date"
            placeholder="When did this transaction occur?"
            dateFormat="d/MM/yyyy"
          />
          {/* partial takeout */}
          <TextFieldJack
            useFormObj={useFormObj}
            name="amount"
            label={redStarLabel("Amount")}
            placeholder="IDR"
            woFormatError
          />
          {/* partial takeout */}
          <CategorySelection
            isError={isErrorCategory}
            selectedCategory={category ?? {}}
            onSelect={(category) => {
              register("category");
              setValue("category", category);
              setIsErrorCategory(false);
            }}
          />
          <CustomRichTextArea
            useFormObj={useFormObj}
            name="description"
            label={redStarLabel("Description")}
            helperText="Give informative description about your expense."
            placeholder="What was this expense for? (e.g., meeting with a client, buying office supplies, medical expenses)"
            defaultValue={memo_text}
            isUnlimited
          />
        </div>
      </div>
      {/* partial takeout */}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #E6E6E8",
        }}
      >
        <div style={{ width: "12%" }}>
          <GothamRegular className="font12">Transaction Details</GothamRegular>
        </div>
        <div
          style={{
            width: rightSideWidth,
            paddingBottom: 32,
            display: "flex",
            flexDirection: "column",
            gap: 32,
          }}
        >
          <LineItems
            name="transaction_items"
            totalName="amount"
            useFormObj={useFormObj}
          />
          <div ref={topRef} />
          <TaxLineItems
            name="transaction_taxes"
            totalName="tax"
            useFormObj={useFormObj}
            isReimbursement
          />
          <InvoiceFormSummary data={summary} isReimbursement />
        </div>
      </div> */}
      {!hideSupportingDoc && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "12%" }}>
            <GothamRegular className="font12">
              Supporting Document(s)
            </GothamRegular>
          </div>
          <div style={{ width: rightSideWidth }}>
            <div
              style={{
                width: "100%",
                height: 44,
                padding: 12,
                backgroundColor: "#F1F1F1",
                borderRadius: 4,
                marginBottom: 8,
                border: "1px solid #BBBBC0",
                display: "flex",
                gap: 12,
                alignItems: "center",
              }}
            >
              <JackIcons
                name="lightbulb"
                fill="#909098"
                style={{ width: "auto", height: 20, marginBottom: 2 }}
              />
              <GothamRegular
                className="font12"
                style={{ color: colors.grey6c }}
              >
                You can provide any relevant documents, such as photo of items
                or medical evidences.
              </GothamRegular>
            </div>
            <FileInputButtonJack
              useFormObj={useFormObj}
              name="upload_file"
              fileTypes={["image/jpeg", "application/pdf", "image/png"]}
              multiple={true}
              showLabel={false}
              enablePreview={true}
            />
          </div>
        </div>
      )}
    </>
  );
};
