import React, { useEffect, useRef, useState } from "react";
import { colors, jackColors } from "../../../../assets/colors";
import deleteIcon from "../../../../assets/images/delete-icon-pink.svg";
import recipientIcon from "../../../../assets/images/recipient-pink-icon.svg";
import xIcon from "../../../../assets/images/x-icon-grey.svg";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { CustomButton } from "../../../../components/Buttons";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import {
  MinimalistSelect,
  MinimalistTextField,
} from "../../../../components/inputs";
import { useModalHook } from "../../../../components/Modals";
import {
  GothamMedium,
  GothamRegular,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import UserList from "../../../../modals/AddFromListModal/UserList";
import {
  CenteredModal,
  JackCenteredModal,
} from "../../../../modals/centeredComponents";
import { CenteredModalWithHeader } from "../../../../modals/smartKYBUploadDocsModal/components";
import { Cards } from "../../../invoiceComponents/onBoarding/modal";
import {
  BackAndConfirmButton,
  ButtonAddRow,
} from "../../../PayrollCreateEnhancement/create/components";
import { FormatterOptionsBank } from "../../../PayrollCreateEnhancement/create/formatter";
import { EmailInput } from "./inputs/email/parent";
import {
  AddRowExistingListFormatter,
  ErrorMessageGeneratorLocal,
  useBooleanTableLocal,
  useCheckerErrorAddRow,
} from "./logic";
import { TheadTableLocal } from "./table";

export const ModalAddRowLocal = ({
  title = "Are You Sure?",
  desc = "You will delete the transaction from the list.",
  isOpen,
  toggle,
  headerArray,
  setCombineData,
  widthParent,
}) => {
  const { successToaster, successSnackBar } = ToasterHook();
  const {
    isOpen: isOpenList,
    toggle: toggleList,
    close: closeList,
  } = useModalHook();

  const data = [
    { name: "delete" },
    { name: "number" },
    { name: "name", value: "", label: "Name" },
    { name: "bank", value: "", label: "Bank" },
    { name: "account_number", value: "", label: "Bank Account Number" },
    { name: "notes", value: "", label: "Notes" },
    { name: "email", value: "", label: "Email" },
    { name: "amount", value: "", label: "Amount" },
    { name: "action" },
  ];

  const { localTransferBanks: optionsBank } = useConstants();
  const formattedOptionsBank = FormatterOptionsBank({ options: optionsBank });
  const getIdBank = (valueRaw) =>
    formattedOptionsBank.filter(({ value }) => value == valueRaw)[0];

  const dummyData = [data];
  const [renderFirst, setRenderFirst] = useState(true);
  const [showError, setShowError] = useState(false);
  const [defaultData, setDefaultData] = useState(dummyData);

  const handleAddRow = () => {
    setDefaultData([...defaultData, data]);
    setShowError(false);
  };

  const handleChangeRow = () => setRenderFirst((p) => !p);

  const handleDelete = (index) => {
    const filteredData = defaultData.filter(
      (_, childIndex) => childIndex !== index
    );
    setDefaultData(filteredData);
  };

  const handleAddData = () => {
    const isError = useCheckerErrorAddRow({ data: defaultData });

    if (isError) {
      setShowError(true);
      setRenderFirst(true);
      return;
    }

    setCombineData(defaultData);
    setDefaultData(dummyData);
    setRenderFirst(true);
    setShowError(false);
    successSnackBar({
      msg: `${defaultData.length} transaction has been added.`,
      showClose: true,
    });
    toggle();
  };

  const handleCloseModal = () => {
    setDefaultData(dummyData);
    setShowError(false);
    toggle();
  };

  useEffect(() => {
    if (defaultData.length === 0) {
      toggle();
      setDefaultData(dummyData);
    }
  }, [defaultData]);

  const handleAddRecipient = (val) => {
    const dataFormatted = AddRowExistingListFormatter({
      data: val,
      formattedOptionsBank,
      defaultData,
      dummyData: data,
    });
    setDefaultData(dataFormatted);
  };

  const handleChange = (event, index, name) => {
    const { name: valueSelect } = event || {};
    const { isBank, isAmount } = useBooleanTableLocal({ name });
    const idBank = getIdBank(valueSelect);
    const { id } = idBank || {};

    const valueDecider = () => {
      if (isAmount) return formatCurrencyNoDecimal(event);
      if (isBank) return valueSelect;
      return event;
    };

    const value = valueDecider();
    setDefaultData((prev) => {
      const result = prev.map((array, childIndex) => {
        const isChangedRow = index == childIndex;
        if (!isChangedRow) return array;
        const newArray = array.map((item) => {
          const { name: currName } = item;
          if (currName != name) return item;
          return {
            ...item,
            id,
            details: idBank,
            value,
          };
        });
        return newArray;
      });
      return result;
    });
  };

  const styleDefault = {
    textAlign: "center",
    cursor: "pointer",
  };

  const wordingStep = renderFirst
    ? "Add new recipient to this batch transaction."
    : "To provide more details, fill out the emails and transaction notes. These fields are optional.";

  const [scrollHeight, setScrollHeight] = useState(0);
  const [modalHeader, setModalHeader] = useState(0);

  return (
    <>
      <CenteredModalWithHeader
        isOpen={isOpen}
        style={{ width: widthParent, maxHeight: 600, overflowY: "hidden" }}
        toggle={handleCloseModal}
        closeModal={handleCloseModal}
        jackClose
      >
        <div>
          <div
            ref={(e) => {
              if (!e) return;
              setModalHeader((prev) => {
                if (!prev) return e.clientHeight;
                return prev;
              });
            }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginBottom: 24 }}
            >
              <GothamMedium
                className="font24"
                style={{
                  color: jackColors.neutral900,
                  marginBottom: 8,
                }}
              >
                Add New Transaction
              </GothamMedium>
              <div className="d-flex" style={{ gap: 8 }}>
                <ButtonJack
                  style={{ padding: 6 }}
                  type="outline"
                  onClick={toggleList}
                  children="Previous Recipient"
                  leftIcon={
                    <JackIcons
                      style={{ width: 20, height: 20 }}
                      name="person-add-outline"
                      fill={jackColors.neutral900}
                    />
                  }
                />
                <ButtonJack
                  style={{ padding: 6 }}
                  type="outline"
                  onClick={handleAddRow}
                  children="Add Row"
                  leftIcon={
                    <JackIcons
                      style={{ width: 20, height: 20 }}
                      name="plus-square-outline"
                      fill={jackColors.neutral900}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <table
            onScroll={(e) => setScrollHeight(e?.target?.scrollTop || 0)}
            style={{
              wordBreak: "break-word",
              minWidth: 1040,
              backgroundColor: "white",
              border: "1px solid #E6E6E8",
              marginBottom: 16,
              maxHeight: "300px",
              display: "block",
              overflowY: "auto",
              position: "relative",
              borderCollapse: "separate",
              borderRadius: 8,
              padding: "0px 8px 8px 8px",
              zIndex: 0,
            }}
            cellSpacing={0}
          >
            <TheadTableLocal
              headerArray={headerArray}
              isFirstScreenTable={renderFirst}
              fromModal
            />
            <tbody>
              {defaultData?.map((data, index) => {
                return (
                  <tr key={index}>
                    {data?.map((item, i) => {
                      const { name, value, label } = item || {};
                      const {
                        isSecondRender,
                        isFirstRender,
                        isNumber,
                        isName,
                        isAction,
                        isAccNumber,
                        isDelete,
                        isBank,
                        isRequired,
                        isAmount,
                        isLocalId,
                        isEmail,
                        isRecipientEmail,
                        isAdditionalEmail,
                      } = useBooleanTableLocal({
                        name,
                      });
                      const { msg: errorMsg, isBelowMinimumAmount } =
                        ErrorMessageGeneratorLocal({
                          name,
                          value,
                        });
                      if (isLocalId || isRecipientEmail || isAdditionalEmail)
                        return null;
                      const isSelect = isBank;
                      const isValueEmpty = !Boolean(value);
                      const isError =
                        showError &&
                        isRequired &&
                        (isValueEmpty || isBelowMinimumAmount);
                      const isOnlyNumber = isAmount || isAccNumber;

                      const renderedValue = () => {
                        if (isNumber)
                          return (
                            <GothamRegular style={styleDefault}>
                              {index + 1}
                            </GothamRegular>
                          );
                        if (isAction)
                          return (
                            <JackIcons
                              name="delete-outline"
                              fill={jackColors.neutral800}
                              style={{
                                width: 20,
                                height: 20,
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(index)}
                            />
                          );
                        if (isSelect) {
                          return (
                            <MinimalistSelect
                              label=""
                              placeholder=""
                              options={formattedOptionsBank}
                              onChange={(e) => handleChange(e, index, name)}
                              noArrowDropDown
                              value={value}
                              required={isRequired}
                              noAsterisk
                              error={isError}
                              errorMsg={errorMsg}
                            />
                          );
                        }
                        if (isEmail) {
                          return (
                            <div>
                              <EmailInput
                                setValue={(e) => handleChange(e, index, name)}
                                value={value}
                                scrollHeight={scrollHeight}
                                headerHeight={modalHeader + 180}
                                index={index}
                              />
                            </div>
                          );
                        }
                        return (
                          <MinimalistTextField
                            name={name}
                            error={isError}
                            errorMsg={errorMsg}
                            prefix={isAmount && "IDR"}
                            label={""}
                            value={value}
                            isNumber={isOnlyNumber}
                            // required={isRequired && "*"}
                            setValue={(e) => handleChange(e, index, name)}
                          />
                        );
                      };

                      if (isDelete) return null;
                      {
                        /* if (renderFirst && isSecondRender) return null;
                      if (!renderFirst && isFirstRender) return null; */
                      }
                      return (
                        <td
                          key={i}
                          style={{
                            padding: "12px 8px",
                            textAlign: (isAction || isNumber) && "center",
                          }}
                        >
                          {renderedValue()}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <BackAndConfirmButton
            renderFirst={renderFirst}
            onClick={handleCloseModal}
            handleAddData={handleAddData}
          />
          <AddRecipientFromList
            isOpen={isOpenList}
            toggle={toggleList}
            close={closeList}
            handleAddRecipient={handleAddRecipient}
          />
        </div>
      </CenteredModalWithHeader>
    </>
  );
};

export const ModalOnboardingLocalTransfer = ({ isOpen, toggle, data }) => {
  return (
    <CenteredModal isOpen={isOpen} toggle={toggle} style={{ width: 516 }}>
      <div className="d-flex justify-content-between">
        <TypeFezNeueMedium className="font24" style={{ color: colors.blue10 }}>
          {data?.headline}
        </TypeFezNeueMedium>
        <img
          src={xIcon}
          style={{ height: 24 }}
          className="hover"
          onClick={toggle}
        />
      </div>
      <TypeFezNeueRegular style={{ marginTop: 16, marginBottom: 24 }}>
        {data?.subHeadline}
      </TypeFezNeueRegular>
      <Cards array={data?.array} />
      <CustomButton
        className="w-100"
        style={{ marginTop: 24 }}
        onClick={() => {
          toggle();
        }}
      >
        Got it
      </CustomButton>
    </CenteredModal>
  );
};

export const AddRecipientFromList = ({
  toggle,
  isOpen,
  close,
  handleAddRecipient,
  isPayroll,
}) => {
  return (
    <JackCenteredModal
      toggle={toggle}
      isOpen={isOpen}
      style={{ borderRadius: "8px !important" }}
      modalWidth={480}
      title={isPayroll ? "Employee List" : "Recipient List"}
    >
      <UserList
        handleClickButton={() => handleContinue("New Recipient")}
        handleAddRecipient={handleAddRecipient}
        toggle={toggle}
        closeModal={close}
        isPayroll={isPayroll}
      />
    </JackCenteredModal>
  );
};
