import { isEmpty } from "lodash";
import { useState } from "react";
import SimpleCenteredModal from "../SimpleCenteredModal";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import styles from "./styles.module.css";
import { useRouter } from "next/router";
import { getUserRole, useActiveModules } from "../../contexts/AuthContext";
import NewTag from "../../components/NewTag";
import { eventsTracker } from "../../universalFunctions/events";
import {
  J2J_EVENT_CLICK_BETWEEN_JACK,
  J2J_EVENT_CLICK_INTERNATIONAL_TRANSFER,
  J2J_EVENT_CLICK_LOCAL_TRANSFER,
  J2J_EVENT_CLICK_SELECT_CONTINUE,
} from "../../pageComponents/jackTransferComponents/constants";

const BETWEEN_JACK_TYPE = "Between Jack";
const LOCAL_TYPE = "Local Transfer";
const INTERNATIONAL_TYPE = "International Transfer";

const TransferSelectionModal = ({ isOpen = false, toggle = () => {} }) => {
  const [isNavigating, setIsNavigating] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});

  const { push } = useRouter();

  const { isDrafterSendMoney, isDrafterBalanceManagement } = getUserRole();

  const isDisabledContinue = isEmpty(selectedOption);

  const handleSelect = (option) => {
    switch (option.type) {
      case BETWEEN_JACK_TYPE:
        eventsTracker(J2J_EVENT_CLICK_BETWEEN_JACK, {
          parameters: { page_title: "dashboard" },
          value: "transfer_option",
        });
        break;
      case LOCAL_TYPE:
        eventsTracker(J2J_EVENT_CLICK_LOCAL_TRANSFER, {
          parameters: { page_title: "dashboard" },
          value: "transfer_option",
        });
        break;
      case INTERNATIONAL_TYPE:
        eventsTracker(J2J_EVENT_CLICK_INTERNATIONAL_TRANSFER, {
          parameters: { page_title: "dashboard" },
          value: "transfer_option",
        });
        break;
      default:
        break;
    }
    setSelectedOption(option);
  };

  const handleToggle = () => {
    if (isNavigating) return;
    setSelectedOption({});
    toggle();
  };

  const handleContinue = () => {
    setIsNavigating(true);
    eventsTracker(J2J_EVENT_CLICK_SELECT_CONTINUE, {
      parameters: { page_title: "dashboard" },
      value: "transfer_option",
    });
    push(selectedOption?.value);
  };

  const { isActiveInternationalTransfer, isActiveLocalTransfer } =
    useActiveModules();

  const options = [
    isDrafterBalanceManagement && {
      type: "Between Jack",
      icon: "swap-outline",
      value: "/jack-transfer",
      description: "Transfer to other companies registered on Jack.",
      isNew: true,
    },
    isDrafterSendMoney &&
      isActiveLocalTransfer && {
        type: "Local Transfer",
        icon: "send-money",
        value: "/local-transfer/create",
        description: "Transfer to conventional bank in Indonesia.",
      },
    isDrafterSendMoney &&
      isActiveInternationalTransfer && {
        type: "International Transfer",
        icon: "international-transfer",
        value: "/cross-border/single",
        description: "Transfer money globally, to any country.",
      },
  ].filter((item) => item);

  return (
    <SimpleCenteredModal
      isOpen={isOpen}
      buttonText="Select & Continue"
      isLoading={isNavigating}
      isDisabled={isDisabledContinue}
      leftHeaderComponent={
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900 }}
        >
          Choose the transfer option
        </GothamMedium>
      }
      toggle={handleToggle}
      onClick={handleContinue}
    >
      <div className={styles.content}>
        {options.map((option) => {
          const { type, icon, description, isNew } = option;

          const isSelected = type === selectedOption?.type;

          return (
            <div
              key={type}
              className={styles.option}
              style={{
                border: isSelected ? `1px solid ${jackColors.neutral900}` : "",
              }}
              onClick={() => handleSelect(option)}
            >
              <div className={styles.iconContainer}>
                <JackIcons name={icon} fill="white" />
              </div>

              <div>
                <div className={styles.typeContainer}>
                  <GothamMedium style={{ color: jackColors.neutral900 }}>
                    {type}
                  </GothamMedium>
                  {isNew && <NewTag />}
                </div>
                <GothamRegular
                  woFontColor
                  className="font12"
                  style={{ color: jackColors.neutral800, marginTop: "4px" }}
                >
                  {description}
                </GothamRegular>
              </div>

              {isSelected && (
                <JackIcons
                  name="checkmark-circle-2"
                  fill={jackColors.neutral900}
                  style={{ position: "absolute", right: "16px", top: "26px" }}
                />
              )}
            </div>
          );
        })}
      </div>
    </SimpleCenteredModal>
  );
};

export default TransferSelectionModal;
