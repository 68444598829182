import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";

export const useSubscription = (subscription) => {
  const { isSuspended, isOverdue } = subscription || {};

  const [showSuspendOrOverdueModal, setShowSuspendOrOverdueModal] =
    useState(false);

  // To show modal if account status is suspended or overdue
  useEffect(() => {
    if ((isSuspended || isOverdue) && !showSuspendOrOverdueModal) {
      const showed = JSON.parse(
        localStorage.getItem("showed_suspend_or_overdue_modal")
      );

      if (!showed) {
        setShowSuspendOrOverdueModal(true);
      }
    }
  }, [isSuspended, isOverdue, showSuspendOrOverdueModal]);

  // To set localStorage if the modal has already been showed in the user login session
  // The user will not see the modal twice or more
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showSuspendOrOverdueModal) {
        localStorage.setItem("showed_suspend_or_overdue_modal", true);
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [showSuspendOrOverdueModal]);

  return { ...subscription, showSuspendOrOverdueModal };
};

export const subscriptionFormatter = (data) => {
  const {
    account_status,
    max_physical_cards,
    max_users,
    max_virtual_cards,
    plan_type,
    total_physical_cards,
    total_users,
    total_virtual_cards,
    due_date,
    invoice_qty_left,
    reimbursement_qty_left,
    max_invoice_free_fee,
    max_reimbursement_free_fee,
  } = data || {};

  const isSuspended = account_status === "suspended";
  const isOverdue = account_status === "overdue";

  const isSeedPlan = plan_type === "seed";
  const isGrowthPlanStrict = plan_type === "growth";
  const isLongTermPlanStrict = plan_type === "long term";
  const isUltimate = plan_type === "ultimate";
  const isGrowthPlan = ["long term", "growth"].includes(plan_type);
  const isUltimatePlan = plan_type === "ultimate";

  const isUnlimitedPhysicalCards = max_physical_cards < 0;
  const isUnlimitedVirtualCards = max_virtual_cards < 0;
  const isUnlimitedUsers = max_users < 0;

  const isReachedMaxPhysicalCards =
    total_physical_cards >= max_physical_cards && !isUnlimitedPhysicalCards;
  const isReachedMaxVirtualCards =
    total_virtual_cards >= max_virtual_cards && !isUnlimitedVirtualCards;
  const isReachedMaxCards =
    isReachedMaxPhysicalCards && isReachedMaxVirtualCards;

  const isReachedMaxSeats = total_users >= max_users && !isUnlimitedUsers;

  const isSeedV2Invoice = isSeedPlan && invoice_qty_left != null;
  const isReachedInvoiceLimit = isSeedV2Invoice && invoice_qty_left <= 0;

  const isSeedV2Reimbursement = isSeedPlan && reimbursement_qty_left != null;
  const isReachedReimbursementLimit =
    isSeedV2Reimbursement && reimbursement_qty_left <= 0;

  const dueDate = isGrowthPlan ? moment(due_date).format("LL") : "";

  const thereIsNoUsage = isEmpty(data);

  const reachedMaxPhysicalCards =
    total_physical_cards >= max_physical_cards && !(max_physical_cards < 0);
  const reachedMaxVirtualCards =
    total_virtual_cards >= max_virtual_cards && !(max_virtual_cards < 0);
  const reachedMaxCards = reachedMaxPhysicalCards && reachedMaxVirtualCards;

  return {
    account_status,
    max_physical_cards,
    max_users,
    max_virtual_cards,
    plan_type,
    total_physical_cards,
    total_users,
    total_virtual_cards,
    dueDate,
    isSuspended,
    isOverdue,
    isGrowthPlan,
    isGrowthPlanStrict,
    isLongTermPlanStrict,
    thereIsNoUsage,
    isSeedPlan,
    isReachedMaxCards,
    isReachedMaxPhysicalCards,
    isReachedMaxVirtualCards,
    invoice_qty_left,
    reimbursement_qty_left,
    isReachedMaxSeats,
    max_invoice_free_fee,
    max_reimbursement_free_fee,
    isReachedInvoiceLimit,
    isReachedReimbursementLimit,
    isSeedV2Invoice,
    isSeedV2Reimbursement,
    isUltimate,
    reachedMaxCards,
    reachedMaxPhysicalCards,
    reachedMaxVirtualCards,
    isUltimatePlan,
  };
};
