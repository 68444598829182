import { times } from "lodash";
import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "../../assets/colors";
import invoices from "../../assets/images/invoices.svg";
import trashGreyV from "../../assets/images/trash-grey-3.svg";
import { CheckBox, useIdsTable } from "../../components/Checkbox";
import { StatusLabel } from "../../components/Status";
import {
  TypeFezNeueBold,
  TypeFezNeueLight,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../components/Text";
import { canRefetchDecider, windowDimension } from "../../components/tools";
import CustomTooltip from "../../components/Tooltip";
import { TableRowDots, Tbody, TdText } from "../tableComponents";
const headerArrDefault = [
  { title: "Invoice Number", width: 14 },
  { title: "Payment to", width: 15 },
  { title: "Created Date", width: 12 },
  { title: "Due Date", width: 12 },
  { title: "Total Amount", width: 15 },
  { title: "Status", width: 12 },
  { title: "Description", width: 20 },
];
const headerArrCreate = [
  { title: "No", width: 5 },
  { title: "Invoice Number", width: 18 },
  { title: "Payment to", width: 16 },
  { title: "Due Date", width: 12 },
  { title: "Total Amount", width: 16 },
  { title: "Description", width: 19 },
  { title: "Status", width: 9 },
  { title: "", width: 5 },
];
const headerArrDraft = [
  { title: "Saved Date", width: 12 },
  { title: "Invoice Number", width: 15 },
  { title: "Payment to", width: 15 },
  { title: "Due Date", width: 12 },
  { title: "Total Amount", width: 14 },
  { title: "Description", width: 13 },
  { title: "Status", width: 9 },
  { title: "", width: 5 },
];

const CardShimmer = () => {
  return times(20, (index) => (
    <TableRowDots key={index} style={{ height: "48px" }}>
      <td style={{ padding: "0px 16px" }}>
        {" "}
        <Skeleton width={120} height={15} />
      </td>
      <td style={{ padding: "0px 16px" }}>
        {" "}
        <Skeleton width={120} height={15} />
      </td>
      <td style={{ padding: "0px 16px" }}>
        {" "}
        <Skeleton width={85} height={15} />
      </td>
      <td style={{ padding: "0px 16px" }}>
        {" "}
        <Skeleton width={85} height={15} />
      </td>
      <td style={{ padding: "0px 16px" }}>
        {" "}
        <Skeleton width={120} height={15} />
      </td>
      <td style={{ padding: "0px 16px" }}>
        {" "}
        <Skeleton width={60} height={15} />
      </td>
      <td style={{ padding: "0px 16px" }}>
        {" "}
        <Skeleton width={140} height={15} />
      </td>
    </TableRowDots>
  ));
};

const PDFOrImage = ({ pdf }) => {
  if (!pdf) return null;

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundColor: colors.greye6,
        border: "1px solid #BBBBC0",
        width: 40,
        height: 40,
        borderRadius: 4,
      }}
    >
      <img src={pdf} style={{ width: 25, height: 36 }} />
    </div>
  );
};

export const ElipsisTextHover = ({
  children,
  style,
  length = 20,
  woHover = false,
  isBold = false,
  fontStyle = {},
}) => {
  const childrenLengthMaxReached = children?.length > length;
  const newString = children?.split("")?.slice(0, length)?.join("") + "...";
  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      {childrenLengthMaxReached && !woHover ? (
        <CustomTooltip
          placement="bottom"
          text={
            <div
              style={{
                padding: 8,
                maxWidth: "auto",
                height: "auto",
                backgroundColor: "white",
                color: "#6C6C71",
                zIndex: 55,
              }}
            >
              <TypeFezNeueLight style={{ fontSize: 12, ...fontStyle }}>
                {children}
              </TypeFezNeueLight>
            </div>
          }
          style={{
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
            opacity: 1,
          }}
          popperClassName="white-arrow"
        >
          {isBold ? (
            <TypeFezNeueBold style={fontStyle}>{newString}</TypeFezNeueBold>
          ) : (
            <TypeFezNeueLight style={fontStyle}>{newString}</TypeFezNeueLight>
          )}
        </CustomTooltip>
      ) : isBold ? (
        <TypeFezNeueBold style={fontStyle}>{children}</TypeFezNeueBold>
      ) : (
        <TypeFezNeueLight style={fontStyle}>{children}</TypeFezNeueLight>
      )}
    </div>
  );
};

export const statusToolTip = (is_duplicate, check_is_complete) => {
  const statusDecider = () => {
    if (is_duplicate) return "Duplicate";
    if (check_is_complete) return "Completed";
    return "Incomplete";
  };

  const textDecider = () => {
    if (is_duplicate)
      return {
        headline: "Invoice already exists",
        text: "Please just keep one and remove the others.",
      };
    return { headline: "Incomplete Data", text: "Please complete this data" };
  };
  const { headline, text } = textDecider();

  const StatusToolTipComponent = ({
    check_is_complete,
    headline,
    text,
    status,
  }) => {
    if (!check_is_complete) {
      return (
        <CustomTooltip
          placement="top"
          text={
            <div
              style={{
                padding: 8,
                maxWidth: "auto",
                height: "auto",
                backgroundColor: "white",
                color: "#6C6C71",
                zIndex: 55,
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
              }}
            >
              <TypeFezNeueMedium style={{ color: "#E73126" }}>
                {headline}
              </TypeFezNeueMedium>
              <TypeFezNeueLight style={{ fontSize: 12 }}>
                {text}
              </TypeFezNeueLight>
            </div>
          }
          style={{
            backgroundColor: "white",
            boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
            opacity: 1,
          }}
          popperClassName="white-arrow"
        >
          <TypeFezNeueRegular
            style={{
              color: check_is_complete ? "#06AC4E" : "#E73126",
              fontSize: 12,
            }}
          >
            {status}
          </TypeFezNeueRegular>
        </CustomTooltip>
      );
    }

    return (
      <TypeFezNeueRegular
        style={{
          color: "#06AC4E",
          fontSize: 12,
        }}
      >
        Completed
      </TypeFezNeueRegular>
    );
  };

  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      <StatusToolTipComponent
        check_is_complete={check_is_complete}
        headline={headline}
        text={text}
        status={statusDecider()}
      />
    </div>
  );
};

export const InvoicePaymentTable = ({
  onClick,
  handleScroll,
  headerArrProps = headerArrDefault,
  bodyArr = [],
  emptyScreenMsg = "",
  componentHeight = 0,
  selection = [],
  isCreate,
  setSelectedIds,
  isLoading,
}) => {
  const tableBodyRef = useRef();
  const onScroll = () => {
    const canRefetch = canRefetchDecider(tableBodyRef, isLoading);
    if (!canRefetch) return;
    handleScroll && handleScroll();
  };
  const isDraft = selection.length;
  const draftOrCreate = Boolean(isDraft || isCreate);
  const {
    handleClickAll,
    selectedIds,
    isAllChecked,
    handleClickItem,
    handleRemoveAllId,
  } = useIdsTable(bodyArr);

  // send selectedIds to parent
  useEffect(() => {
    if (!setSelectedIds) return;
    setSelectedIds(selectedIds);
  }, [selectedIds]);
  // send selectedIds to parent
  // cleans if tablearray not synced
  useEffect(() => {
    const bodyArrIds = bodyArr.map(({ id }) => id);
    const isDeletedBodyArr = selectedIds.filter(
      (id) => !bodyArrIds.includes(id)
    ).length;
    if (isDeletedBodyArr) return;
    handleRemoveAllId();
  }, [bodyArr.length]);
  // cleans if tablearray not synced

  const [hoverId, setHoverId] = useState(false);

  const headerArrDecider = () => {
    if (isCreate) return headerArrCreate;

    if (isDraft)
      return [
        {
          title: <CheckBox isActive={isAllChecked} onClick={handleClickAll} />,
          width: 5,
        },
        ...headerArrDraft,
      ];

    return headerArrProps;
  };

  const headerArr = headerArrDecider();

  const { screenRealHeight } = windowDimension();
  const totalHeight = screenRealHeight - componentHeight;
  return (
    <div
      ref={tableBodyRef}
      onScroll={() => onScroll()}
      className="table-responsive"
      style={{
        height: totalHeight,
      }}
    >
      <table
        className="w-100"
        style={{
          width: "100%",
          borderCollapse: "separate",
          borderSpacing: "0 8px",
          marginBottom: 20,
          position: "relative",
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr
            style={{
              position: "sticky",
              top: 0,
              borderRadius: 8,
              height: 52,
              zIndex: 99,
              backgroundColor: "rgba(230, 230, 232)",
            }}
          >
            {headerArr?.map((header, index) => {
              const { title, width } = header;
              const isFirst = index == 0;
              const isLast = index == headerArr.length - 1;
              return (
                <td
                  key={index}
                  style={{
                    width: `${width}%`,
                    padding: 16,
                    borderTopLeftRadius: isFirst ? 8 : 0,
                    borderBottomLeftRadius: isFirst ? 8 : 0,
                    borderTopRightRadius: isLast ? 8 : 0,
                    borderBottomRightRadius: isLast ? 8 : 0,
                  }}
                >
                  <TypeFezNeueMedium style={{ color: "#333334" }}>
                    {title}
                  </TypeFezNeueMedium>
                </td>
              );
            })}
          </tr>
        </thead>
        <Tbody style={{ textJustify: "center" }}>
          {bodyArr.map((item, index) => {
            const {
              invoiceNumber,
              billTo,
              totalAmount,
              status,
              description,
              id,
              is_duplicate,
              check_is_complete,
              createdDateShort,
              dueDateShort,
              draftedAtShort,
            } = item || {};
            const number = index + 1;
            const isHoverActive = hoverId == id;
            const isActive = selectedIds.includes(id);
            const style = Boolean(isActive)
              ? { backgroundColor: colors.lightPink }
              : {};
            const hoverStyle = Boolean(isHoverActive)
              ? { backgroundColor: "#FDD7F5" }
              : {};
            const selectionDecider = () => {
              if (is_duplicate) return false;
              if (isCreate) {
                if (!check_is_complete) {
                  return selection?.filter((option) => option.name != "submit");
                }
                return selection;
              }

              if (isDraft) {
                if (!check_is_complete) {
                  return selection?.filter(
                    (option) => option.value != "submit"
                  );
                }
                if (!Boolean(isActive) && check_is_complete) {
                  return selection?.filter(({ value }) => value != "submit");
                }
              }

              return selection;
            };

            const checkboxDecider = () => {
              if (isCreate) return null;
              if (isDraft) {
                if (isHoverActive || isActive)
                  return (
                    <td style={{ padding: "0px 16px" }}>
                      <CheckBox
                        isActive={isActive}
                        onClick={(e) => {
                          if (!e) return;
                          e.preventDefault();
                          e.stopPropagation();
                          handleClickItem(id, isActive);
                        }}
                      />
                    </td>
                  );
                return (
                  <TdText style={{ padding: "0px 18px" }}>
                    <TypeFezNeueLight>{number}</TypeFezNeueLight>
                  </TdText>
                );
              }
              return null;
            };

            return (
              <TableRowDots
                style={{
                  height: 56,
                  alignSelf: "end",
                  ...style,
                  ...hoverStyle,
                }}
                trOnClick={() => onClick && onClick(item)}
                data={selectionDecider()}
                key={number}
                onMouseEnter={() => setHoverId(id)}
                onMouseLeave={() => setHoverId(false)}
                onClick={(arrayItem) => onClick({ ...item, arrayItem })}
                woTd
              >
                {checkboxDecider()}
                {isCreate ? (
                  <td style={{ padding: 16 }}>
                    <TypeFezNeueLight
                      style={{
                        marginLeft: 8,
                        color: is_duplicate ? "#909098" : "",
                      }}
                    >
                      {number}
                    </TypeFezNeueLight>
                  </td>
                ) : null}
                {isDraft && !isCreate ? (
                  <td style={{ padding: "0px 16px" }}>
                    <TypeFezNeueLight>{draftedAtShort}</TypeFezNeueLight>
                  </td>
                ) : null}
                <td style={{ padding: "0px 16px" }}>
                  <ElipsisTextHover
                    length={15}
                    fontStyle={is_duplicate ? { color: "#909098" } : {}}
                  >
                    {invoiceNumber}
                  </ElipsisTextHover>
                </td>
                <td style={{ padding: "0px 16px" }}>
                  <ElipsisTextHover
                    length={15}
                    fontStyle={{
                      color: is_duplicate ? "#909098" : "",
                    }}
                  >
                    {billTo || "-"}
                  </ElipsisTextHover>
                </td>
                {!draftOrCreate ? (
                  <td style={{ padding: "0px 16px" }}>
                    <TypeFezNeueLight>{createdDateShort}</TypeFezNeueLight>
                  </td>
                ) : null}
                <td style={{ padding: "0px 16px" }}>
                  <TypeFezNeueLight
                    style={{ color: is_duplicate ? "#909098" : "" }}
                  >
                    {dueDateShort}
                  </TypeFezNeueLight>
                </td>
                <td style={{ padding: "0px 16px" }}>
                  <TypeFezNeueLight
                    style={{ color: is_duplicate ? "#909098" : "" }}
                  >
                    {totalAmount}
                  </TypeFezNeueLight>
                </td>
                {!draftOrCreate ? (
                  <td style={{ padding: "0px 16px" }}>
                    <StatusLabel status={status} />
                  </td>
                ) : null}
                <td style={{ padding: "0px 16px" }}>
                  <ElipsisTextHover
                    style={{ color: is_duplicate ? "#909098" : "" }}
                  >
                    {description || "-"}
                  </ElipsisTextHover>
                </td>
                {isCreate || isDraft ? (
                  <td style={{ padding: "0px 16px" }}>
                    <TypeFezNeueRegular
                      style={{
                        color: check_is_complete ? "#06AC4E" : "#E73126",
                        fontSize: 12,
                      }}
                    >
                      {statusToolTip(is_duplicate, check_is_complete)}
                    </TypeFezNeueRegular>
                  </td>
                ) : null}
                {isCreate && is_duplicate ? (
                  <td>
                    <img
                      src={trashGreyV}
                      onClick={() =>
                        onClick({ ...item, arrayItem: { value: "delete" } })
                      }
                    />
                  </td>
                ) : null}
              </TableRowDots>
            );
          })}
          {isLoading && <CardShimmer />}
        </Tbody>
      </table>
      {!bodyArr.length && !isLoading && (
        <div
          style={{ flexDirection: "column", width: "100%", marginTop: -20 }}
          className="d-flex justify-content-center align-items-center"
        >
          <img
            src={invoices}
            style={{ marginTop: 64, marginBottom: 12, width: 94 }}
          />
          <TypeFezNeueMedium
            className="font16"
            style={{ color: colors.grey90 }}
          >
            {emptyScreenMsg}
          </TypeFezNeueMedium>
        </div>
      )}
    </div>
  );
};
