import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useModalHook } from "../../../components/Modals";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useHeader } from "../../../contexts/Layout/parent";
import { PayrollPinModal } from "../../../modals/smartActivityModal/payroll/pinModal";
import { apiBusiness, fetch, useMutation } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { ProgressBarComponent } from "../../crossBorderComponents";
import { ConfirmationPayroll } from "../../payrollCreateComponents/loop/confirmation/parent";
import { useChangeStatePayroll } from "../../payrollCreateComponents/loop/validation/defaultPage/logic";
import {
  defaultValuesLocalTransferFormatter,
  localTransferTotalAmount,
} from "./formatter";
import { BalanceNotEnoughModal } from "./modals";

export const LocalTransferConfirmation = ({ defaultValues, batch }) => {
  const { id: batch_id } = batch || {};

  const { push, query } = useRouter();

  const pushQuery = (queries) =>
    push({ query: { ...query, ...queries, menu: "all_transactions" } });

  const { user } = useGetAuth();
  // const balance = user?.partner?.disbursement_balance[0]?.balance || 0;

  const mergedDefaultValues = defaultValuesLocalTransferFormatter({
    batch,
    defaultValues,
  });

  const { loading: loadingDelete, mutation: deleteTransactions } = useMutation({
    url: `/local_transaction_batches/${batch_id}/bulk_trx_destroy`,
  });

  const { data: isNeedPin, loading: loadingNeedPin } = fetch({
    url: "/business_workflows/is_default_case",
    formatter: (res) => res.is_default,
  });

  const { changeState, loading: loadingChangeState } = useChangeStatePayroll(
    batch_id,
    () => pushQuery({ state: "create" })
  );

  const {
    changeState: changeStateToSuccess,
    loading: loadingChangeStateToSuccess,
  } = useChangeStatePayroll(batch_id, () => {
    // pushQuery({ state: "success" });
    push(`/success/?type=local_transfer&id=${batch_id}`);
    eventsTracker("continue_to_payment_local_transfer");
  });

  const {
    changeState: changeStateDefault,
    loading: loadingChangeStateDefault,
  } = useChangeStatePayroll();

  const handleSubmit = async (values) => {
    const { pin } = values || {};
    changeStateToSuccess({ pin, state: "payment" });
  };

  const [totalAmount, setTotalAmount] = useState(0);

  const onSubmit = async ({ state, values: ids }) => {
    const isDraft = state == "draft";
    ids.length && (await deleteTransactions({ ids }));

    if (isDraft) {
      eventsTracker("save_as_draft_from_confirmation_local_transfer");
      return push({
        pathname: "/transactions",
        query: {
          page_type_details: "batch_payment",
          id: batch_id,
          page_type: "local_batch",
        },
      });
    }

    const totalAmount = localTransferTotalAmount(mergedDefaultValues, ids);

    // const isBalanceEnough = balance >= Number(totalAmount);

    // if (isNeedPin) return setTotalAmount(totalAmount);

    handleSubmit();
  };

  const loading =
    loadingChangeState ||
    loadingChangeStateToSuccess ||
    loadingChangeStateDefault ||
    loadingDelete ||
    loadingNeedPin;

  const handleBack = async () => {
    try {
      const { action, ancestor_id } = query;
      const isResubmit = action === "resubmit";

      if (isResubmit) {
        await apiBusiness.delete(`/local_transaction_batches/${batch_id}`);
        return push({
          pathname: `/local-transfer/create/${ancestor_id}`,
          query: { action, ancestor_id },
        });
      }

      changeState({ state: "draft" });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  if (isEmpty(defaultValues)) return null;
  return (
    <div style={{ position: "relative" }}>
      <ConfirmationPayroll
        defaultValues={mergedDefaultValues}
        onSubmit={onSubmit}
        headerArr={[
          { label: "Recipient", value: "name", width: 320 },
          { label: "Bank", value: "bank", width: 240 },
          { label: "Notes", value: "notes", width: 240 },
          { label: "Email", value: "formatted_email", width: 240 },
          { label: "Amount", value: "formatted_amount", width: 160 },
        ]}
        hideNav
        title="Confirm to submit"
        handleBack={handleBack}
        loading={loading}
        isLocalTransfer
      />
      {/* <PayrollPinModal
        isOpen={Boolean(totalAmount)}
        toggle={() => setTotalAmount(0)}
        onSubmit={handleSubmit}
        totalAmount={totalAmount}
        loading={loadingChangeStateToSuccess}
      /> */}
    </div>
  );
};
