import styles from "../ledgers.module.css";
import { jackColors } from "../../../assets/colors";
import { GothamBold, GothamRegular } from "../../../components/Text";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import SimpleCenteredModal from "../../../modals/SimpleCenteredModal";
import NextImage from "../../../components/NextImage";
import { useRouter } from "next/router";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../assets/jackIcons/parent";
// import infoImg from "../../../assets/images/va-info-image.svg";
// import { useVaFlag } from "./hooks";
import { eventsTracker } from "../../../universalFunctions/events";
import VaPocketImg from "../../../assets/jackImages/va-pocket-header.svg";
import PrimaryBankImg from "../../../assets/jackImages/primary-bank-header.svg";

export const LedgersOnboardingModal = ({
  isOpen = false,
  toggle = () => {},
  open,
  menu = "primary",
}) => {
  // const { user } = useGetAuth();
  // const { all_flags } = user || [];
  const { push, pathname } = useRouter();
  // const { isEmployee } = getUserRole();
  // const { vaFlags, addVaFlag, hasIntroducedVa, hasSeenVaPage } = useVaFlag();
  // const isFirstTime = !(all_flags || []).includes(vaFlags?.hasSeenVaPage);

  const isVaPocket = menu === "va-pocket";
  const isPrimaryBankAccount = menu === "primary";

  const isFirstTime = false;

  const text = (type, firstTime = false) => {
    switch (type) {
      case "title":
        if (isVaPocket) return "It’s a space for receivables payments!";
        if (isPrimaryBankAccount)
          return "It’s your main source of funds in Jack!";

      case "first":
        if (isVaPocket)
          return "Funds you received through Jack's Virtual Account are automatically saved in this pocket. You can use it as the source of funds for transactions within Jack.";
        if (isPrimaryBankAccount)
          return "Empowered by Danamon Bank, this account securely stores your funds for safe transactions within Jack.";

      case "second":
        if (isVaPocket)
          return "For added security and control, flexibly move the balance to your Primary Bank Account at any time.";
        if (isPrimaryBankAccount)
          return "It functions just like your regular bank account, complete with its own number for familiar and straightforward fund management.";

      case "third":
        if (isPrimaryBankAccount)
          return (
            <>
              To learn more, please check{" "}
              <span
                style={{
                  fontFamily: "GothamMedium",
                  textDecoration: "underline",
                }}
              >
                this article.
              </span>
            </>
          );
      default:
        break;
    }
  };

  const HeaderText = () => (
    <GothamBold style={{ fontSize: "20px", margin: 0 }}>
      {isVaPocket ? "What is VA Pocket?" : "Get to know Primary Bank Account"}
    </GothamBold>
  );

  const modalImage = isVaPocket ? VaPocketImg : PrimaryBankImg;

  const handleMainButtonClick = () => {
    // if (isVaPocket) {
    //   toggle();
    //   eventsTracker("virtual-account-first-time-onboarding-completed");
    //   push("/virtual-account");
    //   return addVaFlag(vaFlags.hasIntroducedVa);
    // }
    return toggle();
  };

  const handleCloseButtonClick = () => {
    // if (isVaPocket) {
    //   eventsTracker("virtual-account-first-time-onboarding-completed");
    //   addVaFlag(vaFlags.hasIntroducedVa);
    //   return toggle();
    // }
    // eventsTracker("virtual-account-first-time-page-visit-completed");
    toggle();
    // return addVaFlag(vaFlags.hasSeenVaPage);
  };

  const handleGoToCreate = () => {
    eventsTracker("virtual-account-first-time-page-visit-completed");
    push("/virtual-account/create");
    return addVaFlag(vaFlags.hasSeenVaPage);
  };

  const fontStyle = {
    woFontColor: false,
    style: { color: jackColors.neutral800 },
  };

  // useEffect(() => {
  //   if (isEmpty(user) || !user) return;
  //   if (isEmployee) return;
  //   const isVaPage = pathname === "/virtual-account" && !isHome;
  //   const isDashboardPage = pathname === "/dashboard" && isHome;

  //   if (isVaPage) {
  //     if (hasSeenVaPage) return;
  //     return open();
  //   }

  //   if (isDashboardPage) {
  //     if (hasIntroducedVa) return;
  //     return open();
  //   }
  // }, [user, pathname, hasIntroducedVa, hasSeenVaPage]);

  // if (isEmployee) return null;

  return (
    <SimpleCenteredModal
      isOpen={isOpen}
      toggle={() => handleCloseButtonClick()}
      buttonText={"Got it!"}
      onClick={() => handleMainButtonClick()}
      modalClassName={styles["modal"]}
      leftHeaderComponent={<HeaderText />}
      customButtons={
        isFirstTime ? (
          <div
            style={{
              display: "flex",
              gap: 16,
              width: "100%",
            }}
          >
            <ButtonJack
              type="outline"
              onClick={() => handleCloseButtonClick()}
              style={{ width: "100%" }}
            >
              I’ll do it later
            </ButtonJack>
            <ButtonJack
              onClick={() => handleGoToCreate()}
              style={{ width: "100%" }}
              rightIcon={
                <JackIcons name="arrow-forward" fill={jackColors.neutral900} />
              }
            >
              Create Virtual Account
            </ButtonJack>
          </div>
        ) : null
      }
    >
      <div className={styles["content-container"]}>
        <div>
          <NextImage
            width={476}
            height={200}
            src={modalImage}
            alt="Modal Image"
          />
          <GothamBold woFontSize className="font20">
            {text("title", isFirstTime)}
          </GothamBold>
        </div>
        <div>
          <GothamRegular {...fontStyle}>
            {text("first", isFirstTime)}
          </GothamRegular>
          <GothamRegular {...fontStyle}>
            {text("second", isFirstTime)}
          </GothamRegular>
          <GothamRegular {...fontStyle}>
            {text("third", isFirstTime)}
          </GothamRegular>
        </div>
      </div>
    </SimpleCenteredModal>
  );
};
