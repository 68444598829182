import React, { Fragment } from "react";
import { colors } from "../../assets/colors";
import { Line } from "../Lines";
import { GothamMedium, GothamRegular } from "../Text";
import { windowDimension } from "../tools";

const pageTogglerHooksDisplay = () => {
  const { figmaWidth, figmaHeight } = windowDimension();
  const defaultDivStyle = {
    minWidth: figmaWidth(185),
    height: figmaHeight(48),
    display: "flex",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  };
  return { defaultDivStyle };
};

const Container = ({ children }) => (
  <div
    className="mx-0 d-flex"
    style={{
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
    }}
  >
    {children}
  </div>
);

const TitleContainer = ({ onClick, isActive, children, isFirst, isLast }) => {
  const { defaultDivStyle } = pageTogglerHooksDisplay();
  return (
    <div
      onClick={onClick}
      style={
        isActive
          ? {
              ...defaultDivStyle,
              backgroundColor: "white",
              boxShadow: "4px -4px 10px 0px rgba(73, 171, 184, 0.25)",
            }
          : {
              ...defaultDivStyle,
              backgroundColor: colors.greyea,
              borderTopLeftRadius: isFirst ? 8 : 0,
              borderTopRightRadius: isLast ? 8 : 0,
            }
      }
      className="align-items-center justify-content-center darkhover px-5"
    >
      {children}
    </div>
  );
};

const Title = ({ children, isActive }) => (
  <GothamMedium
    style={{
      textAlign: "center",
      fontSize: 20,
      color: isActive ? colors.teal : colors.greyC2,
    }}
  >
    {children}
  </GothamMedium>
);

export const NumberNotif = ({ number, style, isLast, woAbsolute = false }) => {
  const getNumber = number > 99 ? "99+" : number;
  if (!number) return null;
  return (
    <div
      style={{
        backgroundColor: colors.lightPink,
        minWidth: 20,
        minHeight: 20,
        borderRadius: 20,
        position: !woAbsolute && "absolute",
        right: !woAbsolute && isLast ? -30 : -10,
        top: !woAbsolute && 8,
        width: 36,
        ...style,
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <GothamRegular
        style={{
          color: colors.pink,
          padding: 8,
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {getNumber}
      </GothamRegular>
    </div>
  );
};

export const PageTogglerMultiDefault = ({
  selected,
  onClick: onClickProps,
  titleArray,
  simple,
  woAbsolute = false,
}) => (
  <>
    <Container>
      {titleArray.map(({ label, value, number }, index) => {
        const isActive = selected == value;
        const isFirst = index == 0;
        const isLast = index == titleArray.length - 1;

        const onClick = () => {
          if (isActive) return;
          onClickProps(value);
        };

        if (simple)
          return (
            <Fragment key={index}>
              <div
                style={{
                  minWidth: 132,
                  padding: 12,
                  border: "1px solid black",
                  borderWidth: 0,
                  borderBottomWidth: 4,
                  borderColor: isActive ? colors.pink : "transparent",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  height: 48,
                  position: "relative",
                  borderRadius: 0,
                }}
                onClick={onClick}
                className="d-flex darkhover"
              >
                <GothamMedium
                  style={{
                    fontSize: 16,
                    color: isActive ? colors.grey33 : colors.grey90,
                    fontFamily: isActive ? "GothamMedium" : "GothamBook",
                  }}
                >
                  {label}
                </GothamMedium>
                <NumberNotif
                  number={number}
                  isLast={isLast}
                  woAbsolute={woAbsolute}
                />
              </div>
            </Fragment>
          );
        return (
          <TitleContainer
            onClick={onClick}
            key={index}
            isActive={isActive}
            isFirst={isFirst}
            isLast={isLast}
          >
            <Title isActive={isActive}>{label}</Title>
          </TitleContainer>
        );
      })}
    </Container>
    {simple && <Line />}
  </>
);
