import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { cardTransactionBodyTableFormatter } from "./formatter";
import { useCategoryFlag } from "../../../../../categoryComponents/general/hooks";
import { fetch } from "../../../../../../tools/api";
import { cardDuplicateFilter } from "../../../../tools";

export const useCardTransactionTable = ({ cardDetail }) => {
  const [bodyTable, setBodyTable] = useState([]);
  const {
    data: transactions,
    loading,
    setData: setTransactions,
    nextPage,
  } = useFetchCardTransaction({ cardDetail });

  const { shallCheckMissingCategory } = useCategoryFlag();

  const headerTable = [
    {
      label: "Descriptions",
      width: "54%",
    },
    {
      label: "Amount",
      textAlign: "right",
      width: "36%",
    },
    {
      label: "Attachment",
      textAlign: "center",
      width: "20%",
    },
  ];

  useEffect(() => {
    const bodyTable = transactions?.map((transaction) =>
      cardTransactionBodyTableFormatter(transaction, shallCheckMissingCategory)
    );
    setBodyTable(bodyTable);
  }, [transactions]);

  return {
    headerTable,
    bodyTable,
    loading,
    transactions,
    setTransactions,
    nextPage,
  };
};

export const useFetchCardTransaction = ({ cardDetail }) => {
  const PER_PAGE = 20;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isMaxedOut, setIsMaxedOut] = useState(false);
  const { id } = cardDetail || {};

  const { query } = useRouter();
  const {
    merchant_name = "",
    sort_by = "date desc",
    transaction_status,
    runQueryTransaction,
  } = query;

  const statusArray = Array.isArray(transaction_status)
    ? [...transaction_status]
    : transaction_status
    ? [transaction_status]
    : [];

  const stateQuery = statusArray
    .map((item) => `q[state_matches_any][]=${item}`)
    .join("&");

  const { refetch, loading } = fetch({
    url: `/card_transactions?page=${page}&per_page=${PER_PAGE}&q[transaction_type_not_in][]=CREDIT_PAYMENT&q[transaction_type_not_in][]=CREDIT_TOPUP_BILL&q[user_card_id_eq]=${id}&q[merchant_name_cont]=${merchant_name}&q[sorts]=${sort_by}${
      stateQuery ? `&${stateQuery}` : ""
    }`,
    afterSuccess: (res) => {
      const transactions = res.data;
      if (transactions.length < PER_PAGE) setIsMaxedOut(true);
      setData((prev) => {
        const newArray = [...prev, ...res.data];
        const uniqueArray = cardDuplicateFilter(newArray);
        return uniqueArray;
      });
    },
  });

  useEffect(() => {
    if (page === 1) return;
    refetch();
  }, [page]);

  const isRunQueryTransaction = runQueryTransaction === "true";

  useEffect(() => {
    if (!isRunQueryTransaction) return;
    setData([]);
    refetch();
    setPage(1);
    setIsMaxedOut(false);
  }, [isRunQueryTransaction]);

  const nextPage = () => {
    if (isMaxedOut) return;
    if (loading) return;
    setPage((p) => p + 1);
  };

  return {
    data,
    loading,
    setData,
    nextPage,
  };
};
