import React from "react";
import Skeleton from "react-loading-skeleton";
import { customDateFormatter } from "../../../components/tools";
import UpdateTracker from "../../../components/UpdateTracker/parent";
import { scheduledTextFormatter } from "../../../pageComponents/scheduledPaymentComponents/formatter";

import { useCrossBorderSingleWorkflow } from "../crossBorderSingleModal/hooks/workflow";
import { useSchedulePaymentTransaction } from "./hooks/schedulePayment";

export const InvoicePaymentStatusLine = ({
  transaction,
  loading = false,
  forSubmitPage = false,
  containerStyle,
}) => {
  const { state, updated_at, scheduled_payment } = transaction;
  const isScheduledTransaction = Boolean(scheduled_payment);
  const {
    firstLineObj,
    isDraft,
    rejection_note,
    approverLineArray,
    validatingObj,
    completedLineObj,
    isCompleted,
    isCancelled,
    isOnProgress,
    cancelledLineObj,
  } = useCrossBorderSingleWorkflow(transaction);

  const rejectLineObj = {
    ...cancelledLineObj,
    text: cancelledLineObj?.text?.replace("Canceled by", "Rejected by"),
  };
  const { name } = transaction?.local_recipient || {};

  const nameDecider = () => {
    if (name && name != "-") return name;
    return "recipients";
  };

  const isReimbursement = Boolean(transaction?.reimbursement_child);
  const isFailed = state === "declined";

  if (isDraft) return null;
  const isRejection = Boolean(
    transaction?.last_rejected_receipt_by || transaction?.rejected_by_id
  );

  const arrayDecider = () => {
    if (forSubmitPage) {
      return [
        firstLineObj,
        {
          text: isReimbursement
            ? `Waiting review from Any Admin`
            : `Waiting approval from Any Admin`,
          isActive: true,
        },
        {
          text: "Waiting payment from Any Admin",
        },
        {
          text: "Sending money to recipient",
        },
      ];
    }
    if (isCompleted) {
      return [firstLineObj, ...approverLineArray, ...completedLineObj];
    }
    if (isCancelled) {
      if (isRejection) return [firstLineObj, rejectLineObj];
      return [firstLineObj, cancelledLineObj];
    }
    if (isFailed) {
      return [
        firstLineObj,
        ...approverLineArray,
        {
          ...validatingObj,
          text: `Transferring money to ${nameDecider()}`,
          isActive: true,
          date: customDateFormatter(updated_at),
        },
        {
          // text: "Transaction failed. We will return the money to your company's main balance",
          // isRedButton: true,
          text: "Transaction failed",
          isActive: true,
          isFailed: true,
          reason:
            rejection_note || "Something's gone wrong. Contact us for details.",
          date: customDateFormatter(updated_at),
        },
      ];
    }
    return [
      firstLineObj,
      ...approverLineArray,
      {
        ...validatingObj,
        text: `Sending money to recipient(s)`,
      },
    ];
  };
  const scheduledArrayDecider = () => {
    const { isScheduled, isPaymentDelayed, scheduledLineArray, queuedObj } =
      useSchedulePaymentTransaction(transaction, approverLineArray);

    if (isScheduled) return [firstLineObj, ...scheduledLineArray, queuedObj];
    if (isCompleted)
      return [firstLineObj, ...scheduledLineArray, ...completedLineObj];

    if (isOnProgress)
      return [
        firstLineObj,
        ...scheduledLineArray,
        {
          ...validatingObj,
          text: `Sending money to recipient(s)`,
        },
      ];
    return [firstLineObj, ...scheduledLineArray];
  };

  const updates = isScheduledTransaction
    ? scheduledArrayDecider()
    : arrayDecider();

  const rejectionNotes = transaction?.rejection_note;
  return (
    <div className="w-100" style={{ marginTop: 12, ...containerStyle }}>
      {loading ? (
        <div
          style={{
            marginLeft: 36,
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton width={150} />
            <Skeleton width={130} height={10} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
            <Skeleton width={240} />
            <Skeleton width={100} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Skeleton width={200} />
            <Skeleton width={130} />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton width={65} />
          </div>
        </div>
      ) : (
        // <StatusPayrollLine array={arrayDecider()} isModal />
        <UpdateTracker updates={updates} rejectionNote={rejectionNotes} />
      )}
    </div>
  );
};
