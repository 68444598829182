import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { TextInlineRegular } from "../../../../components/Text";
import { unformatCurrency } from "../../../../components/tools";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import {
  amountMissMatchChecker,
  createtaxDescription,
  totalAmountOnFields,
} from "../../../../pageComponents/invoiceComponents/createPage/modals/formLogic";
import { idrStringFormatter } from "../../../../pageComponents/invoiceComponents/data/formatter";
import { apiBusiness } from "../../../../tools/api";

export const createInvoiceSummaryForm = ({
  items,
  taxes,
  fee,
  tax,
  amount,
}) => {
  const formatItems = items
    ?.map(
      ({
        item_name,
        price_per_item: ppi,
        total_price: tp,
        item_quantity: qty,
        _destroy,
      }) => {
        return {
          item_name,
          price_per_item: unformatCurrency(ppi),
          total_price: unformatCurrency(tp),
          item_quantity: Number(qty),
          _destroy,
        };
      }
    )
    ?.filter(({ _destroy }) => !_destroy);

  const taxesWOdestroy = taxes?.filter((tax) => !tax?._destroy);
  const formatTaxes = useMemo(() => {
    return taxesWOdestroy?.map((tax, index) => {
      if (isEmpty(tax))
        return { tax_value: 0, fee_tax_name: "", tax_description: "" };
      const { fee_tax_name, taxAmount } = tax;

      const tax_description = createtaxDescription(tax);
      const tax_value = taxAmount ?? 0;
      return { tax_value, fee_tax_name, tax_description };
    });
  }, [taxes, amount]);

  const itemsSubtotal = Number(unformatCurrency(totalAmountOnFields(items)));
  const isMissmatch = Number(unformatCurrency(amount)) != itemsSubtotal;
  const summary = {
    transaction_items: formatItems,
    transaction_taxes: formatTaxes,
    item_subtotal: amount,
    tax_total: tax,
    amount: Number(amount) + Number(tax),
    fee,
    finalAmount: Number(amount) + Number(tax) + Number(fee),
    isMissmatch,
  };

  return summary;
};

export const formatTransactionItems = (items) =>
  items?.map(({ price_per_item: ppi, total_price: tp, id, ...rest }) => {
    const price_per_item = idrStringFormatter(ppi);
    const total_price = idrStringFormatter(tp);
    return {
      itemID: id,
      price_per_item,
      total_price,
      ...rest,
    };
  });

export const useSubmitInvoiceDetails = ({ url, afterSuccess }) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const { errorToasterApi } = ToasterHook();
  const submit = async (val) => {
    try {
      setUpdateLoading(true);
      const {
        amount: amountRaw,
        transaction_items_attributes: ti_raw,
        transaction_taxes_attributes: tt_raw,
        initial_amount,
        notes_from_editing,
      } = val;

      const amount = Number(unformatCurrency(amountRaw));
      const transaction_items_attributes =
        formatTransactionItemsForSubmit(ti_raw);

      const transaction_taxes_attributes = formatTaxesForSubmit(tt_raw);

      const mainPayload = {
        transaction_items_attributes,
        transaction_taxes_attributes,
        notes_from_editing,
      };

      const updateInvoice = async (data) => await apiBusiness.put(url, data);

      //no need to send amount if initial amount equal to amount
      const isAmountMissmatch = amountMissMatchChecker(
        transaction_items_attributes,
        amount
      );

      if (Number(amount) != initial_amount || isAmountMissmatch)
        mainPayload.amount = Number(amount);

      const { data } = await updateInvoice(mainPayload);
      // eventsTracker("edit_invoice_page_save_edited_invoice");
      // await refetchMain();
      afterSuccess && afterSuccess(data?.data);
    } catch (error) {
      setUpdateLoading(false);
      errorToasterApi(error);
      console.log(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  return { submit, loading: updateLoading };
};

export const customNoteLabel = () => {
  return (
    <>
      Notes on editing{" "}
      <TextInlineRegular style={{ color: "#909098" }}>
        (optional)
      </TextInlineRegular>
    </>
  );
};

export const formatTransactionItemsForSubmit = (ti_raw) =>
  ti_raw
    ?.map(
      ({
        itemID: raw_id,
        item_quantity: quantity,
        price_per_item: price,
        total_price: totalPrice,
        item_name,
        _destroy: destroy,
      }) => {
        const newVal = {
          id: typeof raw_id == "number" ? raw_id : null,
          item_quantity: Number(quantity),
          price_per_item: Number(unformatCurrency(price)),
          total_price: Number(unformatCurrency(totalPrice)),
          _destroy: destroy ?? false,
          item_name,
        };

        return newVal;
      }
    )
    ?.filter(({ id, _destroy }) => {
      const idTypeStr = typeof id == "string";
      const isDestroy = _destroy == true;

      if (idTypeStr && isDestroy) return false;
      return true;
    });

export const formatTaxesForSubmit = (tt_raw) =>
  tt_raw
    ?.map((tax) => {
      const idTypeInt = typeof tax?.id == "number";
      const idTypeStr = typeof tax?.id == "string";
      const isEdited = tax?.isEdited;
      const destroyTrue = tax?._destroy;
      if (idTypeInt && !destroyTrue && !isEdited) return {};
      if (idTypeStr && destroyTrue) return {};

      const {
        fee_tax_type,
        fee_tax_name,
        fee_tax_element,
        add_or_deduct,
        calculate_from,
        fee_tax_value: taxVal,
        _destroy,
      } = tax;

      const fee_tax_value =
        typeof taxVal == "number" ? taxVal : Number(unformatCurrency(taxVal));
      const payloadTax = {
        fee_tax_type,
        fee_tax_name,
        fee_tax_element,
        add_or_deduct,
        calculate_from,
        fee_tax_value,
        _destroy: _destroy ?? false,
      };
      if (idTypeInt) payloadTax.id = tax?.id;
      return payloadTax;
    })
    ?.filter((item) => !isEmpty(item));
