import { isEmpty } from "lodash";
import { Fragment, useMemo, useState } from "react";
import { Modal } from "reactstrap";
import styled, { keyframes } from "styled-components";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../components/Avatar";
import { Banner } from "../../../../components/Banner";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { DetailsModal } from "../../../../modals/smartActivityModal/crossBorderSingleModal/components";
import { createInvoiceSummary } from "../../../../modals/smartActivityModal/invoicePayment/hooks/logics";
import {
  billPaymentDetailDecider,
  getTotalAmountSummary,
  pluralize,
} from "../../data/formatter";
import { CloseButton } from "../uploadPage";
import {
  DuplicateToolTip,
  InvoiceFormSummary,
} from "./formComponents/component";

export const InvoiceSummaryRow = ({
  recipient,
  amount,
  scrollAble,
  isDuplicate,
}) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center w-100"
      style={{ height: 24 }}
    >
      <div className="d-flex align-items-center">
        <Avatar
          size="small"
          mainIcon={
            <JackIcons
              name="invoice_payment"
              style={{ width: 14, height: 17.5 }}
            />
          }
        />
        <JackIcons name="invoice_payment" style={{ width: 14, height: 17.5 }} />
        <GothamRegular
          style={{
            fontSize: 12,
            color: colors.grey6c,
            width: 220,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            gap: 8,
          }}
        >
          Bill payment to {recipient}
          <DuplicateToolTip isDuplicate={isDuplicate} />
        </GothamRegular>
      </div>
      <GothamRegular
        style={{
          fontSize: 12,
          color: jackColors.neutral900,
          marginRight: scrollAble && 8,
        }}
      >
        {amount}
      </GothamRegular>
    </div>
  );
};

export const InvoiceLeftRightSummary = ({
  containerStyle,
  data = [],
  scrollAble = false,
  fee,
  hideFee = false,
  finalAmountText = "Final amount",
  FeeComponent = null,
  SummaryRowComponent = null,
  totalAmount: totalAmountProp = null,
  totalAmountString: totalAmountStringProp = null,
  allTotalAmountFee: allTotalAmountFeeProp = null,
}) => {
  const { totalAmount, totalAmountString } = getTotalAmountSummary(data);
  const allTotalAmountFee = allTotalAmountFeeProp ?? fee * data?.length;
  const totalAmountFee = `IDR ${formatCurrencyNoDecimal(allTotalAmountFee)}`;
  const feeFormatted = `IDR ${formatCurrencyNoDecimal(fee)}`;
  const totalAmountInvoice = `IDR ${formatCurrencyNoDecimal(
    Number(totalAmountProp ?? totalAmount) +
      Number(hideFee ? 0 : allTotalAmountFee)
  )}`;
  const [long, setLong] = useState(false);
  const shortData = data?.slice(0, 5);
  const displayData = long || scrollAble ? data : shortData;

  const low = heightCalc(shortData);
  const tall = heightCalc(data);
  const notZeroAmount = !!totalAmount || !!totalAmountProp;
  const transactionCardContainerStyle = notZeroAmount
    ? { borderBottom: "1px solid #E6E6E8", padding: "0px 0px 19px 0px" }
    : {};

  return (
    <div style={{ padding: "16px 20px 32px 20px", ...containerStyle }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: notZeroAmount ? 16 : 0,
          padding: 12,
          border: "1px solid #E6E6E8",
          borderRadius: 4,
        }}
      >
        {/* <div>
          <div className="d-flex justify-content-between">
            <GothamMedium>Total amount</GothamMedium>
            <GothamMedium>{totalAmountString}</GothamMedium>
          </div>
          <GothamMedium>
            {totalAmountStringProp ?? totalAmountString}
          </GothamMedium>
        </div> */}
        <div style={transactionCardContainerStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
              height: long ? tall : low,
              overflowY: scrollAble && "auto",
              transition: "height 300ms ease-out",
            }}
          >
            {displayData?.map((item) => {
              const { billTo, totalAmount, duplicate } = item ?? {};

              return (
                <Fragment>
                  {!!SummaryRowComponent ? (
                    <SummaryRowComponent
                      key={item.id}
                      data={item}
                      scrollAble={scrollAble}
                    />
                  ) : (
                    <InvoiceSummaryRow
                      amount={totalAmount}
                      recipient={billTo}
                      scrollAble={scrollAble}
                      isDuplicate={duplicate}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
          {data?.length > 5 && !scrollAble && (
            <div style={{ height: 24, display: "flex", marginTop: 19 }}>
              <div
                style={{
                  width: 24,
                  height: 24,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  alignItems: "center",
                  marginRight: 14,
                  justifyContent: "center",
                  opacity: long ? 0 : 1,
                  transition: !long && "opacity 500ms ease-out",
                }}
              >
                <div
                  style={{
                    width: 4,
                    height: 4,
                    borderRadius: 100,
                    backgroundColor: jackColors.black34,
                  }}
                />
                <div
                  style={{
                    width: 4,
                    height: 4,
                    borderRadius: 100,
                    backgroundColor: jackColors.black34,
                  }}
                />
                <div
                  style={{
                    width: 4,
                    height: 4,
                    borderRadius: 100,
                    backgroundColor: jackColors.black34,
                  }}
                />
              </div>
              <GothamMedium
                className="font12"
                style={{
                  marginTop: 4,
                  textDecorationLine: "underline",
                  width: "auto",
                }}
              >
                {long
                  ? "See less"
                  : `+${data?.length - 5} more ${pluralize(
                      data?.length - 5,
                      "transaction"
                    )}`}
              </GothamMedium>
              {!long ? (
                <JackIcons
                  name="chevron_down"
                  fill="#343434"
                  style={{
                    height: 20,
                    width: "auto",
                    marginLeft: 8,
                    marginBottom: 6,
                    cursor: "pointer",
                  }}
                  onClick={() => setLong(true)}
                />
              ) : (
                <JackIcons
                  name="chevron_up"
                  fill="#343434"
                  style={{
                    height: 20,
                    width: "auto",
                    marginLeft: 8,
                    marginBottom: 6,
                    cursor: "pointer",
                  }}
                  onClick={() => setLong(false)}
                />
              )}
            </div>
          )}
        </div>
        <div>
          <div>
            {notZeroAmount && (
              <div className="d-flex justify-content-between">
                <GothamMedium>Total amount</GothamMedium>
                <GothamMedium>{totalAmountInvoice}</GothamMedium>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div>
          <div className="d-flex justify-content-between">
            <GothamMedium>Total fee</GothamMedium>
            <GothamMedium>{totalAmountFee}</GothamMedium>
          </div>
          {FeeComponent ?? (
            <div
              style={{
                padding: "16px 0px 16px 20px",
                display: "flex",
                gap: 16,
                borderBottom: "1px solid #E6E6E8",
              }}
            >
              <div className="d-flex justify-content-between align-items-center w-100">
                <GothamRegular style={{ fontSize: 12, color: colors.grey6c }}>
                  Handling fee
                </GothamRegular>
                <GothamRegular
                  style={{ fontSize: 12, color: jackColors.neutral900 }}
                >
                  {feeFormatted} x {data?.length}
                </GothamRegular>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
};

export const SingleRequestSummaryInvoice = ({
  containerStyle,
  data,
  fee,
  isPay = false,
}) => {
  const [invoiceDetail] = data;
  const { recipient_name } = invoiceDetail;
  // const { totalAmount, totalAmountString: totalAmountInvoice } =
  //   getTotalAmountSummary(data);
  // const totalAmountFee = `IDR ${formatCurrencyNoDecimal(Number(tax))}`;
  // const grandTotal = `IDR ${formatCurrencyNoDecimal(
  //   Number(total_amount) + Number(tax)
  // )}`;

  const detailArray = billPaymentDetailDecider(invoiceDetail, isPay);
  return (
    <div style={{ ...containerStyle, width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 16,
          border: "1px solid #E6E6E8",
          borderRadius: 4,
        }}
      >
        <div className="d-flex align-items-center">
          <Avatar
            style={{ marginRight: 8 }}
            size="medium"
            mainIcon={
              <JackIcons
                name="invoice_payment"
                style={{ width: 20, height: "auto" }}
              />
            }
          />
          <div className="d-flex-column justify-content-between">
            <GothamMedium className="font12">Bill Payment</GothamMedium>
            <GothamRegular
              className="font12"
              style={{ color: colors.grey90, lineHeight: "16px" }}
            >
              Transfer to {recipient_name}
            </GothamRegular>
          </div>
        </div>
        {/* <div className="d-flex justify-content-between w-100">
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            Subtotal
          </GothamRegular>
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            {totalAmountInvoice}
          </GothamRegular>
        </div>
        <div className="d-flex justify-content-between w-100">
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            VAT
          </GothamRegular>
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            {totalAmountFee || "IDR 0"}
          </GothamRegular>
        </div>
        <div
          style={{ width: "100%", height: 1, backgroundColor: colors.greye6 }}
        />
        <div className="d-flex justify-content-between w-100">
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            {isPay ? "Total payment" : "Invoice amount"}
          </GothamRegular>
          <GothamMedium
            className="font12"
            style={{ color: jackColors.neutral900 }}
          >
            {grandTotal}
          </GothamMedium>
        </div> */}
        <DetailsModal
          array={detailArray}
          containerStyle={{ border: "0px solid transparent", padding: 0 }}
        />
      </div>
    </div>
  );
};

export const SubmitConfirmModal = ({
  toggle,
  isOpen,
  data,
  onClickSubmit,
  loading,
  fee,
}) => {
  const { selectedData } = data;
  const { totalAmountString: totalAmountInvoice } =
    getTotalAmountSummary(selectedData);
  const { totalAmountString: totalAmountFee } = getTotalAmountSummary(
    selectedData,
    "fee"
  );
  const dataLength = selectedData?.length || 0;
  const isSingleRequest = selectedData?.length == 1 || false;

  const singleRequestData = (data) => {
    if (isEmpty(data)) return {};

    return createInvoiceSummary(data);
  };

  const isDuplicate = selectedData?.[0]?.duplicate;

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName="modal-transfez"
    >
      <div
        style={{
          width: 480,
          height: "auto",
          backgroundColor: "#FFFFFF",
          borderRadius: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 106,
            padding: "20px 20px 16px 20px",
            width: "100%",
            borderBottom: "1px solid #E6E6E8",
          }}
        >
          <div>
            <GothamMedium className="font20 mb-1">
              Submit {dataLength} {pluralize(dataLength, "Invoice")}?
            </GothamMedium>
            <GothamRegular style={{ color: colors.grey90 }}>
              Please make sure your invoice details are correct. You can’t{" "}
              <br /> make any further changes once it's submitted.
            </GothamRegular>
          </div>
          <CloseButton onClick={toggle} />
        </div>
        {isSingleRequest ? (
          <div
            style={{
              padding: "16px 20px 32px",
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
          >
            <InvoiceFormSummary
              hideFee
              data={singleRequestData(selectedData[0])}
              customHeader={
                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    gap: 8,
                    borderBottom: "1px solid #e6e6e8",
                    paddingBottom: 16,
                  }}
                >
                  <Avatar
                    mainIcon={<JackIcons name="invoice_payment" fill="#FFF" />}
                    size="medium"
                  />
                  <div className="d-flex-column justify-content-between">
                    <GothamMedium className="font12">Bill Payment</GothamMedium>
                    <GothamRegular
                      className="font12"
                      style={{ color: "#909098" }}
                    >
                      Transfer to {selectedData?.[0]?.recipient_name}
                    </GothamRegular>
                  </div>
                </div>
              }
            />
            {isDuplicate && (
              <Banner
                title="Duplicated Invoice"
                msg="You’ve decided to keep this invoice. The approver will be notified accordingly."
                type="warning"
              />
            )}
          </div>
        ) : (
          <InvoiceLeftRightSummary
            data={selectedData}
            scrollAble={true}
            fee={fee}
            hideFee
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 20,
            borderTop: "1px solid #E6E6E8",
          }}
        >
          <ButtonJack type="outline" style={{ width: "48%" }} onClick={toggle}>
            Cancel
          </ButtonJack>
          <ButtonJack
            style={{ width: "48%" }}
            onClick={onClickSubmit}
            isLoading={loading}
          >
            Submit
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

const heightCalc = (data) => {
  const componentHeight = data?.length * 24;
  const allGaps = (data?.length - 1) * 16;
  return componentHeight + allGaps;
};
